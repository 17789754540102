'use strict';

var cache = {};
var util = require('_core/util'),
    Promise = require('promise');

function initCache() {
    var currentForm = '',
        saveButton = '',
        emailField = '',
        phoneField = '';

    if ($('#RegistrationForm').length > 0) {
        currentForm = $('#RegistrationForm');
        saveButton = 'js-qas-validation';
        emailField = 'dwfrm_profile_customer_emailsingle';
        phoneField = 'dwfrm_profile_customer_phoneMobile';
    }
    else if ($('#edit-address-form').length > 0) {
        currentForm = $('#edit-address-form');
        saveButton = 'js-qas-validation';
        phoneField = 'dwfrm_profile_address_phone';
    }
    else if ($('#js-catalogue-request').length > 0) {
        currentForm = $('#js-catalogue-request');
        saveButton = 'js-qas-validation';
        phoneField = 'dwfrm_cataloguerequest_address_phone';
    }
    else if ($('#dwfrm_singleshipping_shippingAddress').length > 0) { //TODO: should be changed for checkout forms
        currentForm = $('#dwfrm_singleshipping_shippingAddress');
        saveButton = 'dwfrm_singleshipping_shippingAddress_save';
        phoneField = 'dwfrm_singleshipping_shippingAddress_addressFields_phone';
    }
    else if ($('#dwfrm_billing').length > 0) { //TODO: should be changed for checkout forms
        currentForm = $('#dwfrm_billing');
        saveButton = 'dwfrm_billing_save';
        phoneField = 'dwfrm_billing_billingAddress_addressFields_phone';
    }

    cache = {
        $save: saveButton,
        $form: currentForm,
        isAJAXinProgress: false,
        $emailField: emailField,
        $phoneField: phoneField,
        $url: Urls.qasVerifyEmailAndPhone,
        $emailErrorClass: "js-qas-email-error",
        $phoneErrorClass: "js-qas-phone-error"
    };
}

function scrollToErrors(isEmailErr, isPhoneErr) {

    var offset,
        emailField = $('input[name="' + cache.$emailField + '"]'),
        phoneField = $('input[name="' + cache.$phoneField + '"]');

    if (isEmailErr && isPhoneErr && emailField.length && phoneField.length) {
        offset = emailField.offset().top > phoneField.offset().top ? phoneField.offset().top : emailField.offset().top;
    } else if (isEmailErr && emailField.length) {
        offset = emailField.offset().top;
    } else if (isPhoneErr && phoneField.length) {
        offset = phoneField.offset().top;
    }

    if (offset && !isNaN(offset)) {
        util.scrollBrowser(parseInt(offset) - 25, 500, true);
    }
}

function showErrorMsg(className, msg, field) {

    if (!$("span." + className).length || !$("span." + className).is(":visible")) {
        $('<span/>').addClass(className).html(msg).appendTo( $('input[name="' + field + '"]').closest('.form-row') );

        // disable submit/next button
        $('.' + cache.$save).prop('disabled', true);
    }
}

function initEvents() {

    $(document).on('change, input', 'input[name="' + cache.$emailField + '"], input[name="' + cache.$phoneField + '"]', function() {
        var nameAttr = $(this).attr('name');
        var $emailError = $("." + cache.$emailErrorClass);
        var $phoneError = $("." + cache.$phoneErrorClass);

        if (nameAttr === cache.$emailField && $emailError.length) {
            $emailError.hide();
        } else if (nameAttr === cache.$phoneField && $phoneError.length) {
            $phoneError.hide();
        }

        if (($emailError.length && $emailError.is(":visible")) || ($phoneError.length && $phoneError.is(":visible"))) {
            $('.' + cache.$save).prop('disabled', true);
        } else {
            $('.' + cache.$save).prop('disabled', false);
        }
    });
}

function validation(data, url) {
    var promise = new Promise(function (resolve, reject) {

        // prevent repeated request
        if (cache.isAJAXinProgress) {
            return;
        }

        cache.isAJAXinProgress = true;
        $.ajax({
            type: 'POST',
            url: url,
            data: data,
            dataType: 'html'
        }).done( function(content) {
            var response;

            try {
                response = jQuery.parseJSON(content);
            } catch (e) {
                // continue submission of form
                qas.isAlreadyVerified = true;
                cache.isAJAXinProgress = false;
                resolve();
                return;
            }

            if (response.email === 'verified' && response.phone === 'verified') {
                resolve(response);
                qas.isAlreadyVerified = true;
            } else {
                var isEmailErr, isPhoneErr;
                if (response.email === 'error') {
                    showErrorMsg(cache.$emailErrorClass, Resources.QAS_ERROR_EMAIL, cache.$emailField);
                    isEmailErr = true;
                }

                if (response.phone === 'error') {
                    showErrorMsg(cache.$phoneErrorClass, Resources.QAS_ERROR_PHONE, cache.$phoneField);
                    isPhoneErr = true;
                }
                scrollToErrors(isEmailErr, isPhoneErr);
                reject(response);
            }
            cache.isAJAXinProgress = false;
        }).fail( function () {
            console.log('[ERROR] ajax request: ' + url);
            cache.isAJAXinProgress = false;
            resolve();
        });
    });
    return promise;
}

function setEmailAndPhoneFields() {

    var fields = '';
    if (cache.$emailField) {
        fields += '&email_field_name=' + cache.$emailField;
    }

    if (cache.$phoneField) {
        fields += '&phone_field_name=' + cache.$phoneField;
    }
    return fields;
}

var qas = {

    isAlreadyVerified: false,

    init: function () {
        var allowedLocale = (SitePreferences.QAS_LOCALES).find(function (obj) {
            return obj.locale === SitePreferences.LOCALE;
        });

        // initialize it only on allowed locales
        if (allowedLocale && ((SitePreferences.QAS_EMAIL_ENABLED && SitePreferences.QAS_EMAIL_TOKEN) || (SitePreferences.QAS_PHONE_ENABLED && SitePreferences.QAS_TOKEN))) {
            initCache();
            initEvents();
        }
    },

    isQASvalidationButton: function($this) {

        // prevent request if there is only phoneField AND phoneField is empty
        if (!cache.$emailField && cache.$phoneField && $('input[name="' + cache.$phoneField + '"]').length && $.trim($('input[name="' + cache.$phoneField + '"]').val()).length === 0) {
            return false;
        }

        return cache.$save && $this.hasClass(cache.$save);
    },

    validate: function($this) {
        if ($this.hasClass(cache.$save) && cache.$form.length > 0 && cache.$form.validate().form()) {
            var data = cache.$form.serialize() + setEmailAndPhoneFields();
            return validation(data, cache.$url);
        }
        return false;
    }
};

module.exports = qas;
