
'use strict';
/*
    Implementation of:
    PerimeterX Advanced Blocking Response
*/

var dialog = require('../dialog');
var JsonUtil = require('_core_ext/pages/json-util');

function showBlockedDialog (pxInfo) {
    if (!pxInfo || !pxInfo.blockScript) {
        return;
    }

    dialog.open({
        url: Urls.perimeterBlockedDialog,
        options: {
            height: 500,
            width: 400,
            title: Resources.PERIMETERX_MODAL_TITLE,
            closeOnEscape: false,
            dialogClass: 'perimeterx-modal',
            open: function () {
                window._pxAppId = pxInfo.appId;
                window._pxJsClientSrc = pxInfo.jsClientSrc;
                window._pxFirstPartyEnabled = pxInfo.firstPartyEnabled;
                window._pxVid = pxInfo.vid;
                window._pxUuid = pxInfo.uuid;
                window._pxHostUrl = pxInfo.hostUrl;

                var script = document.createElement('script');
                script.src = pxInfo.blockScript;
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        }
    });
}

function defaultCaptchaHandler (isValid) {
    if (isValid) {
        dialog.closeSafe();
    } else {
        window.location.reload();
    }
}

function initializeDOM () {
    window._pxOnCaptchaSuccess = defaultCaptchaHandler;

    $(document.body).on('custom-perimeter-modal', {}, function(event, pxInfo) {
        SitePreferences.PERIMETERX_ENABLED && showBlockedDialog(pxInfo);
    });
}

module.exports = {
    init: function() {
        if (SitePreferences.PERIMETERX_ENABLED) {
            initializeDOM();
        }
    },
    handleResponce: function(response) {
        var responseJSON = response.responseJSON || JsonUtil.parse(response.responseText || '{}', {});

        if (SitePreferences.PERIMETERX_ENABLED && responseJSON) {
            var json = JsonUtil.parse(responseJSON.pxInfo , {});

            if (json && json.blockScript) {
                $(document.body).trigger('custom-perimeter-modal', json);
                return true;
            }
        }
        return false;
    }
};