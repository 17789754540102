'use strict';

var _ = require('lodash');
var perimeterx = require('_core_ext/trackers/perimeterx');

var util = require('_core_ext/util'),
    layout = require('_core_ext/layout'),
    bonusProductsView = require('_core_ext/bonus-products/bonus-products-view'),
    JSConstants = require('_core_ext/pages/cart/constants'),
    quantityModule = require('_core_ext/pages/product/quantity');

var isAJAXinProgress = false;
var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};
var forbiddenPages = {
    scroll: ['wishlist', 'clp', 'product', 'search', 'storefront', 'orderconfirmation', 'storedetails'],
    slide: ['wishlist']
};

var minicart = {
    init: function () {
        this.$el = $(JSConstants.CLASS_HEADER_CART);
        this.$content = this.$el.find(JSConstants.CLASS_MINI_CART_CONTENT);

        if (layout.isDesktop()) {
            // events
            this.$el.find(JSConstants.CLASS_MINI_CART_TOTAL).on('mouseenter', function () {
                if (this.$content.not(':visible')) {
                    $(JSConstants.CLASS_MY_ACCOUNT_CONTENT).hide();
                    this.slide();
                }
            }.bind(this));

            this.$content.on('mouseenter', function () {
                timer.clear();
            }).on('mouseleave', function () {
                timer.clear();
                timer.start(30, this.close.bind(this));
            }.bind(this));

            // change quantity with ajax request
            $(document).on('input', JSConstants.CLASS_MINI_CART_PRODUCTS + ' ' + JSConstants.CLASS_QTY_CURRENT,
                _.debounce(updateProductQuantity, 1000)
            );

            /*
                Minicart q-ty elements disabled if R&C,
                hence no need to update them
            */
            if (!SiteDynamic.RESERVE_COLLECT_ENABLE) {
                quantityModule.availabilityQuantity(true);
            }
        }
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     */
    show: function (html) {
        this.$el.html(html);

        if (!layout.isMobile()) {
            this.init();
            if (!~forbiddenPages.slide.indexOf(pageContext.ns)) {
                this.slide();
            }
        }

        if (!~forbiddenPages.scroll.indexOf(pageContext.ns)) {
            util.scrollBrowser(0);
        }

        bonusProductsView.loadBonusOption();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(2000, this.close.bind(this));
    },
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay);
    },
    update: function (showOnLoad, isChangeQuantity, pid) {
        var that = this;
        var $request = $.ajax({
            type: 'GET',
            url: Urls.minicart,
            headers: {
                'X-PERIMETER-TYPE': 'json',
            }
        }).done(function (response) {

            if (isChangeQuantity && pid) {
                updateMiniCartData(response, pid);
            } else {
                $(JSConstants.CLASS_HEADER_CART).html(response);
            }

            that.init();
            bonusProductsView.loadBonusOption();

            if (SitePreferences.MONETATE_ENABLED) {
                window.initMonetateTracking();
            }
        }).fail(function(response) {
            perimeterx.handleResponce(response);

        }).always(function () {
            isAJAXinProgress = false;
        });

        !!showOnLoad && $request.done();
    }
};

function updateProductQuantity(event) {
    event.stopImmediatePropagation();
    var $this = $(this),
        $container = $this.closest(JSConstants.CLASS_QUANTITY);

    if (!$container.length || isAJAXinProgress) {
        return;
    }

    var $quantityInput = $container.find('input[type="number"]'),
        $csrfInput = $container.find('input[name="' + Constants.CSRF_TOKEN_NAME + '"]');

    var requestData = {},
        pid = $quantityInput.data('product-id'),
        url = $container.data('url'),
        quantity = parseInt($quantityInput.val()),
        csrfToken = $csrfInput.val(),
        csrfName = $csrfInput.attr('name');

    isAJAXinProgress = true;
    requestData[csrfName] = csrfToken;

    $.ajax({
        type: 'POST',
        url: util.appendParamsToUrl(url, {
            'Quantity': quantity,
            'countryCode': $('select[id$="_country"]').val(),
            'isMiniCart': true
        }),
        headers: {
            'X-PERIMETER-TYPE': 'json',
        },
        data: requestData
    }).done(function() {
        minicart.update(false, true, pid);

        // refresh the base cart block if it is Cart Page
        if (pageContext && pageContext.ns === 'cart') {
            var cartProduct = $(JSConstants.CLASS_CART_CONTENT + ' ' + JSConstants.CLASS_ITEM + ' ' + JSConstants.CLASS_QUANTITY + ' input[data-product-id=' + pid + ']');

            if (cartProduct.length) {
                cartProduct.val(quantity);
                cartProduct.trigger("change", true);
            } else {
                location.reload();
            }
        }
    }).fail(function(response) {
        perimeterx.handleResponce(response);

    }).always(function () {
        isAJAXinProgress = false;
    });
}

function updateMiniCartData(response, pid) {

    // try to get elements from response
    var $miniCartProductResponse = $(response).find(JSConstants.CLASS_MINI_CART_PRODUCT + ':has(input[data-product-id=' + pid + '])'),
        $miniCartDiscountsResponse = $(response).find(JSConstants.CLASS_MINI_CART_DISCOUNTS),
        $miniCartTotalsResponse = $(response).find(JSConstants.CLASS_MINI_CART_TOTALS),
        $miniCartHeaderResponse = $(response).find(JSConstants.CLASS_MINI_CART_HEADER),
        $miniCartTotalResponse = $(response).find(JSConstants.CLASS_MINI_CART_TOTAL);

    var $miniCartProduct = $(JSConstants.CLASS_MINI_CART + ' ' + JSConstants.CLASS_MINI_CART_PRODUCT + ':has(input[data-product-id=' + pid + '])'),
        $miniCartDiscounts = $(JSConstants.CLASS_MINI_CART + ' ' + JSConstants.CLASS_MINI_CART_DISCOUNTS),
        $miniCartTotals = $(JSConstants.CLASS_MINI_CART + ' ' + JSConstants.CLASS_MINI_CART_TOTALS),
        $miniCartHeader = $(JSConstants.CLASS_MINI_CART + ' ' + JSConstants.CLASS_MINI_CART_HEADER),
        $miniCartTotal = $(JSConstants.CLASS_MINI_CART + ' ' + JSConstants.CLASS_MINI_CART_TOTAL);

    // try to update elements from response and insert to minicart
    if (pid && $miniCartTotalResponse.length && $miniCartTotal.length && $miniCartTotalsResponse.length && $miniCartTotals.length && $miniCartProduct.length && $miniCartHeaderResponse.length && $miniCartHeader.length) {
        if ($miniCartProductResponse.length) {
            $miniCartProduct.html($miniCartProductResponse.html());
        } else {
            $miniCartProduct.remove();
        }
        $miniCartTotals.html($miniCartTotalsResponse.html());
        $miniCartHeader.html($miniCartHeaderResponse.html());
        $miniCartTotal.html($miniCartTotalResponse.html());

        // update approaching discounts if they exist
        if ($miniCartDiscountsResponse.length && $miniCartDiscounts.length) {
            $miniCartDiscounts.html($miniCartDiscountsResponse.html());
        }
    } else {
        $(JSConstants.CLASS_HEADER_CART).html(response);
        return;
    }

    // update elements below if they exist
    var $miniCartWrapper = $(response).find(JSConstants.CLASS_MINI_CART_CONTENT + ' ' + JSConstants.CLASS_MINI_CART_WRAPPER);
    if ($miniCartWrapper.length) {
        $(JSConstants.CLASS_MINI_CART + ' ' + JSConstants.CLASS_MINI_CART_CONTENT).prepend($miniCartWrapper);
    }

    var $errorMessage = $(response).find(JSConstants.CLASS_MINI_CART_ERROR);
    if ($errorMessage.length) {
        $(JSConstants.CLASS_MINI_CART).prepend($errorMessage);
    }

    var $bonusDiscount = $(response).find(JSConstants.CLASS_MINI_CART_BONUS_DISC);
    if ($bonusDiscount.length) {
        $(JSConstants.CLASS_MINI_CART).append($bonusDiscount);
    }
}
 
module.exports = minicart;