'use strict';

var initialized = false;

var layout = require('_core_ext/layout'),
    cssConstants = require('_core_ext/cssconstants'),
    productConstants = require('_core_ext/pages/product/constants');

function globalAccordion() {
    $(document).on('click', productConstants.CLASS_TAB_TITLE_MOBILE, function() {
        var $this = $(this);
        openAccordion(!$this.hasClass(cssConstants.OPEN), $this);
    });
}

function openAccordion(state, $acorHeader) {
    var cssOpen = cssConstants.OPEN,
        $desc = $acorHeader.next(productConstants.CLASS_TAB_DESCRIPTION);

    if (state) {
        $acorHeader.addClass(cssOpen);
        $desc.addClass(cssOpen);
    } else {
        $acorHeader.removeClass(cssOpen);
        $desc.removeClass(cssOpen);
    }
}

function initializeEvents() {
    globalAccordion();
}

function initializeDom() {
    $.each($(productConstants.CLASS_TAB_TITLE_MOBILE + '.' + cssConstants.OPEN), function(i, elem) {
        openAccordion(true, $(elem));
    });
}

module.exports = {
    'init' : function () {
        if (initialized) {
            return;
        }

        initializeEvents();
        initializeDom();
        initialized = true;
    }
};