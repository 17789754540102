'use strict';

var enquire = require('enquire.js');
var $cache = {},
    initialized = false,
    media;

media = {
    s: "screen and (max-width: 767px)",
    m: "screen and (min-width: 768px) and (max-width: 1199px) and (orientation: portrait)",
    e: "screen and (min-width: 768px) and (max-width: 1199px) and (orientation: landscape)",
    l: "screen and (min-width: 1200px)"
};

function initializeCache() {
    $cache.allResponsiveImages = $('.js-responsive_img');
    $cache.allResponsiveImagesPaths = $cache.allResponsiveImages.find('noscript');
}

function initializeEvents() {
    $(document).on('products.loaded', function() {
        initializeCache();
        screenDetection();
    })
}

function handlerFactory(mediaSize) {
    return {
        match : function() {
            $cache.allResponsiveImages.each(function() {
                var $this = $(this),
                    $imageData = $this.find('noscript'),
                    imgSrc = $imageData.data(mediaSize + '-src') || $imageData.data('default-src'),
                    imgAlt = $imageData.data('alt'),
                    imgTitle = $imageData.data('title'),
                    imgClass = $imageData.data('cssclass');

                if ($this.has('img').length) {
                    $this.find('img').attr({
                        src : imgSrc,
                        alt : imgAlt,
                        title : imgTitle,
                        class : imgClass
                    });
                } else {
                    $this.append('<img class="' + imgClass + '" src="' + imgSrc + '" alt="' + imgAlt + '" title="' + imgTitle + '" />');
                }
            });
        }
    };
}

function screenDetection() {
    enquire
        .register(media.s, handlerFactory('s'))
        .register(media.m, handlerFactory('m'))
        .register(media.e, handlerFactory('e'))
        .register(media.l, handlerFactory('l'));
    $(document).trigger('enquire.done', '.js-hero-carousel');
}

module.exports = {
    'init' : function () {
        if (initialized) {
            return;
        }
        initializeCache();
        initializeEvents();
        screenDetection();
        initialized = true;
    }
};
