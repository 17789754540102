'use strict';

var jsonUtil = require('_core_ext/pages/json-util');
var JSConstants = require('_core_ext/pages/product/constants'),
    CSSConstants = require('_core_ext/cssconstants');

function increaseQuantity($container) {
    var $input = $container.find(JSConstants.CLASS_QTY_CURRENT),
        $decButton = $container.find(JSConstants.CLASS_DECR),
        $incButton = $container.find(JSConstants.CLASS_INC),
        currentVal = $input.val();

    if (currentVal) {
        currentVal = parseInt(currentVal);
        if (!isNaN(currentVal)) {
            var available = $input.data('available');
            if (available > currentVal) {
                $input.val(currentVal + 1);
                $decButton.removeClass(CSSConstants.DISABLED);
            }
            if (available <= currentVal + 1) {
                $incButton.addClass(CSSConstants.DISABLED);
            }
        } else {
            $input.val($input.data('minquantity') || 1);
            $decButton.addClass(CSSConstants.DISABLED);
        }
    }
}

function decreaseQuantity($container) {
    var $input = $container.find(JSConstants.CLASS_QTY_CURRENT),
        $decButton = $container.find(JSConstants.CLASS_DECR),
        $incButton = $container.find(JSConstants.CLASS_INC),
        currentVal = parseInt($input.val() || 0),
        context = $input.data('context') || 'pdp',
        minQuantity = parseInt($input.data('minquantity') || 1);

    if (currentVal) {
        if (context !== 'pdp' && currentVal === minQuantity) {
            $input.val(0);
            $decButton.addClass(CSSConstants.DISABLED);
        } else {
            $input.val(currentVal - 1);
            if (currentVal - 1 === minQuantity) {
                $decButton.addClass(CSSConstants.DISABLED);
            } else {
                $incButton.removeClass(CSSConstants.DISABLED);
            }
        }
    }
}

function disableControlls($this) {
    $this.find(JSConstants.CLASS_QTY_CURRENT).prop('disabled', true);
    $this.find(JSConstants.CLASS_DECR).addClass(CSSConstants.DISABLED);
    $this.find(JSConstants.CLASS_INC).addClass(CSSConstants.DISABLED);
}

function enableControlls($this) {
    $this.find(JSConstants.CLASS_QTY_CURRENT).removeAttr('disabled');
    $this.find(JSConstants.CLASS_DECR).removeClass(CSSConstants.DISABLED);
    $this.find(JSConstants.CLASS_INC).removeClass(CSSConstants.DISABLED);
}

var handleAvailability = function($qtyCMP, options) {
    var showError = options.showError,
        recoveryData = options.recoveryData,
        recoverError = options.recoverError;

    var $this = $qtyCMP,
        $input = $this.find(JSConstants.CLASS_QTY_CURRENT),
        $quantityBlock = $this.parents(JSConstants.CLASS_QTY_BLOCK),
        $quantityError = $quantityBlock.find(JSConstants.CLASS_QTY_ERROR);

    var availableCount = parseInt($input.data('available')),
        currentQty = parseInt($input.val()),
        minQuantity = parseInt($input.data('minquantity') || 1),
        context = $input.data('context') || 'pdp',
        isMinicart = context === 'minicart',
        isCartPage = pageContext.ns === 'cart' || context === 'cart';

    var $initError = $quantityBlock.find(JSConstants.CLASS_QTY_INIT_ERROR);

    var dataIsAdjustedPliEml = $quantityBlock.find('[data-is-adjusted-pli]');
    var isAdjustedPli = dataIsAdjustedPliEml.length ? !!dataIsAdjustedPliEml.data('is-adjusted-pli') : true;
    $quantityError.hide();

    if (!isAdjustedPli && isCartPage) {
        enableControlls($this);
    }

    if (currentQty >= availableCount) {
        // show warning/error image in minicart
        var $warnSign = $quantityBlock.find('.js_minicart_quantity_warn');
        $this.find(JSConstants.CLASS_INC).addClass(CSSConstants.DISABLED);

        if (isMinicart && $warnSign.length && $warnSign.not(':visible')) {
            $warnSign.show();
        } else {
            var limitMessage = $input.data('limit-message');
            var blockOverwrite = $initError.data('block-overwrite');

            if (
                !blockOverwrite && $quantityError.length &&
                limitMessage && !$initError.length
            ) {
                if (showError) {
                    $quantityError.show().text(limitMessage);

                } else if (recoverError && recoveryData) {
                    recoredProductQtyBlock($this, recoveryData);
                }
            }
        }

        if (!isAdjustedPli && isCartPage) {
            disableControlls($this);
        }
    }

    if (!isMinicart) {
        if (isCartPage) {
            highlightQuantityFields($this);
        } else if (currentQty === minQuantity) {
            $this.find(JSConstants.CLASS_DECR).addClass(CSSConstants.DISABLED);
        }
    }
};

var handleQtyChange = function ($qtyInput) {
    var $this = $qtyInput,
        isReadonly = $this.attr('readonly');
    if (isReadonly) { return false; }

    var available = +$this.data('available'),
        minQuantity = +($this.data('minquantity') || 1),
        val = parseInt($this.val()),
        context = $this.data('context') || 'pdp';

    var $qtyCMP = $this.closest(JSConstants.CLASS_QTY_CMP);
    var $plusSign = $qtyCMP.find(JSConstants.CLASS_DECR);
    var $minusSign = $qtyCMP.find(JSConstants.CLASS_INC);

    if (!val && val !== 0) {
        if (context === 'pdp') {
            $this.val(minQuantity);
        }
    } else if (val >= available) {
        $this.val(available);
    } else if (val <= 0) {
        $this.val((pageContext.ns === 'cart' || context === 'cart') ? 0 : minQuantity);
    } else if (val < minQuantity) {
        $this.val(minQuantity);
    }

    $minusSign.removeClass(CSSConstants.DISABLED);
    $plusSign.removeClass(CSSConstants.DISABLED);

    handleAvailability($qtyCMP, {
        showError: true
    });
};

function availabilityQuantity(showError, recoverError) {
    var prevErrors = recoverError
        ? jsonUtil.parse(localStorage.getItem(JSConstants.LS_KEY_PRODUCTS_ERRORS), {})
        : {};

    $(JSConstants.CLASS_QTY_CMP + ':not([data-disabled=true])').each(function() {
        var $qtyCmp = $(this);
        var productId = $qtyCmp.find(JSConstants.CLASS_QTY_CURRENT).data('product-id');

        if (recoverError && productId in prevErrors) {
            var recoveryData = prevErrors[productId];
        }

        handleAvailability($qtyCmp, {
            showError: !!showError,
            recoverError: !!recoverError,
            recoveryData: recoveryData
        });
    });
}

function recoredProductQtyBlock($qtyCMP, recoveryData) {
    var recoveryStatus = false;
    var $quantityBlock = $qtyCMP.parents(JSConstants.CLASS_QTY_BLOCK),
        $quantityError = $quantityBlock.find(JSConstants.CLASS_QTY_ERROR);

    if (recoveryData.prevError && recoveryData.prevError.length) {
        recoveryStatus = true;
        $quantityError.show().text(recoveryData.prevError);
        highlightQuantityFields($qtyCMP);
    }

    return recoveryStatus;
}

// highlight quantity field for product if there is warning or error
function highlightQuantityFields($wrapper) {
    var productLiBlock = $wrapper.parents(JSConstants.CLASS_ITEM);

    if (productLiBlock.length) {
        var $errorMsg = productLiBlock.find(JSConstants.CLASS_QTY_ERROR);
        var $errorInitMsg = productLiBlock.find(JSConstants.CLASS_QTY_INIT_ERROR);

        var errorVisible = $errorMsg.length && $errorMsg.is(':visible');
        var initErrorVisible = $errorInitMsg.length && $errorInitMsg.is(':visible');

        if (errorVisible || initErrorVisible) {
            $wrapper.addClass(CSSConstants.HIGHLIGHT);
        } else {
            $wrapper.removeClass(CSSConstants.HIGHLIGHT);
        }
    }
}

function initializeEvents() {
    $(document)
        .on('click', JSConstants.CLASS_DECR + ',' + JSConstants.CLASS_INC, function() {
            var $this = $(this),
                $container = $this.closest(JSConstants.CLASS_QTY_CMP);

            if ($this.hasClass(CSSConstants.DISABLED)) { return false; }

            if ($this.hasClass(JSConstants.CLASS_DECR.substring(1))) {
                decreaseQuantity($container);
            } else if ($this.hasClass(JSConstants.CLASS_INC.substring(1))) {
                increaseQuantity($container);
            }

            $this.siblings(JSConstants.CLASS_QTY_CURRENT)
                .trigger('input');
        })
        .on('input', JSConstants.CLASS_QTY_CURRENT, function() {
            handleQtyChange($(this));
        });
}

var quantity = {
    init: function() {
        initializeEvents();
        localStorage.removeItem(JSConstants.LS_KEY_PRODUCTS_ERRORS);

        if (SiteDynamic.RESERVE_COLLECT_ENABLE) {
            availabilityQuantity(false);
        }
    },
    availabilityQuantity: availabilityQuantity,
    handleQtyChange: handleQtyChange
};

module.exports = quantity;
