'use strict';

var dialog = require('_core_ext/dialog');
var JsBarcode = require('jsbarcode');
var util = require('_core_ext/util');
var COOKIE = {
    LIFETIME: 90,
    NAME: 'voucherPopupHide'
};

function showVouchersTooltip() {
    var $openDialogButton = $('.js-vouchers-tooltip');

    $openDialogButton.on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                dialogClass: 'm-vouchers m-hide_close'
            },
            callback: function () {
                $('.js-vouchers-tooltip-close').one('click', function () {
                    dialog.close();
                });
            }
        });
    });
}

function showCopyVoucherCodeDialog() {
    var $openDialogButton = $('.js-voucher-copy-open');

    $openDialogButton.on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(this).data('href'),
            options: {
                dialogClass: 'm-vouchers'
            },
            callback: function () {
                $('.js-voucher-copy-close').one('click', function () {
                    dialog.close();
                });
                $('.js-voucher-copy-code').on('click', function () {
                    var $self = $(this);
                    copyVoucherToClipboard();
                    $self.attr('disabled', 'disabled').addClass('m-copied');
                    $('.js-voucher-copy-message').removeClass('hidden');
                    setTimeout(function () {
                        $self.removeAttr('disabled').removeClass('m-copied');
                    }, 5000);
                });
            }
        });
    });
}

function copyVoucherToClipboard() {
    var $codeField = $('#voucher-code-field');

    navigator.clipboard.writeText($codeField.val());
}

function showBarcodeDialog() {
    var $openDialogButton = $('.js-voucher-barcode-open');

    $openDialogButton.on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(this).data('href'),
            options: {
                dialogClass: 'm-vouchers m-hide_close'
            },
            callback: function () {
                JsBarcode(".js-voucher-barcode").init();

                $('.js-voucher-barcode-close').one('click', function () {
                    dialog.close();
                });
            }
        });
    });
}

function showTermsAndConditionsDialog() {
    var $openDialogButton = $('.js-vouchers-terms-and-conditions');

    $openDialogButton.on('click', function (e) {
        e.preventDefault();

        var terms = $(this).data('terms');

        dialog.open({
            url: $(this).attr('href'),
            options: {
                dialogClass: 'm-vouchers m-hide_close'
            },
            callback: function () {
                var $content = $('.js-vouchers-terms-content');

                $content.html(terms);
                $('.js-vouchers-terms-close').one('click', function () {
                    dialog.close();
                });
            }
        });
    });
}

function showFirstVisitVoucherDialog() {
    if ($('.js-vouchers-page').length > 0 && !util.getCookie(COOKIE.NAME)) {
        dialog.open({
            url: Urls.vouchersFirstVisitDialog,
            options: {
                dialogClass: 'm-vouchers m-hide_close'
            },
            callback: function () {
                $('.js-vouchers-info-close').one('click', function () {
                    dialog.close();
                });
            }
        });

        util.setCookie(COOKIE.NAME, true, COOKIE.LIFETIME);
    }
}

function initializeEvents() {
    showFirstVisitVoucherDialog();
    showVouchersTooltip();
    showCopyVoucherCodeDialog();
    showBarcodeDialog();
    showTermsAndConditionsDialog();
}

var vouchers = {
    init: function () {
        initializeEvents();
    }
};

module.exports = vouchers;
