'use strict';

var perimeterx = require('_core_ext/trackers/perimeterx'),
    util = require('_core_ext/util');

var getJson = function (options) {
    options.url = util.toAbsoluteUrl(options.url);
    // make the server call
    return $.ajax({
        dataType: (typeof options.dataType === 'undefined' || options.dataType === null) ? 'json' : options.dataType,
        url: options.url,
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {}
    })
    // success
        .done(function (response) {
            if (options.callback) {
                options.callback(response);
            }
        })
    // failed
        .fail(function (response, textStatus) {
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            }
            if (options.callback) {
                options.callback(null);
            }
            perimeterx.handleResponce(response);
        });
};

module.exports = {
    getJson: getJson
};
