/*eslint no-console: [0] */

/** */
var metricMethod = 'performance' in window && 'now' in window.performance ? performance : Date;

/** */
var stats = {};

/** Internal storage of components initialization status mapping */
var statusRegister = {
    'initialized' : [],
    'disabled' : [],
    'skipped' : []
};

/**
 * Util methods namespace which are specific for this module
 * @namespace
 */
var utils = {
    /**
     * The generic util method for exceptions handling
     *
     * @param {Error} error The native instance of trown Error
     * @param {String} message Custom error message
     * @returns {void}
     */
    'handleExceptions' : function (error, message) {
        !!message && console.warn(message);
        console.error(error);
    },

    /**
     * Rigister the status of initialization process for
     * the specific component module
     *
     * @param {String} name The module name
     * @param {'initialized'|'disabled'|'skipped'} status The status flag for grouping
     * @returns {void}
     */
    'setInitStatus' : function (name, status) {
        if (!name || 'undefined' === statusRegister[status]) {
            return;
        }

        statusRegister[status].push(name);
        status === 'initialized' && this.setExecutionData(name);
    },

    /**
     * Setting the cached execution data to the internal "stats" object
     * @param {String} name The name of the component
     * @returns {void}
     */
    'setExecutionData' : function (name) {
        if (!stats._cache) {
            return;
        }

        stats[name] = {
            'executionTime' : stats._cache
        };

        delete stats._cache;
    },

    /**
     * Getting the all status register contents
     * @returns {Object}
     */
    'getInitStatuses' : function () {
        return statusRegister;
    },

    /**
     * Collecting execution data for the passed method
     * which will be executed with given arguments
     * @param {Funtion} method Function which excution data should be collection
     * @param {Object} context The context object for passed function execution
     * @param {Array} args Arguments for the passed function
     */
    'collectExecutionData' : function (method, context, args) {
        var start = metricMethod.now();

        method.call(context || window, args);

        stats._cache = +(metricMethod.now() - start).toFixed(3);
    },

    /**
     * Exposing in browser console the register contents using console methods
     * @returns {void}
     */
    'exposeStatuses' : function (namespace, pageName) {
        var page = require('_core_ext/page');
        namespace = namespace || page.ns;
        pageName = pageName || page.page;

        console.group('The "%s" Namespace "%s" Page Initialization', namespace, pageName || 'default');

        for (var status in statusRegister) {
            if (!statusRegister[status].length) {
                continue;
            }
            console.debug('%s components %o', status, statusRegister[status]);
        }

        this.exposeStatistic();
        console.groupEnd();
    },

    /**
     * Exposing the collection execution data for each component
     * @returns {void}
     */
    'exposeStatistic' : function () {
        console.groupCollapsed('Components Initialization Statistic');
        console['table' in console ? 'table' : 'dir'](stats);
        console.groupEnd();
    }

};

module.exports = utils;