'use strict';

var util = require('_core_ext/util'),
    layout = require('_core_ext/layout'),
    CSSConstants = require('_core_ext/cssconstants'),
    JSCommonConstants = require('_core_ext/constants');

var COOKIE = {
    LIFETIME: 60,
    NAME: 'own_hideHeaderTopPromo'
};

var $cache = {
    window: $(window),
    searchBlock: $(JSCommonConstants.CLASS_HEADER_SEARCH),
    header: $(JSCommonConstants.CLASS_STICKY_HEADER_MOBILE),
    searchTimer: 400,
    searchOffsetTop: 25,
    searchOffsetBack: 10
};

var $promo = {
    promotion: $(JSCommonConstants.CLASS_HEADER_TOP_PROMO),
    promoMock: $(JSCommonConstants.CLASS_HEADER_TOP_MOCK)
};

function showHeaderTopPromo (state) {
    var $openBtn = $(JSCommonConstants.CLASS_HEADER_TOP_PROMO_OPEN);

    if ($promo.promotion.length && $openBtn.length && $promo.promoMock.length) {
        var hideClass = CSSConstants.HIDDEN;
        util.setCookie(COOKIE.NAME, JSON.stringify(state), COOKIE.LIFETIME);

        if (state) {
            $promo.promoMock.removeClass(hideClass);
            $promo.promotion.removeClass(hideClass);
            $openBtn.addClass(hideClass);
        } else {
            $promo.promoMock.addClass(hideClass);
            $promo.promotion.addClass(hideClass);
            $openBtn.removeClass(hideClass);
        }
    }
}

function calculateHeaderHeight() {
    var headerHeight = $cache.header.outerHeight();

    $('body').attr('style', '--header-height: ' + headerHeight + 'px');
}

function initializeEvents() {
    $(document)
        .on('click', JSCommonConstants.CLASS_HEADER_TOP_PROMO_CLOSE, function() {
            showHeaderTopPromo(false);
        })
        .on('click', JSCommonConstants.CLASS_HEADER_TOP_PROMO_OPEN, function() {
            showHeaderTopPromo(true);
        })
        .on('window.modechanged', calculateHeaderHeight);
}

function initializeDom() {
    var state = util.getCookie(COOKIE.NAME);

    if (state == null || JSON.parse(state) === true) {
        showHeaderTopPromo(true);
    } else {
        showHeaderTopPromo(false);
    }
}

module.exports = {
    'init' : function () {
        initializeDom();
        initializeEvents();
        calculateHeaderHeight();
    }
};
