'use-strict';
var jsonUtil = require('../pages/json-util');

var utils = {
    getRegionByLocale: function (locale) {
        var region = jsonUtil.parse(SitePreferences.GOOGLE_MAPS_REGION);
        region = region[locale] || 'uk';
        return region;
    },
    compareDistance: function (a, b) {
        if (parseFloat(a.distance) < parseFloat(b.distance)) {
            return -1;
        }
        if (parseFloat(a.distance) > parseFloat(b.distance)) {
            return 1;
        }
        return 0;
    },
    isEmpty: function (value) {
        return (value === null || (typeof value === 'undefined') || value.length === 0 || value === false);
    },
    getStoreListTemplateObject: function (stores) {
        return {stores: stores};
    },
    paginateStores: function (stores, offsetSize) {
        var paginatedStores = [];
        for (var i = 0; i < stores.length; i += offsetSize) {
            paginatedStores.push(stores.slice(i, i + offsetSize));
        }
        return paginatedStores;
    },
};


module.exports = utils;
