module.exports = {
    CLASS_BODY: 'body',
    CLASS_MAIN: '.js-main',
    CLASS_CAROUSEL: '.js-carousel',
    CLASS_PDP_CONTENT: '.js-pdp-main',
    CLASS_MAIN_IMAGE: '.js-primary-image',
    CLASS_MAIN_IMAGE_LINK: '.js-main-image',
    CLASS_MAIN_IMAGE_WRAPPER: '.js-image-wrapper',
    CLASS_MAIN_CAROUSEL: '.js-main-carousel',
    CLASS_PLAYING_VIDEO: 'js-video-playing',
    CLASS_VIDEO_IFRAME_OVERLAY: '.js-video-overlay',
    CLASS_VIDEO_PLACEHOLDER: '.js-vimeo-placeholder',
    CLASS_ALT_CAROUSEL: '.js-alt-carousel',
    CLASS_PRODUCT_DATA: '.js-product-data',
    CLASS_SWATCH: '.js-swatch-item',
    CLASS_VARIATION_LINK: '.js-variation-link',
    CLASS_VARIATION_SELECT: '.js-variation-select',
    CLASS_ADD_TO_WISHLIST: '.js-add-wishlist',
    CLASS_GO_TO_WISHLIST: '.js-goto-wishlist',
    ID_VIDEO_PLAYER: '#js-videoPlayer',
    CLASS_TAB_TITLE: '.js-tab-title',
    CLASS_TAB_TITLE_MOBILE: '.js-tab-mobile-title',
    CLASS_SINGLE_TAB: '.js-tab',
    CLASS_TAB_DESCRIPTION: '.js-tab-description',
    CLASS_MINI_CART_PRODUCT: '.js-mini-cart_product',
    CLASS_PROMO_BUTTON: '.js-promo-button',
    CLASS_PROMO_MODAL: '.js-promo-item-modal',
    CLASS_BACK_IN_STOCK_MODAL: 'js-back-in-stock-modal',
    CLASS_PROF_PRICE_POPUP: '.js-delivery_tooltip',
    CLASS_PROF_PROMO_MESSAGE: '.js-profpromo-data',

    CLASS_QTY_CMP: '.js-quantity-cmp',
    CLASS_QTY_BLOCK: '.js-quantity-block',
    CLASS_QTY_CURRENT: '.js_qty-current',
    CLASS_QTY_ERROR: '.js-quantity_error',
    CLASS_QTY_INIT_ERROR: '.js-qty-init_error',
    CLASS_DECR: '.js-decr',
    CLASS_INC: '.js-inc',
    CLASS_ITEM: '.js-item',
    LS_KEY_PRODUCTS_ERRORS: 'cartProductErrors',

    CLASS_MINI_CART: '.js-minicart',
    CLASS_ERROR_MESSAGE: '.js-error_message',
    CLASS_ERROR_BLOCK: '.js-error_block',
    CLASS_PDP_FORM_WRAPPER: '.js-pdp_form_wrapper',
    CLASS_VARIATION_DROPDOWN: '.js-variation_dropdown',
    CLASS_VARIATION_DROPDOWN_OPEN: 'js-variation_dropdown',
    CLASS_VARIATION_DROPDOWN_CONTAINER: '.js-variation_dropdown_container',
    CLASS_VARIATION_DROPDOWN_VARIANT: '.js-variation_dropdown_variant',
    CLASS_COLOUR_BLOCK: '.js-colour_block',
    CLASS_COLOUR_SWATCH: '.js-colour_swatch',
    CLASS_SWATCHES_SHOW_MORE: '.js-swatches_show_more',
    CLASS_MORE_SHOWN: '.js-more_shown',
    CLASS_MORE_COLOURS: 'js-more_colours',
    CLASS_PDP_MINIRATING: '.js-pdp-rating',
    CLASS_FEEFO_GLOBALRATING: '.feefo-product_rating',
    CLASS_FEEFO_MORELINK: '.feefo-more',
    CLASS_FEEFO_RATINGVIEW: '.js-feefo-rating_view',
    CLASS_FEEFO_REVIEW_CONTAINER: '.js-feefo_review_container',
    CLASS_FEEFO_RATING_NUMBER:'.js-feefo-rating-number',
    CLASS_PRODUCT_REVIEW: '.product-review',
    CLASS_PRODUCT_REVIEWS_SECTION: '.pdp-reviews',
    CLASS_HEADER_MIDDLE: '.js-header-middle',
    CLASS_PDP_SOCIAL_ICON : '.js-pdp_social_icon',
    CLASS_VENUE_BLOCK: 'js-venue_block',
    CLASS_VENUE_LABEL: '.js-venue_label',
    CLASS_DATE_BLOCK: '.js-date_block',
    CLASS_AVAILABILITY: '.js-availability',
    CLASS_PDP_FORM: '.js-pdp-form',
    CLASS_ADD_TO_CART: '.js-add_to_cart',
    CLASS_NOTIFY_ME: '.js-notify_me',
    CLASS_BACKINSTOCK_SUCCESS_MSG: '.js-backinstock_success-message',
    CLASS_BACKINSTOCK_ERROR_MSG: '.js-backinstock_error-message',
    CLASS_BACKINSTOCK_TITLE_DEFAULT: '.js-backinstock_title-default',
    CLASS_BACKINSTOCK_TITLE_SUCCESS: '.js-backinstock_title-success',
    CLASS_BACKINSTOCK_CATEGORY_LINK: '.js-backinstock-exploremore_link',
    CLASS_BACKINSTOCK_FORM: '.js-backinstock-form',
    CLASS_BACKINSTOCK_EMAIL: '.js_backinstock_email',
    CLASS_BACKINSTOCK_CLOSE: '.js-backinstock-close',
    CLASS_ADD_TO_CART_DISABLED: 'js-add_to_cart_disabled',
    CLASS_MAIN_VARIATIONS: '.js-pdp_main_variations',
    CLASS_REQUIRED_VARIATION_BLOCK: '.js-required_variation_block',
    CLASS_REQUIRED_VARIATIONS: '.js-required_variations',
    CLASS_REQUIRED_SHOW_OPTIONS: '.js_required_show_options',
    CLASS_REQUIRED_PRODUCT: '.js-required_product',
    CLASS_REQUIRED_PRODUCT_CHECKBOX: '.js-required_product_checkbox',
    CLASS_SELECT_ALL_KITREQUIRED: '.js-select_all_kitrequired',
    CLASS_KITREQUIRED_TOTAL_PRICE: '.js-kitrequired_total_price',
    CLASS_KITREQUIRED_TOTAL_PRICE_VALUE: '.js-kitrequired_total_price_value',
    CLASS_SHOWN_OPTIONS: 'js-shown_options',
    CLASS_KITREQUIRED_PRODUCTS: '.js-kitrequired_products',
    CLASS_KITREQUIRED: '.js-kitrequired',
    CLASS_DELEGATE_INFO: '.js-delegate_info',
    CLASS_SELECT_VARIATION: 'js-select_vriation',
    CLASS_PRODUCT_VARIATIONS: '.js-product_variations',

    CLASS_PRODUCT_WRAPPER: '.js-description-wrapper',
    CLASS_PRODUCT_READMORE: '.js-description-readmore',
    CLASS_PRODUCT_READLESS: '.js-description-readless',
    CLASS_PRODUCT_FULLDESC: '.js-full-description',
    CLASS_PRODUCT_SHORTDESC: '.js-short-description',
    CLASS_PRODUCT_MORE_OPTIONS: '.js-more-options',

    CLASS_PRODUCT_ZOOM_ROTATE: '.js-zoom-rotate',
    CLASS_CART_CONTENT: '.js-cart_content',
};
