'use strict';

var pageContext = require('_core_ext/page'),
    constants = require('_core_ext/pages/search/constants');

function getSearchResults() {
    var products = [];
    $(constants.CLASS_PRODUCT_GRID).find('[data-represented-product]').each(function() {
        var $this = $(this),
            representedProduct = $this.data('represented-product');
        if (representedProduct) {
            products.push(representedProduct + '');
        }
    });
    return products;
}

var pageViewHandlers = {
        'storefront' : function() {
            return {
                event : 'viewHome'
            };
        },
        'search' : function() {
            return {
                event : 'viewList',
                item : getSearchResults()
            };
        },
        'product' : function() {
            return {
                event : 'viewItem',
                item : criteoData.item || ''
            };
        },
        'cart' : function() {
            return {
                event : 'viewBasket',
                item : criteoData.item || ''
            };
        },
        'orderconfirmation' : function() {
            return {
                event : 'trackTransaction',
                item : criteoData.item || '',
                id : criteoData.id || '',
            };
        }
    };

function initCriteo() {
    if (criteoData && pageContext && pageContext.ns && pageContext.ns in pageViewHandlers) {
        window.criteo_q = window.criteo_q || [];
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        window.criteo_q.push(
            { event: "setAccount", account: SitePreferences.CRITEO_ACCOUNTID },
            { event: "setEmail", email: criteoData.encodedEmail || '' },
            { event: "setSiteType", type: deviceType },
            pageViewHandlers[pageContext.ns]()
        );
    }
}

function initializeEvents() {
    $(document).on('products.loaded', function() {
        initCriteo();
    });
}

var criteo = {
    init : function (params) {
        if (!SitePreferences.CRITEO_ENABLED || !SitePreferences.CRITEO_ACCOUNTID) {
            return;
        }
        initializeEvents();
        initCriteo();
    }
};

module.exports = criteo;