'use strict';

var ajax = require('_core/ajax'),
    util = require('_core/util'),
    _ = require('lodash'),
    progress = require('_core_ext/progress'),
    imagesLoaded = require('imagesloaded'),
    JSConstants = require('_core_ext/cssconstants');

var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params) {
        var $target;

        if (_.isString(params.target)) {
            if (params.target.charAt(0) === '#') {
                $target = $(params.target);
            } else {
                $target = $('#' + params.target);
            }
        } else if (params.target instanceof jQuery) {
            $target = params.target;
        }

        // if no element found, create one
        if (!$target || $target.length === 0) {
            $target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
        }

        // create the dialog
        this.$container = $target;
        this.$container.dialog(_.merge({}, this.settings, params.options || {}));
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     * @param {Boolean} showPrevious dont close previous modal window
     */
    open: function (params, showPrevious) {
        progress.show();
        if (!showPrevious) {
            // close any open dialog
            this.close();
            $(JSConstants.BODY).addClass(JSConstants.LOCK);
        }
        this.create(params);
        this.replace(params);
        this.wrapContent();
        this.addEvents(params);
    },

    wrapContent: function() {
        var $dialogContainer = this.$container,
            $dialogMain = $dialogContainer.parents('.ui-dialog'),
            $dialogWrapper = $dialogMain.find('.ui-dialog-content_wrapper');

        if (!$dialogWrapper.length) {
            $dialogMain.wrapInner("<div class='ui-dialog-content_wrapper'></div>");
        }
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        if (!this.$container) { return; }
        content = params.content || params.html;
        if (!content) { return; }

        this.$container.empty().html(content);


        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }

        if (params.options) {
            position = params.options.position;
        }
        if (!position) {
            position = this.settings.position;
        }
        imagesLoaded(this.$container).on('done', function () {
            this.$container.dialog('option', 'position', position);
        }.bind(this));

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }

        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                data: params.data,
                callback: function (response) {
                    progress.hide();

                    if (response) {
                        params.content = response;
                        this.openWithContent(params);
                    } else {
                        $(JSConstants.BODY).removeClass(JSConstants.LOCK);
                    }
                }.bind(this)
            });
        } else if (params.html) {
            this.openWithContent(params);
            progress.hide();
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }
        if (this.$container.data('ui-dialog')) {
            this.$container.dialog('destroy').remove();
            delete this.$container;
        }

        $(JSConstants.BODY).removeClass(JSConstants.LOCK);
        $(document).off('keydown', onEscapePress);
    },
    closeSafe: function () {
        if (!this.$container) {
            return;
        }

        this.$container.dialog('close');
        $(JSConstants.BODY).removeClass(JSConstants.LOCK);
        $(document).off('keydown', onEscapePress);
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    isOpen: function () {
        return this.$container.dialog('isOpen');
    },
    addEvents: function (params) {
        var $this = this,
        settings = _.merge({}, this.settings, params.options || {}),
        $dialogMain = $this.$container.parents('.ui-dialog');
        var auxiliaryBtn = document.createElement('button');
        $('.ui-dialog-titlebar').prepend(auxiliaryBtn);
        $(auxiliaryBtn).trigger('focus');
        $(auxiliaryBtn).addClass('hidden');

        if (settings.outsideClose) {
            $dialogMain.bind('click', function(e) {
                if ($(e.target).hasClass('ui-dialog')) {
                    var $closeBtn = $('button.ui-dialog-titlebar-close');

                    if ($closeBtn.length > 0) {
                        $closeBtn.trigger('click');
                    } else {
                        $this.close();
                    }
                }
            });
        }

        $(document).on('keydown', onEscapePress);
    },

    settings: {
        autoOpen: false,
        height: 'auto',
        modal: true,
        overlay: {
            opacity: 0.5,
            background: 'black'
        },
        resizable: false,
        title: '',
        width: '800',
        open: function () {
            $('a').blur();
        },
        close: function () {
            $(this).dialog('close');

            $(JSConstants.BODY).removeClass(JSConstants.LOCK);
            $(document).off('keydown', onEscapePress);
        },
        buttons : [],
        position: {
            my: 'center',
            at: 'center',
            of: window,
            collision: 'flipfit'
        },
        outsideClose: true
    }
};

var onEscapePress = (function (e) {
    if (e.key === 'Escape') {
        this.closeSafe();
    }
}).bind(dialog);

module.exports = dialog;
