'use strict';

var util = require('_core_ext/util');
var layout,
    initialized = false,
    viewportWidth = {
        'mobile' : { 'maxWidth' : 767, 'minWidth' : 320 },
        'tablet' : { 'maxWidth' : 1199, 'minWidth' : 768 }
    };

function toggleBodyLocking() {
    var $this = $(this);
    if (!$this.hasClass('noscroll')) {
        $this.trigger('layout.lock');
    } else {
        $this.trigger('layout.unlock');
    }
}

function bodyLock() {
    $(this).addClass('noscroll');
}

function bodyUnlock() {
    $(this).removeClass('noscroll');
}

function initializeEvents() {
    var viewMode = layout.getMode();
    $(window).on('resize', util.eventDelay(function() {
        $(document).trigger('window.resize');
        var layoutView = layout.getMode();
        if (viewMode !== layoutView) {
            viewMode = layoutView;
            $(document).trigger('window.modechanged', { mode: viewMode });
        }
    }, 500));
    $(window).on('scroll', util.eventDelay(function() {
        $(document).trigger('window.scroll', { scrollTop: $(this).scrollTop() });
    }, 300));

    $(document).on('layout.togglelock', 'body', toggleBodyLocking)
                  .on('layout.lock', 'body', bodyLock)
                  .on('layout.unlock', 'body', bodyUnlock);
}

layout = {
    viewports: viewportWidth,
    getMode : function () {
        var windowWidth = $(window).width();
        if (windowWidth <= viewportWidth.mobile.maxWidth ) {
            return 'mobile';
        } else if ( (windowWidth <= viewportWidth.tablet.maxWidth) && (window.innerHeight < window.innerWidth) ) {
            return 'tabletLandscape';
        }  else if ( (windowWidth <= viewportWidth.tablet.maxWidth) && (window.innerHeight > window.innerWidth) ) {
            return 'tabletPortrait';
        } else {
            return 'desktop';
        }
    },
    isMobile: function() {
        return this.getMode() === 'mobile';
    },
    isTablet: function() {
        return this.getMode().indexOf('tablet') !== -1;
    },
    isTabletPortrait: function() {
        return this.getMode() === 'tabletPortrait';
    },
    isTabletLandscape: function() {
        return this.getMode() === 'tabletLandscape';
    },
    isDesktop: function() {
        return this.getMode() === 'desktop';
    },
    init : function() {
        if (initialized) {return;}
        initializeEvents();
        initialized = true;
    }
};

module.exports = layout;
