'use strict';

var cssConstants = require('_core_ext/cssconstants'),
    JSConstants = require('_core_ext/constants');

var initialized = false;

var DialogPopUpModule = function() {
    var COOKIE = {
        name: null,
        value: null
    };

    var ELEM = {
        $content: null,
        $closeIcon: null,
        $hiders: null
    };

    function init (cookieName, $mainElement, disableautohide, hiders) {
        COOKIE.name = cookieName;
        COOKIE.value = $.cookie(COOKIE.name);
        ELEM.$content = $mainElement.find(JSConstants.CLASS_DIALOG_HINT);
        ELEM.$closeIcon = $mainElement.find(JSConstants.CLASS_DIALOG_HINT_CLOSE);

        if (ELEM.$content.length < 0 || ELEM.$closeIcon.length < 0) {
            return;
        }

        if (!COOKIE.value) {
            setTimeout(function () {
                _showPopUp();
                if (!disableautohide) {
                    _setHideCookie('yes');
                }
            }, 7000);
        }

        ELEM.$closeIcon.on('click', function(e) {
            e.preventDefault();
            _hidePopUp();
        });

        var hiderQuery = hiders && hiders.length > 0 ? hiders.join(',') : null;
        if (hiderQuery) {
            ELEM.$hiders = $mainElement.find(hiderQuery);
            ELEM.$hiders.one('click', function() {
                _hidePopUp();
        });
        }
    }

    var _showPopUp = function () {
        if (ELEM.$content.length) {
            ELEM.$content.removeClass(cssConstants.HIDDEN);
        }
    };

    var _hidePopUp = function () {
        if (ELEM.$content.length) {
            _setHideCookie('yes');
            ELEM.$content.addClass(cssConstants.HIDDEN);
        }
    };
    var _setHideCookie = function(value) {
        if (COOKIE.name === 'hideTradePopup') {
            $.cookie(COOKIE.name, value, { expires: 30, path: '/' });
        } else {
            $.cookie(COOKIE.name, value, { expires: 365, path: '/' });
        }
    };

    return {
        init: init
    };
};

function initializeEvents() {
    var $tradePopUp = $(JSConstants.CLASS_TRADE_CARD_POP);

    if ($tradePopUp.length) {
        DialogPopUpModule().init('hideTradePopup', $tradePopUp, true, [JSConstants.CLASS_TRADE_CARD_POP_SELECT_LINK]);
    }
}

module.exports = {
    'init' : function () {
        if (initialized) {
            return;
        }
        initializeEvents();
        initialized = true;
    }
};
