'use strict';

var dialog = require('_core_ext/dialog'),
    ajax = require('_core/ajax'),
    util = require('_core_ext/util'),
    layout = require('_core_ext/layout'),
    JSConstants = require('_core_ext/pages/cart/constants'),
    JSCheckoutConstants = require('_core_ext/pages/checkout/constants'),
    JSCommonConstants = require('_core_ext/constants'),
    CSSConstants = require('_core_ext/cssconstants');

/**
 * @description Moved from SG app.js
 */
function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        })
        .on('click', '.js-toggle_mobile', function(event) {
            event.preventDefault();
            if (layout.isMobile()) {
                $(this).toggleClass('open');
                $(this).next('.js-toggle_container').toggleClass('open');
            }
        })
        .on('click', CSSConstants.BACK_TO_TOP, function() {
            $('html, body').animate({scrollTop: 0}, 500);
        })
        .on('click', JSCommonConstants.CLASS_SCROLL_SECTION_LINK, function(e) {
            e.preventDefault();
            var $this = $(this);
            var targetSection = $this.data('scrollsection'),
                scrollOffset = $this.data('scrolloffset') || 0,
                scrollDelay = $this.data('scrolldelay') || 0;

            var $target = $(targetSection);

            setTimeout(function() {
                var offset = ($target && $target.length) ? $target.offset().top : 0;
                offset -= parseInt(scrollOffset);

                util.scrollBrowser(offset, 500, true);
            }, scrollDelay);
        })
        .on('click', JSCommonConstants.CLASS_SHOW_PASSWORD_INLINE, function(e) {
            e.preventDefault();
            var $el = $(e.target);
            var $input = $el.siblings('input');

            $el.toggleClass(CSSConstants.CLASS_PASSWORD_INLINE_HIDDEN + ' ' + CSSConstants.CLASS_PASSWORD_INLINE_VISIBLE);
            $input.attr('type', function(index, attr) {
                return attr === 'text' ? 'password' : 'text';
            });
        });

    // add generic toggle functionality
    function genericTabs() {
        var accordionItem = JSCommonConstants.CLASS_TOGGLE,
            accordionContent = JSCommonConstants.CLASS_TOGGLE_CONTENT,
            accordionExpanded = JSCommonConstants.CLASS_TOGGLE_EXPANDED,
            accordionParent = JSCommonConstants.CLASS_TOGGLE_PARENT;

        $(accordionItem).next(accordionContent).addClass('close');
        $(accordionItem).on('click', function () {
            var $this = $(this);
            if ($this.next(accordionContent).hasClass('close')) {
                $this.next(accordionContent).removeClass('close');
                $this.toggleClass(accordionExpanded).next(accordionContent).addClass('open');
            } else if ($this.next(accordionContent).hasClass('open')) {
                $this.next(accordionContent).removeClass('open');
                $this.toggleClass(accordionExpanded).next(accordionContent).addClass('close');
            }
            $this.parent(accordionParent).siblings().each(function() {
                if ($(this).find(accordionItem).next(accordionContent).hasClass('open')) {
                    $(this).find(accordionItem).next(accordionContent).removeClass('open');
                    $(this).find(accordionItem).removeClass(accordionExpanded).next(accordionContent).addClass('close');
                }
            });
            scrollTo($this);
        });
    }

    function scrollTo($element) {
        var stickyHeaderOuterHeight;
        var $headerTopPromo;

        if (layout.isMobile()) {
            stickyHeaderOuterHeight = $(JSCommonConstants.CLASS_STICKY_HEADER_MOBILE).outerHeight(true);
        } else {
            $headerTopPromo = $(JSCommonConstants.CLASS_HEADER_TOP_PROMO);

            if ($headerTopPromo.length && !$headerTopPromo.hasClass(CSSConstants.HIDDEN)) {
                stickyHeaderOuterHeight = $headerTopPromo.outerHeight(true);
            } else {
                stickyHeaderOuterHeight = 0;
            }

        }

        var scrollValue = $element.offset().top - stickyHeaderOuterHeight;
        util.scrollBrowser(scrollValue);
    }


    $(JSCommonConstants.CLASS_DIALOG).on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            callback: genericTabs
        });
    });


    //cropping image instead of re-scaling
    function cropImage($imageContainer) {
        $imageContainer.each(function() {
            var $container = $(this),
                $image = $container.find('img'),
                imageSrc = $image.attr('src');

            if (!layout.isMobile()) {
                $image.hide();
                $container.css({
                    "height": $container.data(layout.isDesktop() ? 'desktop' : 'tablet'),
                    "background": "url(" + imageSrc + ") no-repeat center"
                });
            } else {
                $image.show();
                $container.removeAttr('style');
            }
        });
    }

    //back to top
    $(window).on('scroll', function () {
        var scrollPosition = 5000;

        if (!document.querySelector("body").classList.contains("pt_cart")) {
            $(this).scrollTop() > scrollPosition ? $(CSSConstants.BACK_TO_TOP).fadeIn() : $(CSSConstants.BACK_TO_TOP).fadeOut();
        }
    });

    $(document)
        .on('click', JSConstants.CLASS_VAT_DISCLAIMER, function(e) {
            e.preventDefault();
            $(JSConstants.CLASS_VAT_EXPLANATION).toggleClass(CSSConstants.HIDDEN);
        })
        .on('click', JSCheckoutConstants.CLASS_PRINT_RECEIPT, function() {
            window.print();
        })
        .on('enquire.done window.modechanged', function() {
            if ($(CSSConstants.CROP_IMAGE_CONTAINER) && $(CSSConstants.CROP_IMAGE_CONTAINER).length > 0) {
                cropImage($(CSSConstants.CROP_IMAGE_CONTAINER));
            }
        })
        .on('click', function() {
            if (layout.isTablet() && $(JSCommonConstants.CLASS_MINI_CART_CONTENT).is(':visible')) {
                $(JSCommonConstants.CLASS_MINI_CART_CONTENT).hide();
            }
        });

    util.limitCharacters();
    genericTabs();
}

function setUniqueCSFRTokens() {
    var $tokenInputs = $(JSCommonConstants.CLASS_CSFR_TOKEN);

    $tokenInputs.each(function() {
        var $this = $(this);

        ajax.getJson({
            url: Urls.getCsrfToken,
            callback: function(data) {
                if (data && !!data.success) {
                    $this.val(data.value).attr('name', data.name);
                }
            }
        });
    });
}

function setSharedCSFRTokens() {
    var $tokenInputs = $(JSCommonConstants.CLASS_CSFR_SHARED_TOKEN);

    if ($tokenInputs && $tokenInputs.length) {
        ajax.getJson({
            url: Urls.getCsrfToken,
            callback: function(data) {
                if (data && !!data.success) {

                    $tokenInputs.each(function() {
                        $(this).val(data.value).attr('name', data.name);
                    });
                }
            }
        });
    }
}

/**
 * @description Moved from SG app.js
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL && pageContext.ns !== 'blog') {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    if (!navigator.cookieEnabled) {
        $('<div/>').addClass('browser-compatibility_alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('.browser-check');
    }

    setSharedCSFRTokens();
}

module.exports = {
    'init' : function () {
        initializeDom();
        initializeEvents();
    }
};
