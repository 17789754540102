module.exports = {
    /*
     * Global
     */
    CLASS_LOGIN_BOX: '.js-login_box',
    CLASS_ACCOUNT_OVERVIEW: '.js-account_overview',
    CLASS_ADDRESS_FIELD: '.js-address_field',
    CLASS_UPDATE_ADDRESS: '.js-update_address',
    CLASS_NAV_LINK: '.js-account-nav-link',
    CLASS_CSFR_TOKEN: '.js-csfr-token',
    CLASS_CSFR_SHARED_TOKEN: '.js-csfr-shared-token',
    /*
     * Personal Details
     */
    CLASS_PERSONAL_DETAILS_FORM: '.js-personal_details',
    CLASS_SET_NEW_PASSWORD_FORM: '.js-set_new_password',
    CLASS_PERSONAL_DATA_SUBMIT_BUTTON: '.js-personal_data_submit_button',
    CLASS_CHANGE_PASSWORD_SUBMIT_BUTTON: '.js-change_password_submit_button',
    CLASS_BIRTHDAY: '.js-birthday',
    CLASS_BIRTHDAY_DAY: '.js-birthday_day',
    CLASS_BIRTHDAY_MONTH : '.js-birthday_month',
    CLASS_BIRTHDAY_YEAR: '.js-birthday_year',
    CLASS_VALIDATE_IGNORE: 'js-validate-ignore',
    CLASS_EMAIL_ALREADY_EXIST_ERROR: 'js-email_already_exist_error',
    CLASS_CURRENT_PASSWORD_NO_MACH_ERROR: 'js-current_password_no_match_error',

    /*
     * Address Book
     */
    CLASS_ADDRESS_CONTAINER: '.js-addresses',
    CLASS_ADDRESS_DEFAULT_LIST: '.js-addresses_default',
    CLASS_ADDRESS_MAKE_DAFAULT: '.js-address_make_default',
    CLASS_ADDRESS_INCLUDE: '.js-address_include',
    CLASS_ADDRESS_CREATE: '.js-address_create',
    CLASS_ADDRESS_DELETE: '.js-address_delete',
    CLASS_ADDRESS_CHANGE_DEFAULT: '.js-address_changedefault',
    CLASS_ADDRESS_EDIT: '.js-address_edit',
    CLASS_ADDRESS_NAME_EXIST_ERROR: 'js-address_name_exist_error',
    CLASS_ADDRESS_FORM: '.js-address_form',
    CLASS_ADDRESS_CONTENT_LINK: '.js-address-content_link',
    CLASS_ADDRESS_TOKEN: '.js-address-form-token',

    /*
     * Address form
     */
    CLASS_ADDRESS_FORM_APPLY: '.js-address_form_apply',
    CLASS_ADDRESS_FORM_CLOSE: '.js-address_form_close',
    CLASS_ADDRESS_FORM_DELETE: '.js-address_form_delete',

    /*
     * Subscription form
     */
    CLASS_EMAIL_FORM: '.js-footer_subscription',
    CLASS_EMAIL_FORM_MESSAGE: '.js-subscription_message',
    CLASS_AGREEMENT: '.js-acknowledgement_agree',
    CLASS_PROFESIONAL_RADIO: '.js-profesionaltrade_radio',

    /*
     * Card request
     */
    CLASS_CARDREQUEST_FORM: '.js-cart_request',
    CLASS_SELECT_ADDRESS: '.js-select_address',
    /*
     * News & alerts
     */
    CLASS_NEWS_SUBSCRIPTION: '.js-news_subscription',
    CLASS_NEWS_VIP: '.js-newsletter-vip',
    CLASS_UNSUBSCRIBE: 'js-unsubscribe',
    CLASS_MESSAGES_CONTAINER: '.js-account_subscribe-messages-container',

    /*
     * Payment Settings
    */
    CLASS_PAYMENTS_LIST: '.js-payments_list',
    CLASS_ADD_CARD: '.js-add-card',
    CLASS_DELETE_CARD: '.js-delete-card',
    CLASS_PAYMENTS_LIST_POPUP_APPLY_BUTTON: '.js-payments_list-popup_apply',
    CLASS_PAYMENTS_LIST_POPUP_CANCEL_BUTTON: '.js-payments_list-popup_cancel',

    /*
     * Wish List
     */
    CLASS_ADD_TO_CART_WISH_LIST: '.js-add-to-cart_wish_list',

    /*
    * Login page
    */
    CLASS_PASSWORD_RESET: '.js-password_reset',

    /*
     * Forgot Password Dialog
     */
    CLASS_REQUEST_PASSWORD_SEND_BUTTON: '.js-request_password_button',

    CLASS_CARD_DELETE: '.js-card_delete',
    CLASS_PAYMENT_LIST: '.js-payment_list',
    CLASS_TRADE_REGISTRATION_FORM: '.js-trade_registration_form',

    /*
     * Change password form
     */
    CLASS_CHANGE_PASS_FORM: '.js-change_password_form',
    CLASS_CHANGE_PASS_APPLY: '.js-change_password_apply',

    /*
     * Change Business/Company Name form
     */
    CLASS_CHANGE_BUSINESS_NAME_FORM: '.js-change_businesscompanyname_form',
    CLASS_CHANGE_BUSINESS_NAME_APPLY: '.js-change_businesscompanyname_apply',

    /*
     * Change login Name form
     */
    CLASS_CHANGE_LOGIN_FORM: '.js-change_login_form',
    CLASS_CHANGE_LOGIN_APPLY: '.js-change_login_apply',

    /*
     * Change Hear/Beauty professionals detail Name form
     */
    CLASS_CHANGE_PROFESSIONAL_FORM: '.js-change_professional_form',
    CLASS_CHANGE_PROFESSIONAL_APPLY: '.js-change_professional_apply',

    /*
     * Change Profile form
     */
    CLASS_CHANGE_PROFILE_FORM: '.js-change_profile_form',
    CLASS_CHANGE_PROFILE_APPLY: '.js-change_profile_apply',

    /*
     * My account pop up
     */
    CLASS_MY_ACCOUNT: '.js-myaccount-info',
    CLASS_MY_ACCOUNT_ICON: '.js-myaccount-info_icon',
    CLASS_MY_ACCOUNT_CONTENT: '.js-myaccount-info_content',
    CLASS_MINI_CART_CONTENT: '.js-mini-cart-content',
};
