'use strict';

const MAX_PRODUCTS_FOR_PUSH = 20;
const DEBOUNCE = 300;

var _pageContext, analyticsData, currentPage;
var _ = require('lodash');
var util = require('_core_ext/util');

//Config of functions names, depending on current page
//Executed on page load
var pageTagsConfig = {
    'global': ['viewPromotion', 'viewItemList'],
    'product': ['viewItem'],
    'cart': ['viewCart'],
    'account': ['beginCheckout'],
    'orderconfirmation': ['purchase'],
};

 //Config of functions names, depending on current page
 //Executed on user interaction
var pageEventsConfig = {
    'global': ['viewItemList', 'productClicks', 'selectPromotion', 'addToCart', 'notifyMePopup', 'notifyMeSubmitted', 'headerClicks', 'footerClick', 'formTracking', 'buttonClick'],
    'product': ['addToWishlist', 'viewItem'],
    'cart': ['removeFromCart', 'changeQuantity'],
    'registation_step1': ['singupStart', 'stageEvent', 'singupComplete']
};

// Save product data for events on the next steps
function saveProductData(item) {
    if (item && item.item_list_name) {
        sessionStorage.setItem(item.item_id, JSON.stringify(item));
        sessionStorage.setItem('last_select_item', JSON.stringify(item));
    }
}

// Update current product data from saved data
function updateProductData(item, isPDPupdate) {
    if (item) {
        let savedItem = JSON.parse(sessionStorage.getItem(isPDPupdate ? 'last_select_item' : item.item_id));
        if (savedItem) {
            item.item_list_name = savedItem.item_list_name;
            item.index = savedItem.index;
        } else {
            if (!item.item_list_name) {
                var breadcrumbs = document.querySelectorAll('.js-monetate-breadcrumb');
                if (breadcrumbs && breadcrumbs.length) {
                    var breadcrumbsNames = [];
                    breadcrumbs.forEach( breadcrumb => {
                        breadcrumbsNames.push(breadcrumb.innerText);
                    });
                    item.item_list_name = breadcrumbsNames.join(' -> ');
                }
            }
            saveProductData(item);

        }
    }
}

// Push the event in the event array
function pushEvent(data) {
    window.dataLayer  = window.dataLayer || [];
    var neededCleanLayerEvents = ['view_item_list','select_item', 'view_item', 'add_to_cart', 'add_to_wishlist', 'remove_from_cart', 'view_cart', 'begin_checkout', 'Add_shipping_info', 'add_payment_info', 'purchase']
    if (neededCleanLayerEvents.includes(data.event)) {
        dataLayer.push({ ecommerce: null });
    }
    dataLayer.push(data);
}

// Get items list name for the element with product
function getItemListName($element) {
    var name;
    var breadcrumbs = document.querySelectorAll('.js-monetate-breadcrumb');
    if ($element.closest('.js-last-visited').length) {
        name = analyticsData.lastvisited && analyticsData.lastvisited.itemListName;
    } else if ($element.closest('.js-product-tiles').length) {
        name = analyticsData.productTiles && analyticsData.productTiles.itemListName;
    } else if ($element.closest('.js-recommendation').length) {
        name = 'recommendations';
        var recommendationsTitle = $element.closest('.recommendation-tab').find('.recommendation-title');
        if (recommendationsTitle.length) {
            name = recommendationsTitle[0].innerText.replace(/(\r\n|\n|\r)/gm,"");
        }
    } else if (breadcrumbs && breadcrumbs.length) {
        var breadcrumbsNames = [];
        breadcrumbs.forEach( breadcrumb => {
            breadcrumbsNames.push(breadcrumb.innerText);
        });
        name = breadcrumbsNames.join(' -> ');
    } else {
        name = analyticsData.itemListName;
    }
    return name;
}

function createItemsListsForSend() {
    return {
        search: {
            items: []
        },
        lastvisited: {
            items: []
        },
        productTiles: {
            items: []
        },
        recommendations: {
            items: []
        }
    };
}

function getItemAnaliticDate($product) {
    var item = $product.data('productDetailsGa4');
    if (item) {
        item.item_list_name = getItemListName($product);
    }
    return item;
}

function addItemForSend(itemsList, $product, index) {
    var isSkip = !$product.height() || !util.isScrolledIntoView($product) || $product.data('viewedGa4')
        || !!$product.closest("div[aria-hidden=true]").length;

    if (!isSkip) {
        let productData = getItemAnaliticDate($product);
        if (productData) {
            productData.index = index;
            itemsList.items.push({...productData});
        }
        $product.data('viewedGa4', true);
    }
}

function sendItemsLists(itemsLists) {
    viewItemList(itemsLists.search.items, analyticsData.page.currencyCode);
    viewItemList(itemsLists.lastvisited.items, analyticsData.page.currencyCode);
    viewItemList(itemsLists.recommendations.items, analyticsData.page.currencyCode);
    viewItemList(itemsLists.productTiles.items, analyticsData.page.currencyCode);
}

function gatherViewItems() {
    var itemsLists = createItemsListsForSend();
    var indexTileContainer = 0;
    var indexLastVisited = 0;
    var indexProductTiles = 0;
    var indexRecommendation = 0;

    $('.js-tile_container').find('.js-product-impression').each(function() {
        var $product = $(this);
        addItemForSend(itemsLists.search, $product, indexTileContainer++);
    });
    $('.js-last-visited').find('.js-product-impression').each(function() {
        var $product = $(this);
        addItemForSend(itemsLists.lastvisited, $product, indexLastVisited++);
    });
    $('.js-product-tiles').find('.js-product-impression').each(function() {
        var $product = $(this);
        addItemForSend(itemsLists.productTiles, $product, indexProductTiles++);
    });
    $('.js-recommendation').find('.js-product-impression').each(function() {
        var $product = $(this);
        addItemForSend(itemsLists.recommendations, $product, indexRecommendation++);
    });

    sendItemsLists(itemsLists);
}

function viewItemList(innerItems, currency) {
    if (innerItems) {
        var items = innerItems.slice(0);

        while(items.length) {
            var products = items.splice(0, MAX_PRODUCTS_FOR_PUSH);
            pushEvent({
                event: 'view_item_list',
                ecommerce: {
                    currency: currency,
                    items: products
                }
            });
        };
    }
}

function viewItem(items, isPDPupdate) {
    if (items) {
        analyticsData.items = items;
    }
    var item = analyticsData.items.length ? analyticsData.items[0] : null;
    updateProductData(item, isPDPupdate);
    pushEvent({
        event: 'view_item',
        ecommerce: {
            currency: analyticsData.page.currencyCode,
            items: analyticsData.items
        }
    });
}

//Holder for events functions
var eventsLibrary = {
    'viewItemList': function() {
        $(document).on('products.last.visited.created', function() {
            if (window.pageContextLastVisited && window.pageContextLastVisited.analytics) {
                analyticsData.lastvisited = pageContextLastVisited.analytics.lastvisited;
                gatherViewItems();
            }
        });

        $(document).on('products.tiles.created', function() {
            if (window.pageContextProductTiles && window.pageContextProductTiles.analytics) {
                analyticsData.productTiles = pageContextProductTiles.analytics.productTiles;
                gatherViewItems();
            }
        });

        $(document).on('page.context.update', function() {
            gatherViewItems();
        });

        $(document).on('setPosition', '.js-carousel.js-last-visited, .js-carousel.js-product-tiles', _.debounce(function() {
            gatherViewItems();
        }, DEBOUNCE));
    },

    'viewItem': function() {
        $(document).on('pdp.updated', function(e, $response) {
            let $product = $response.find('.js-pdp-form');
            var productData = $product.data('productDetailsGa4');
            if (productData) {
                viewItem([productData], true);
            }
        });
    },

    'productClicks': function() {
        $(document).on('click', '.js-product-impression', _.debounce(function() {
            var $this = $(this);
            if ($this.closest('.js-cart-items-form').length) {
                return;
            }
            var item = getItemAnaliticDate($this);

            saveProductData(item);
            pushEvent({
                event: 'select_item',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
        }, DEBOUNCE));
    },

    'changeQuantity': function() {
        $(document).on('product.changedQuantity', function(event, itemId, quantity) {
            var items = analyticsData.items;
            var item = items.find(function(i) {
                return i.item_id === itemId;
            });
            var pQuantity = 0;

            if (item) {
                updateProductData(item);
                pQuantity = item.quantity;
                item.quantity = quantity;
                item = { ...item };
                item.quantity = Math.abs(pQuantity - quantity);
            }
            pushEvent({
                event: pQuantity < quantity ? 'add_to_cart' : 'remove_from_cart',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
         });
     },

    'addToCart': function() {
        $(document).on('product.addedToCart', function(event, $form) {
            var $product = $form.closest('.js-product-impression');
            var item;

            if ($product.length) {
                item = getItemAnaliticDate($product);
            } else {
                var items = analyticsData.items;
                var itemId = $form.find('input[name="pid"]').val();
                item = items.find(function(i) {
                    return i.item_id === itemId;
                });
            }

            if (item) {
                updateProductData(item);
                item = { ...item };
                item.quantity = +$form.find('input[name="Quantity"]').val() || 1;
            }
            pushEvent({
                event: 'add_to_cart',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
         });
     },

    'notifyMePopup': function() {
        $(document).on('product.notifyMePopup', function(event, $form) {
            var $product = $form.closest('.js-product-impression');
            var item;

            if ($product.length) {
                item = getItemAnaliticDate($product);
            } else {
                var items = analyticsData.items;
                var itemId = $form.find('input[name="pid"]').val();
                item = items.find(function(i) {
                    return i.item_id === itemId;
                });
            }

            if (item) {
                updateProductData(item);
            }
            pushEvent({
                event: 'notifyMePopup',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
         });
    },

    'notifyMeSubmitted': function() {
        $(document).on('product.notifyMeSubmitted', function(event, $form) {
            var $product = $form.closest('.js-product-impression');
            var item;

            if ($product.length) {
                item = getItemAnaliticDate($product);
            } else {
                var items = analyticsData.items;
                var itemId = $form.find('input[name="pid"]').val();
                item = items.find(function(i) {
                    return i.item_id === itemId;
                });
            }

            if (item) {
                updateProductData(item);
            }
            pushEvent({
                event: 'notifyMeSubmitted',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
         });
    },

    'addToWishlist': function() {
        $(document).on('click', '.js-ga-add-to-wish-list', _.debounce(function() {
            var $this = $(this);
            var pid = $this.data('pid');
            var items = analyticsData.items;
            var item = items.find(function(i) {
                return i.item_id === pid.toString();
            });

            updateProductData(item);
            pushEvent({
                event: 'add_to_wishlist',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
         }, DEBOUNCE));
    },

    'removeFromCart': function() {
        function sendRemoveFromCartEvent(item) {
            updateProductData(item);
            pushEvent({
                event: 'remove_from_cart',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: [item]
                }
            });
        }

        $(document).on('product.removeFromCart', function(event, $form) {
            var items = analyticsData.items;
            var itemId = $form.find('input[name="pid"]').val();
            var item = items.find(function(i) {
                return i.item_id === itemId;
            });

            if (item) {
                item.quantity = $form.find('#Quantity').val();
            }
            sendRemoveFromCartEvent(item);
         });

         $(document).on('click', '.js-remove-from-cart', _.debounce(function() {
            var $this = $(this);
            var $cartItemWrapper = $this.closest('.js-product-impression');
            var items = analyticsData.items;
            var itemId = $cartItemWrapper.data('itemid').toString();
            var item = items.find(function(i) {
                return i.item_id === itemId;
            });

            if (item) {
                item.quantity = $cartItemWrapper.find('.item-quantity input').val();
            }
            sendRemoveFromCartEvent(item);
        }, DEBOUNCE));
    },

    'selectPromotion': function() {
        $('.js-promo-impression').on('click', _.debounce(function() {
            var promoDetails = $(this).data('promoDetails');

            if (promoDetails) {
                pushEvent({
                    event: 'select_promotion',
                    ecommerce: {
                        items: [{
                            promotion_id: promoDetails.id,
                            promotion_name: promoDetails.name,
                            creative_name: promoDetails.creative,
                            creative_slot: promoDetails.position
                        }]
                    }
                });
            }
        }, DEBOUNCE));
    },

    'headerClicks': function() {
        function sendHeaderClick(e, level, recursion = false, isOnlyTitle = false) {
            var path = '';
            if (e.originalEvent.path) {
                e.originalEvent.path.some(i => {
                    if (!path && i.attributes['title']) {
                        path = i.attributes['title'].value;
                    } else if (!isOnlyTitle && !path && i.innerText) {
                        path = i.innerText.trim();
                    }
                    return path.length;
                });
            }
           
            if (recursion) {
                let $this = $(e.target);
                while ($this.length) {
                    $this = $($this[0].closest('ul').closest('li'));
                    let $a = $this.find('a');
                    let a = $a.length && $a[0];
                    if (a && a.attributes['title']) {
                        path = a.attributes['title'].value + '-' + path;
                    } else if (a && a.innerText) {
                        path = a.innerText + '-' + path;
                    }
                };
            }
            pushEvent({
                event: 'header_click',
                header: {
                    element: path,
                    level: level
                }
            });
        };

        $('.js-tau-header-logo, .js-search-submit, .js-header-controls').on('click', _.debounce(function(e) {
            sendHeaderClick(e, 'Level 1', false, true);
        }, DEBOUNCE));
        $('.js-level1_item').on('click', _.debounce(function(e) {
            if (e.target.className.indexOf('js-level1-link') > -1) {
                sendHeaderClick(e, 'Level 2');
            }
        }, DEBOUNCE));
        $('.js-level2_item').on('click', _.debounce(function(e) {
            sendHeaderClick(e, 'Level 3', true);
        }, DEBOUNCE));
    },

    'footerClick': function() {
        $('.js-footer-container .footer-nav_link').on('click', _.debounce(function(e) {
            let target = e.target || {};
            pushEvent({
                event: 'footer_click',
                footer: {
                    element: target.innerText,
                    url: target.href
                }
            });
        }, DEBOUNCE));
    },

    'formTracking': function() {
        const FIRST_INTERACTION = 'First Interaction';
        const SUCCESS = 'Success';
        const ERROR = 'Error';
        var statusForms = {};

        function sendFormTracking(e, status, skipErrors) {
            var $form = $(e).closest('form');
            var formName = $form.attr('name') || $form.attr('id');
            if (!formName) {
                return;
            }

            var errors = [];
            if (!skipErrors) {
                $.each($form.find('.error-form'), (_,v) => {
                    errors.push(v.innerText);
                });

                if (!errors.length) {
                    var fieldsets = $form.find('fieldset');
                    $.each(fieldsets, (_,fieldset) => {
                        let $fieldset = $(fieldset);
                        let elements = $fieldset.find('.input-text.error, .input-radio.error, .input-checkbox.error, .input-select.error');
                        $.each(elements, (_,el) => {
                            let nameEl = $fieldset.find('label[for="' + el.id + '"]').text().trim().replace('*','') || el.name || el.id;
                            errors.push(nameEl + ' is not valid');
                        });
                    });
                }
            }

            var errorsStr = errors.join('|');
            var newStatus = errorsStr ? ERROR : status;

            if (!statusForms[formName]) {
                statusForms[formName] = {
                    status: FIRST_INTERACTION,
                    errorMessage: ''
                };
                pushEvent({
                    event: 'form_tracking',
                    form: {
                        name: formName,
                        status: statusForms[formName].status,
                        error_message: ''
                    }
                });
            }

            let statusForm = statusForms[formName];
            if (newStatus !== FIRST_INTERACTION && (statusForm.status !== newStatus || statusForm.errorMessage !== errorsStr) ) {
                statusForm.status = newStatus;
                statusForm.errorMessage = errorsStr;
                pushEvent({
                    event: 'form_tracking',
                    form: {
                        name: formName,
                        status: statusForm.status,
                        error_message: statusForm.errorMessage
                    }
                });
                if (skipErrors && statusForm.status === SUCCESS) {
                    statusForms[formName] = null;
                }
            }
        }

        $(document).on('focus', 'fieldset .input-text, fieldset .input-radio, fieldset .input-checkbox, fieldset .input-select', function () {
            sendFormTracking(this, FIRST_INTERACTION);
        });

        $(document).on('click', ':submit', _.debounce(function () {
            var form = $(this).closest('form');
            form.on('submit', function () {
                sendFormTracking(this, SUCCESS, true);
            });
            sendFormTracking(this, SUCCESS);
        }, DEBOUNCE));
    },

    'buttonClick': function() {
        function sendButtonClick(e) {
            const sectionsMap = {
                'homepage': 'Home',
                'cart': 'Cart',
                'product': 'PDP',
                'search': 'PLP',
                'customerservice': 'Contact Us'
            };
            let target = e.target || {};
            pushEvent({
                event: 'button_click',
                button: {
                    name: target.innerText,
                    section: sectionsMap[currentPage] || currentPage
                }
            });
        }

        // home
        $('.js-tau-home-hero a').on('click', _.debounce(function(e) {
            sendButtonClick(e);
        }, DEBOUNCE));

        // cart
        $('.js-cart-promo_form-add_coupon, .js-ga-checkout-delivery').on('click', _.debounce(function(e) {
            sendButtonClick(e);
        }, DEBOUNCE));

        // PDP
        $('.js-add_to_cart, .js-reserve-select_store').on('click', _.debounce(function(e) {
            sendButtonClick(e);
        }, DEBOUNCE));

        // PLP and .js-add_to_cart
        $('.js-load-more, .js-choose-options').on('click', _.debounce(function(e) {
            sendButtonClick(e);
        }, DEBOUNCE));

        // customerservice
        $('.js-contact-us').on('click', _.debounce(function(e) {
            sendButtonClick(e);
        }, DEBOUNCE));

        // Checkout in ga4.js
    },

    'singupStart': function() {
        $(document).on('tradecard.singupStart', function(event, professionType) {
            pushEvent({
                event: 'trade_signup_start',
                profession_type: professionType,
                form_stage: 'profession'
            });
         });
    },

    'stageEvent': function() {
        $(document).on('tradecard.stageEvent', function(event, eventType, stageType) {
            pushEvent({
                event: eventType,
                form_stage: stageType
            });
         });
    },

    'singupComplete': function() {
        $(document).on('tradecard.singupComplete', function(event) {
            pushEvent({
                event: 'trade_signup_complete'
            });
         });
    },
};

 /**
  * @description collection of functions which sends reporting
  *              specific for each page, page identifier is in _pageContext.currentPage
  */
 var tagsLibrary = {
    'viewItemList': function() {
        var timerId;
        gatherViewItems();

        $(window).on('scroll', function() {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(gatherViewItems, DEBOUNCE);
        });
    },

    'viewItem': function() {
        viewItem();
    },

    'viewCart': function() {
        analyticsData.items.forEach(function(item){
            updateProductData(item);
        });
        pushEvent({
            event: 'view_cart',
            ecommerce: {
                currency: analyticsData.page.currencyCode,
                items: analyticsData.items
            }
        });
    },

    'beginCheckout': function() {
        if (_pageContext.monetateCurrentPage === 'checkoutlogin' && !sessionStorage.getItem('beginCheckout')) {
            analyticsData.checkout.items.forEach(function(item){
                updateProductData(item);
            });
            pushEvent({
                event: 'begin_checkout',
                ecommerce: {
                    currency: analyticsData.page.currencyCode,
                    items: analyticsData.checkout.items
                }
            });
            sessionStorage.setItem('beginCheckout', true);
        }
    },

    'viewPromotion': function() {
        var items = [];
        $('.js-promo-impression').each(function() {
            var promoDetails = $(this).data('promoDetails');

            if (promoDetails) {
                items.push({
                    promotion_id: promoDetails.id,
                    promotion_name: promoDetails.name,
                    creative_name: promoDetails.creative,
                    creative_slot: promoDetails.position
                });
            }
        });

        if (items.length) {
            pushEvent({
                event: 'view_promotion',
                ecommerce: {
                    items: items
                }
            });
        }
    },

    'purchase': function() {
        let ecommerce = {
            transaction_id: analyticsData.checkout.transaction_id,
            affiliation: analyticsData.checkout.affiliation,
            value: analyticsData.checkout.value,
            tax: analyticsData.checkout.tax,
            shipping: analyticsData.checkout.shipping,
            currency: analyticsData.checkout.currency,
            items: analyticsData.checkout.items
        };

        if ('new_customer' in analyticsData.checkout) {
            ecommerce.new_customer = analyticsData.checkout.new_customer;
        }

        analyticsData.checkout.items.forEach(function(item){
            updateProductData(item);
        });

        if (analyticsData.checkout.coupon) {
            ecommerce.coupon = analyticsData.checkout.coupon;
        }
        if (analyticsData.checkout.promo_id) {
            ecommerce.promo_id = analyticsData.checkout.promo_id;
        }

        sessionStorage.removeItem('addPaymentInfo');
        sessionStorage.removeItem('addShippingInfo');
        sessionStorage.removeItem('beginCheckout');
        pushEvent({
            event: 'purchase',
            ecommerce: ecommerce
        });
    }
 };

 /**
  * @description pageContext is a global window object, usually it contains page specific data.
  *              In our case it should contain currentPage variable,
  *              which allows us to differentiate this page from other pages
  */
 function initPageContext() {
    _pageContext = window.pageContext || {analytics:{}};

    if (window.pageContextLastVisited && window.pageContextLastVisited.analytics) {
        _pageContext.analytics.lastvisited = pageContextLastVisited.analytics.lastvisited;
    }
    if (window.pageContextProductTiles && window.pageContextProductTiles.analytics) {
        _pageContext.analytics.productTiles = pageContextProductTiles.analytics.productTiles;
    }

     //analyticsData need to be collected on BackEnd, should contain page specific or/and global data
     analyticsData = _pageContext.analytics || {};
     currentPage = _pageContext.currentPage || _pageContext.ns || {};
 }

 /**
  * @description initialize events depending on current page
  *
  * @return {Void}
  */
 function initEvents() {
     var eventsToInit = $.merge($.merge([], pageEventsConfig.global || []), pageEventsConfig[currentPage] || []);

     initFunctionsFromObject(eventsToInit, eventsLibrary);
 }

 /**
  * @description sends global data and page specific data, depending on 'currentPage' value
  *
  * @return {Void}
  */
 function sendTags() {
    var tagsToSend = $.merge($.merge([], pageTagsConfig.global || []), pageTagsConfig[currentPage] || []);
    initFunctionsFromObject(tagsToSend, tagsLibrary);
}

 /**
  * @description starts functions execution from list of provided functions
  * @param functionsList {Array} List of functions names
  * @param ObjectWithFunctions {Object} contains collection of named functions
  */
 function initFunctionsFromObject(functionsList, ObjectWithFunctions) {
     if (functionsList && ObjectWithFunctions) {
         var functionsListLength = functionsList.length,
             i = 0;

         for (; i < functionsListLength; i++) {
             var functionName = functionsList[i];
             if (functionName in ObjectWithFunctions) {
                 ObjectWithFunctions[functionName]();
             }
         }
     }
 }

 module.exports = {
     init: function () {
         if (!SitePreferences.GA4_ENABLED) {
             return;
         }
         initPageContext();
         sendTags();
         initEvents();
     }
 };
