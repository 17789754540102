"use strict";

const { getAccessToken } = require('web-auth-library/google');
const jwt = require('jsonwebtoken');

var giftcert = require("_core/giftcert"),
    tooltip = require("_core/tooltip"),
    util = require("_core_ext/util"),
    dialog = require("_core_ext/dialog"),
    page = require("_core_ext/page"),
    login = require("_core_ext/login"),
    validator = require("_core_ext/validator"),
    postcodeanywhere = require("_core_ext/postcodeanywhere"),
    globalintuitive = require("_core_ext/globalintuitive"),
    JSConstants = require("_core_ext/pages/account/constants"),
    qas = require("_core_ext/qas"),
    layout = require('_core_ext/layout'),
    perimeterx = require("_core_ext/trackers/perimeterx"),
    vouchers = require("_core_ext/pages/account/vouchers");

function initShowSuccessMessage() {
    var $message = $(".js-change_profile_message");

    if (!$message.hasClass("hidden")) {
        $message.addClass("hidden");
    }

    if (localStorage.getItem("isMessageShown") === "true") {
        setTimeout(function () {
            $message.removeClass("hidden");
        }, 1000);
    }

    localStorage.removeItem("isMessageShown");
}

function initSubmitForm(formClass, formButtonApply) {
    var $form = $(formClass);

    if ($form && $form.length && formClass === JSConstants.CLASS_CHANGE_PASS_FORM) {
        var urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('invalid') && urlParams.get('invalid') === 'true') {
            $form.valid();
        }
    }

    $form.on("submit", function (e) {
        e.preventDefault();
        var url = util.appendParamToURL($form.attr("action"), "format", "ajax");
        var applyName = $form.find(formButtonApply).attr("name");
        var $message = $(".js-change_profile_message");

        if (!$message.hasClass("hidden")) {
            $message.addClass("hidden");
        }

        if (!$form.hasClass(JSConstants.CLASS_CHANGE_PASS_FORM.substring(1)) && !$form.valid()) {
            return false;
        }

        var options = {
            url: url,
            data: $form.serialize() + "&" + applyName + "=x",
            type: "POST",
        };

        $.ajax(options).done(function (data) {
            if (data.success) {
                $("html, body").animate({ scrollTop: 0 }, "100");
                setTimeout(function () {
                    $message.removeClass("hidden");
                }, 1000);
                $form.find("input.js-password").val("");
            } else {
                var url;
                if (data.pageToRedirect === "editPassword") {
                    url = Urls.continueUrl;
                } else {
                    url = Urls.editProfile;
                }
                url = util.appendParamToURL(url, "invalid", "true");
                page.redirect(url);
            }
        });
    });
}

function initSubmitInteractiveForm(formClass, formButtonApply) {
    var $form = $(formClass);
    $form.on("submit", function (e) {
        var url = util.appendParamToURL($form.attr("action"), "format", "ajax"),
            applyName = $form.find(formButtonApply).attr("name");

        var $message = $(".js-change_profile_message");

        if ($message.hasClass("hidden")) {
            return;
        } else {
            $message.addClass("hidden");
        }

        if (!$form.valid()) {
            return false;
        }

        var options = {
            url: url,
            data: $form.serialize() + "&" + applyName + "=x",
            type: "POST",
        };

        $.ajax(options).done(function (data) {
            $form.find("input[type=password]").blur().val("");

            $("html, body").animate({ scrollTop: 0 }, "100");
            setTimeout(function () {
                $message.removeClass("hidden");
            }, 1000);
        });
    });
}

function initChangeProfileForm() {
    initSubmitForm(
        JSConstants.CLASS_CHANGE_PROFILE_FORM,
        JSConstants.CLASS_CHANGE_PROFILE_APPLY
    );
}

function initChangeLoginForm() {
    initSubmitForm(
        JSConstants.CLASS_CHANGE_LOGIN_FORM,
        JSConstants.CLASS_CHANGE_LOGIN_APPLY
    );
}

function initChangeBusinessCompanyNameForm() {
    initSubmitForm(
        JSConstants.CLASS_CHANGE_BUSINESS_NAME_FORM,
        JSConstants.CLASS_CHANGE_BUSINESS_NAME_APPLY
    );
}

function initChangePasswordForm() {
    initSubmitForm(
        JSConstants.CLASS_CHANGE_PASS_FORM,
        JSConstants.CLASS_CHANGE_PASS_APPLY
    );
}

function initChangeProfessionalForm() {
    initSubmitForm(
        JSConstants.CLASS_CHANGE_PROFESSIONAL_FORM,
        JSConstants.CLASS_CHANGE_PROFESSIONAL_APPLY
    );
}

function getCSRFToken() {
    var $csrfInput = $(JSConstants.CLASS_ADDRESS_TOKEN);
    var obj = {};

    obj[$csrfInput.attr("name")] = $csrfInput.val();

    return obj;
}

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initializeAddressForm() {
    var $form = $("#edit-address-form");

    $form.find('input[name="format"]').remove();
    tooltip.init();

    globalintuitive.init(
        {
            fieldsPrefix: "dwfrm_profile_address",
        },
        true
    );

    postcodeanywhere.init(
        {
            fieldsPrefix: "dwfrm_profile_address",
        },
        true
    );
    //$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

    qas.init(); // reinit QAS
    $(document).on("submit", JSConstants.CLASS_ADDRESS_FORM, function (e) {
        e.preventDefault();
    });

    $form
        .on("click", function () {
            validator.init();
        })
        .on("click", JSConstants.CLASS_ADDRESS_FORM_APPLY, function (e) {
            QAScheck(e, submitAddressForm, $form, $(this));
        })
        .on(
            "click touchstart",
            JSConstants.CLASS_ADDRESS_FORM_CLOSE,
            function (e) {
                e.preventDefault();
                dialog.closeSafe();
            }
        )
        .on("click", JSConstants.CLASS_ADDRESS_FORM_DELETE, function (e) {
            e.preventDefault();
            var infoMessage = String.format(
                Resources.CONFIRM_DELETE,
                Resources.TITLE_ADDRESS
            );

            if (window.confirm(infoMessage)) {
                var url = util.appendParamsToUrl(Urls.deleteAddress, {
                    AddressID: $form.find("#addressid").val(),
                    format: "ajax",
                });

                $.ajax({
                    url: url,
                    method: "POST",
                    dataType: "json",
                    data: getCSRFToken(),
                })
                    .done(function (data) {
                        if (data.status.toLowerCase() === "ok") {
                            dialog.close();
                            page.refresh();
                        } else if (data.message.length > 0) {
                            window.alert(data.message);
                            return false;
                        } else {
                            dialog.close();
                            page.refresh();
                        }
                    })
                    .fail(function (response) {
                        var handled = perimeterx.handleResponce(response);

                        if (!handled) {
                            location.reload();
                        }
                    });
            }
        });
}

function submitAddressForm($form) {
    if (!$form.valid()) {
        return false;
    }

    var url = util.appendParamToURL($form.attr("action"), "format", "ajax");
    var applyName = $form
        .find(JSConstants.CLASS_ADDRESS_FORM_APPLY)
        .attr("name");
    var options = {
        url: url,
        data: $form.serialize() + "&" + applyName + "=x",
        type: "POST",
    };

    $.ajax(options).done(function (data) {
        if (typeof data !== "string") {
            if (data.success) {
                dialog.close();
                page.refresh();
                localStorage.setItem("isMessageShown", "true");
            } else if (data.error) {
                page.redirect(Urls.csrffailed);
            } else {
                window.alert(data.message);
                return false;
            }
        } else {
            $("#dialog-container").html(data);
            account.init();
            tooltip.init();
        }
    });

    return false;
}

/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
    $(".order-items")
        .find("li.hidden:first")
        .prev("li")
        .append('<a class="toggle">View All</a>')
        .children(".toggle")
        .click(function () {
            $(this).parent().siblings("li.hidden").show();
            $(this).remove();
        });
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
    var $document = $(document);
    var $addresses = $(JSConstants.CLASS_ADDRESS_CONTAINER);
    if ($addresses.length === 0) {
        return;
    }

    $document
        .on(
            "click",
            JSConstants.CLASS_ADDRESS_CREATE +
                ", " +
                JSConstants.CLASS_ADDRESS_EDIT,
            function (e) {
                e.preventDefault();

                $.ajax({
                    type: "GET",
                    dataType: "html",
                    headers: {
                        "X-PERIMETER-TYPE": "json",
                    },
                    url: util.ajaxUrl(this.href),
                })
                    .done(function (response) {
                        if (response) {
                            dialog.open({
                                html: response,
                                options: {
                                    open: function () {
                                        initializeAddressForm();
                                        $('.ui-dialog-title').remove();
                                    },
                                    dialogClass: "address-popup",
                                    outsideClose: false,
                                },
                            });
                        }
                    })
                    .fail(function (response) {
                        perimeterx.handleResponce(response);
                    });
            }
        )
        .on("click", JSConstants.CLASS_ADDRESS_DELETE, function (e) {
            var requestData = { format: "ajax" };

            e.preventDefault();
            if (
                window.confirm(
                    String.format(
                        Resources.CONFIRM_DELETE,
                        Resources.TITLE_ADDRESS
                    )
                )
            ) {
                $.extend(requestData, getCSRFToken());

                $.ajax({
                    url: util.appendParamsToUrl(
                        $(this).attr("href"),
                        requestData
                    ),
                    dataType: "json",
                })
                    .done(function (data) {
                        if (data.status.toLowerCase() === "ok") {
                            page.redirect(Urls.addressesList);
                            localStorage.setItem("isMessageShown", "true");
                        } else if (data.message.length > 0) {
                            window.alert(data.message);
                        } else {
                            page.refresh();
                        }
                    })
                    .fail(function (response) {
                        perimeterx.handleResponce(response);
                    });
            }
        })
        .on("click", JSConstants.CLASS_ADDRESS_CHANGE_DEFAULT, function (e) {
            var requestData = { format: "ajax" };

            e.preventDefault();
            if ($(e.target).hasClass("is-active")) {
                $(this)
                    .closest(".address-list_content")
                    .find(".address-error-message")
                    .show();
                return;
            }
            $.extend(requestData, getCSRFToken());

            $.ajax({
                url: util.appendParamsToUrl($(this).attr("href"), requestData),
                dataType: "json",
            })
                .done(function () {
                    $.ajax({
                        url: util.appendParamToURL(
                            Urls.getAddressList,
                            "format",
                            "ajax"
                        ),
                    }).done(function (addressesHTML) {
                        $document
                            .find(JSConstants.CLASS_ADDRESS_CONTAINER)
                            .replaceWith($(addressesHTML));
                    });
                })
                .fail(function (response) {
                    var handled = perimeterx.handleResponce(response);

                    if (!handled) {
                        location.reload();
                    }
                });
        });
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
    $(".add-card").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href"),
            options: {
                open: initializePaymentForm,
            },
        });
    });

    var paymentList = $(JSConstants.CLASS_PAYMENT_LIST);
    if (paymentList.length === 0) {
        return;
    }

    util.deleteConfirmation(
        paymentList,
        String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD)
    );

    $('form[name="payment-remove"]').on("submit", function (e) {
        e.preventDefault();
        // override form submission in order to prevent refresh issues
        var button = $(this).find(JSConstants.CLASS_CARD_DELETE);
        $("<input/>")
            .attr({
                type: "hidden",
                name: button.attr("name"),
                value: button.attr("value") || "delete card",
            })
            .appendTo($(this));
        var data = $(this).serialize();
        $.ajax({
            type: "POST",
            url: $(this).attr("action"),
            data: data,
        }).done(function () {
            page.redirect(Urls.paymentsList);
        });
    });
}

function initializePaymentForm() {
    $("#CreditCardForm").on("click", ".cancel-button", function (e) {
        e.preventDefault();
        dialog.close();
    });
}

function scrollToAddressList() {
    $(document).on(
        "click",
        JSConstants.CLASS_ADDRESS_CONTENT_LINK,
        function (e) {
            var offset = $(this).data("offset") || 0;
            var totalOffset = $("#address-list").offset().top - offset;

            e.preventDefault();
            util.scrollBrowser(totalOffset, 500, true);
        }
    );
}

function scrollToFirstError() {
    var $errorField = $(".form-row_error").first();
    util.scrollBrowserTo($errorField, 500, true);
}

function markCurrentNavLink() {
    var url = window.location;

    $(JSConstants.CLASS_NAV_LINK)
        .filter(function () {
            return this.href === url || url.href.indexOf(this.href) === 0;
        })
        .addClass("account-nav_active");
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initializeEvents() {
    initChangeProfileForm();
    initChangeBusinessCompanyNameForm();
    initChangeLoginForm();
    initChangePasswordForm();
    initChangeProfessionalForm();
    toggleFullOrder();
    initAddressEvents();
    initPaymentEvents();
    login.init();
    markCurrentNavLink();
    scrollToAddressList();
    scrollToFirstError();
    initShowSuccessMessage();

    $(function () {
        util.checkExpiredTradeCard(JSConstants.CLASS_ACCOUNT_OVERVIEW);
    })
        .on("submit", JSConstants.CLASS_TRADE_REGISTRATION_FORM, function (e) {
            var $currentForm = $(this);
            var $submitButton = $currentForm.find('button[type="submit"]');
            QAScheck(
                e,
                submitTradeRegistrationForm,
                false,
                $submitButton,
                $currentForm
            );
        })
        .on("submit", "#js-catalogue-request", function (e) {
            var $currentForm = $(this);
            var $submitButton = $currentForm.find('button[type="submit"]');
            QAScheck(e, false, false, $submitButton, $currentForm);
        })
        .on("submit", JSConstants.CLASS_ADDRESS_FORM, function () {
            $('input[name$="_country"]').removeAttr("disabled");
        });
}

function submitTradeRegistrationForm() {
    $(JSConstants.CLASS_BIRTHDAY).val(
        $(JSConstants.CLASS_BIRTHDAY_MONTH).val() +
            "/" +
            $(JSConstants.CLASS_BIRTHDAY_DAY).val() +
            "/" +
            $(JSConstants.CLASS_BIRTHDAY_YEAR).val()
    );
}

//check Email and Mobile (via Experian) before submit
function QAScheck(e, submitFn, submitFnParams, $submitButton, $currentForm) {
    if (qas.isQASvalidationButton($submitButton)) {
        var promiseExist = qas.validate($submitButton);

        if (promiseExist) {
            e.preventDefault();
            promiseExist.then(function () {
                if (submitFn) {
                    submitFn(submitFnParams);
                }

                if ($currentForm) {
                    $currentForm.append(
                        '<input type="hidden" name="' +
                            $submitButton.attr("name") +
                            '"  />'
                    );
                    $currentForm.submit();
                }
            });
        }
    } else if (submitFn) {
        submitFn(submitFnParams);
    }
}

function initializeWallets() {
    //Google wallet
    const gwBtn = $("#js-gw-btn"),
        link = gwBtn.find('.js-gw-add');

    const credentials = {
        "type": "service_account",
        "project_id": "my-project-1501837459141",
        "private_key_id": "a34c14906a8b4e11757f21dd4e40bc9ef829461e",
        "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDDPu0egXN8tyhK\nqBPSHos9+agfYJUbA3UHnHtjNTzAnQTA1lx4OVMi637EDoOt3F18ElyJTI+TUHh6\n+n1tj5ijgrT59jfjthI/b0swh8Lc64R+wnKVtXTdraN9w6Zj9umLcEVpxUpacr3j\ncAa+NryH8EB1I0l7zmrlHz0MUqR8v/ltAKxN4J249HKQO8Xbl+CXGfuBqCWKOt1Q\nit6Hpo45tglGTeS2IF32+pKtYKKf9wIoBAhdZHTYBLMi+bRklNFz589CxUq6FKHL\nzVS2GH0EDT6jKcFGG+DSAyK9uaaVwtUpAPLdzCYKvSMoD/vPlfRmtn1GPdxDWY5C\nffY6oRLNAgMBAAECggEAA6VQaK4qoaTK5g/65MTIvVTE2KclSZ6YKBb6L9lN49va\nu54VFnKdlIyYrqEFwPCZN0ihfTfWg33wbf1w66OPilgVuIgqNYCQGSqjGQoj75uf\nOv23BYggoUYNhDrrx7iT1lR3m1jBgWxckttj6NfdT92C2p0Y0WzCjro1Wd5OL30e\nz6DdFLUme8uaLlP9m7i1dZqP4Icl9UgyX0RAiD3BmsfcNfxWoTa7cq8UbrAOYLqn\nOUBHE8n7smyKdn4xBYaVB2X4pwjlWCv+jdWJdE9Zl+x2KR47j4s2lKT4fvoQ4Pm7\n1qi58gV+hrIbhuApeBnYUe+9Egl2HYcEaNmKidD9wQKBgQD2uM4f7OHox9RF8Qja\n8Ql0LbHhTcay7t0VWt8WOOLHmqmbRE1Cd3pctPA3V42Vk2E7Spn/5P6rz11TQpjb\nmhaxudo+HvpaxVzs6RoiggqKfcz1FNr1v8p66WZ6ut7XAucOFnDOEdxH1os+1cxc\nstiIkl5jpFzWLXpDpwAYED2hDQKBgQDKlo9i5DcYvQTGZLGLiDi4mOOVIxZtwft6\n+/kfEmeN0VCGGOYYKphE2EmdBKjv6WG/+0a3Ie+YGh+8irvXwHtkm5AlJIPI3/0p\n6aL5wNR95POnGc9BI0/1wjbawF7k5xZj/V5i4Ag6oKIulqF6Inq0hHjxJezqeWue\n8KS/tJtIwQKBgQCHhryzjMwLsrlIMj9LBVzMFM3BlZewcsW/FUu2SUlJnAvO3pYS\nDM+KXVoXDGEZ6PtgNnqhtoiumBoLwml1OA9/UTgxJ5MeiYuiF+njSsu/h5suhWZB\n2DjBi7RfdocyVMXXVzxf9i+qxo921LqNsTCA1OZEleTpQdIHl5TVoxY3gQKBgQCK\nV/qe253MZPfny0d7M5EYbj+rzoQ4e6mSOJPM5jKVJAVLLaGjiyLkkJbkhRQgkU1R\nXvTRhnkpc12M0WipUyZwc9MhqifceylU/9ltmIMDXeANkpB3uPeXqgLvq0KiKjSe\nH53fA4JbFurpya+HyhflQjmc4/s/u58EsOywhVMtgQKBgQCoa5JaXIGH9Di9U4xD\nSMBmDKh4WMH3xAytq8Rzvyw7zj1lQBtKur+J2LvN9QTrG21dK6uilGVrt896KaY8\n1n8bavpghBRIZ3O9J3xRWLw2NV+C/NKVZU2ZdZsxLXMrOyOE36b43Ws81o8Qrd/b\nychkxeZfOCkrUJa50hoH7RY0Ww==\n-----END PRIVATE KEY-----\n",
        "client_email":"sally-europe-02@my-project-1501837459141.iam.gserviceaccount.com",
        "client_id": "102543877856663262789",
        "auth_uri": "https://accounts.google.com/o/oauth2/auth",
        "token_uri": "https://oauth2.googleapis.com/token",
        "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
        "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/sally-europe-02%40my-project-1501837459141.iam.gserviceaccount.com"
    };
    const baseUrl = 'https://walletobjects.googleapis.com/walletobjects/v1';
    const private_key = credentials.private_key;
    const loyaltyObjectUrl = `${baseUrl}/loyaltyObject`;

    const claims = gwBtn.data('gwjson');

    link.on('click', async function() {
        if (claims) {
            var loyaltyObject = claims.payload.loyaltyObjects[0];
            var objectId = loyaltyObject.id;

            try {
                // Obtain the access token using the web-auth-library
                const token = await getAccessToken({
                    scope: 'https://www.googleapis.com/auth/wallet_object.issuer',
                    credentials: credentials
                });

                // Set the headers for the request
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                // First, check if the loyalty object exists
                let response = await fetch(`${baseUrl}/loyaltyObject/${objectId}`, {
                    method: 'GET',
                    headers: headers
                });

                if (response.status === 404) {
                    // Object does not exist, create it
                    response = await fetch(`${baseUrl}/loyaltyObject`, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify(loyaltyObject)
                    });
                }

                // The service account credentials are used to sign the JWT for the Save to Google Pay link
                const saveToken = jwt.sign(claims, private_key, { algorithm: 'RS256' });
                window.open(`https://pay.google.com/gp/v/save/${saveToken}`, '_blank');

            } catch (error) {
                console.error('Error during Google Wallet integration:', error);
            }
        }
    });
}

var account = {
    init: function () {
        initializeEvents();
        initializeWallets();
        giftcert.init();
        qas.init();
        vouchers.init();

        var $searchAddress = $('input[name*="_search"],input.js-search-field');
        if (layout.isMobile() && $searchAddress.length) {
            globalintuitive.init({fieldsPrefix: "dwfrm_profile_address"}, true);
        }
    },
    initCartLogin: function () {
        login.init();
    },
    markCurrentNavLink: markCurrentNavLink,
};

module.exports = account;
