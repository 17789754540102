module.exports = {
    CLASS_MAIN: '.js-main',
    CLASS_BODY: 'body',
    CLASS_CAROUSEL: '.js-carousel',
    CLASS_PRODUCT_GRID : '.js-product_grid_container',
    CLASS_REFINEMENTS_HEADER: '.js-refinements_header',
    CLASS_REFINEMENTS_CONTAINER: '.js-refinements_container',
    CLASS_PRICE_REFINEMENT: '.js-custom_price_refinement',
    CLASS_PAGINATION_CONTAINER: '.js-pagination',
    CLASS_MIN_PRICE: '.js-min_price',
    CLASS_MAX_PRICE: '.js-max_price',
    CLASS_REFINEMENT_PRICE: '.js-refinement_price',
    CLASS_SORTINGRULES_SELECT: '.js-sorting_rules_select',
    CLASS_CATEGORY_REFINEMENT: '.js-category_refinement',
    CLASS_FOLDER_REFINEMENT: '.js-folder_refinement',
    CLASS_UNSELECTABLE: 'js-unselectable',
    CLASS_REFINEMENT_BRAND: '.js-refinement_brand',
    CLASS_REFINEMENT_VENUE: '.js-refinement_venue',
    CLASS_UI_SLIDER: '.js-ui_slider',
    CLASS_MIN_LABEL: '.js-min_label',
    CLASS_MAX_LABEL: '.js-max_label',
    CLASS_BREADCRUMB_REFINEMENT_VALUE: '.js-breadcrumb_refinement_value',
    CLASS_REFINEMENT_LINK: '.js-refinement_link',
    CLASS_MYACCOUNT_INFO: '.js-myaccount-info',
    CLASS_MOBILE_SEARCH_BTN_REG: '.js-mobile-search-button-registered',
    CLASS_MOBILE_SEARCH_BTN_UNREG: '.js-mobile-search-button-unregistered',
    CLASS_SHOW_SEARCH_INPUT: '.js-show-search-input',
    CLASS_SEARCH_SECTION_MOBILE: '.js-search-section-mobile',
    CLASS_SEARCH_SECTION: '.js-search-section',
    CLASS_HEADER_SEARCH_OVERLAY: '.header-search_overlay',
    CLASS_REFINEMENT_BRANDS_SEARCH: '.js-refinement_brands_search',
    CLASS_REFINEMENT_VENUE_SEARCH: '.js-refinement_venue_search',
    CLASS_SORT_BY_LINK: '.js-sort-by-label',
    CLASS_FILTER_BY_LINK: '.js-filter-by-label',
    CLASS_SORT_REFINEMENT_WRAPPER: '.js-refinement-wrapper',
    CLASS_PLP_SEARCH_OPTIONS: '.js-plp-search_options',
    CLASS_REFINEMENT_CLOSE: '.js-refinement-close',
    CLASS_REFINEMENT_APPLY:  '.js-refinement-apply',
    CLASS_REFINEMENT_CLOSE_SECTION: '.js-refinement-close-section',
    CLASS_REFINEMENT_SECTION: '.js-refinement-section',
    CLASS_REFINEMENT_DIALOG: '.js-refinement-dialog',
    CLASS_ITEMS_PER_PAGE: '.js-items-per-page',
    CLASS_REFINEMENT_HEADER_SEARCH: '.js-refinement-header-search',
    CLASS_TOGGLE_GRID: '.js-toggle-grid',
    CLASS_TILE_CONTAINER: '.js-tile_container',
    CLASS_HEADER_MIDDLE: '.js-header-middle',
    CLASS_NO_HITS_INPUT: '.js-no-hits-input',
    CLASS_NO_HITS_BUTTON: '.js-no-hits-button',
    CLASS_NO_SEARCH_CONTAINER: '.js-no-search-container',
    CLASS_NO_SEARCH_FORM: '.js-no-search-form',
    CLASS_HEADER_SEARCH_INPUT: '.js-header-search-input',
    CLASS_SEARCH_BREADCRUMBS: '.js-breadcrumbs',
    CLASS_SEARCH_BACK: '.js-back_to_products',

    CLASS_SEARCH_READMORE: '.js-description-readmore',
    CLASS_SEARCH_READLESS: '.js-description-readless',
    CLASS_SEARCH_FULLDESC: '.js-full-description',

    CLASS_PLP_LOADER: '.js-plp-loader',
    CLASS_SEARCH_LOADMORE: '.js-load-more',
    CLASS_SCROLL_PLACEHOLDER: '.js-scroll-placeholder',
    CLASS_SEARCH_SUBMIT: '.js-search-submit',
    CLASS_SEARCH_PLACEHOLDER: '.js-search-placeholder',

    CLASS_NOTIFY_ME_TILE: '.js-notify_me_tile',
    CLASS_BACKINSTOCK_SUCCESS_MSG: '.js-backinstock_success-message',
    CLASS_BACKINSTOCK_ERROR_MSG: '.js-backinstock_error-message',
    CLASS_BACKINSTOCK_CATEGORY_LINK: '.js-backinstock-exploremore_link',
    CLASS_BACKINSTOCK_FORM: '.js-backinstock-form',
    CLASS_BACKINSTOCK_EMAIL: '.js_backinstock_email',
    CLASS_BACKINSTOCK_CLOSE: '.js-backinstock-close',
    CLASS_BACKINSTOCK_TITLE_DEFAULT: '.js-backinstock_title-default',
    CLASS_BACKINSTOCK_TITLE_SUCCESS: '.js-backinstock_title-success',
};
