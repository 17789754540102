"use strict";

const showElements = (elements) => {
    elements.forEach(element => {
        element.length ? showElements(element) : element.classList.remove('hidden');
    })
};

const hideElements = (elements) => {
    elements.forEach(element => {
        element.length ? hideElements(element) : element.classList.add('hidden');
    })
};

const toggleEmptyOffersState = (alloffers) => {
    const offerUndoContainer = document.querySelector('.js-offer-undo-container');
    const currentUndoContainer = alloffers ? document.querySelector('.js-alloffers-undo-container') : offerUndoContainer;
    const emptyPageContainer = document.querySelector('.js-empty-offers-container');
    const offersContainer = document.querySelector('.js-offers-conteiner');
    const clearAllContainers = document.querySelectorAll('.js-alloffers-clear-container');
    const communicationPreferencesContainers = document.querySelectorAll('.js-communication-preferences');
    const optionsMenu = document.querySelector('.js-all-offers-options-menu');
    const emptyStateElements = [currentUndoContainer, emptyPageContainer];
    const offersStateElements = [clearAllContainers, communicationPreferencesContainers, offersContainer];
    const isoffersStateActive = emptyPageContainer.classList.contains('hidden');

    if (isoffersStateActive) {
        if (alloffers) offersStateElements.push(offerUndoContainer, optionsMenu);
        showElements(emptyStateElements);
        hideElements(offersStateElements);
        window.scrollTo(0, 0);
    } else {
        showElements(offersStateElements);
        hideElements(emptyStateElements);
    }
};

const request = async (url) => {
    const data  = await fetch (
        url,
        {
            'headers': {
                'Content-type': 'application/json',
            }
        })
        .then(req => {
            if (req.status !== 200) {
                return Promise.reject(new Error(response.statusText))
            }
            return req.json();
        })
        .catch(error => console.log('error', error))
    return data;
};

exports.request = request;
exports.toggleEmptyOffersState = toggleEmptyOffersState;
