'use strict';

var JSConstants = require('_core_ext/constants'),
    CSSConstants = require('_core_ext/cssconstants');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var initialized, $mainWrapper;
var allowedPages = {
    addNotification: ['clp', 'product', 'search', 'storefront', 'orderconfirmation', 'storedetails', 'wishlist']
};

function _showNotificationWrapper() {
    if ($mainWrapper && $mainWrapper.length) {
        $mainWrapper.addClass(CSSConstants.ACTIVE);

        timer.start(2000, function() {
            closeNotification();
        });
    }
}

function closeNotification() {
    if ($mainWrapper && $mainWrapper.length) {
        $mainWrapper.removeClass(CSSConstants.ACTIVE);
        timer.clear();
    }
}

function showAddToCardNotification (count) {
    var $msgWrapper = $(JSConstants.ID_ADD_CARD_NOTIFICATION);
    var str = Resources.TOP_NOTIFICATION_ADDTO;
    str = typeof count === 'number' ? str.replace(/\{0}/g, parseInt(count)) : str;

    if (pageContext.ns !== 'wishlist') {
        showNotification($msgWrapper, str, allowedPages.addNotification);
    }
}

function showOutStockNotification (count) {
    var $msgWrapper = $(JSConstants.ID_OUT_STOCK_NOTIFICATION);
    var str = Resources.TOP_NOTIFICATION_OUTSTOCK;
    str = typeof count === 'number' ? str.replace(/\{0}/g, parseInt(count)) : str;

    showNotification($msgWrapper, str, allowedPages.addNotification);
}

function showMessageNotification (message , count) {
    var $msgWrapper = $(JSConstants.ID_OUT_STOCK_NOTIFICATION);

    if (message && message.length) {
        message = typeof count === 'number' ? message.replace(/\{0}/g, parseInt(count)) : message;
        showNotification($msgWrapper, message, allowedPages.addNotification);
    }
}

function showNotification($elem, string, pages) {
    var allowedPage = pages && pages.length && ~pages.indexOf(pageContext.ns);

    if ($elem && $elem.length && allowedPage) {
        $elem.find(JSConstants.CLASS_TOP_NOTIFICATION_TEXT).text(string);

        _setVisibleOnly($elem);
        _showNotificationWrapper();
    }
}

function _setVisibleOnly($elem) {
    if ($mainWrapper && $mainWrapper.length) {
        $mainWrapper.find(JSConstants.CLASS_TOP_NOTIFICATION_MSG).each(function() {
            var $cur = $(this);

            if ($elem && $cur.is($elem)) {
                $cur.show();
            } else {
                $cur.hide();
            }
        });
    }
}

function initializeEvents() {
    $mainWrapper = $(JSConstants.CLASS_TOP_NOTIFICATION);

    $(document).on('click', JSConstants.CLASS_TOP_NOTIFICATION_CLOSE, function() {
        closeNotification();
    });
}

module.exports = {
    init: function() {
        if (initialized) {
            return;
        }
        initializeEvents();
        initialized = true;
    },
    showAddToCard: showAddToCardNotification,
    showOutStock: showOutStockNotification,
    showMessage: showMessageNotification
};
