'use strict';

var util = require('_core_ext/util'),
    urls = require('urls'),
    carousel = require('_core_ext/carousel'),
    JSConstants = require('_core_ext/pages/product/constants');

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
}

function initializeEvents() {
    const emarsysCarouselEnable = document.querySelector('#predict-recs');
    if (!emarsysCarouselEnable) {
        return;
    }
    $cache.document.on('emarsys.predict', function(e, SC) {
        var pids = $.map(SC.page.products, function(n) {
            return n.id;
        });

        var requestUrl = util.appendParamsToUrl(urls.productTiles, {'pids': pids.join('|')});
        requestUrl = util.appendParamsToUrl(requestUrl, {'context': 'emarsys-recommendations'});
        requestUrl = util.appendParamsToUrl(requestUrl, {'originalAction': Resources.ACTION});
        if (Resources.ACTION_PARAMS) {
            requestUrl = util.appendParamsToUrl(requestUrl, {'originalActionParams': Resources.ACTION_PARAMS});
        }

        $.ajax({
            type: 'GET',
            url: requestUrl
        }).done(function(response) {
            response = typeof response === 'string' ? response.trim() : response;
            var $container = $(SC.recommender.container);
            var $relatedBlocks = $('.js-predict-header');
            $container.html(response);
            carousel.initCarousel($container.find(JSConstants.CLASS_CAROUSEL));

            if ($container.children().length > 0) {
                $relatedBlocks.removeClass('hidden');
            }
        });
    });
}

var emarsys = {
    init: function() {
        initializeCache();
        initializeEvents();
    }
};

module.exports = emarsys;