'use strict';
require('slick-carousel');
const progress = require('_core_ext/progress');

function loadSlidesContent(carouselWrapper) {
    const activeSlides = carouselWrapper.querySelectorAll('.slick-slide.slick-active');
    activeSlides.forEach(function(slide) {
        const contentContainer = slide.querySelector('[data-content-link]');
        const backgroundImageElement = slide.querySelector('[data-background-image]');

        if (contentContainer) {
            progress.show(contentContainer);
            const contentLink = contentContainer.getAttribute('data-content-link');
            $.ajax({
                type: 'GET',
                url: contentLink
            }).done(function(response) {
                if (response) {
                    const sameContentContainers = carouselWrapper.querySelectorAll(`div[data-content-link="${contentLink}"]`);
                    sameContentContainers.forEach(function(contentContainer) {
                        contentContainer.innerHTML = response;
                        contentContainer.removeAttribute('data-content-link');
                        const img = contentContainer.querySelector('img');
                        img.onload = () => progress.hide();
                    });
                } else {
                    const sameContentContainers = carouselWrapper.querySelectorAll(`div[data-content-link="${contentLink}"]`);
                    sameContentContainers.forEach(function(contentContainer) {
                        contentContainer.innerHTML = "";
                        contentContainer.removeAttribute('data-content-link');
                        progress.hide();
                    });
                }
            });
        }

        if (backgroundImageElement) {
            const imageLink = backgroundImageElement.getAttribute('data-background-image');
            const samebackgroundImageElements = carouselWrapper.querySelectorAll(`div[data-background-image="${imageLink}"]`);
            samebackgroundImageElements.forEach(function(imageElement) {
                imageElement.style.backgroundImage = `url('${imageLink}')`;
                imageElement.removeAttribute('data-background-image');
            });
        }
    });
}


const loadSlides = {
    loadSlidesContent: function (carouselWrapper) {
        loadSlidesContent(carouselWrapper);
    }
};

module.exports = loadSlides;
