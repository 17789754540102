'use strict';

var initialized = false;

var layout = require('_core_ext/layout');

function setActiveElement($carousel) {
    var $activeChildren = $carousel.children('.slick-active'),
        activeChildrenLength = $activeChildren.length;

    $carousel.children().removeClass('is-active');

    if (activeChildrenLength > 1) {
        $activeChildren.eq(1).addClass('is-active');
    } else if (activeChildrenLength > 0) {
        $activeChildren.eq(0).addClass('is-active');
    }
}

function initializeEvents() {
    if (layout.isDesktop() || layout.isTablet()) {
        $('.js-hp-reviews-section')
            .on('mouseenter', '.slick-slide', function () {
                var $elm = $(this),
                    $parent = $elm.parent();

                $parent.children().removeClass('is-active');
                $elm.addClass('is-active');
            })
            .on('mouseleave', '.slick-slide', function () {
                setActiveElement($(this).parent());
            })
            .on('init reInit setPosition swipe', '.hp-reviews-carousel', function () {
                setActiveElement($('.js-hp-reviews-section .slick-track'));
            });
    }
}

module.exports = {
    'init' : function () {
        if (initialized) {
            return;
        }
        initializeEvents();
        initialized = true;
    }
};
