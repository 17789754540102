'use strict';

var JSConstants = require('../constants'),
    GoogleMap = require('_core_ext/reservecollect/google-map'),
    _ = require('lodash'),
    Utils = require('_core_ext/reservecollect/utils');

var MainMap,
    store = null;

const MAX_STORES_CNT = SitePreferences.MAX_NEAREST_STORES_QUANTITY;

/**
 * Receives Google map from JSONP call and invokes initReserveCollectlocatorMap function
 */
function getGoogleMapFromAPI() {

    var data = {
        key: SitePreferences.GOOGLE_API_KEY,
        libraries: 'geometry,places',
        region: Utils.getRegionByLocale(SitePreferences.LOCALE),
        language: SitePreferences.LOCALE
    };

    $.ajax({
        url: Urls.googlemapsAPIUrl,
        data: data,
        dataType: 'jsonp',
        complete: function (data) {
            if (data.status === 200) {
                store.state.initialized = true;
                createMainMap();
                preselectStore();
            }
        }
    });
}

/**
 * Centers map by a nearest store and shows it on a store 'search stores' dialog window
 */
function preselectStore() {
    var selectedStoreId = store.state.selectedStoreId;

    if (selectedStoreId) {
        var index = _.findIndex(store.state.storeList, function (storeItem) {
            return String(storeItem.id) === selectedStoreId;
        });
        var selectedStore = store.state.storeList[index];

        if (selectedStore) {
            MainMap.centerByNearestStore(selectedStore.latitude, selectedStore.longitude);
        }
    }
}

/**
 * Creates a map on stores search page
 */
function createMainMap() {
    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).append(Handlebars.templates.RESERVE_storeNearestDialog({isAddToCardDialog: store.state.isPDPPage || store.state.isPLPPage}));
    MainMap = GoogleMap();
    MainMap.init($(JSConstants.ID_STORE_SEARCH_MAP_CONTAINER)[0]);
    showStoresOnMap(store.state.storeList, MainMap);
    initAutoComplete();
    store.state.MainMap = MainMap;
}

/**
 * Inits address autocomplete events
 */
function initAutoComplete() {
    var $inputElement = $(JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT);
    MainMap.initAutoCompleteEvents($inputElement[0], function () {
        var valueStr = $inputElement.val();
        store.state.controlesState.values(
            JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT,
            valueStr
        );
        store.events.publish('searchByStr', [valueStr]);
    });
}

/**
 * Shows stores on map. And assign click events to markers. Shows the whole map if store is not selected.
 * @param stores
 * @param map
 */
function showStoresOnMap(stores, map) {
    var storeMarkers = map.getStoreMarkers(stores, onMarkerClick);
    map.setStoreBounds(storeMarkers);
}

/**
 * Render store details dialog windows on marker click
 * @param customData
 */
function onMarkerClick(customData) {
    $(JSConstants.CLASS_STORES_SELECT).closest(JSConstants.CLASS_STORE_SELECT).remove();
    $(JSConstants.ID_STORE_DETAILS_MAP_CONTAINER).remove();
    store.state.selectedStoreLocation = customData.position;
    store.events.publish('renderStoreDetails', [customData.id, customData.distanceText]);
}

/**
 * Creates a map on the store details page and shows selected store on it.
 * Copies most of the values from a store search map.
 */
function createStoreDetailsMap() {
    var StoreDetailsMap = GoogleMap();
    StoreDetailsMap.init($(JSConstants.ID_STORE_DETAILS_MAP_CONTAINER)[0]);

    var mainMapBounds = MainMap.getMapBounds();
    var mainMapCenter = MainMap.getMapCenter();
    var mainMapZoom = MainMap.getMapZoom();
    var mainMarkerPosition = MainMap.getMarkerPosition();

    StoreDetailsMap.setMapCenter(mainMapCenter);
    StoreDetailsMap.setMapZoom(mainMapZoom);
    StoreDetailsMap.setMarkerPosition(mainMarkerPosition);
    StoreDetailsMap.getStoreMarkers(store.state.storeList, onMarkerClick);

    if (store.state.selectedStoreLocation) {
        StoreDetailsMap.centerMap(store.state.selectedStoreLocation, true);
    } else {
        StoreDetailsMap.setMapBounds(mainMapBounds);
    }
}

/**
 * Returns nearest stores in radius assigned by a site preference. And sorts it by distance.
 */
function getNearestStores(stores, map) {
    var nearestStoresInRadius = map.getNearestStoresInRadius(stores, parseFloat(SitePreferences.NEAREST_STORES_RADIUS));

    if (nearestStoresInRadius.length > 0) {
        return nearestStoresInRadius.sort(Utils.compareDistance);
    }
    var nearestStores = map.calculateDistanceToStores(stores);
    return nearestStores.sort(Utils.compareDistance);
}

/**
 * Receives nearest stores and assignees them to the nearestStores global variable.
 * Shows nearest store on the Store search dialog map and centers map by a location of that store.
 * @TODO I think that this method should be renamed in some other way.
 */
function renderNearestStores() {
    var parsedfullStoreList;
    var fullStoreListNeedToBeSet = true; // true by default for example for case when no sessionStorage.getItem('fullStoreList')

    if (sessionStorage.getItem('fullStoreList')) {

        parsedfullStoreList = JSON.parse(sessionStorage.getItem('fullStoreList'));

        var isSelectDefaltStorePageFormStorage = parsedfullStoreList[0] && parsedfullStoreList[0].isSelectDefaltStorePage;
        var isSelectDefaltStorePageFormStoreList = store.state.storeList[0] && store.state.storeList[0].isSelectDefaltStorePage;

        // left the same fullStoreList only if list if source page has not been changed SelectDefaltStorePage vs PLP
        if (isSelectDefaltStorePageFormStoreList === isSelectDefaltStorePageFormStorage) {
            fullStoreListNeedToBeSet = false;
        }
    }

    if (fullStoreListNeedToBeSet) {
        sessionStorage.setItem('fullStoreList', JSON.stringify(store.state.storeList));

        parsedfullStoreList = JSON.parse(sessionStorage.getItem('fullStoreList'));
    }

    var nearestStores = getNearestStores(parsedfullStoreList, MainMap);

    if (store.state.isPDPPage || store.state.isPLPPage) {
        store.state.paginatedStores = Utils.paginateStores(nearestStores, MAX_STORES_CNT);
        nearestStores.length = MAX_STORES_CNT;
        store.state.paginatedStores.shift();
    }
    store.state.storeList = nearestStores;
    store.state.nearestStores = nearestStores;

    var nearestStore = store.state.nearestStores[0];

    MainMap.centerByNearestStore(nearestStore.latitude, nearestStore.longitude);

    store.events.publish('renderStoresList', store.state.nearestStores);
    store.events.publish('resetMainMap');
}

/**
 * Searches stores by a nearest user location. Location must be turned on in browser.
 */
function searchByCurrentLocation() {
    window.navigator.geolocation.getCurrentPosition(function (position) {
        var userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
        };
        MainMap.searchByCurrentLocation(userLocation, function (address) { store.events.publish('fillAddressField', [address]); });
    });
}

/**
 * Searches stores by a address string.
 */
function searchByStr(addressStr) {
    MainMap.searchByAddressStr(addressStr, function (address) { store.events.publish('fillAddressField', [address]); });
}

function resetMainMap() {
    MainMap = GoogleMap();
    MainMap.init($(JSConstants.ID_STORE_SEARCH_MAP_CONTAINER)[0]);
    showStoresOnMap(store.state.storeList, MainMap);
    store.state.MainMap = MainMap;
}

function initStoreEvents() {
    store.events.subscribe('createStoreDetailsMap', function () {
        createStoreDetailsMap();
    });

    store.events.subscribe('createMainMap', function () {
        createMainMap();
    });

    store.events.subscribe('preselectStore', function () {
        preselectStore();
    });

    store.events.subscribe('getGoogleMapFromAPI', function () {
        getGoogleMapFromAPI();
    });

    store.events.subscribe('searchByCurrentLocation', function () {
        searchByCurrentLocation();
    });

    store.events.subscribe('renderNearestStores', function () {
        renderNearestStores();
    });

    store.events.subscribe('searchByStr', function (args) {
        searchByStr.apply(null, args);
    });

    store.events.subscribe('resetMainMap', function () {
        resetMainMap();
    });
}

module.exports = {
    init: function (_store) {
        store = _store;
        initStoreEvents();
    }
};