'use strict';

require('slick-carousel');
var carouselLazyLoud = require('_core_ext/carouselLazyLoud');

var options = require('./config-carousel'),
    layout = require('_core_ext/layout'),
    JSCommonConstants = require('_core_ext/constants'),
    productTile = require('_core_ext/product-tile');

var events = ['afterChange', 'beforeChange', 'breakpoint', 'destroy', 'edge', 'init', 'reInit', 'setPosition', 'swipe', 'lazyLoaded', 'lazyLoadError'],
    initialized = false;

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function setHeroDesktop($item) {

    $item.each(function() {

        var $this = $(this),
            imagesSrc,
            elementView,
            heroImage = $this.find(options.heroImage);

        if (heroImage.length > 0) {
            imagesSrc = heroImage.attr('src');
        } else {
            heroImage = $this.find('noscript');

            if (heroImage.length > 0 && heroImage.data('l-src')) {
                imagesSrc = heroImage.data('l-src');
            } else if (heroImage.length > 0 && heroImage.data('default-src')) {
                imagesSrc = heroImage.data('default-src');
            }
        }

        if (imagesSrc) {
            elementView = {
                backgroundImage: 'url(' + imagesSrc + ')',
                height: $this.data('height')
            };

            $this.css(elementView);
        }
    });
}

function checkHeroCarousel() {
    var $carouselItem = $(this).find(options.heroItem);
    layout.isDesktop() ? setHeroDesktop($carouselItem) : $carouselItem.removeAttr('style');
}

function initializeSlick() {
    var $carousel = $(this),
        carouselOptions = $carousel.data('slick');
    carouselOptions = $.extend({}, options, carouselOptions);
    if (carouselOptions.events) {
        for (var i in events) {
            if (carouselOptions.events[events[i]]) {
                $carousel.on(events[i], carouselOptions.events[events[i]], this);
            }
        }
    }

    var isMobile = layout.isMobile(),
        isTablet = layout.isTablet(),
        imgLazyloadSelector = 'img' + JSCommonConstants.CLASS_IMAGES_CAROUSEL_LAZYLOAD;

    $(imgLazyloadSelector).each(function() {
        var $this = $(this);

        $this.attr('data-lazy', $this.data(
            isMobile && 'mobileSrc' ||
            isTablet && 'tabletSrc' ||
            'desktopSrc'
        ));
    });

    // Check if Carousel is Hero
    if ($carousel.hasClass(options.heroClass)) {
       checkHeroCarousel.call(this);
    }

    $carousel.children().each(function() {
        var $this = $(this);
        if (!/[\S]/.test($this.html())) {
            $this.remove();
        }
    });

    carouselLazyLoud.loadSlidesContent($carousel[0]);

    $carousel.on('afterChange', function() {
        carouselLazyLoud.loadSlidesContent($carousel[0]);
    });

    $carousel.on('init', function() {
        carouselLazyLoud.loadSlidesContent($carousel[0]);
    });

    $carousel.slick(carouselOptions);
    $carousel.slick('resize');
}

function initializeAllCarousels() {
    $(this).find(options.baseClass).each(initializeSlick);
}

function initializeCarousel(container, callback) {
    if (!container) {
        return;
    }
    if (callback) {
        $(container).one('init', callback);
    }
    initializeSlick.call(container);
}

function initializeEvents() {
    $(document).on('window.modechanged', function() {
        if ($(this).find(options.baseClass).hasClass(options.heroClass)) {
            checkHeroCarousel.call(this);
        }
    });

    $(document).on('enquire.done', function(event, element) {
        var $this = $(element);
        if ($this.hasClass(options.heroClass)) {
            checkHeroCarousel.call($this);
        }
    });
}

module.exports = {
    init: function(params) {
        if (initialized) {
            return;
        }
        initializeParams(params);
        initializeAllCarousels.call(params.container || document);
        initializeEvents();
        productTile.initBackInStock();
        initialized = true;
    },
    initCarousel: function(element, callback) {
        initializeCarousel(element, callback);
    },
    initCarousels: function(elements, callback) {
        $(elements).each(function() {
            if (!$(this).hasClass('slick-initialized')) {
                initializeCarousel(this, callback);
            }
        });
    }
};
