module.exports = {
    /*
     * Global
     */
    ELEMENTS_HTML_BODY          : 'html, body',
    CLASS_BILLING_FORM          : '.js-checkout_billing',
    CLASS_SHIPPING_FORM         : '.js-checkout_shipping',
    CLASS_PAYMENT_METHODS       : '.js-payment_method_options',
    CLASS_PAYMENT_CC_WRAPPER    : '.js-payment_method_credit_card',
    CLASS_PAYMENT_HEADER        : '.js-payment-header',
    CLASS_ADDRESS               : '.js-address',
    CLASS_VALIDATE_IGNORE       : 'js-validate-ignore',
    CLASS_SELECT_ADDRESS        : '.js-select_address',
    CLASS_LOADER_OVERLAY        : '.js-loader-overlay',

    CLASS_PLACE_ORDER_BUTTON    : '.js-checkout-continue',
    CLASS_CHECKOUT_SAVE         : '.js-checkout_save',
    CLASS_PAYPAL_SELECTOR       : '.js-paypal-button',
    CLASS_CARDNUMBER_FIELD      : '.js-cardnumber',
    CLASS_CARDCVN_FIELD         : '.js-cardcvn',
    CLASS_FORM_ROW              : '.js-form_row',
    CLASS_MINI_CART_HEADER      : '.js-header-cart',
     /*
     * VipPoints integration
     */
    CLASS_VP_CONTAINER          : '.js-vp_wrapper',
    // actions
    CLASS_VP_CHECKBALANCE       : '.js-vp_action_checkbalance',
    CLASS_VP_REDEEM             : '.js-vp_action_redeem',
    CLASS_VP_USE                : '.js-vp_action_use',
    CLASS_VP_REMOVE             : '.js-vp_action_remove',
    // containers
    CLASS_VP_INPUT              : '.js-vp_input',
    CLASS_VP_INPUT_AFTER        : '.js-vp_input_after',
    CLASS_VP_CHECK_CONTAINER    : '.js-vp-check-container',
    CLASS_VP_RESULT_WRAPPER     : '.js-vp_result_wrapper',
    CLASS_VP_RESULT             : '.js-vp_result',
    CLASS_VP_USE_MSG            : '.js-vp_use_message',
    CLASS_VP_USE_MSG_WRAPPER    : '.js-vp_use_message_wrapper',

    /*
     * Gift Cards integration
     */

    CLASS_GC_CONTAINER          : '.js-gc-wrapper',
    CLASS_GC_CONTENT            : '.js-giftcard-method',
    // actions
    CLASS_GC_CHECKBALANCE       : '.js-gc-action-checkbalance',
    CLASS_GC_APPLYGIFTCARD      : '.js-gc-action-apply',
    CLASS_GC_REMOVEGIFTCARD     : '.js-gc-action-remove',
    // containers
    CLASS_GC_CODE               : '.js-gc-cardnumber',
    CLASS_GC_PIN                : '.js-gc-cardpin',
    CLASS_GC_AMOUNT             : '.js-gc-cardamount',
    CLASS_GC_BALANCEWRAPPER     : '.js-gc-balance-wrapper',
    CLASS_GC_RESULTWRAPPER      : '.js-gc-result-wrapper',
    CLASS_GC_RESULTMSG          : '.js-gc-result',
    CLASS_GC_APPLYWRAPPER       : '.js-gc-amountapply-wrapper',
    CLASS_GC_USE_MSG            : '.js-gc-use-message',
    CLASS_GC_USEMSGWRAPPER      : '.js-gc-use-message-wrapper',
    CLASS_GC_ERROR              : '.js-gc-error',
    CLASS_GC_CURRENCY_SYMBOL    : '.js-gc_currency_symbol',

    /*
     * Selectors for instore pickup
     */
    CLASS_POSTCODE_FIELD : '.js-delivery_to_store',
    CLASS_POSTCODE_BUTTON : '.js-delivery_to_store_button',
    CLASS_POSTCODE_FORM : '.js-postcode_form',
    CLASS_STORE : '.js-store',
    CLASS_TAB_HEADER : '.js-tab_header',
    CLASS_CHOOSEN_STORE : '.js-choosen_store',
    CLASS_DELIVERY_TO_STORE_SECTION : '.js-delivery_to_store_section',
    CLASS_SHOW_ALL_STORES : '.js-show-all-postcode-stores',
    CLASS_CHOOSE_AGAIN : '.js-choose_again',
    CLASS_CHOOSE_STORE : '.js-chose_store',
    CLASS_SHIPPING_METHOD_LIST: '.js-shipping_method_list',
    CLASS_SHIPING_ERROR_MESSAGE: '.js-shipping_error_message',
    CLASS_DELIVERY_POSTCODE_ERROR : '.js-delivery_postcode_error',
    CLASS_POSTCODE_EDIT_MESSAGE: '.js-postcode_edit_message',
    CLASS_POSTCODE_EDIT_LINK: '.js-postcode_edit_link',
    CLASS_POSTCODE_FIND_BUTTON: '.js-postcode_find_button',
    CLASS_DELIVERYTOSTORE_POSTAL: '.js-deliverytostore_postcode',
    CLASS_STORE_PARAMS: '.js-store_params',
    CLASS_COUNTRY_FIELD: '.js-country_field',
    CLASS_HOME_DELIVERY: '.js-home_delivery',
    CLASS_CLICK_AND_COLLECT_DELIVERY: '.js-cc_delivery',
    CLASS_SHIPPING_FIELD: '.js-shipping_field',
    CLASS_SHIPPING_POSTAL: '.js-shipping_postal',
    CLASS_SHIPPING_COUNTY: '.js-shipping_county',
    CLASS_SHIPPING_CITY: '.js-shipping_city',
    CLASS_SHIPPING_CHANGED : '.js-shipping_changed',
    CLASS_SHIPPING_DIFFERENT_COST_APPLIED: '.js-shipping-different_cost_applied',

    /*
     * Gift Wrapping selectors
     */
    CLASS_GIFT_MESSAGE_TEXT : '.js-gift-message-text',
    CLASS_GIFT_WRAPPING_ITEM : '.js-gift_wrapping-item',
    CLASS_NICESCROLL_VIEWPORT: '.js-postcode_finder-viewport',

    /*
     * Delivery options selectors
     */
    CLASS_DELIVERY_OPTION : '.js-delivery_form_row',

    /*
     * Credit Cards
     */
    CLASS_CREDIT_CARD_NUMBER: '.js-creditcard-number',
    CLASS_PAYMENT_METHOD_IMAGE: '.js-payment-method-image',
    CLASS_CREDIT_CARD_TYPE: '.js-creditcard-type',
    /*
     * Confirmation register
     */
    CLASS_ORDERDETAILS_REGISTRATION     : '.js-orderdetails_registration',
    CLASS_CONFIRMATION_REGISTER_FORM    : '.js-confirmation_register_form',
    CLASS_CONFIRNATION_REGISTER_CONTENT : '.js-confirmation_register_content',
    CLASS_CONFIRMATION_REGISTER_ERROR   : '.js-confirmation_register_error',

    /*
     * Size Guide
     */
    CLASS_SIZE_CHART_lINK: '.js-size_chart_link',
    CLASS_SIZE_CHART_NICESCROLL_VIEWPORT: '.js-size_chart_viewport',

    /*
     * Billing page
     */
    CLASS_BILLING_ADDRESS_FORM : '.js-billing_address',
    CLASS_USE_SHIPPING_AS_BILLING : '.js-use_shipping_address',
    CLASS_PAYMENT_METHOD_CONTENT : '.js-paymentmethod_content',
    CLASS_PAYMENT_METHOD_EXPANDED : '.js-paymentmethod_expanded',
    CLASS_PAYMENT_METHOD_RADIO : '.js-paymentmethod_radio',
    CLASS_PRINT_RECEIPT: '.js-confirmation_print',
    CLASS_CREDITCARD_DATA: '.js-creditcard_data',
    CLASS_CREDITCARD_SAVED_DATA: '.js-savedcard_data',
    CLASS_CREDITCARD_SAVED_CARDHOLDER: '.js-savedcard_cardholder',
    CLASS_CREDITCARD_SAVED_CARDTYPE: '.js-savedcard_cardtype',
    CLASS_CREDITCARD_SAVED_CARDNUMBER: '.js-savedcard_cardnumber',
    CLASS_CREDITCARD_SAVED_CARDEXPIRATION: '.js-savedcard_cardexpiration',
    CLASS_CREDITCARD_SAVED_CSC: '.js-cardcvn_saved',
    CLASS_CREDITCARD_EXPIRATION_ERROR: '.js-expiration_error',

    /*
     * Google maps
     */
    CLASS_GOOLEMAPS_MAP : '.js-googlemaps-map',
    CLASS_NEAREST_STORE : '.js-googlemaps-nearestStore',
    CLASS_SELECT_STORE : '.js-googlemaps-selectStore',
    CLASS_ADDRESS_STORE : '.js-googlemaps-address',
    CLASS_NEAREST_STORES_BLOCK : '.js-googlemaps-nearestStoresBlock',
    CLASS_SELECTED_STORE_BLOCK : '.js-googlemaps-selectedStoreBlock',
    CLASS_SHOW_NEAREST_STORE_LINK : '.js-googlemaps-showNearestStoresLink',
    CLASS_NEAREST_STORE_RADIO_BTN : '.js-googlemaps-nearestStoreRadioBtn',
    CLASS_GOOFLEMAPS_TABS : '.js-googlemaps-tabs',
    CLASS_SELECTED_STORE_FOR_COLLECTION : '.js-googlemaps-selectedStoreForCollection',
    CLASS_FAKE_LINK : '.js-fakeLink',
    CLASS_MSG_BOX_CONTAINER: '.js-msgBoxContainer',
    CLASS_PROCEED_TO_PAYMENT_BTN: '.js-proceedToPaymentBtn'
};