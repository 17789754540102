'use strict';

var util = require('_core_ext/util'),
    JSConstants = require('_core_ext/pages/checkout/constants'),
    formPrepare = require('_core_ext/pages/checkout/formPrepare');

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function() {
    var $form = $(JSConstants.CLASS_ADDRESS);
    // select address from list
    $('select[name$="_addressList"]', $form).on('change', function () {
        var selected = $(this).children(':selected').first();
        var selectedAddress = $(selected).data('address');
        if (!selectedAddress) {
            return;
        }
        util.fillAddressFields(selectedAddress, $form);
        $(document).trigger('address.updated');
        // re-validate the form
        $form.validate().form();
        formPrepare.validateForm();
    });
};
