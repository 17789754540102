'use strict';

var KILOMETERS_CONVERSATION_LEVEL = Constants.KILOMETERS_CONVERSATION_LEVEL;
var Utils = require('_core_ext/reservecollect/utils');

var GoogleMap = function () {
    var _map = {};
    var _marker = {};
    var _geocoder = {};
    var _storeIconTrade = {};
    var _storeIconRetail = {};
    var _userIcon = { url: Urls.mapUserIcon };
    var _autocomplete = {};

    function searchByCurrentLocation(userLocation, callback) {
        _geocoder.geocode(
            { location: userLocation },
            function (results, status) {
                if (status === 'OK') {
                    var address = results[0];
                    centerMap(address.geometry.location, true);
                    callback(address);
                }
            }
        );
    }

    function searchByAddressStr(addressStr, callback) {
        var geocodeInfo = { address: addressStr };
        var localeInfo = SitePreferences.LOCALE_INFO;

        if (localeInfo && localeInfo.countryCode && SitePreferences.GOOGLE_COUNTRY_CHECK) {
            geocodeInfo.componentRestrictions = {
                country: localeInfo.countryCode
            };
        }

        _geocoder.geocode(geocodeInfo, function (results, status) {
            if (status === 'OK') {
                var address = results[0];
                centerMap(address.geometry.location, true);
                callback(address);
            }
        });
    }

    function initAutoCompleteEvents($element, callback) {
        _autocomplete = new google.maps.places.Autocomplete($element);
        _autocomplete.setComponentRestrictions({
            country: [Utils.getRegionByLocale(SitePreferences.LOCALE)],
        });
        _autocomplete.addListener('place_changed', function () {
            var place = _autocomplete.getPlace();

            if (place && place.geometry) {
                centerMap(place.geometry.location, true);
                callback();
            }
        });
    }

    function calculateDistanceToStores(stores) {
        for (var key in stores) {
            var store = stores[key];
            var distance = _computeDistanceToStore(store);
            store.distance = parseFloat(distance / 1000).toFixed(3);
            store.distanceText = parseFloat(distance / 1000).toFixed(1);
        }
        return stores;
    }

    function _computeDistanceToStore(store) {
        return (
            google.maps.geometry.spherical.computeDistanceBetween(
                _marker.getPosition(),
                new google.maps.LatLng({
                    lat: store.latitude,
                    lng: store.longitude,
                })
            ) * KILOMETERS_CONVERSATION_LEVEL
        );
    }

    function getNearestStoresInRadius(stores, radius) {
        return calculateDistanceToStores(stores).filter(function (store) {
            return store.distance <= radius;
        });
    }

    function _getStoreIcon(store) {
        return store.custom.isTradeOnly ? _storeIconTrade : _storeIconRetail;
    }

    function _getIconScaledSize() {
        return new google.maps.Size(
            parseFloat(SitePreferences.STORE_PIN_WIDTH),
            parseFloat(SitePreferences.STORE_PIN_HEIGHT)
        );
    }

    function setStoreBounds(storeMarkers) {
        var bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < storeMarkers.length; i++) {
            bounds.extend(storeMarkers[i].getPosition());
        }
        _map.fitBounds(bounds, 0);
    }

    function getStoreMarkers(stores, onMarkerClick) {
        var storeMarkers = [];
        for (var key in stores) {
            if (!Object.prototype.hasOwnProperty.call(stores, key)) {
                continue;
            }
            var store = stores[key];
            if (
                !Utils.isEmpty(store.latitude) &&
                !Utils.isEmpty(store.longitude)
            ) {
                var marker = new google.maps.Marker({
                    position: {
                        lat: store.latitude,
                        lng: store.longitude,
                    },
                    map: _map,
                    title: store.name,
                    icon: _getStoreIcon(store),
                    customData: store,
                });
                if (onMarkerClick) {
                    _addStoreMarkerClickEvent(marker, onMarkerClick);
                }
                storeMarkers.push(marker);
                // initStoreEvents(marker)
            }
        }
        return storeMarkers;
    }

    function _addStoreMarkerClickEvent(marker, callback) {
        marker.addListener('click', function () {
            this.customData.position = createPosition(
                this.customData.latitude,
                this.customData.longitude
            );
            callback(this.customData);
        });
    }

    function createPosition(latitude, longitude) {
        return new google.maps.LatLng(latitude, longitude);
    }

    function centerMap(location, zoomIn) {
        _map.setCenter(location);
        _marker.setPosition(location);
        if (zoomIn) {
            _map.setZoom(SitePreferences.DEFAULT_MAP_ZOOM);
        }
    }

    function centerByNearestStore(latitude, longitude) {
        var position = createPosition(latitude, longitude);
        centerMap(position, true);
    }

    function init(containerId) {
        _map = new google.maps.Map(containerId, {
            zoom: SitePreferences.DEFAULT_MAP_ZOOM,
            center: SitePreferences.DEFAULT_MAP_CENTER,
            disableDefaultUI: true,
            zoomControl: true,
            streetViewControl: false,
        });
        _storeIconRetail = {
            url: Urls.mapStoreRetailIcon,
            scaledSize: _getIconScaledSize(),
        };
        _storeIconTrade = {
            url: Urls.mapStoreIcon,
            scaledSize: _getIconScaledSize(),
        };
        _geocoder = new google.maps.Geocoder();
        _marker = new google.maps.Marker({
            position: SitePreferences.DEFAULT_MAP_CENTER,
            map: _map,
            icon: _userIcon,
        });
    }

    function getMapBounds() {
        return _map.getBounds();
    }

    function getMapCenter() {
        return _map.getCenter();
    }

    function getMapZoom() {
        return _map.getZoom();
    }

    function getMarkerPosition() {
        return _marker.getPosition();
    }

    function setMapBounds(bounds) {
        _map.fitBounds(bounds, 0);
    }

    function setMapCenter(center) {
        _map.setCenter(center);
    }

    function setMapZoom(zoom) {
        _map.setZoom(zoom);
    }

    function setMarkerPosition(position) {
        _marker.setPosition(position);
    }

    return {
        init: init,
        searchByCurrentLocation: searchByCurrentLocation,
        searchByAddressStr: searchByAddressStr,
        initAutoCompleteEvents: initAutoCompleteEvents,
        getNearestStoresInRadius: getNearestStoresInRadius,
        getStoreMarkers: getStoreMarkers,
        setStoreBounds: setStoreBounds,
        getMapBounds: getMapBounds,
        getMapCenter: getMapCenter,
        setMapBounds: setMapBounds,
        setMapCenter: setMapCenter,
        getMapZoom: getMapZoom,
        setMapZoom: setMapZoom,
        getMarkerPosition: getMarkerPosition,
        setMarkerPosition: setMarkerPosition,
        centerMap: centerMap,
        centerByNearestStore: centerByNearestStore,
        calculateDistanceToStores: calculateDistanceToStores,
        createPosition: createPosition,
    };
};

module.exports = GoogleMap;
