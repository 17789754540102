"use strict";

let elementstartX = 0;
let touchstartX = 0;
let touchendX = 0;
let dif = 0;
let difABS = 0;
let clientWidth = 0;
let leftArrow = null;
let rightArrow = null;

const swipeStart = (e) => {
    if (e.touches.length === 1) {
        elementstartX = e.currentTarget.getBoundingClientRect().left;
        touchstartX = e.touches[0].clientX;
    }
};

const swipeMove = (e) => {
    if (e.touches.length === 1) {
        touchendX = e.touches[0].clientX;
        dif = touchendX - touchstartX;
        difABS = Math.abs(dif);
        const threshold = 20;

        if (difABS > threshold) {
            e.currentTarget.parentElement.style.height = e.currentTarget.offsetHeight + 'px';
            e.currentTarget.style.position = "absolute";
            e.currentTarget.style.height = e.currentTarget.offsetHeight + 'px';
            let tileShift = touchendX - touchstartX + elementstartX;
            leftArrow = e.currentTarget.querySelector('.js-arrow-left');
            rightArrow = e.currentTarget.querySelector('.js-arrow-right');
            let arrorRotateAngle = 180 * tileShift / clientWidth * 2;
            if (arrorRotateAngle > 180) {
                arrorRotateAngle = 180;
            } else if (arrorRotateAngle < -180) {
                arrorRotateAngle = -180;
            }
            e.currentTarget.style.left = tileShift + 'px';
            leftArrow.style.transform = `rotate(${arrorRotateAngle}deg)`;
            rightArrow.style.transform = `rotate(${arrorRotateAngle}deg)`;

        }
    }
};

const swipeEnd = (e) => {
    if (difABS >= clientWidth / 2) {
        e.currentTarget.querySelector('.js-clear-offer-btn').click();
        e.currentTarget.querySelector('.js-show-options').classList.remove('clicked');
        e.currentTarget.querySelector('.js-tile-options').classList.add('hidden');
    }
    e.currentTarget.style.position = "relative";
    e.currentTarget.style.left = '0px';
    leftArrow.style.transform = `rotate(0deg)`;
    rightArrow.style.transform = `rotate(0deg)`;
};

const handleSwipe = () => {
    clientWidth = document.documentElement.clientWidth;
    const tiles = document.querySelectorAll(".js-offer-conteiner");
    tiles.forEach(tile => {
        tile.addEventListener('touchstart', swipeStart);
        tile.addEventListener('touchmove', swipeMove);
        tile.addEventListener('touchend', swipeEnd);
    });
};

const removeSwipeHandling = () => {
    const tiles = document.querySelectorAll(".js-offer-conteiner");
    tiles.forEach(tile => {
        tile.removeEventListener('touchstart', swipeStart);
        tile.removeEventListener('touchmove', swipeMove);
        tile.removeEventListener('touchend', swipeEnd);
    });
}

exports.handleSwipe = handleSwipe;
exports.removeSwipeHandling = removeSwipeHandling;

