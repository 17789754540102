/*! Copyright © 2009-2018 Postcode Anywhere (Holdings) Ltd. (http://www.postcodeanywhere.co.uk)
 *
 * CapturePlus v2.30
 * Capture Plus for the web.
 *
 * WEB-2-3 08/10/2018 08:10:23
 */
(function(n, t) {
    function e() {
        r.addEventListener ? (i.ignore(r, "DOMContentLoaded", e),
        f = !0,
        i.ready()) : r.readyState === "complete" && (i.ignore(r, "onreadystatechange", e),
        f = !0,
        i.ready())
    }
    function v() {
        r.readyState === "complete" ? (f = !0,
        i.ready()) : (r.addEventListener ? i.listen(r, "DOMContentLoaded", e) : i.listen(r, "onreadystatechange", e),
        i.listen(n, "load", e))
    }
    function s(t) {
        if (i.blockRequests && i.waitingRequest) {
            i.requestQueue = [t];
            return
        }
        if (t.block && (i.blockRequests = !0),
        t.queue && i.waitingRequest) {
            i.requestQueue.push(t);
            return
        }
        if (i.waitingRequest = !0,
        t.cache && i.requestCache[t.url]) {
            function r() {
                t.callback(i.requestCache[t.url].response)
            }
            n.setImmediate ? n.setImmediate(r) : n.setTimeout(r, 1);
            return
        }
        t.post ? h(t) : a(t)
    }
    function y(n) {
        i.waitingRequest = !1,
        n.block && (i.blockRequests = !1),
        n.response.Items.length === 1 && n.response.Items[0].Error !== t ? n.error(n.response.Items[0].Description) : n.success(n.response.Items, n.response),
        n.cache && (i.requestCache[n.url] = n),
        n.position && (i.scriptRequests[n.position - 1] = null),
        i.requestQueue.length && s(i.requestQueue.shift())
    }
    function a(n) {
        navigator.appName == "Microsoft Internet Explorer" ? w(n) : p(n)
    }
    function p(n) {
        var t = new i.XMLHttpRequest;
        n.url.length > i.limit ? (n.post = !0,
        h(n)) : (t.onreadystatechange = function() {
            t.readyState == 4 && t.status == 200 && n.callback(i.parseJSON(t.responseText))
        }
        ,
        n.credentials && (t.withCredentials = n.credentials),
        t.onerror = n.serviceError,
        t.ontimeout = n.timeoutError,
        t.open("GET", n.url, !0),
        t.send())
    }
    function w(n) {
        var t = i.create("script", {
            type: "text/javascript",
            async: "async"
        })
          , u = r.getElementsByTagName("head")[0];
        n.position = i.scriptRequests.push(n),
        t.src = n.url + "&callback=pca.scriptRequests[" + (n.position - 1) + "].callback",
        t.onload = t.onreadystatechange = function() {
            this.readyState && this.readyState !== "loaded" && this.readyState !== "complete" || (t.onload = t.onreadystatechange = null,
            u && t.parentNode && u.removeChild(t))
        }
        ,
        t.src.length > i.limit ? (n.post = !0,
        h(n)) : u.insertBefore(t, u.firstChild)
    }
    function h(n) {
        navigator.appName == "Microsoft Internet Explorer" ? l(n) : c(n)
    }
    function c(n) {
        var t = new i.XMLHttpRequest;
        t.onreadystatechange = function() {
            t.readyState == 4 && t.status == 200 && n.callback(i.parseJSON(t.responseText))
        }
        ,
        n.credentials && (t.withCredentials = n.credentials),
        t.onerror = n.serviceError,
        t.ontimeout = n.timeoutError,
        t.open("POST", n.destination, !0),
        t.setRequestHeader("Content-Type", "application/x-www-form-urlencoded"),
        t.send(n.query)
    }
    function l(n) {
        function o(n, t) {
            var i = r.createElement("input");
            i.name = n,
            i.value = t,
            u.appendChild(i)
        }
        var u = r.createElement("form"), t = r.createElement("iframe"), s = !1, e, f;
        u.method = "POST",
        u.action = i.protocol + "//" + i.host + "/" + n.service + "/json.ws";
        for (e in n.data)
            o(e, n.data[e]);
        o("CallbackVariable", "window.name"),
        o("CallbackWithScriptTags", "true"),
        t.onload = function() {
            s ? (n.callback({
                Items: i.parseJSON(t.contentWindow.name)
            }),
            r.body.removeChild(t)) : (s = !0,
            t.contentWindow.location = "about:blank")
        }
        ,
        t.style.display = "none",
        r.body.appendChild(t),
        f = t.contentDocument || t.contentWindow.document,
        f.body ? f.body.appendChild(u) : f.appendChild(u),
        u.submit()
    }
    function u(t) {
        return t == n || t == r || t == r.body
    }
    var i = n.pca = n.pca || {}, r = n.document, f, o;
    i.protocol = (n.location || r.location).protocol == "https:" ? "https:" : "http:",
    i.host = "services.postcodeanywhere.co.uk",
    i.endpoint = "json3ex.ws",
    i.limit = 2e3,
    i.synonyms = [{
        r: /\bN(?=\s)/,
        w: "NORTH"
    }, {
        r: /\b(?:NE|NORTHEAST)(?=\s)/,
        w: "NORTH EAST"
    }, {
        r: /\b(?:NW|NORTHWEST)(?=\s)/,
        w: "NORTH WEST"
    }, {
        r: /\bS(?=\s)/,
        w: "SOUTH"
    }, {
        r: /\b(?:SE|SOUTHEAST)(?=\s)/,
        w: "SOUTH EAST"
    }, {
        r: /\b(?:SW|SOUTHWEST)(?=\s)/,
        w: "SOUTH WEST"
    }, {
        r: /\bE(?=\s)/,
        w: "EAST"
    }, {
        r: /\bW(?=\s)/,
        w: "WEST"
    }, {
        r: /\bST(?=\s)/,
        w: "SAINT"
    }],
    i.diacritics = [{
        r: /[ÀÁÂÃ]/gi,
        w: "A"
    }, {
        r: /Å/gi,
        w: "AA"
    }, {
        r: /[ÆæÄ]/gi,
        w: "AE"
    }, {
        r: /Ç/gi,
        w: "C"
    }, {
        r: /Ð/gi,
        w: "DJ"
    }, {
        r: /[ÈÉÊË]/gi,
        w: "E"
    }, {
        r: /[ÌÍÏ]/gi,
        w: "I"
    }, {
        r: /Ñ/gi,
        w: "N"
    }, {
        r: /[ÒÓÔÕ]/gi,
        w: "O"
    }, {
        r: /[ŒØÖ]/gi,
        w: "OE"
    }, {
        r: /Š/gi,
        w: "SH"
    }, {
        r: /ß/gi,
        w: "SS"
    }, {
        r: /[ÙÚÛ]/gi,
        w: "U"
    }, {
        r: /Ü/gi,
        w: "UE"
    }, {
        r: /[ŸÝ]/gi,
        w: "ZH"
    }, {
        r: /-/gi,
        w: " "
    }, {
        r: /[.,]/gi,
        w: ""
    }],
    i.hypertext = [{
        r: /&/g,
        w: "&amp;"
    }, {
        r: /"/g,
        w: "&quot;"
    }, {
        r: /'/g,
        w: "&#39;"
    }, {
        r: /</g,
        w: "&lt;"
    }, {
        r: />/g,
        w: "&gt;"
    }],
    i.requestQueue = [],
    i.requestCache = {},
    i.scriptRequests = [],
    i.waitingRequest = !1,
    i.blockRequests = !1,
    i.styleFixes = [],
    i.agent = navigator && navigator.userAgent || "",
    i.galaxyFix = /Safari\/534.30/.test(i.agent) && /GT-I8190|GT-I9100|GT-I9305|GT-P3110/.test(i.agent),
    i.container = null,
    i.XMLHttpRequest = n.XMLHttpRequest,
    f = !1,
    o = [],
    i.fuzzyMatch = !0,
    i.fuzzyTags = ["*"],
    i.ready = function(n) {
        var i, t;
        if (f) {
            if (o.length)
                for (i = o,
                o = [],
                t = 0; t < i.length; t++)
                    i[t]();
            n && n()
        } else
            typeof n == "function" && o.push(n)
    }
    ,
    i.Eventable = function(n) {
        var t = n || this;
        return t.listeners = {},
        t.listen = function(n, i) {
            t.listeners[n] = t.listeners[n] || [],
            t.listeners[n].push(i)
        }
        ,
        t.ignore = function(n, i) {
            if (t.listeners[n])
                for (var r = 0; r < t.listeners[n].length; r++)
                    if (t.listeners[n][r] === i) {
                        t.listeners[n].splice(r, 1);
                        break
                    }
        }
        ,
        t.fire = function(n, i) {
            var u, f, r;
            if (t.listeners[n])
                for (u = 0; u < t.listeners[n].length; u++) {
                    for (f = [i],
                    r = 2; r < arguments.length; r++)
                        f.push(arguments[r]);
                    t.listeners[n][u].apply(t, f)
                }
        }
        ,
        t
    }
    ,
    i.Request = function(n, t, r, u) {
        var f = new i.Eventable(this), e;
        f.service = n || "",
        f.data = t || {},
        f.success = r || function() {}
        ,
        f.error = u || function() {}
        ,
        f.response = null,
        f.cache = !!f.data.$cache,
        f.queue = !!f.data.$queue,
        f.block = !!f.data.$block,
        f.post = !!f.data.$post,
        f.credentials = !!f.data.$credentials,
        f.destination = ~f.service.indexOf("//") ? f.service : i.protocol + "//" + i.host + "/" + f.service + "/" + i.endpoint,
        f.query = "";
        for (e in f.data)
            f.query += (f.query ? "&" : "") + e + "=" + encodeURIComponent(f.data[e]);
        f.url = f.destination + "?" + f.query,
        f.callback = function(n) {
            f.response = n,
            y(f)
        }
        ,
        f.serviceError = function(n) {
            f.error(n && n.currentTarget && n.currentTarget.statusText ? "Webservice request error: " + n.currentTarget.statusText : "Webservice request failed.")
        }
        ,
        f.timeoutError = function() {
            f.error("Webservice request timed out.")
        }
        ,
        f.process = function() {
            i.process(f)
        }
    }
    ,
    i.process = function(n) {
        s(n)
    }
    ,
    i.fetch = function(n, t, r, u) {
        s(new i.Request(n,t,r,u))
    }
    ,
    i.clearBlockingRequests = function() {
        i.waitingRequest = !1,
        i.blockRequests = !1
    }
    ,
    i.loadScript = function(n, t, u) {
        var f = i.create("script", {
            type: "text/javascript"
        })
          , e = (u || r).getElementsByTagName("head")[0];
        f.onload = f.onreadystatechange = function() {
            this.readyState && this.readyState !== "loaded" && this.readyState !== "complete" || (f.onload = f.onreadystatechange = null,
            (t || function() {}
            )())
        }
        ,
        f.src = (~n.indexOf("/") ? "" : i.protocol + "//" + i.host + "/js/") + n,
        e.insertBefore(f, e.firstChild)
    }
    ,
    i.loadStyle = function(n, t, u) {
        var f = i.create("link", {
            type: "text/css",
            rel: "stylesheet"
        })
          , e = (u || r).getElementsByTagName("head")[0];
        f.onload = f.onreadystatechange = function() {
            this.readyState && this.readyState !== "loaded" && this.readyState !== "complete" || (f.onload = f.onreadystatechange = null,
            (t || function() {}
            )())
        }
        ,
        f.href = (~n.indexOf("/") ? "" : i.protocol + "//" + i.host + "/css/") + n,
        e.insertBefore(f, e.firstChild)
    }
    ,
    i.Item = function(n, t) {
        var r = new i.Eventable(this)
          , u = "pcaselected";
        return r.data = n,
        r.format = t,
        r.html = i.formatLine(n, t),
        r.title = n.title || i.removeHtml(r.html),
        r.tag = i.formatTag(n.tag || r.html),
        r.element = i.create("div", {
            className: "pcaitem",
            innerHTML: r.html,
            title: r.title
        }),
        r.visible = !0,
        r.highlight = function() {
            return i.addClass(r.element, u),
            r.fire("highlight"),
            r
        }
        ,
        r.lowlight = function() {
            return i.removeClass(r.element, u),
            r.fire("lowlight"),
            r
        }
        ,
        r.mouseover = function() {
            r.fire("mouseover")
        }
        ,
        r.mouseout = function() {
            r.fire("mouseout")
        }
        ,
        r.mousedown = function() {
            r.fire("mousedown")
        }
        ,
        r.mouseup = function() {
            r.fire("mouseup"),
            i.galaxyFix && r.select()
        }
        ,
        r.click = function() {
            (r.fire("click"),
            i.galaxyFix) || r.select()
        }
        ,
        r.select = function() {
            return r.fire("select", r.data),
            r
        }
        ,
        r.hide = function() {
            return r.visible = !1,
            r.element.style.display = "none",
            r.fire("hide"),
            r
        }
        ,
        r.show = function() {
            return r.visible = !0,
            r.element.style.display = "",
            r.fire("show"),
            r
        }
        ,
        i.listen(r.element, "mouseover", r.mouseover),
        i.listen(r.element, "mouseout", r.mouseout),
        i.listen(r.element, "mousedown", r.mousedown),
        i.listen(r.element, "mouseup", r.mouseup),
        i.listen(r.element, "click", r.click),
        r
    }
    ,
    i.Collection = function() {
        var n = new i.Eventable(this);
        return n.items = [],
        n.highlighted = -1,
        n.count = 0,
        n.firstItem = null,
        n.lastItem = null,
        n.firstVisibleItem = null,
        n.lastVisibleItem = null,
        n.add = function(t, r, u) {
            function o(t) {
                var f = new i.Item(t,r);
                return f.listen("mouseover", function() {
                    n.highlight(f)
                }),
                f.listen("select", function(t) {
                    n.fire("select", t),
                    u(t)
                }),
                n.items.push(f),
                e.push(f),
                f
            }
            var e = [], f;
            if (u = u || function() {}
            ,
            t.length)
                for (f = 0; f < t.length; f++)
                    o(t[f]);
            else
                o(t);
            return n.count += t.length,
            n.firstVisibleItem = n.firstItem = n.items[0],
            n.lastVisibleItem = n.lastItem = n.items[n.items.length - 1],
            n.fire("add", e),
            n
        }
        ,
        n.sort = function(t) {
            return n.items.sort(function(n, i) {
                return t ? n.data[t] > i.data[t] ? 1 : -1 : n.tag > i.tag ? 1 : -1
            }),
            n.fire("sort"),
            n
        }
        ,
        n.reverse = function() {
            return n.items.reverse(),
            n.fire("reverse"),
            n
        }
        ,
        n.filter = function(t) {
            var u = i.formatTag(t)
              , r = n.count;
            return n.count = 0,
            n.firstVisibleItem = null,
            n.lastVisibleItem = null,
            n.all(function(t) {
                ~t.tag.indexOf(u) ? (t.show(),
                n.count++,
                n.firstVisibleItem = n.firstVisibleItem || t,
                n.lastVisibleItem = t) : t.hide()
            }),
            r != n.count && n.fire("filter"),
            n
        }
        ,
        n.match = function(t) {
            var u = i.formatTag(t)
              , r = [];
            return n.all(function(n) {
                ~n.tag.indexOf(u) && r.push(n)
            }),
            r
        }
        ,
        n.clear = function() {
            return n.items = [],
            n.count = 0,
            n.highlighted = -1,
            n.firstItem = null,
            n.lastItem = null,
            n.firstVisibleItem = null,
            n.lastVisibleItem = null,
            n.fire("clear"),
            n
        }
        ,
        n.all = function(t) {
            for (var i = 0; i < n.items.length; i++)
                if (t(n.items[i], i) === !1)
                    break;
            return n
        }
        ,
        n.highlight = function(t) {
            return ~n.highlighted && n.items[n.highlighted].lowlight(),
            n.highlighted = n.index(t),
            ~n.highlighted && n.items[n.highlighted].highlight(),
            n.fire("highlight", t),
            n
        }
        ,
        n.index = function(t) {
            for (var i = 0; i < n.items.length; i++)
                if (n.items[i] == t)
                    return i;
            return -1
        }
        ,
        n.first = function(t) {
            for (var i = 0; i < n.items.length; i++)
                if (t ? t(n.items[i]) : n.items[i].visible)
                    return n.items[i];
            return null
        }
        ,
        n.last = function(t) {
            for (var i = n.items.length - 1; i >= 0; i--)
                if (t ? t(n.items[i]) : n.items[i].visible)
                    return n.items[i];
            return null
        }
        ,
        n.next = function(t) {
            for (var i = n.highlighted + 1; i < n.items.length; i++)
                if (t ? t(n.items[i]) : n.items[i].visible)
                    return n.items[i];
            return n.first()
        }
        ,
        n.previous = function(t) {
            for (var i = n.highlighted - 1; i >= 0; i--)
                if (t ? t(n.items[i]) : n.items[i].visible)
                    return n.items[i];
            return n.last()
        }
        ,
        n.visibleItems = function() {
            var t = [];
            return n.all(function(n) {
                n.visible && t.push(n)
            }),
            t
        }
        ,
        n
    }
    ,
    i.List = function(t) {
        function u(n, t, u) {
            var f = new i.Item(n,t);
            return f.listen("mouseover", function() {
                r.collection.highlight(f),
                f.highlight()
            }),
            r.collection.listen("highlight", f.lowlight),
            f.listen("select", function(n) {
                r.collection.fire("select", n),
                u(n)
            }),
            f
        }
        var r = new i.Eventable(this);
        return r.options = t || {},
        r.element = i.create("div", {
            className: "pca pcalist"
        }),
        r.collection = new i.Collection,
        r.visible = !0,
        r.scroll = {
            held: !1,
            moved: !1,
            origin: 0,
            position: 0,
            x: 0,
            y: 0,
            dx: 0,
            dy: 0
        },
        r.highlightedItem = null,
        r.headerItem = null,
        r.footerItem = null,
        r.firstItem = null,
        r.lastItem = null,
        r.firstItemClass = "pcafirstitem",
        r.lastItemClass = "pcalastitem",
        r.options.minItems = r.options.minItems || 0,
        r.options.maxItems = r.options.maxItems || 10,
        r.options.allowTab = r.options.allowTab || !1,
        r.show = function() {
            return r.visible = !0,
            r.element.style.display = "",
            r.fire("show"),
            r.resize(),
            r
        }
        ,
        r.hide = function() {
            return r.visible = !1,
            r.element.style.display = "none",
            r.fire("hide"),
            r
        }
        ,
        r.draw = function() {
            return r.destroy(),
            r.headerItem && r.element.appendChild(r.headerItem.element),
            r.collection.all(function(n) {
                r.element.appendChild(n.element)
            }),
            r.footerItem && r.element.appendChild(r.footerItem.element),
            r.resize(),
            r.fire("draw"),
            r
        }
        ,
        r.markItems = function() {
            r.firstItem && i.removeClass(r.firstItem.element, r.firstItemClass),
            r.lastItem && i.removeClass(r.lastItem.element, r.lastItemClass),
            r.collection.count && (r.firstItem = r.headerItem || r.collection.firstVisibleItem,
            r.lastItem = r.footerItem || r.collection.lastVisibleItem,
            i.addClass(r.firstItem.element, r.firstItemClass),
            i.addClass(r.lastItem.element, r.lastItemClass))
        }
        ,
        r.add = function(n, t, i) {
            return r.collection.add(n, t, i),
            r.draw(),
            r
        }
        ,
        r.destroy = function() {
            while (r.element.childNodes && r.element.childNodes.length)
                r.element.removeChild(r.element.childNodes[0]);
            return r
        }
        ,
        r.clear = function() {
            return r.collection.clear(),
            r.destroy(),
            r.fire("clear"),
            r
        }
        ,
        r.setScroll = function(n) {
            return r.element.scrollTop = n,
            r.fire("scroll"),
            r
        }
        ,
        r.enableTouch = function() {
            function f(t) {
                t = t || n.event,
                r.scroll.held = !0,
                r.scroll.moved = !1,
                r.scroll.origin = parseInt(r.scrollTop),
                r.scroll.y = parseInt(t.touches[0].pageY)
            }
            function e() {
                r.scroll.held = !1
            }
            function t() {
                r.scroll.held = !1
            }
            function u(t) {
                r.scroll.held && (t = t || n.event,
                i.smash(t),
                r.scroll.dy = r.scroll.y - parseInt(t.touches[0].pageY),
                r.scroll.position = r.scroll.origin + r.scroll.dy,
                r.setScroll(r.scroll.position),
                r.scroll.moved = !0)
            }
            return i.listen(r.element, "touchstart", f),
            i.listen(r.element, "touchmove", u),
            i.listen(r.element, "touchend", e),
            i.listen(r.element, "touchcancel", t),
            r
        }
        ,
        r.move = function(n) {
            return n && (r.collection.highlight(n),
            (n == r.headerItem || n == r.footerItem) && n.highlight(),
            r.scrollToItem(n)),
            r
        }
        ,
        r.next = function() {
            return r.move(r.nextItem())
        }
        ,
        r.previous = function() {
            return r.move(r.previousItem())
        }
        ,
        r.first = function() {
            return r.move(r.firstItem)
        }
        ,
        r.last = function() {
            return r.move(r.lastItem)
        }
        ,
        r.nextItem = function() {
            return r.highlightedItem ? r.highlightedItem == r.collection.lastVisibleItem && (r.footerItem || r.headerItem) ? r.footerItem || r.headerItem : r.footerItem && r.headerItem && r.highlightedItem == r.footerItem ? r.headerItem : r.collection.next() : r.firstItem
        }
        ,
        r.previousItem = function() {
            return r.highlightedItem ? r.highlightedItem == r.collection.firstVisibleItem && (r.footerItem || r.headerItem) ? r.headerItem || r.footerItem : r.footerItem && r.headerItem && r.highlightedItem == r.headerItem ? r.footerItem : r.collection.previous() : r.lastItem
        }
        ,
        r.currentItem = function() {
            return r.highlightedItem
        }
        ,
        r.selectable = function() {
            return r.visible && !!r.currentItem()
        }
        ,
        r.select = function() {
            return r.selectable() && r.currentItem().select(),
            r
        }
        ,
        r.navigate = function(n) {
            switch (n) {
            case 40:
                return r.next(),
                !0;
            case 38:
                return r.previous(),
                !0;
            case 13:
                if (r.selectable())
                    return r.select(),
                    !0;
            case 9:
                if (r.options.allowTab)
                    return r.next(),
                    !0
            }
            return !1
        }
        ,
        r.scrollToItem = function(n) {
            return r.scroll.position = r.element.scrollTop,
            n.element.offsetTop < r.scroll.position ? (r.scroll.position = n.element.offsetTop,
            r.setScroll(r.scroll.position)) : n.element.offsetTop + n.element.offsetHeight > r.scroll.position + r.element.offsetHeight && (r.scroll.position = n.element.offsetTop + n.element.offsetHeight - r.element.offsetHeight,
            r.setScroll(r.scroll.position)),
            r
        }
        ,
        r.filter = function(n) {
            var t = r.collection.count;
            return r.collection.filter(n),
            r.markItems(),
            t != r.collection.count && r.fire("filter", n),
            r
        }
        ,
        r.getHeight = function() {
            for (var t = r.collection.visibleItems(), o = r.headerItem ? i.getSize(r.headerItem.element).height : 0, e = r.footerItem ? i.getSize(r.footerItem.element).height : 0, f = 0, u = 0, n = 0; n < t.length && n < r.options.maxItems; n++)
                f = i.getSize(t[n].element).height,
                u += f;
            return t.length < r.options.minItems && (u += (f + 1) * (r.options.minItems - t.length)),
            u + o + e
        }
        ,
        r.resize = function() {
            var n = r.getHeight();
            n > 0 && (r.element.style.height = n + "px")
        }
        ,
        r.setHeaderItem = function(n, t, f) {
            return r.headerItem = u(n, t, f),
            i.addClass(r.footerItem.element, "pcaheaderitem"),
            r.markItems(),
            r
        }
        ,
        r.setFooterItem = function(n, t, f) {
            return r.footerItem = u(n, t, f),
            i.addClass(r.footerItem.element, "pcafooteritem"),
            r.markItems(),
            r
        }
        ,
        r.collection.listen("highlight", function(n) {
            r.highlightedItem = n
        }),
        r.collection.listen("add", function(n) {
            r.markItems(),
            r.fire("add", n)
        }),
        r.options.name && (i.setAttributes(r.element, {
            id: r.options.name,
            role: "listbox",
            "aria-activedescendant": ""
        }),
        r.collection.listen("add", function(n) {
            function u(n) {
                n.listen("highlight", function() {
                    i.setAttributes(r.element, {
                        "aria-activedescendant": n.id
                    })
                })
            }
            for (var t = 0; t < n.length; t++)
                u(n[t]);
            r.collection.all(function(n, t) {
                n.element.id = n.id = r.options.name + "_item" + t,
                i.setAttributes(n.element, {
                    role: "option"
                })
            })
        })),
        r
    }
    ,
    i.AutoComplete = function(t, f) {
        function s() {
            e.checkHide()
        }
        function o() {
            e.resize()
        }
        var e = new i.Eventable(this);
        return e.options = f || {},
        e.options.force = e.options.force || !1,
        e.options.allowTab = e.options.allowTab || !1,
        e.options.onlyDown = e.options.onlyDown || !1,
        e.element = i.create("div", {
            className: "pcaautocomplete pcatext"
        }),
        e.anchors = [],
        e.list = new i.List(e.options),
        e.fieldListeners = [],
        e.field = null,
        e.positionField = null,
        e.visible = !0,
        e.hover = !1,
        e.focused = !1,
        e.upwards = !1,
        e.controlDown = !1,
        e.disabled = !1,
        e.fixedWidth = !1,
        e.emptyMessage = e.options.emptyMessage || "",
        e.skipFilter = !1,
        e.stealth = !1,
        e.header = {
            element: i.create("div", {
                className: "pcaheader"
            }),
            headerText: i.create("div", {
                className: "pcamessage"
            }),
            init: function() {
                this.hide()
            },
            setContent: function(n) {
                return n = n || "",
                typeof n == "string" ? this.element.innerHTML = n : this.element.appendChild(n),
                e.fire("header"),
                this
            },
            setText: function(n) {
                return n = n || "",
                this.element.appendChild(this.headerText),
                typeof n == "string" ? (i.clear(this.headerText),
                this.headerText.appendChild(i.create("span", {
                    className: "pcamessageicon"
                })),
                this.headerText.appendChild(i.create("span", {
                    innerHTML: n
                }))) : this.headerText.appendChild(n),
                e.fire("header"),
                this
            },
            clear: function() {
                return this.setContent(),
                e.fire("header"),
                this
            },
            show: function() {
                return this.element.style.display = "",
                e.fire("header"),
                this
            },
            hide: function() {
                return this.element.style.display = "none",
                e.fire("header"),
                this
            }
        },
        e.footer = {
            element: i.create("div", {
                className: "pcafooter"
            }),
            init: function() {
                this.hide()
            },
            setContent: function(n) {
                return n = n || "",
                typeof n == "string" ? this.element.innerHTML = n : this.element.appendChild(n),
                e.fire("footer"),
                this
            },
            show: function() {
                return this.element.style.display = "",
                e.fire("footer"),
                this
            },
            hide: function() {
                return this.element.style.display = "none",
                e.fire("footer"),
                this
            }
        },
        e.load = function() {
            if (t.length && t.constructor == Array)
                for (var u = 0; u < t.length; u++)
                    e.attach(i.getElement(t[u]));
            else
                e.attach(i.getElement(t));
            return i.listen(e.element, "mouseover", function() {
                e.hover = !0
            }),
            i.listen(e.element, "mouseout", function() {
                e.hover = !1
            }),
            i.listen(r, "click", s),
            i.listen(n, "resize", o),
            (r.documentMode && r.documentMode <= 7 || /\bMSIE (7|6)/.test(i.agent)) && e.setWidth(280),
            r.documentMode && r.documentMode <= 5 && (i.applyStyleFixes(".pca .pcafooter", {
                fontSize: "0pt"
            }),
            i.applyStyleFixes(".pca .pcaflag", {
                fontSize: "0pt"
            })),
            e
        }
        ,
        e.attach = function(n) {
            function t(n, t, r) {
                i.listen(n, t, r),
                e.fieldListeners.push({
                    field: n,
                    event: t,
                    action: r
                })
            }
            function f(n) {
                function f() {
                    o.appendChild(e.element),
                    e.focus(n)
                }
                var u = i.create("table", {
                    className: "pca pcaanchor",
                    cellPadding: 0,
                    cellSpacing: 0
                })
                  , s = [u.insertRow(0).insertCell(0), u.insertRow(1).insertCell(0)]
                  , o = i.create("div", {
                    className: "pcachain"
                });
                if (!n || !n.tagName) {
                    i.append(e.element);
                    return
                }
                n.parentNode.insertBefore(u, n),
                s[0].appendChild(n),
                s[1].appendChild(o),
                e.anchors.push(u),
                i.inputField(n) && (t(n, "keyup", e.keyup),
                t(n, "keydown", e.keydown),
                t(n, "focus", f),
                t(n, "blur", e.blur),
                t(n, "keypress", e.keypress),
                t(n, "paste", e.paste),
                typeof r.activeElement != "unknown" && n == r.activeElement && f()),
                t(n, "click", function() {
                    e.click(n)
                }),
                t(n, "dblclick", function() {
                    e.dblclick(n)
                }),
                t(n, "change", function() {
                    e.change(n)
                })
            }
            function u(n) {
                function u() {
                    e.focus(n)
                }
                (i.append(e.element),
                n && n.tagName) && (i.inputField(n) && (t(n, "keyup", e.keyup),
                t(n, "keydown", e.keydown),
                t(n, "focus", u),
                t(n, "blur", e.blur),
                t(n, "keypress", e.keypress),
                t(n, "paste", e.paste),
                typeof r.activeElement != "unknown" && n == r.activeElement && u()),
                t(n, "click", function() {
                    e.click(n)
                }),
                t(n, "dblclick", function() {
                    e.dblclick(n)
                }),
                t(n, "change", function() {
                    e.change(n)
                }))
            }
            e.options.force ? f(n) : u(n)
        }
        ,
        e.position = function(t) {
            var s = i.getPosition(t), v = i.getSize(t), w = i.getTopOffsetParent(t), c = i.getParentScroll(t), h = i.getSize(e.element), y = i.getSize(n), f = i.getScroll(n), o = !u(w), b = s.top + h.height - (o ? 0 : f.top) < y.height, p = s.top - (o ? 0 : f.top) > h.height, a, l;
            return e.upwards = !b && p && !e.options.onlyDown,
            e.upwards ? e.options.force ? e.element.style.top = -(h.height + v.height + 2) + "px" : (e.element.style.top = s.top - c.top - h.height + (o ? f.top : 0) + "px",
            e.element.style.left = s.left - c.left + (o ? f.left : 0) + "px") : e.options.force ? e.element.style.top = "auto" : (e.element.style.top = s.top - c.top + v.height + 1 + (o ? f.top : 0) + "px",
            e.element.style.left = s.left - c.left + (o ? f.left : 0) + "px"),
            e.options.left && (e.element.style.left = parseInt(e.element.style.left) + parseInt(e.options.left) + "px"),
            e.options.top && (e.element.style.top = parseInt(e.element.style.top) + parseInt(e.options.top) + "px"),
            a = parseInt(i.getStyle(e.element, "borderLeftWidth")) + parseInt(i.getStyle(e.element, "borderRightWidth")) || 0,
            l = Math.max(i.getSize(t).width - a, 0),
            e.fixedWidth || (e.element.style.minWidth = l + "px"),
            (r.documentMode && r.documentMode <= 7 || /\bMSIE (7|6)/.test(i.agent)) && (e.setWidth(Math.max(l, 280)),
            e.element.style.left = (parseInt(e.element.style.left) || 0) - 2 + "px",
            e.element.style.top = (parseInt(e.element.style.top) || 0) - 2 + "px"),
            e.positionField = t,
            e.fire("move"),
            e
        }
        ,
        e.reposition = function() {
            return e.positionField && e.position(e.positionField),
            e
        }
        ,
        e.prompt = function(n, t) {
            if (typeof t == "number") {
                if (t == 0 ? n = " " + n : t >= n.length ? (n = n + " ",
                t++) : (n = n.substring(0, t) + "  " + n.substring(t, n.length),
                t++),
                i.setValue(e.field, n),
                e.field.setSelectionRange)
                    e.field.focus(),
                    e.field.setSelectionRange(t, t);
                else if (e.field.createTextRange) {
                    var r = e.field.createTextRange();
                    r.move("character", t),
                    r.select()
                }
            } else
                i.setValue(e.field, n);
            return e
        }
        ,
        e.show = function() {
            return e.disabled || e.stealth || (e.visible = !0,
            e.element.style.display = "",
            e.list.collection.count ? (e.options.emptyMessage && e.header.clear().hide(),
            e.list.show()) : (e.options.emptyMessage && e.header.setText(e.options.emptyMessage).show(),
            e.list.hide()),
            e.setScroll(0),
            e.reposition(),
            e.fire("show")),
            e
        }
        ,
        e.showAll = function() {
            e.list.filter(""),
            e.show()
        }
        ,
        e.hide = function() {
            return e.visible = !1,
            e.element.style.display = "none",
            e.fire("hide"),
            e
        }
        ,
        e.focus = function(n) {
            e.field = n,
            e.focused = !0,
            e.show(),
            e.position(n),
            e.fire("focus")
        }
        ,
        e.blur = function() {
            e.focused = !1,
            e.checkHide(),
            e.fire("blur")
        }
        ,
        e.checkHide = function() {
            return !e.visible || e.focused || e.hover || e.hide(),
            e
        }
        ,
        e.handleKey = function(n, t) {
            n == 27 ? (e.hide(),
            e.fire("escape")) : n == 17 ? e.controlDown = !1 : n == 8 || n == 46 ? (e.filter(),
            e.fire("delete")) : n != 0 && n <= 46 && n != 32 ? e.visible && e.list.navigate(n) ? t && i.smash(t) : (n == 38 || n == 40) && e.filter() : e.visible && e.filter(),
            e.fire("keyup", n)
        }
        ,
        e.keydown = function(t) {
            t = t || n.event;
            var r = t.which || t.keyCode;
            r == 17 && (e.controlDown = !0),
            r == 9 && e.options.allowTab && i.smash(t)
        }
        ,
        e.keyup = function(t) {
            t = t || n.event;
            var i = t.which || t.keyCode;
            e.handleKey(i, t)
        }
        ,
        e.keypress = function(t) {
            var r = n.event ? n.event.keyCode : t.which;
            e.visible && r == 13 && e.list.selectable() && i.smash(t)
        }
        ,
        e.paste = function() {
            n.setTimeout(function() {
                e.filter(),
                e.fire("paste")
            }, 0)
        }
        ,
        e.click = function(n) {
            e.fire("click", n)
        }
        ,
        e.dblclick = function(n) {
            e.fire("dblclick", n)
        }
        ,
        e.change = function(n) {
            e.fire("change", n)
        }
        ,
        e.resize = function() {
            e.visible && e.reposition()
        }
        ,
        e.add = function(n, t, i) {
            return e.list.add(n, t, i),
            e
        }
        ,
        e.clear = function() {
            return e.list.clear(),
            e
        }
        ,
        e.setScroll = function(n) {
            return e.list.setScroll(n),
            e
        }
        ,
        e.setWidth = function(n) {
            return typeof n == "number" ? (n = Math.max(n, 220),
            e.element.style.width = n + "px",
            r.documentMode && r.documentMode <= 5 && (n -= 2),
            e.list.element.style.width = n + "px") : (e.element.style.width = n,
            e.list.element.style.width = n),
            e.fixedWidth = n != "auto",
            e.element.style.minWidth = 0,
            e
        }
        ,
        e.setHeight = function(n) {
            return e.list.element.style.height = typeof n == "number" ? n + "px" : n,
            e
        }
        ,
        e.filter = function(n) {
            return n = n || i.getValue(e.field),
            e.skipFilter ? e.list.collection.match(n).length < e.list.collection.count && e.list.fire("filter") : (e.list.filter(n, e.skipFilter),
            n && !e.list.collection.count && !e.skipFilter && !e.options.emptyMessage ? e.hide() : e.show()),
            e.fire("filter", n),
            e
        }
        ,
        e.disable = function() {
            return e.disabled = !0,
            e
        }
        ,
        e.enable = function() {
            return e.disabled = !1,
            e
        }
        ,
        e.destroy = function() {
            i.remove(e.element),
            i.ignore(r, "click", s),
            i.ignore(n, "resize", o);
            for (var t = 0; t < e.fieldListeners.length; t++)
                i.ignore(e.fieldListeners[t].field, e.fieldListeners[t].event, e.fieldListeners[t].action)
        }
        ,
        e.element.appendChild(e.header.element),
        e.element.appendChild(e.list.element),
        e.element.appendChild(e.footer.element),
        e.header.init(),
        e.footer.init(),
        t && e.load(t),
        e.options.width && e.setWidth(e.options.width),
        e.options.height && e.setHeight(e.options.height),
        e.options.className && i.addClass(e.element, e.options.className),
        e.field || e.hide(),
        e
    }
    ,
    i.Modal = function(n) {
        function u(n) {
            return n = n || {},
            n.type = n.type || "text",
            n
        }
        var t = new i.Eventable(this);
        return t.options = n || {},
        t.element = i.create("div", {
            className: "pcamodal"
        }),
        t.border = i.create("div", {
            className: "pcaborder"
        }),
        t.frame = i.create("div", {
            className: "pcaframe"
        }),
        t.content = i.create("div", {
            className: "pcacontent pcatext"
        }),
        t.mask = i.create("div", {
            className: "pcafullscreen pcamask"
        }),
        t.form = [],
        t.header = {
            element: i.create("div", {
                className: "pcaheader"
            }),
            headerText: i.create("div", {
                className: "pcatitle"
            }, t.options.titleStyle || ""),
            init: function() {
                this.setText(t.options.title || "")
            },
            setContent: function(n) {
                return n = n || "",
                typeof n == "string" ? this.element.innerHTML = n : this.element.appendChild(n),
                t.fire("header"),
                this
            },
            setText: function(n) {
                return n = n || "",
                this.element.appendChild(this.headerText),
                typeof n == "string" ? this.headerText.innerHTML = n : this.headerText.appendChild(n),
                t.fire("header"),
                this
            },
            show: function() {
                return this.element.style.display = "",
                t.fire("header"),
                this
            },
            hide: function() {
                return this.element.style.display = "none",
                t.fire("header"),
                this
            }
        },
        t.footer = {
            element: i.create("div", {
                className: "pcafooter"
            }),
            setContent: function(n) {
                return n = n || "",
                typeof n == "string" ? this.element.innerHTML = n : this.element.appendChild(n),
                t.fire("footer"),
                this
            },
            show: function() {
                return this.element.style.display = "",
                t.fire("header"),
                this
            },
            hide: function() {
                return this.element.style.display = "none",
                t.fire("header"),
                this
            }
        },
        t.setTitle = function(n) {
            t.header.setText(n).show()
        }
        ,
        t.setContent = function(n) {
            return typeof n == "string" ? t.content.innerHTML = n : t.content.appendChild(n),
            t.fire("change"),
            t
        }
        ,
        t.addField = function(n, r) {
            r = u(r);
            var e = i.create("div", {
                className: "pcainputrow"
            })
              , f = i.create(r.tag || "input", r)
              , o = i.create("label", {
                htmlFor: f.id || "",
                innerHTML: n || ""
            });
            return e.appendChild(o),
            e.appendChild(f),
            t.setContent(e),
            t.form.push({
                label: n,
                element: f
            }),
            f
        }
        ,
        t.addHalfFields = function(n, r, f) {
            r = u(r),
            f = u(f);
            var s = i.create("div", {
                className: "pcainputrow"
            })
              , e = i.create(r.tag || "input", r)
              , o = i.create(f.tag || "input", f)
              , h = i.create("label", {
                htmlFor: e.id || "",
                innerHTML: n || ""
            });
            return i.addClass(e, "pcahalf"),
            i.addClass(o, "pcahalf"),
            s.appendChild(h),
            s.appendChild(e),
            s.appendChild(o),
            t.setContent(s),
            t.form.push({
                label: "First " + n,
                element: e
            }),
            t.form.push({
                label: "Second " + n,
                element: o
            }),
            [e, o]
        }
        ,
        t.addButton = function(n, u, f) {
            function o() {
                for (var r = {}, n = 0; n < t.form.length; n++)
                    r[t.form[n].label] = i.getValue(t.form[n].element);
                u(r)
            }
            var e = i.create("input", {
                type: "button",
                value: n,
                className: "pcabutton"
            });
            return u = u || function() {}
            ,
            !f || r.documentMode && r.documentMode <= 7 || (e.style.cssFloat = "right"),
            i.listen(e, "click", o),
            t.footer.setContent(e),
            e
        }
        ,
        t.centre = function() {
            var n = i.getSize(t.element);
            return t.element.style.marginTop = -(n.height / 2) + "px",
            t.element.style.marginLeft = -(n.width / 2) + "px",
            t
        }
        ,
        t.show = function() {
            return r.documentMode && r.documentMode <= 5 || /\bMSIE 6/.test(i.agent) || (t.element.style.display = "",
            t.mask.style.display = "",
            t.centre(),
            t.fire("show")),
            t
        }
        ,
        t.hide = function() {
            return t.element.style.display = "none",
            t.mask.style.display = "none",
            t.fire("hide"),
            t
        }
        ,
        t.clear = function() {
            while (t.content.childNodes.length)
                t.content.removeChild(t.content.childNodes[0]);
            while (t.footer.element.childNodes.length)
                t.footer.element.removeChild(t.footer.element.childNodes[0]);
            return t.form = [],
            t.fire("clear"),
            t
        }
        ,
        i.listen(t.mask, "click", t.hide),
        t.element.appendChild(t.border),
        t.element.appendChild(t.frame),
        t.frame.appendChild(t.header.element),
        t.frame.appendChild(t.content),
        t.frame.appendChild(t.footer.element),
        t.header.init(),
        i.append(t.mask),
        i.append(t.element),
        t.hide(),
        t
    }
    ,
    i.Tooltip = function(t, r) {
        var f = new i.Eventable(this);
        return f.element = i.create("div", {
            className: "pcatooltip"
        }),
        f.background = i.create("div", {
            className: "pcabackground"
        }),
        f.message = i.create("div", {
            className: "pcamessage",
            innerText: r
        }),
        f.show = function() {
            return f.element.style.display = "",
            f.position(),
            f.fire("show"),
            f
        }
        ,
        f.hide = function() {
            return f.element.style.display = "none",
            f.fire("hide"),
            f
        }
        ,
        f.setMessage = function(n) {
            i.setValue(f.message, n)
        }
        ,
        f.position = function() {
            var h = i.getPosition(t)
              , a = i.getSize(t)
              , v = i.getTopOffsetParent(t)
              , s = i.getSize(f.message)
              , l = i.getSize(n)
              , o = i.getScroll(n)
              , c = !u(v)
              , r = h.top - s.height - 5 + (c ? o.top : 0)
              , e = h.left + a.width / 2 - s.width / 2 + (c ? o.left : 0);
            r = Math.min(r, l.height + o.top - s.height),
            r = Math.max(r, 0),
            e = Math.min(e, l.width + o.left - s.width),
            e = Math.max(e, 0),
            f.element.style.top = r + "px",
            f.element.style.left = e + "px"
        }
        ,
        (t = i.getElement(t)) && (i.listen(t, "mouseover", f.show),
        i.listen(t, "mouseout", f.hide)),
        f.element.appendChild(f.background),
        f.element.appendChild(f.message),
        f.setMessage(r),
        i.append(f.element),
        f.hide(),
        f
    }
    ,
    i.formatLine = function(n, t) {
        function i(t, i) {
            var r = (typeof n[t] == "function" ? n[t]() : n[t]) || "";
            return i === "!" ? r.toUpperCase() : r
        }
        return t = t.replace(/\{([^\}]*\{(\w+)([^\}\w])?\}[^\}]*)\}/g, function(n, t, r, u) {
            var f = i(r, u);
            return f ? t.replace(/\{(\w+)([^\}\w])?\}/g, f) : ""
        }),
        t.replace(/\{(\w+)([^\}\w])?\}/g, function(n, t, r) {
            return i(t, r)
        })
    }
    ,
    i.formatTag = function(n) {
        return n ? i.replaceList(i.replaceList(i.removeHtml(n.toUpperCase()), i.diacritics), i.synonyms) : ""
    }
    ,
    i.formatTagWords = function(n) {
        return i.formatTag(n).split(" ")
    }
    ,
    i.formatCamel = function(n, t) {
        function i(n, i, r) {
            return i + t + r
        }
        return t = t || " ",
        n = n.replace(/([a-z])([A-Z0-9])/g, i),
        n = n.replace(/([0-9])([A-Z])/g, i),
        n = n.replace(/([A-Z])([A-Z][a-z])/g, i)
    }
    ,
    i.replaceList = function(n, t) {
        for (var i = 0; i < t.length; i++)
            n = n.toString().replace(t[i].r, t[i].w);
        return n
    }
    ,
    i.removeHtml = function(n) {
        return n.replace(/<(?:.|\s)*?>+/g, "")
    }
    ,
    i.escapeHtml = function(n) {
        return i.replaceList(n, i.hypertext)
    }
    ,
    i.validId = function(n) {
        return /[a-z0-9-_:\.\[\]]+/gi.exec(n)
    }
    ,
    i.trimSpaces = function(n) {
        return n.replace(/^\s+|\s(?=\s)|\s$/g, "")
    }
    ,
    i.tidy = function(n, t) {
        t = t.replace("\\", "\\\\");
        var i = new RegExp("^" + t + "+|" + t + "(?=" + t + ")|" + t + "$","gi");
        return n.replace(i, "")
    }
    ,
    i.getText = function(n) {
        return /[a-zA-Z][a-zA-Z\s]+[a-zA-Z]/.exec(n)
    }
    ,
    i.getNumber = function(n) {
        return /\d+/.exec(n)
    }
    ,
    i.parseJSON = function(n) {
        return n && /^[\],:{}\s]*$/.test(n.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, "@").replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, "]").replace(/(?:^|:|,)(?:\s*\[)+/g, "")) ? typeof JSON != "undefined" ? JSON.parse(n) : eval(n) : {}
    }
    ,
    i.parseJSONDate = function(n) {
        return new Date(parseInt(i.getNumber(n)))
    }
    ,
    i.containsWord = function(n, t) {
        var i = new RegExp("\\b" + t + "\\b","gi");
        return i.test(n)
    }
    ,
    i.removeWord = function(n, t) {
        var i = new RegExp("\\s?\\b" + t + "\\b","gi");
        return n.replace(i, "")
    }
    ,
    i.merge = function(n, t) {
        for (var i in n)
            t[i] || (t[i] = n[i]);
        return t
    }
    ,
    i.getElement = function(n, t) {
        var f, u;
        if (!n)
            return null;
        if (typeof n.nodeType == "number")
            return n;
        if (typeof n == "string") {
            if (t = i.getElement(t) || r,
            f = t.getElementById ? t.getElementById(n) : null,
            f)
                return f;
            if (u = t.getElementsByName ? t.getElementsByName(n) : null,
            u.length)
                return u[0]
        }
        return i.fuzzyMatch ? i.getElementByRegex(n, t) : null
    }
    ,
    i.getElementByRegex = function(n, t) {
        var e, o, u, f;
        if (typeof n == "string")
            try {
                n = new RegExp(n)
            } catch (s) {
                return null
            }
        if (n && typeof n == "object" && n.constructor == RegExp)
            for (t = i.getElement(t) || r,
            e = 0; e < i.fuzzyTags.length; e++)
                for (o = t.getElementsByTagName(i.fuzzyTags[e]),
                u = 0; u < o.length; u++)
                    if (f = o[u],
                    f.id && n.test(f.id))
                        return f;
        return null
    }
    ,
    i.getValue = function(n) {
        var u, t, f;
        if (n = i.getElement(n)) {
            if (n.tagName == "INPUT" || n.tagName == "TEXTAREA") {
                if (n.type == "checkbox")
                    return n.checked;
                if (n.type == "radio") {
                    for (u = r.getElementsByName(n.name),
                    t = 0; t < u.length; t++)
                        if (u[t].checked)
                            return u[t].value
                } else
                    return n.value
            }
            if (n.tagName == "SELECT")
                return n.selectedIndex < 0 ? "" : (f = n.options[n.selectedIndex],
                f.value || f.text || "");
            if (n.tagName == "DIV" || n.tagName == "SPAN" || n.tagName == "TD" || n.tagName == "LABEL")
                return n.innerHTML
        }
        return ""
    }
    ,
    i.setValue = function(n, t) {
        var o, e, s, f, u;
        if ((t || t == "") && (n = i.getElement(n)))
            if (o = t.toString(),
            e = i.formatTag(o),
            n.tagName == "INPUT")
                if (n.type == "checkbox")
                    n.checked = typeof t == "boolean" && t || e == "TRUE";
                else if (n.type == "radio") {
                    for (s = r.getElementsByName(n.name),
                    f = 0; f < s.length; f++)
                        if (i.formatTag(s[f].value) == e) {
                            s[f].checked = !0;
                            return
                        }
                } else
                    n.value = i.tidy(o.replace(/\\n|\n/gi, ", "), ", ");
            else if (n.tagName == "TEXTAREA")
                n.value = o.replace(/\\n|\n/gi, "\n");
            else if (n.tagName == "SELECT") {
                for (u = 0; u < n.options.length; u++)
                    if (i.formatTag(n.options[u].value) == e || i.formatTag(n.options[u].text) == e) {
                        n.selectedIndex = u;
                        return
                    }
            } else
                (n.tagName == "DIV" || n.tagName == "SPAN" || n.tagName == "TD" || n.tagName == "LABEL") && (n.innerHTML = o.replace(/\\n|\n/gi, "<br/>"))
    }
    ,
    i.inputField = function(n) {
        return (n = i.getElement(n)) ? n.tagName && (n.tagName == "INPUT" || n.tagName == "TEXTAREA") && n.type && (n.type == "text" || n.type == "search" || n.type == "email" || n.type == "textarea") : !1
    }
    ,
    i.selectList = function(n) {
        return (n = i.getElement(n)) ? n.tagName && n.tagName == "SELECT" : !1
    }
    ,
    i.getSelectedItem = function(n) {
        return (n = i.getElement(n)) && n.tagName == "SELECT" && n.selectedIndex >= 0 ? n.options[n.selectedIndex] : null
    }
    ,
    i.checkBox = function(n) {
        return (n = i.getElement(n)) ? n.tagName && n.tagName == "INPUT" && n.type && n.type == "checkbox" : !1
    }
    ,
    i.clear = function(n) {
        i.setValue(n, "")
    }
    ,
    i.getPosition = function(t) {
        var e = {
            left: 0,
            top: 0
        }, f, r;
        if (t = i.getElement(t)) {
            if (!t.tagName)
                return e;
            if (typeof t.getBoundingClientRect != "undefined") {
                var h = t.getBoundingClientRect()
                  , o = !u(i.getTopOffsetParent(t))
                  , c = i.getScroll(n)
                  , s = i.getParentScroll(t);
                return {
                    left: h.left + s.left + (o ? 0 : c.left),
                    top: h.top + s.top + (o ? 0 : c.top)
                }
            }
            f = 0,
            r = 0;
            do
                f += t.offsetLeft,
                r += t.offsetTop;
            while (t = t.offsetParent);return {
                left: f,
                top: r
            }
        }
        return e
    }
    ,
    i.getScroll = function(n) {
        return {
            left: parseInt(n.scrollX || n.scrollLeft, 10) || (u(n) ? parseInt(r.documentElement.scrollLeft) || 0 : 0),
            top: parseInt(n.scrollY || n.scrollTop, 10) || (u(n) ? parseInt(r.documentElement.scrollTop) || 0 : 0)
        }
    }
    ,
    i.getSize = function(n) {
        return {
            height: n.offsetHeight || n.innerHeight || (u(n) ? r.documentElement.clientHeight || r.body.clientHeight : 0),
            width: n.offsetWidth || n.innerWidth || (u(n) ? r.documentElement.clientWidth || r.body.clientWidth : 0)
        }
    }
    ,
    i.getParentScroll = function(n) {
        var f = {
            left: 0,
            top: 0
        }, r, t;
        if (n = i.getElement(n)) {
            if (!n.tagName || !(n = n.parentNode))
                return f;
            r = 0,
            t = 0;
            do {
                if (u(n))
                    break;
                r += parseInt(n.scrollLeft) || 0,
                t += parseInt(n.scrollTop) || 0
            } while (n = n.parentNode);return {
                left: r,
                top: t
            }
        }
        return f
    }
    ,
    i.getTopOffsetParent = function(n) {
        while (n.offsetParent)
            if (n = n.offsetParent,
            i.getStyle(n, "position") == "fixed")
                break;
        return n
    }
    ,
    i.getStyle = function(t, i) {
        return ((n.getComputedStyle ? n.getComputedStyle(t) : t.currentStyle) || {})[i] || ""
    }
    ,
    i.addClass = function(n, t) {
        (n = i.getElement(n)) && (i.containsWord(n.className || "", t) || (n.className += (n.className ? " " : "") + t))
    }
    ,
    i.removeClass = function(n, t) {
        (n = i.getElement(n)) && (n.className = i.removeWord(n.className, t))
    }
    ,
    i.setAttribute = function(n, t, r) {
        (n = i.getElement(n)) && n.setAttribute(t, r)
    }
    ,
    i.setAttributes = function(n, t) {
        if (n = i.getElement(n))
            for (var r in t)
                n.setAttribute(r, t[r])
    }
    ,
    i.applyStyleFixes = function(n, t) {
        for (var h, u, f, s, o, e = 0; e < r.styleSheets.length; e++) {
            h = r.styleSheets[e],
            u = [];
            try {
                u = h.rules || h.cssRules || []
            } catch (c) {}
            for (f = 0; f < u.length; f++)
                if (s = u[f],
                s.selectorText.toLowerCase() == n)
                    for (o in t)
                        s.style[o] = t[o]
        }
        i.styleFixes.push({
            selectorText: n,
            fixes: t
        })
    }
    ,
    i.reapplyStyleFixes = function() {
        var t = i.styleFixes, n;
        for (i.styleFixes = [],
        n = 0; n < t.length; n++)
            i.applyStyleFixes(t[n].selectorText, t[n].fixes)
    }
    ,
    i.createStyleSheet = function(n) {
        r.createStyleSheet ? r.createStyleSheet().cssText = n : r.head.appendChild(i.create("style", {
            type: "text/css",
            innerHTML: n
        }))
    }
    ,
    i.create = function(n, t, i) {
        var f = r.createElement(n), u;
        for (u in t || {})
            f[u] = t[u];
        return i && (f.style.cssText = i),
        f
    }
    ,
    i.append = function(n) {
        i.container || (i.container = i.create("div", {
            className: "pca"
        }),
        r.body.appendChild(i.container)),
        i.container.appendChild(n)
    }
    ,
    i.remove = function(n) {
        n && n.parentNode && n.parentNode == i.container && i.container.removeChild(n)
    }
    ,
    i.listen = function(t, i, r, u) {
        n.addEventListener ? t.addEventListener(i, r, u) : t.attachEvent("on" + i, r)
    }
    ,
    i.fire = function(n, t) {
        if (r.createEvent) {
            var i = r.createEvent("HTMLEvents");
            return i.initEvent(t, !0, !0),
            !n.dispatchEvent(i)
        }
        return n.fireEvent("on" + t, r.createEventObject())
    }
    ,
    i.ignore = function(t, i, r) {
        n.removeEventListener ? t.removeEventListener(i, r) : t.detachEvent("on" + i, r)
    }
    ,
    i.smash = function(t) {
        var i = t || n.event;
        i.stopPropagation ? i.stopPropagation() : i.cancelBubble = !0,
        i.preventDefault ? i.preventDefault() : i.returnValue = !1
    }
    ,
    i.debug = function(n) {
        typeof console != "undefined" && console.debug && console.debug(n)
    }
    ,
    v()
}
)(window);
(function() {
    var n = window.pca = window.pca || {};
    n.countries = [{
        iso2: "AF",
        iso3: "AFG",
        name: "Afghanistan",
        name_fr: "Afghanistan",
        flag: 1
    }, {
        iso2: "AX",
        iso3: "ALA",
        name: "Åland",
        name_fr: "Åland(les Îles)",
        flag: 220
    }, {
        iso2: "AL",
        iso3: "ALB",
        name: "Albania",
        name_fr: "Albanie",
        alternates: ["Shqipëria"],
        flag: 2
    }, {
        iso2: "DZ",
        iso3: "DZA",
        name: "Algeria",
        name_fr: "Algérie",
        flag: 3
    }, {
        iso2: "AS",
        iso3: "ASM",
        name: "American Samoa",
        name_fr: "Samoa américaines",
        flag: 4
    }, {
        iso2: "AD",
        iso3: "AND",
        name: "Andorra",
        name_fr: "Andorre",
        flag: 5
    }, {
        iso2: "AO",
        iso3: "AGO",
        name: "Angola",
        name_fr: "Angola",
        flag: 6
    }, {
        iso2: "AI",
        iso3: "AIA",
        name: "Anguilla",
        name_fr: "Anguilla",
        flag: 7
    }, {
        iso2: "AQ",
        iso3: "ATA",
        name: "Antarctica",
        name_fr: "Antarctique",
        flag: 0
    }, {
        iso2: "AG",
        iso3: "ATG",
        name: "Antigua and Barbuda",
        name_fr: "Antigua-et-Barbuda",
        flag: 8
    }, {
        iso2: "AR",
        iso3: "ARG",
        name: "Argentina",
        name_fr: "Argentine",
        flag: 9
    }, {
        iso2: "AM",
        iso3: "ARM",
        name: "Armenia",
        name_fr: "Arménie",
        flag: 10
    }, {
        iso2: "AW",
        iso3: "ABW",
        name: "Aruba",
        name_fr: "Aruba",
        flag: 11
    }, {
        iso2: "AU",
        iso3: "AUS",
        name: "Australia",
        name_fr: "Australie",
        flag: 12
    }, {
        iso2: "AT",
        iso3: "AUT",
        name: "Austria",
        name_fr: "Autriche",
        alternates: ["Österreich"],
        flag: 13
    }, {
        iso2: "AZ",
        iso3: "AZE",
        name: "Azerbaijan",
        name_fr: "Azerbaïdjan",
        flag: 14
    }, {
        iso2: "BS",
        iso3: "BHS",
        name: "Bahamas",
        name_fr: "Bahamas",
        flag: 15
    }, {
        iso2: "BH",
        iso3: "BHR",
        name: "Bahrain",
        name_fr: "Bahreïn",
        flag: 16
    }, {
        iso2: "BD",
        iso3: "BGD",
        name: "Bangladesh",
        name_fr: "Bangladesh",
        flag: 17
    }, {
        iso2: "BB",
        iso3: "BRB",
        name: "Barbados",
        name_fr: "Barbade",
        flag: 18
    }, {
        iso2: "BY",
        iso3: "BLR",
        name: "Belarus",
        name_fr: "Bélarus",
        flag: 19
    }, {
        iso2: "BE",
        iso3: "BEL",
        name: "Belgium",
        name_fr: "Belgique",
        alternates: ["België"],
        flag: 20
    }, {
        iso2: "BZ",
        iso3: "BLZ",
        name: "Belize",
        name_fr: "Belize",
        flag: 21
    }, {
        iso2: "BJ",
        iso3: "BEN",
        name: "Benin",
        name_fr: "Bénin",
        flag: 22
    }, {
        iso2: "BM",
        iso3: "BMU",
        name: "Bermuda",
        name_fr: "Bermudes",
        flag: 23
    }, {
        iso2: "BT",
        iso3: "BTN",
        name: "Bhutan",
        name_fr: "Bhoutan",
        flag: 24
    }, {
        iso2: "BO",
        iso3: "BOL",
        name: "Bolivia",
        name_fr: "Bolivie, l'État plurinational de la",
        flag: 25
    }, {
        iso2: "BQ",
        iso3: "BES",
        name: "Bonaire, Sint Eustatius and Saba",
        name_fr: "Bonaire, Saint-Eustache et Saba",
        flag: 0
    }, {
        iso2: "BA",
        iso3: "BIH",
        name: "Bosnia and Herzegovina",
        name_fr: "Bosnie-Herzégovine",
        alternates: ["Bosna i Hercegovina"],
        flag: 26
    }, {
        iso2: "BW",
        iso3: "BWA",
        name: "Botswana",
        name_fr: "Botswana",
        flag: 27
    }, {
        iso2: "BV",
        iso3: "BVT",
        name: "Bouvet Island",
        name_fr: "Bouvet (l'Île)",
        flag: 0
    }, {
        iso2: "BR",
        iso3: "BRA",
        name: "Brazil",
        name_fr: "Brésil",
        alternates: ["Brasil"],
        flag: 28
    }, {
        iso2: "IO",
        iso3: "IOT",
        name: "British Indian Ocean Territory",
        name_fr: "Indien (le Territoire britannique de l'océan)",
        flag: 29
    }, {
        iso2: "VG",
        iso3: "VGB",
        name: "British Virgin Islands",
        name_fr: "Vierges britanniques (les Îles)",
        flag: 30
    }, {
        iso2: "BN",
        iso3: "BRN",
        name: "Brunei",
        name_fr: "Brunei",
        flag: 0
    }, {
        iso2: "BG",
        iso3: "BGR",
        name: "Bulgaria",
        name_fr: "Bulgarie",
        flag: 31
    }, {
        iso2: "BF",
        iso3: "BFA",
        name: "Burkina Faso",
        name_fr: "Burkina Faso",
        flag: 32
    }, {
        iso2: "MM",
        iso3: "MMR",
        name: "Burma",
        name_fr: "Myanmar",
        flag: 33
    }, {
        iso2: "BI",
        iso3: "BDI",
        name: "Burundi",
        name_fr: "Burundi",
        flag: 34
    }, {
        iso2: "KH",
        iso3: "KHM",
        name: "Cambodia",
        name_fr: "Cambodge",
        flag: 35
    }, {
        iso2: "CM",
        iso3: "CMR",
        name: "Cameroon",
        name_fr: "Cameroun",
        flag: 36
    }, {
        iso2: "CA",
        iso3: "CAN",
        name: "Canada",
        name_fr: "Canada",
        flag: 37
    }, {
        iso2: "CV",
        iso3: "CPV",
        name: "Cape Verde",
        name_fr: "Cabo Verde",
        flag: 38
    }, {
        iso2: "KY",
        iso3: "CYM",
        name: "Cayman Islands",
        name_fr: "Caïmans (les Îles)",
        flag: 39
    }, {
        iso2: "CF",
        iso3: "CAF",
        name: "Central African Republic",
        name_fr: "République centrafricaine",
        flag: 40
    }, {
        iso2: "TD",
        iso3: "TCD",
        name: "Chad",
        name_fr: "Tchad",
        flag: 41
    }, {
        iso2: "CL",
        iso3: "CHL",
        name: "Chile",
        name_fr: "Chili",
        flag: 42
    }, {
        iso2: "CN",
        iso3: "CHN",
        name: "China",
        name_fr: "Chine",
        flag: 43
    }, {
        iso2: "CX",
        iso3: "CXR",
        name: "Christmas Island",
        name_fr: "Christmas (l'Île)",
        flag: 0
    }, {
        iso2: "CC",
        iso3: "CCK",
        name: "Cocos (Keeling) Islands",
        name_fr: "Cocos (les Îles)/ Keeling (les Îles)",
        flag: 0
    }, {
        iso2: "CO",
        iso3: "COL",
        name: "Colombia",
        name_fr: "Colombie",
        flag: 44
    }, {
        iso2: "KM",
        iso3: "COM",
        name: "Comoros",
        name_fr: "Comores",
        flag: 45
    }, {
        iso2: "CG",
        iso3: "COG",
        name: "Congo",
        name_fr: "Congo",
        flag: 0
    }, {
        iso2: "CD",
        iso3: "COD",
        name: "Congo (Democratic Republic)",
        name_fr: "Congo (la République démocratique du)",
        flag: 46
    }, {
        iso2: "CK",
        iso3: "COK",
        name: "Cook Islands",
        name_fr: "Cook (les Îles)",
        flag: 47
    }, {
        iso2: "CR",
        iso3: "CRI",
        name: "Costa Rica",
        name_fr: "Costa Rica",
        flag: 48
    }, {
        iso2: "HR",
        iso3: "HRV",
        name: "Croatia",
        name_fr: "Croatie",
        alternates: ["Hrvatska"],
        flag: 50
    }, {
        iso2: "CU",
        iso3: "CUB",
        name: "Cuba",
        name_fr: "Cuba",
        flag: 51
    }, {
        iso2: "CW",
        iso3: "CUW",
        name: "Curaçao",
        name_fr: "Curaçao",
        flag: 0
    }, {
        iso2: "CY",
        iso3: "CYP",
        name: "Cyprus",
        name_fr: "Chypre",
        flag: 52
    }, {
        iso2: "CZ",
        iso3: "CZE",
        name: "Czechia",
        name_fr: "tchèque (la République)",
        alternates: ["Ceská republika"],
        flag: 53
    }, {
        iso2: "DK",
        iso3: "DNK",
        name: "Denmark",
        name_fr: "Danemark",
        flag: 54
    }, {
        iso2: "DJ",
        iso3: "DJI",
        name: "Djibouti",
        name_fr: "Djibouti",
        flag: 55
    }, {
        iso2: "DM",
        iso3: "DMA",
        name: "Dominica",
        name_fr: "Dominique",
        flag: 56
    }, {
        iso2: "DO",
        iso3: "DOM",
        name: "Dominican Republic",
        name_fr: "dominicaine (la République)",
        flag: 57
    }, {
        iso2: "TL",
        iso3: "TLS",
        name: "East Timor",
        name_fr: "Timor-Leste",
        flag: 0
    }, {
        iso2: "EC",
        iso3: "ECU",
        name: "Ecuador",
        name_fr: "Équateur",
        flag: 61
    }, {
        iso2: "EG",
        iso3: "EGY",
        name: "Egypt",
        name_fr: "Égypte",
        flag: 58
    }, {
        iso2: "SV",
        iso3: "SLV",
        name: "El Salvador",
        name_fr: "Salvador",
        flag: 59
    }, {
        iso2: "GQ",
        iso3: "GNQ",
        name: "Equatorial Guinea",
        name_fr: "Guinée équatoriale",
        flag: 62
    }, {
        iso2: "ER",
        iso3: "ERI",
        name: "Eritrea",
        name_fr: "Érythrée",
        flag: 63
    }, {
        iso2: "EE",
        iso3: "EST",
        name: "Estonia",
        name_fr: "Estonie",
        alternates: ["Eesti"],
        flag: 64
    }, {
        iso2: "SZ",
        iso3: "SWZ",
        name: "Eswatini",
        name_fr: "Eswatini",
        flag: 191
    }, {
        iso2: "ET",
        iso3: "ETH",
        name: "Ethiopia",
        name_fr: "Éthiopie",
        flag: 65
    }, {
        iso2: "FK",
        iso3: "FLK",
        name: "Falkland Islands",
        name_fr: "Falkland (les Îles)/Malouines (les Îles)",
        flag: 66
    }, {
        iso2: "FO",
        iso3: "FRO",
        name: "Faroe Islands",
        name_fr: "Féroé (les Îles)",
        flag: 67
    }, {
        iso2: "FJ",
        iso3: "FJI",
        name: "Fiji",
        name_fr: "Fidji",
        flag: 68
    }, {
        iso2: "FI",
        iso3: "FIN",
        name: "Finland",
        name_fr: "Finlande",
        alternates: ["Suomi"],
        flag: 69
    }, {
        iso2: "FR",
        iso3: "FRA",
        name: "France",
        name_fr: "France",
        flag: 70
    }, {
        iso2: "GF",
        iso3: "GUF",
        name: "French Guiana",
        name_fr: "Guyane française ",
        flag: 0
    }, {
        iso2: "PF",
        iso3: "PYF",
        name: "French Polynesia",
        name_fr: "Polynésie française",
        flag: 71
    }, {
        iso2: "TF",
        iso3: "ATF",
        name: "French Southern Territories",
        name_fr: "Terres australes françaises",
        flag: 0
    }, {
        iso2: "GA",
        iso3: "GAB",
        name: "Gabon",
        name_fr: "Gabon",
        flag: 72
    }, {
        iso2: "GM",
        iso3: "GMB",
        name: "Gambia",
        name_fr: "Gambie",
        flag: 73
    }, {
        iso2: "GE",
        iso3: "GEO",
        name: "Georgia",
        name_fr: "Géorgie",
        flag: 74
    }, {
        iso2: "DE",
        iso3: "DEU",
        name: "Germany",
        name_fr: "Allemagne",
        alternates: ["Deutschland"],
        flag: 75
    }, {
        iso2: "GH",
        iso3: "GHA",
        name: "Ghana",
        name_fr: "Ghana",
        flag: 76
    }, {
        iso2: "GI",
        iso3: "GIB",
        name: "Gibraltar",
        name_fr: "Gibraltar",
        flag: 77
    }, {
        iso2: "GR",
        iso3: "GRC",
        name: "Greece",
        name_fr: "Grèce",
        alternates: ["Hellas"],
        flag: 79
    }, {
        iso2: "GL",
        iso3: "GRL",
        name: "Greenland",
        name_fr: "Groenland",
        flag: 80
    }, {
        iso2: "GD",
        iso3: "GRD",
        name: "Grenada",
        name_fr: "Grenade",
        flag: 81
    }, {
        iso2: "GP",
        iso3: "GLP",
        name: "Guadeloupe",
        name_fr: "Guadeloupe",
        flag: 0
    }, {
        iso2: "GU",
        iso3: "GUM",
        name: "Guam",
        name_fr: "Guam",
        flag: 82
    }, {
        iso2: "GT",
        iso3: "GTM",
        name: "Guatemala",
        name_fr: "Guatemala",
        flag: 83
    }, {
        iso2: "GG",
        iso3: "GGY",
        name: "Guernsey",
        name_fr: "Guernesey",
        flag: 84
    }, {
        iso2: "GN",
        iso3: "GIN",
        name: "Guinea",
        name_fr: "Guinée",
        flag: 85
    }, {
        iso2: "GW",
        iso3: "GNB",
        name: "Guinea-Bissau",
        name_fr: "Guinée-Bissau",
        flag: 86
    }, {
        iso2: "GY",
        iso3: "GUY",
        name: "Guyana",
        name_fr: "Guyana",
        flag: 87
    }, {
        iso2: "HT",
        iso3: "HTI",
        name: "Haiti",
        name_fr: "Haïti",
        flag: 88
    }, {
        iso2: "HM",
        iso3: "HMD",
        name: "Heard Island and McDonald Islands",
        name_fr: "Heard-et-Îles MacDonald (l'Île)",
        flag: 0
    }, {
        iso2: "HN",
        iso3: "HND",
        name: "Honduras",
        name_fr: "Honduras",
        flag: 89
    }, {
        iso2: "HK",
        iso3: "HKG",
        name: "Hong Kong",
        name_fr: "Hong Kong",
        flag: 90
    }, {
        iso2: "HU",
        iso3: "HUN",
        name: "Hungary",
        name_fr: "Hongrie",
        alternates: ["Magyarország"],
        flag: 91
    }, {
        iso2: "IS",
        iso3: "ISL",
        name: "Iceland",
        name_fr: "Islande",
        alternates: ["Ísland"],
        flag: 92
    }, {
        iso2: "IN",
        iso3: "IND",
        name: "India",
        name_fr: "Inde",
        flag: 93
    }, {
        iso2: "ID",
        iso3: "IDN",
        name: "Indonesia",
        name_fr: "Indonésie",
        flag: 94
    }, {
        iso2: "IR",
        iso3: "IRN",
        name: "Iran",
        name_fr: "Iran (République Islamique d')",
        flag: 95
    }, {
        iso2: "IQ",
        iso3: "IRQ",
        name: "Iraq",
        name_fr: "Iraq",
        flag: 96
    }, {
        iso2: "IE",
        iso3: "IRL",
        name: "Ireland",
        name_fr: "Irlande",
        flag: 97
    }, {
        iso2: "IM",
        iso3: "IMN",
        name: "Isle of Man",
        name_fr: "Île de Man",
        flag: 98
    }, {
        iso2: "IL",
        iso3: "ISR",
        name: "Israel",
        name_fr: "Israël",
        flag: 99
    }, {
        iso2: "IT",
        iso3: "ITA",
        name: "Italy",
        name_fr: "Italie",
        alternates: ["Italia"],
        flag: 100
    }, {
        iso2: "CI",
        iso3: "CIV",
        name: "Ivory Coast",
        name_fr: "Côte d'Ivoire",
        flag: 49
    }, {
        iso2: "JM",
        iso3: "JAM",
        name: "Jamaica",
        name_fr: "Jamaïque",
        flag: 101
    }, {
        iso2: "JP",
        iso3: "JPN",
        name: "Japan",
        name_fr: "Japon",
        flag: 102
    }, {
        iso2: "JE",
        iso3: "JEY",
        name: "Jersey",
        name_fr: "Jersey",
        flag: 103
    }, {
        iso2: "JO",
        iso3: "JOR",
        name: "Jordan",
        name_fr: "Jordanie",
        flag: 104
    }, {
        iso2: "KZ",
        iso3: "KAZ",
        name: "Kazakhstan",
        name_fr: "Kazakhstan",
        flag: 105
    }, {
        iso2: "KE",
        iso3: "KEN",
        name: "Kenya",
        name_fr: "Kenya",
        flag: 106
    }, {
        iso2: "KI",
        iso3: "KIR",
        name: "Kiribati",
        name_fr: "Kiribati",
        flag: 107
    }, {
        iso2: "KP",
        iso3: "PRK",
        name: "Korea (North)",
        name_fr: "Corée (la République populaire démocratique de )",
        flag: 149
    }, {
        iso2: "KR",
        iso3: "KOR",
        name: "Korea (South)",
        name_fr: "Corée (la République de)",
        flag: 185
    }, {
        iso2: "KW",
        iso3: "KWT",
        name: "Kuwait",
        name_fr: "Koweït",
        flag: 108
    }, {
        iso2: "KG",
        iso3: "KGZ",
        name: "Kyrgyzstan",
        name_fr: "Kirghizistan",
        flag: 109
    }, {
        iso2: "LA",
        iso3: "LAO",
        name: "Laos",
        name_fr: "Lao, République démocratique populaire",
        flag: 0
    }, {
        iso2: "LV",
        iso3: "LVA",
        name: "Latvia",
        name_fr: "Lettonie",
        flag: 110
    }, {
        iso2: "LB",
        iso3: "LBN",
        name: "Lebanon",
        name_fr: "Liban",
        flag: 111
    }, {
        iso2: "LS",
        iso3: "LSO",
        name: "Lesotho",
        name_fr: "Lesotho",
        flag: 112
    }, {
        iso2: "LR",
        iso3: "LBR",
        name: "Liberia",
        name_fr: "Libéria",
        flag: 113
    }, {
        iso2: "LY",
        iso3: "LBY",
        name: "Libya",
        name_fr: "Libye",
        flag: 114
    }, {
        iso2: "LI",
        iso3: "LIE",
        name: "Liechtenstein",
        name_fr: "Liechtenstein",
        flag: 115
    }, {
        iso2: "LT",
        iso3: "LTU",
        name: "Lithuania",
        name_fr: "Lituanie",
        alternates: ["Lietuva"],
        flag: 116
    }, {
        iso2: "LU",
        iso3: "LUX",
        name: "Luxembourg",
        name_fr: "Luxembourg",
        flag: 117
    }, {
        iso2: "MO",
        iso3: "MAC",
        name: "Macao",
        name_fr: "Macao",
        flag: 118
    }, {
        iso2: "MK",
        iso3: "MKD",
        name: "Macedonia",
        name_fr: "Macédoine (l'ex-République yougoslave de)",
        alternates: ["Poraneshna Jugoslovenska Republika Makedonija"],
        flag: 119
    }, {
        iso2: "MG",
        iso3: "MDG",
        name: "Madagascar",
        name_fr: "Madagascar",
        flag: 120
    }, {
        iso2: "MW",
        iso3: "MWI",
        name: "Malawi",
        name_fr: "Malawi",
        flag: 121
    }, {
        iso2: "MY",
        iso3: "MYS",
        name: "Malaysia",
        name_fr: "Malaisie",
        flag: 122
    }, {
        iso2: "MV",
        iso3: "MDV",
        name: "Maldives",
        name_fr: "Maldives",
        flag: 123
    }, {
        iso2: "ML",
        iso3: "MLI",
        name: "Mali",
        name_fr: "Mali",
        flag: 124
    }, {
        iso2: "MT",
        iso3: "MLT",
        name: "Malta",
        name_fr: "Malte",
        flag: 125
    }, {
        iso2: "MH",
        iso3: "MHL",
        name: "Marshall Islands",
        name_fr: "Marshall (Îles)",
        flag: 126
    }, {
        iso2: "MQ",
        iso3: "MTQ",
        name: "Martinique",
        name_fr: "Martinique",
        flag: 127
    }, {
        iso2: "MR",
        iso3: "MRT",
        name: "Mauritania",
        name_fr: "Mauritanie",
        flag: 128
    }, {
        iso2: "MU",
        iso3: "MUS",
        name: "Mauritius",
        name_fr: "Maurice",
        flag: 129
    }, {
        iso2: "YT",
        iso3: "MYT",
        name: "Mayotte",
        name_fr: "Mayotte",
        flag: 0
    }, {
        iso2: "MX",
        iso3: "MEX",
        name: "Mexico",
        name_fr: "Mexique",
        flag: 130
    }, {
        iso2: "FM",
        iso3: "FSM",
        name: "Micronesia",
        name_fr: "Micronésie, États fédérés de",
        flag: 131
    }, {
        iso2: "MD",
        iso3: "MDA",
        name: "Moldova",
        name_fr: "Moldova , République de",
        flag: 132
    }, {
        iso2: "MC",
        iso3: "MCO",
        name: "Monaco",
        name_fr: "Monaco",
        flag: 133
    }, {
        iso2: "MN",
        iso3: "MNG",
        name: "Mongolia",
        name_fr: "Mongolie",
        flag: 134
    }, {
        iso2: "ME",
        iso3: "MNE",
        name: "Montenegro",
        name_fr: "Monténégro",
        alternates: ["Crna Gora"],
        flag: 0
    }, {
        iso2: "MS",
        iso3: "MSR",
        name: "Montserrat",
        name_fr: "Montserrat",
        flag: 135
    }, {
        iso2: "MA",
        iso3: "MAR",
        name: "Morocco",
        name_fr: "Maroc",
        flag: 136
    }, {
        iso2: "MZ",
        iso3: "MOZ",
        name: "Mozambique",
        name_fr: "Mozambique",
        flag: 137
    }, {
        iso2: "NA",
        iso3: "NAM",
        name: "Namibia",
        name_fr: "Namibie",
        flag: 138
    }, {
        iso2: "NR",
        iso3: "NRU",
        name: "Nauru",
        name_fr: "Nauru",
        flag: 139
    }, {
        iso2: "NP",
        iso3: "NPL",
        name: "Nepal",
        name_fr: "Népal",
        flag: 140
    }, {
        iso2: "NL",
        iso3: "NLD",
        name: "Netherlands",
        name_fr: "Pays-Bas",
        alternates: ["Holland"],
        flag: 141
    }, {
        iso2: "NC",
        iso3: "NCL",
        name: "New Caledonia",
        name_fr: "Nouvelle-Calédonie",
        flag: 0
    }, {
        iso2: "NZ",
        iso3: "NZL",
        name: "New Zealand",
        name_fr: "Nouvelle-Zélande",
        flag: 142
    }, {
        iso2: "NI",
        iso3: "NIC",
        name: "Nicaragua",
        name_fr: "Nicaragua",
        flag: 143
    }, {
        iso2: "NE",
        iso3: "NER",
        name: "Niger",
        name_fr: "Niger",
        flag: 144
    }, {
        iso2: "NG",
        iso3: "NGA",
        name: "Nigeria",
        name_fr: "Nigéria",
        flag: 145
    }, {
        iso2: "NU",
        iso3: "NIU",
        name: "Niue",
        name_fr: "Niue",
        flag: 146
    }, {
        iso2: "NF",
        iso3: "NFK",
        name: "Norfolk Island",
        name_fr: "Norfolk (l'Île)",
        flag: 147
    }, {
        iso2: "MP",
        iso3: "MNP",
        name: "Northern Mariana Islands",
        name_fr: "Mariannes du Nord (les Îles)",
        flag: 148
    }, {
        iso2: "NO",
        iso3: "NOR",
        name: "Norway",
        name_fr: "Norvège",
        flag: 150
    }, {
        iso2: "OM",
        iso3: "OMN",
        name: "Oman",
        name_fr: "Oman",
        flag: 151
    }, {
        iso2: "PK",
        iso3: "PAK",
        name: "Pakistan",
        name_fr: "Pakistan",
        flag: 152
    }, {
        iso2: "PW",
        iso3: "PLW",
        name: "Palau",
        name_fr: "Palaos",
        flag: 153
    }, {
        iso2: "PS",
        iso3: "PSE",
        name: "Palestine",
        name_fr: "Palestine, État de",
        flag: 0
    }, {
        iso2: "PA",
        iso3: "PAN",
        name: "Panama",
        name_fr: "Panama",
        flag: 154
    }, {
        iso2: "PG",
        iso3: "PNG",
        name: "Papua New Guinea",
        name_fr: "Papouasie-Nouvelle-Guinée",
        flag: 155
    }, {
        iso2: "PY",
        iso3: "PRY",
        name: "Paraguay",
        name_fr: "Paraguay",
        flag: 156
    }, {
        iso2: "PE",
        iso3: "PER",
        name: "Peru",
        name_fr: "Pérou",
        flag: 157
    }, {
        iso2: "PH",
        iso3: "PHL",
        name: "Philippines",
        name_fr: "Philippines",
        flag: 158
    }, {
        iso2: "PN",
        iso3: "PCN",
        name: "Pitcairn",
        name_fr: "Pitcairn",
        flag: 0
    }, {
        iso2: "PL",
        iso3: "POL",
        name: "Poland",
        name_fr: "Pologne",
        alternates: ["Polska"],
        flag: 159
    }, {
        iso2: "PT",
        iso3: "PRT",
        name: "Portugal",
        name_fr: "Portugal",
        flag: 160
    }, {
        iso2: "PR",
        iso3: "PRI",
        name: "Puerto Rico",
        name_fr: "Porto Rico",
        flag: 161
    }, {
        iso2: "QA",
        iso3: "QAT",
        name: "Qatar",
        name_fr: "Qatar",
        flag: 162
    }, {
        iso2: "RE",
        iso3: "REU",
        name: "Réunion",
        name_fr: "Réunion",
        flag: 0
    }, {
        iso2: "RO",
        iso3: "ROU",
        name: "Romania",
        name_fr: "Roumanie",
        alternates: ["România"],
        flag: 163
    }, {
        iso2: "RU",
        iso3: "RUS",
        name: "Russia",
        name_fr: "Russie (la Fédération de)",
        alternates: ["Rossiya"],
        flag: 164
    }, {
        iso2: "RW",
        iso3: "RWA",
        name: "Rwanda",
        name_fr: "Rwanda",
        flag: 165
    }, {
        iso2: "MF",
        iso3: "MAF",
        name: "Saint Martin (French)",
        name_fr: "Saint-Martin (partie française)",
        flag: 0
    }, {
        iso2: "WS",
        iso3: "WSM",
        name: "Samoa",
        name_fr: "Samoa",
        flag: 171
    }, {
        iso2: "SM",
        iso3: "SMR",
        name: "San Marino",
        name_fr: "Saint-Marin",
        flag: 172
    }, {
        iso2: "ST",
        iso3: "STP",
        name: "Sao Tome and Principe",
        name_fr: "Sao Tomé-et-Principe",
        flag: 173
    }, {
        iso2: "SA",
        iso3: "SAU",
        name: "Saudi Arabia",
        name_fr: "Arabie saoudite",
        flag: 174
    }, {
        iso2: "SN",
        iso3: "SEN",
        name: "Senegal",
        name_fr: "Sénégal",
        flag: 175
    }, {
        iso2: "RS",
        iso3: "SRB",
        name: "Serbia",
        name_fr: "Serbie",
        alternates: ["Srbija"],
        flag: 0
    }, {
        iso2: "SC",
        iso3: "SYC",
        name: "Seychelles",
        name_fr: "Seychelles",
        flag: 176
    }, {
        iso2: "SL",
        iso3: "SLE",
        name: "Sierra Leone",
        name_fr: "Sierra Leone",
        flag: 177
    }, {
        iso2: "SG",
        iso3: "SGP",
        name: "Singapore",
        name_fr: "Singapour",
        flag: 178
    }, {
        iso2: "SX",
        iso3: "SXM",
        name: "Sint Maarten (Dutch)",
        name_fr: "Saint-Martin (partie néerlandaise)",
        flag: 0
    }, {
        iso2: "SK",
        iso3: "SVK",
        name: "Slovakia",
        name_fr: "Slovaquie",
        alternates: ["Slovenská republika"],
        flag: 179
    }, {
        iso2: "SI",
        iso3: "SVN",
        name: "Slovenia",
        name_fr: "Slovénie",
        alternates: ["Slovenija"],
        flag: 180
    }, {
        iso2: "SB",
        iso3: "SLB",
        name: "Solomon Islands",
        name_fr: "Salomon (Îles)",
        flag: 181
    }, {
        iso2: "SO",
        iso3: "SOM",
        name: "Somalia",
        name_fr: "Somalie",
        flag: 182
    }, {
        iso2: "ZA",
        iso3: "ZAF",
        name: "South Africa",
        name_fr: "Afrique du Sud",
        flag: 183
    }, {
        iso2: "GS",
        iso3: "SGS",
        name: "South Georgia and the South Sandwich Islands",
        name_fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
        flag: 184
    }, {
        iso2: "SS",
        iso3: "SSD",
        name: "South Sudan ",
        name_fr: "Soudan du Sud",
        flag: 0
    }, {
        iso2: "ES",
        iso3: "ESP",
        name: "Spain",
        name_fr: "Espagne",
        alternates: ["España"],
        flag: 186
    }, {
        iso2: "LK",
        iso3: "LKA",
        name: "Sri Lanka",
        name_fr: "Sri Lanka",
        flag: 187
    }, {
        iso2: "BL",
        iso3: "BLM",
        name: "St Barthélemy",
        name_fr: "Saint-Barthélemy",
        flag: 0
    }, {
        iso2: "SH",
        iso3: "SHN",
        name: "St Helena, Ascension and Tristan da Cunha",
        name_fr: "Sainte-Hélène, Ascension et Tristan da Cunha",
        flag: 166
    }, {
        iso2: "KN",
        iso3: "KNA",
        name: "St Kitts and Nevis",
        name_fr: "Saint-Kitts-et-Nevis",
        flag: 167
    }, {
        iso2: "LC",
        iso3: "LCA",
        name: "St Lucia",
        name_fr: "Sainte-Lucie",
        flag: 168
    }, {
        iso2: "PM",
        iso3: "SPM",
        name: "St Pierre and Miquelon",
        name_fr: "Saint-Pierre-et-Miquelon",
        flag: 169
    }, {
        iso2: "VC",
        iso3: "VCT",
        name: "St Vincent",
        name_fr: "Saint-Vincent-et-les-Grenadines",
        flag: 170
    }, {
        iso2: "SD",
        iso3: "SDN",
        name: "Sudan",
        name_fr: "Soudan",
        flag: 188
    }, {
        iso2: "SR",
        iso3: "SUR",
        name: "Suriname",
        name_fr: "Suriname",
        flag: 189
    }, {
        iso2: "SJ",
        iso3: "SJM",
        name: "Svalbard and Jan Mayen",
        name_fr: "Svalbard et l'Île Jan Mayen",
        flag: 190
    }, {
        iso2: "SE",
        iso3: "SWE",
        name: "Sweden",
        name_fr: "Suède",
        alternates: ["Sverige"],
        flag: 192
    }, {
        iso2: "CH",
        iso3: "CHE",
        name: "Switzerland",
        name_fr: "Suisse",
        alternates: ["Schweiz"],
        flag: 193
    }, {
        iso2: "SY",
        iso3: "SYR",
        name: "Syria",
        name_fr: "République arabe syrienne",
        flag: 0
    }, {
        iso2: "TW",
        iso3: "TWN",
        name: "Taiwan",
        name_fr: "Taïwan (Province de Chine)",
        flag: 194
    }, {
        iso2: "TJ",
        iso3: "TJK",
        name: "Tajikistan",
        name_fr: "Tadjikistan",
        flag: 195
    }, {
        iso2: "TZ",
        iso3: "TZA",
        name: "Tanzania",
        name_fr: "Tanzanie, République-Unie de",
        flag: 196
    }, {
        iso2: "TH",
        iso3: "THA",
        name: "Thailand",
        name_fr: "Thaïlande",
        flag: 197
    }, {
        iso2: "TG",
        iso3: "TGO",
        name: "Togo",
        name_fr: "Togo",
        flag: 198
    }, {
        iso2: "TK",
        iso3: "TKL",
        name: "Tokelau",
        name_fr: "Tokelau",
        flag: 0
    }, {
        iso2: "TO",
        iso3: "TON",
        name: "Tonga",
        name_fr: "Tonga",
        flag: 199
    }, {
        iso2: "TT",
        iso3: "TTO",
        name: "Trinidad and Tobago",
        name_fr: "Trinité-et-Tobago",
        flag: 200
    }, {
        iso2: "TN",
        iso3: "TUN",
        name: "Tunisia",
        name_fr: "Tunisie",
        flag: 201
    }, {
        iso2: "TR",
        iso3: "TUR",
        name: "Turkey",
        name_fr: "Turquie",
        alternates: ["Türkiye"],
        flag: 202
    }, {
        iso2: "TM",
        iso3: "TKM",
        name: "Turkmenistan",
        name_fr: "Turkménistan",
        flag: 203
    }, {
        iso2: "TC",
        iso3: "TCA",
        name: "Turks and Caicos Islands",
        name_fr: "Turks-et-Caïcos (les Îles)",
        flag: 204
    }, {
        iso2: "TV",
        iso3: "TUV",
        name: "Tuvalu",
        name_fr: "Tuvalu",
        flag: 205
    }, {
        iso2: "UG",
        iso3: "UGA",
        name: "Uganda",
        name_fr: "Ouganda",
        flag: 206
    }, {
        iso2: "UA",
        iso3: "UKR",
        name: "Ukraine",
        name_fr: "Ukraine",
        alternates: ["Ukraina"],
        flag: 207
    }, {
        iso2: "AE",
        iso3: "ARE",
        name: "United Arab Emirates",
        name_fr: "Émirats arabes unis",
        alternates: ["UAE"],
        flag: 208
    }, {
        iso2: "GB",
        iso3: "GBR",
        name: "United Kingdom",
        name_fr: "Royaume-Uni",
        alternates: ["Britain", "England", "Great Britain", "Northern Ireland", "Scotland", "UK", "Wales"],
        flag: 78
    }, {
        iso2: "US",
        iso3: "USA",
        name: "United States",
        name_fr: "États-Unis",
        alternates: ["America", "United States of America"],
        flag: 210
    }, {
        iso2: "UM",
        iso3: "UMI",
        name: "United States Minor Outlying Islands",
        name_fr: "Îles mineures éloignées des États-Unis",
        flag: 0
    }, {
        iso2: "VI",
        iso3: "VIR",
        name: "United States Virgin Islands",
        name_fr: "Vierges des États-Unis (les Îles)",
        flag: 215
    }, {
        iso2: "UY",
        iso3: "URY",
        name: "Uruguay",
        name_fr: "Uruguay",
        flag: 209
    }, {
        iso2: "UZ",
        iso3: "UZB",
        name: "Uzbekistan",
        name_fr: "Ouzbékistan",
        flag: 211
    }, {
        iso2: "VU",
        iso3: "VUT",
        name: "Vanuatu",
        name_fr: "Vanuatu",
        flag: 212
    }, {
        iso2: "VA",
        iso3: "VAT",
        name: "Vatican City",
        name_fr: "Saint-Siège [État de la Cité du Vatican]",
        flag: 0
    }, {
        iso2: "VE",
        iso3: "VEN",
        name: "Venezuela",
        name_fr: "Venezuela, République bolivarienne du ",
        flag: 213
    }, {
        iso2: "VN",
        iso3: "VNM",
        name: "Vietnam",
        name_fr: "Viet Nam",
        flag: 214
    }, {
        iso2: "WF",
        iso3: "WLF",
        name: "Wallis and Futuna",
        name_fr: "Wallis-et-Futuna ",
        flag: 216
    }, {
        iso2: "EH",
        iso3: "ESH",
        name: "Western Sahara",
        name_fr: "Sahara occidental",
        flag: 0
    }, {
        iso2: "YE",
        iso3: "YEM",
        name: "Yemen",
        name_fr: "Yémen",
        flag: 217
    }, {
        iso2: "ZM",
        iso3: "ZMB",
        name: "Zambia",
        name_fr: "Zambie",
        flag: 218
    }, {
        iso2: "ZW",
        iso3: "ZWE",
        name: "Zimbabwe",
        name_fr: "Zimbabwe",
        flag: 219
    }],
    n.countryNameType = {
        NAME: 0,
        ISO2: 1,
        ISO3: 2
    },
    n.CountryList = function(t, i) {
        var r = new n.Eventable(this);
        r.fields = t || [],
        r.options = i || {},
        r.options.defaultCode = r.options.defaultCode || "",
        r.options.value = r.options.value || "",
        r.options.codesList = r.options.codesList || "",
        r.options.fillOthers = r.options.fillOthers || !1,
        r.options.list = r.options.list || {},
        r.options.populate = typeof r.options.populate == "boolean" ? r.options.populate : !0,
        r.options.prepopulate = typeof r.options.prepopulate == "boolean" ? r.options.prepopulate : !0,
        r.options.language = r.options.language || "en",
        r.options.nameType = r.options.nameType || n.countryNameType.NAME,
        r.options.valueType = r.options.valueType || n.countryNameType.NAME,
        r.options.fallbackCode = r.options.fallbackCode || "GBR",
        r.autocomplete = new n.AutoComplete(r.fields,r.options.list),
        r.country = null,
        r.textChanged = !1,
        r.nameProperty = r.options.language == "fr" ? "name_fr" : "name",
        r.template = "<div class='pcaflag'></div><div class='pcaflaglabel'>{" + r.nameProperty + "}</div>",
        r.load = function() {
            function l(n) {
                r.change(n),
                r.fire("select", n)
            }
            function c(t) {
                if (n.selectList(t)) {
                    var i = n.getSelectedItem(t);
                    r.change(r.find(i.value) || r.find(i.text))
                } else
                    r.setCountry(n.getValue(t));
                r.textChanged = !1
            }
            var s, i, e, h, t, u, o, f;
            if (n.addClass(r.autocomplete.element, "pcacountrylist"),
            r.options.codesList) {
                for (s = r.options.codesList.replace(/\s/g, "").split(","),
                i = [],
                r.autocomplete.clear(),
                e = 0; e < s.length; e++)
                    for (h = s[e].toString().toUpperCase(),
                    t = 0; t < n.countries.length; t++)
                        if (n.countries[t].iso2 == h || n.countries[t].iso3 == h) {
                            i.push(n.countries[t]);
                            break
                        }
                if (r.options.fillOthers)
                    for (u = 0; u < n.countries.length; u++) {
                        for (o = !1,
                        f = 0; f < i.length; f++)
                            n.countries[u].iso3 == i[f].iso3 && (o = !0);
                        o || i.push(n.countries[u])
                    }
                r.autocomplete.clear().add(i, r.template, l)
            } else
                r.autocomplete.clear().add(n.countries, r.template, l);
            r.autocomplete.list.collection.all(function(t) {
                r.setFlagPosition(t.element.firstChild, t.data.flag),
                t.tag += " " + n.formatTag(t.data.iso3 + (t.data.alternates ? " " + t.data.alternates.join(" ") : ""))
            }),
            r.autocomplete.listen("focus", function() {
                r.autocomplete.showAll()
            }),
            r.autocomplete.listen("change", function(n) {
                r.autocomplete.visible ? r.textChanged = !0 : c(n)
            }),
            r.autocomplete.listen("hide", function() {
                r.textChanged && c(r.autocomplete.field)
            }),
            r.options.value && (r.country = r.find(r.options.value)),
            !r.country && r.options.defaultCode && (r.country = r.find(r.options.defaultCode)),
            r.country = r.country || (r.options.codesList ? r.first() : r.find(r.options.fallbackCode)) || r.first() || r.find(r.options.fallbackCode),
            r.fire("load")
        }
        ,
        r.getName = function(t) {
            switch (r.options.nameType) {
            case n.countryNameType.NAME:
                return (t || r.country)[r.nameProperty];
            case n.countryNameType.ISO2:
                return (t || r.country).iso2;
            case n.countryNameType.ISO3:
                return (t || r.country).iso3
            }
            return (t || r.country)[r.nameProperty]
        }
        ,
        r.getValue = function(t) {
            switch (r.options.valueType) {
            case n.countryNameType.NAME:
                return (t || r.country)[r.nameProperty];
            case n.countryNameType.ISO2:
                return (t || r.country).iso2;
            case n.countryNameType.ISO3:
                return (t || r.country).iso3
            }
            return (t || r.country).iso3
        }
        ,
        r.populate = function() {
            var e, f, i, t, u;
            if (r.options.populate) {
                for (e = r.getName(),
                f = r.getValue(),
                i = 0; i < r.fields.length; i++)
                    t = n.getElement(r.fields[i]),
                    u = n.getValue(t),
                    n.setValue(t, n.selectList(t) ? f : e),
                    r.options.prepopulate && u != n.getValue(t) && n.fire(t, "change");
                r.fire("populate")
            }
        }
        ,
        r.find = function(n) {
            function t(t) {
                if (t.data.alternates)
                    for (var i = 0; i < t.data.alternates.length; i++)
                        if (t.data.alternates[i].toUpperCase() == n)
                            return !0;
                return !1
            }
            return n = n.toString().toUpperCase(),
            (r.autocomplete.list.collection.first(function(i) {
                return i.data.iso2.toUpperCase() == n || i.data.iso3.toUpperCase() == n || i.data.name.toUpperCase() == n || i.data.name_fr.toUpperCase() == n || t(i)
            }) || {}).data
        }
        ,
        r.first = function() {
            return r.autocomplete.list.collection.first().data
        }
        ,
        r.change = function(n) {
            n && (r.country = n,
            r.populate(),
            r.textChanged = !1,
            r.fire("change", r.country))
        }
        ,
        r.setFlagPosition = function(n, t) {
            n.style.backgroundPosition = "-1px -" + (t * 16 + 2) + "px"
        }
        ,
        r.flag = function() {
            function t(n) {
                r.setFlagPosition(i, n.flag)
            }
            var i = n.create("div", {
                className: "pcaflag"
            });
            return t(r.country),
            r.listen("change", t),
            i
        }
        ,
        r.setCountry = function(n) {
            return r.change(r.find(n)),
            r
        }
        ,
        r.setCountryByIP = function(t) {
            function i(n) {
                n.length && n[0].Iso3 && r.setCountry(n[0].Iso3)
            }
            t && n.fetch("Extras/Web/Ip2Country/v1.10", {
                Key: t
            }, i)
        }
        ,
        r.load()
    }
}
)();
(function() {
    var n = window.pca = window.pca || {};
    n.fieldMode = {
        DEFAULT: 3,
        NONE: 0,
        SEARCH: 1,
        POPULATE: 2,
        PRESERVE: 4,
        COUNTRY: 8
    },
    n.filteringMode = {
        EVERYTHING: "Everything",
        POSTCODES: "PostalCodes",
        PLACES: "Places",
        COMPANIES: "Companies",
        RESIDENTIAL: "Residential"
    },
    n.orderingMode = {
        DEFAULT: "UserLocation",
        LOCATION: "UserLocation",
        NONE: ""
    },
    n.messages = {
        en: {
            DIDYOUMEAN: "Did you mean:",
            NORESULTS: "No results found",
            KEEPTYPING: "Keep typing your address to display more results",
            RETRIEVEERROR: "Sorry, we could not retrieve this address",
            SERVICEERROR: "Service Error:",
            COUNTRYSELECT: "Change Country",
            NOLOCATION: "Sorry, we could not get your location",
            NOCOUNTRY: "Sorry, we could not find this country",
            MANUALENTRY: "I cannot find my address. Let me type it in",
            RESULTCOUNT: "<b>{count}</b> matching results"
        },
        cy: {
            DIDYOUMEAN: "A oeddech yn meddwl:",
            NORESULTS: "Dim canlyniadau ar ganlyniadau",
            KEEPTYPING: "Cadwch teipio eich cyfeiriad i arddangos mwy o ganlyniadau",
            RETRIEVEERROR: "Mae'n ddrwg gennym, ni allem adfer y cyfeiriad hwn",
            SERVICEERROR: "Gwall gwasanaeth:",
            COUNTRYSELECT: "Dewiswch gwlad",
            NOLOCATION: "Mae'n ddrwg gennym, nid oeddem yn gallu cael eich lleoliad",
            NOCOUNTRY: "Mae'n ddrwg gennym, ni allem ddod o hyd y wlad hon",
            MANUALENTRY: "Ni allaf ddod o hyd i fy nghyfeiriad. Gadewch i mi deipio mewn",
            RESULTCOUNT: "<b>{count}</b> Canlyniadau paru"
        },
        fr: {
            DIDYOUMEAN: "Vouliez-vous dire:",
            NORESULTS: "Aucun résultat n'a été trouvé",
            KEEPTYPING: "Continuer à taper votre adresse pour afficher plus de résultats",
            RETRIEVEERROR: "Désolé , nous ne pouvions pas récupérer cette adresse",
            SERVICEERROR: "Erreur de service:",
            COUNTRYSELECT: "Changer de pays",
            NOLOCATION: "Désolé, nous n'avons pas pu obtenir votre emplacement",
            NOCOUNTRY: "Désolé, nous n'avons pas trouvé ce pays",
            MANUALENTRY: "Je ne peux pas trouver mon adresse. Permettez-moi de taper dans",
            RESULTCOUNT: "<b>{count}</b> résultats correspondants"
        },
        de: {
            DIDYOUMEAN: "Meinten Sie:",
            NORESULTS: "Keine Adressen gefunden",
            KEEPTYPING: "Geben Sie mehr von Ihrer Adresse ein, um weitere Ergebnisse anzuzeigen",
            RETRIEVEERROR: "Wir konnten diese Adresse leider nicht abrufen",
            SERVICEERROR: "Service-Fehler:",
            COUNTRYSELECT: "Land wechseln",
            NOLOCATION: "Wir konnten Ihren Standort leider nicht finden",
            NOCOUNTRY: "Wir konnten dieses Land leider nicht finden",
            MANUALENTRY: "Ich kann meine Adresse nicht finden. Lassen Sie mich es manuell eingeben",
            RESULTCOUNT: "<b>{count}</b> passenden Ergebnisse"
        }
    },
    n.exampleAddress = {
        Id: "GBR|PR|52509479|0|0|0",
        DomesticId: "52509479",
        Language: "ENG",
        LanguageAlternatives: "ENG",
        Department: "",
        Company: "Postcode Anywhere (Europe) Ltd",
        SubBuilding: "",
        BuildingNumber: "",
        BuildingName: "Waterside",
        SecondaryStreet: "",
        Street: "Basin Road",
        Block: "",
        Neighbourhood: "",
        District: "",
        City: "Worcester",
        Line1: "Waterside",
        Line2: "Basin Road",
        Line3: "",
        Line4: "",
        Line5: "",
        AdminAreaName: "Worcester",
        AdminAreaCode: "47UE",
        Province: "Worcestershire",
        ProvinceName: "Worcestershire",
        ProvinceCode: "",
        PostalCode: "WR5 3DA",
        CountryName: "United Kingdom",
        CountryIso2: "GB",
        CountryIso3: "GBR",
        CountryIsoNumber: 826,
        SortingNumber1: "94142",
        SortingNumber2: "",
        Barcode: "(WR53DA1PX)",
        Label: "Postcode Anywhere (Europe) Ltd\nWaterside\nBasin Road\n\nWorcester\nWR5 3DA\nUnited Kingdom",
        Type: "Commercial",
        DataLevel: "Premise",
        Field1: "",
        Field2: "",
        Field3: "",
        Field4: "",
        Field5: "",
        Field6: "",
        Field7: "",
        Field8: "",
        Field9: "",
        Field10: "",
        Field11: "",
        Field12: "",
        Field13: "",
        Field14: "",
        Field15: "",
        Field16: "",
        Field17: "",
        Field18: "",
        Field19: "",
        Field20: ""
    },
    n.templates = {
        AUTOCOMPLETE: "{HighlightedText}{<span class='pcadescription'>{Description}</span>}"
    },
    n.Address = function(t, i) {
        function u() {
            r.lastActionTimer != null && (window.clearTimeout(r.lastActionTimer),
            r.lastActionTimer = null)
        }
        function f() {
            r.reset(),
            r.message(n.messages[r.language].NORESULTS, {
                clickToDisable: r.options.manualEntry,
                error: !0,
                clearList: !0
            }),
            r.fire("noresults")
        }
        function e(t) {
            r.message(n.formatLine({
                count: t
            }, n.messages[r.language].RESULTCOUNT))
        }
        var r = new n.Eventable(this);
        r.fields = t || [],
        r.options = i || {},
        r.key = r.options.key || "",
        r.options.name = r.options.name || "",
        r.options.source = r.options.source || "",
        r.options.populate = typeof r.options.populate == "boolean" ? r.options.populate : !0,
        r.options.onlyInputs = typeof r.options.onlyInputs == "boolean" ? r.options.onlyInputs : !1,
        r.options.autoSearch = typeof r.options.autoSearch == "boolean" ? r.options.autoSearch : !1,
        r.options.preselect = typeof r.options.preselect == "boolean" ? r.options.preselect : !0,
        r.options.minSearch = r.options.minSearch || 1,
        r.options.minItems = r.options.minItems || 1,
        r.options.maxItems = r.options.maxItems || 7,
        r.options.advancedFields = r.options.advancedFields || [],
        r.options.manualEntry = typeof r.options.manualEntry == "boolean" ? r.options.manualEntry : !1,
        r.options.manualEntryItem = typeof r.options.manualEntryItem == "boolean" ? r.options.manualEntryItem : !1,
        r.options.disableTime = r.options.disableTime || 6e4,
        r.options.suppressAutocomplete = typeof r.options.suppressAutocomplete == "boolean" ? r.options.suppressAutocomplete : !0,
        r.options.setCountryByIP = typeof r.options.setCountryByIP == "boolean" ? r.options.setCountryByIP : !1,
        r.options.brand = r.options.brand || "PostcodeAnywhere" || "PostcodeAnywhere",
        r.options.product = r.options.product || "Capture+",
        r.options.provider = r.options.provider || "CapturePlus" || "CapturePlus",
        r.options.culture = r.options.culture || "en-GB",
        r.options.prompt = typeof r.options.prompt == "boolean" ? r.options.prompt : !1,
        r.options.promptDelay = r.options.promptDelay || 0,
        r.options.inlineMessages = typeof r.options.inlineMessages == "boolean" ? r.options.inlineMessages : !1,
        r.options.setCursor = typeof r.options.setCursor == "boolean" ? r.options.setCursor : !1,
        r.options.matchCount = typeof r.options.matchCount == "boolean" ? r.options.matchCount : !1,
        r.options.languagePreference = r.options.languagePreference || "",
        r.options.filteringMode = r.options.filteringMode || n.filteringMode.EVERYTHING,
        r.options.orderingMode = r.options.orderingMode || n.orderingMode.DEFAULT,
        r.options.countries = r.options.countries || {},
        r.options.countries.defaultCode = r.options.countries.defaultCode || "",
        r.options.countries.value = r.options.countries.value || "",
        r.options.countries.prepopulate = typeof r.options.countries.prepopulate == "boolean" ? r.options.countries.prepopulate : !0,
        r.options.list = r.options.list || {},
        r.options.list.name = r.options.name ? r.options.name + "_results" : "",
        r.options.list.maxItems = r.options.list.maxItems || r.options.maxItems,
        r.options.list.minItems = r.options.list.minItems || r.options.minItems,
        r.options.countries.list = r.options.countries.list || n.merge(r.options.list, {}),
        r.options.countries.list.name = r.options.name ? r.options.name + "_countries" : "",
        r.options.bar = r.options.bar || {},
        r.options.bar.visible = typeof r.options.bar.visible == "boolean" ? r.options.bar.visible : !0,
        r.options.bar.showCountry = typeof r.options.bar.showCountry == "boolean" ? r.options.bar.showCountry : !0,
        r.options.bar.showLogo = typeof r.options.bar.showLogo == "boolean" ? r.options.bar.showLogo : !0,
        r.options.bar.logoLink = typeof r.options.bar.logoLink == "boolean" ? r.options.bar.logoLink : !1,
        r.options.bar.logoClass = r.options.bar.logoClass || "pcalogo" || "pcalogo",
        r.options.bar.logoTitle = r.options.bar.logoTitle || "Powered by www.pcapredict.com",
        r.options.bar.logoUrl = r.options.bar.logoUrl || "http://www.pcapredict.com/",
        r.options.search = r.options.search || {},
        r.country = r.options.countries.defaultCode,
        r.advancedFields = r.options.advancedFields,
        r.initialSearch = !1,
        r.searchContext = null,
        r.lastActionTimer = null,
        r.notifcationTimer = null,
        r.storedSearch = null,
        r.geolocation = null,
        r.loaded = !1,
        r.language = "en",
        r.filteringMode = r.options.filteringMode,
        r.orderingMode = r.options.orderingMode,
        r.autocomplete = null,
        r.countrylist = null,
        r.messageBox = null,
        r.load = function() {
            for (var p = [], h = [], t, v, f, b, e, w, y, u, a, l, o, s, c = 0; c < r.fields.length; c++)
                t = r.fields[c],
                t.mode = typeof t.mode == "number" ? t.mode : n.fieldMode.DEFAULT,
                t.mode & n.fieldMode.COUNTRY ? (h.push(t.element),
                /CountryIso2/.test(t.field) && (r.options.countries.nameType = r.options.countries.nameType || n.countryNameType.ISO2,
                r.options.countries.valueType = r.options.countries.valueType || n.countryNameType.ISO2),
                /CountryIso3/.test(t.field) && (r.options.countries.nameType = r.options.countries.nameType || n.countryNameType.ISO3,
                r.options.countries.valueType = r.options.countries.valueType || n.countryNameType.ISO3)) : t.mode & n.fieldMode.SEARCH && (p.push(t.element),
                r.options.suppressAutocomplete && (v = n.getElement(t.element),
                v && (v.autocomplete = "off"))),
                t.field = r.checkFormat(t.field);
            r.detectLanguage(),
            r.autocomplete = new n.AutoComplete(p,r.options.list),
            r.autocomplete.skipFilter = !0,
            r.autocomplete.listen("keyup", function(n) {
                r.countrylist.autocomplete.visible ? r.countrylist.autocomplete.handleKey(n) : r.autocomplete.controlDown && n == 40 ? r.switchToCountrySelect() : (n == 0 || n == 8 || n == 32 || n > 36 || !n) && r.searchFromField()
            }),
            r.autocomplete.listen("paste", function() {
                r.newSearch(),
                r.searchFromField()
            }),
            r.autocomplete.listen("focus", r.focus),
            r.autocomplete.listen("blur", r.blur),
            r.autocomplete.listen("show", function() {
                r.fire("show")
            }),
            r.autocomplete.listen("hide", function() {
                r.fire("hide")
            }),
            r.autocomplete.listen("dblclick", r.searchFromField),
            r.autocomplete.list.listen("filter", function() {
                r.search(n.getValue(r.autocomplete.field))
            }),
            r.autocomplete.listen("delete", r.newSearch),
            !r.options.countries.value && h.length && (r.options.countries.value = n.getValue(h[0])),
            r.options.countries.language = r.language,
            r.countrylist = new n.CountryList(h,r.options.countries),
            r.countrylist.autocomplete.options.emptyMessage = n.messages[r.language].NOCOUNTRY,
            r.country = r.countrylist.country.iso3,
            r.countrylist.listen("change", function(n) {
                r.country = n && n.iso3 ? n.iso3 : r.options.countries.defaultCode,
                r.fire("country", n)
            }),
            r.countrylist.listen("select", r.switchToSearchMode),
            r.countrylist.autocomplete.listen("filter", function() {
                r.options.preselect && r.countrylist.autocomplete.list.first()
            }),
            r.countrylist.autocomplete.listen("show", function() {
                r.fire("show")
            }),
            r.countrylist.autocomplete.listen("hide", function() {
                r.autocomplete.enable(),
                r.storedSearch != null && n.setValue(r.autocomplete.field, r.storedSearch),
                r.storedSearch = null,
                r.fire("hide")
            }),
            r.countrylist.autocomplete.list.collection.count == 1 && (r.options.bar.showCountry = !1),
            f = n.create("div", {
                className: "pcaflagbutton"
            }),
            b = r.countrylist.flag(),
            f.appendChild(b),
            r.autocomplete.footer.setContent(r.options.bar.showCountry ? f : ""),
            n.listen(f, "click", r.switchToCountrySelect),
            e = n.create("div", {
                className: "pcaflagbutton"
            }),
            w = r.countrylist.flag(),
            e.appendChild(w),
            r.countrylist.autocomplete.footer.setContent(r.options.bar.showCountry ? e : ""),
            n.listen(e, "click", r.switchToSearchMode),
            y = n.create("div", {
                className: "pcamessage pcadisableselect",
                innerHTML: n.messages[r.language].COUNTRYSELECT
            }),
            r.autocomplete.footer.setContent(r.options.bar.showCountry ? y : ""),
            u = n.create("a", {
                href: r.options.bar.logoUrl,
                target: "_blank",
                rel: "nofollow"
            }),
            a = n.create("div", {
                className: r.options.bar.logoClass + " pcalogo" + r.language,
                title: r.options.bar.logoTitle
            }),
            r.options.bar.logoLink ? u.appendChild(a) : u = a,
            r.autocomplete.footer.setContent(u),
            r.showFooterLogo = function() {
                u.style.display = r.options.bar.showLogo ? "" : "none"
            }
            ,
            r.showFooterMessage = function() {
                u.style.display = r.options.bar.showCountry ? "none" : r.options.bar.showLogo ? "" : "none"
            }
            ,
            r.options.bar.visible ? (r.autocomplete.footer.show(),
            r.showFooterMessage()) : r.autocomplete.hide(),
            l = n.create("div", {
                className: "pcamessage pcadisableselect",
                innerHTML: n.messages[r.language].COUNTRYSELECT
            }),
            r.countrylist.autocomplete.footer.setContent(r.options.bar.showCountry ? l : ""),
            r.options.bar.visible && r.countrylist.autocomplete.footer.show(),
            r.options.manualEntryItem && r.addManualEntryItem(),
            r.options.setCountryByIP && r.setCountryByIP(),
            i.name && (o = i.list.name,
            s = i.countries.list.name,
            n.setAttributes(y, {
                id: o + "_label"
            }),
            n.setAttributes(f, {
                id: o + "_button",
                role: "button",
                "aria-labelledby": o + "_label"
            }),
            n.setAttributes(l, {
                id: s + "_label"
            }),
            n.setAttributes(e, {
                id: s + "_button",
                role: "button",
                "aria-labelledby": s + "_label"
            })),
            r.messageBox = n.create("div", {
                className: "pcatext pcanotification"
            }),
            n.append(r.messageBox, n.container),
            r.loaded = !0,
            r.fire("load")
        }
        ,
        r.searchFromField = function() {
            var t = n.getValue(r.autocomplete.field);
            t && !r.autocomplete.disabled && !r.initialSearch && t.length >= r.options.minSearch && (r.initialSearch = !0,
            r.search(t))
        }
        ,
        r.search = function(t) {
            function o(t, i) {
                t.length ? r.display(t, n.templates.AUTOCOMPLETE, i) : f()
            }
            var e, i, u;
            return r.searchContext && (~t.indexOf(r.searchContext.search) ? t = t.replace(r.searchContext.search, r.searchContext.text) : r.searchContext = null),
            e = r.searchContext ? r.searchContext.id || "" : "",
            i = {
                searchTerm: t,
                lastId: e,
                options: r.options.search
            },
            r.fire("search", i),
            i.searchTerm && (u = {
                Key: r.key,
                Country: r.country,
                SearchTerm: i.searchTerm,
                LanguagePreference: r.language,
                LastId: i.lastId,
                SearchFor: r.filteringMode,
                OrderBy: r.orderingMode,
                $block: !0,
                $cache: !0
            },
            i.options.maxSuggestions && (u.MaxSuggestions = i.options.maxSuggestions),
            i.options.maxResults && (u.MaxResults = i.options.maxResults),
            n.fetch(r.options.provider + "/Interactive/Find/v2.10", u, o, r.error)),
            r
        }
        ,
        r.searchByLocation = function(t, i) {
            function s(n) {
                n && n.coords ? (t = n.coords.latitude,
                i = n.coords.longitude,
                o = n.coords.accuracy,
                r.geolocation = n.coords,
                r.fire("location", r.geolocation),
                e()) : u()
            }
            function u() {
                r.message(n.messages[r.language].NOLOCATION, !0),
                r.fire("location")
            }
            function e() {
                function u(t, i) {
                    t.length ? r.display(t, n.templates.AUTOCOMPLETE, i) : f()
                }
                n.fetch(r.options.provider + "/Interactive/FindByPosition/v2.00", {
                    Key: r.key,
                    Latitude: t,
                    Longitude: i,
                    LocationAccuracy: o,
                    LanguagePreference: r.language,
                    $block: !0,
                    $cache: !0
                }, u, r.error)
            }
            var o = 0;
            return (t || t == 0) && (i || i == 0) ? e() : navigator.geolocation ? navigator.geolocation.getCurrentPosition(s, u, {
                enableHighAccuracy: !0
            }) : u(),
            r
        }
        ,
        r.retrieve = function(t) {
            function e(n) {
                n.length ? r.populate(n) : u(n)
            }
            function u(t) {
                r.message(n.messages[r.language].RETRIEVEERROR, {
                    clickToDisable: r.options.manualEntry,
                    error: !0,
                    clearList: !0
                }),
                r.error(t)
            }
            for (var f = {
                Key: r.key,
                Id: t,
                Source: r.options.source,
                $cache: !0
            }, i = 0; i < r.advancedFields.length; i++)
                f["field" + (i + 1) + "format"] = r.advancedFields[i];
            n.fetch(r.options.provider + "/Interactive/RetrieveFormatted/v2.10", f, e, u)
        }
        ,
        r.error = function(t) {
            if (r.fire("error", t),
            n.clearBlockingRequests(),
            !r.listeners.error)
                if (typeof console != "undefined" && typeof console.error != "undefined")
                    console.error(n.messages[r.language].SERVICEERROR + " " + t);
                else
                    throw n.messages[r.language].SERVICEERROR + " " + t;
        }
        ,
        r.display = function(t, i, f) {
            if (r.autocomplete.header.hide(),
            r.highlight(t),
            r.fire("results", t, f),
            r.autocomplete.clear().add(t, i, r.select).show(),
            r.showFooterLogo(),
            r.autocomplete.list.collection.all(function(t) {
                t.data && t.data.Next && t.data.Next == "Find" && n.addClass(t.element, "pcaexpandable")
            }),
            r.options.preselect && r.autocomplete.list.first(),
            r.options.prompt) {
                function o() {
                    r.message(n.messages[r.language].KEEPTYPING)
                }
                u(),
                r.options.promptDelay ? r.lastActionTimer = window.setTimeout(o, r.options.promptDelay) : o()
            }
            return r.options.matchCount && f && f.ContainerCount && e(f.ContainerCount),
            r.fire("display", t, f),
            r
        }
        ,
        r.message = function(t, i) {
            if (i = i || {},
            i.notificationTimeout = i.notificationTimeout || 3e3,
            i.inline = i.inline || r.options.inlineMessages,
            u(),
            i.inline)
                r.autocomplete.show(),
                i.clickToDisable ? r.autocomplete.header.clear().setContent(n.create("div", {
                    className: "pcamessage",
                    innerHTML: t,
                    onclick: r.manualEntry
                }, "cursor:pointer;")).show() : r.autocomplete.header.clear().setText(t).show(),
                r.reposition();
            else {
                r.messageBox.innerHTML = t,
                n.addClass(r.messageBox, "pcavisible"),
                n.removeClass(r.messageBox, "pcaerror"),
                i.error && n.addClass(r.messageBox, "pcaerror"),
                r.notifcationTimer && window.clearTimeout(r.notifcationTimer),
                n.removeClass(r.messageBox, "pcafade"),
                r.notifcationTimer = window.setTimeout(function() {
                    n.addClass(r.messageBox, "pcafade"),
                    window.setTimeout(function() {
                        n.removeClass(r.messageBox, "pcavisible")
                    }, 500)
                }, i.notificationTimeout);
                var f = n.getPosition(r.autocomplete.field)
                  , o = n.getSize(r.autocomplete.field)
                  , e = n.getSize(r.messageBox);
                r.messageBox.style.top = (r.autocomplete.upwards ? f.top + o.height + 8 : f.top - e.height - 8) + "px",
                r.messageBox.style.left = f.left + "px"
            }
            return i.clearList && r.autocomplete.clear().list.hide(),
            r
        }
        ,
        r.setCursorText = function(n, t) {
            return r.autocomplete.prompt(n, t),
            r
        }
        ,
        r.select = function(t) {
            function i() {
                var i = n.getValue(r.autocomplete.field);
                r.options.setCursor ? (i = n.removeHtml(t.Text).replace("...", ""),
                r.setCursorText(i, t.Cursor >= 0 ? t.Cursor : null)) : (n.setValue(r.autocomplete.field, i + " "),
                r.autocomplete.field.focus()),
                r.searchContext = {
                    id: t.Id,
                    text: t.Text,
                    search: i
                },
                r.search(i)
            }
            return r.fire("select", t),
            t.Next == "Retrieve" ? r.retrieve(t.Id) : i(),
            r
        }
        ,
        r.highlight = function(n, t, i) {
            var e, r, o, f, u;
            for (t = t || "<b>",
            i = i || "</b>",
            e = 0; e < n.length; e++)
                if (r = n[e],
                r.HighlightedText = r.title = r.tag = r.Text,
                r.Highlight)
                    for (o = r.Highlight.split(","),
                    f = o.length - 1; f >= 0; f--)
                        u = o[f].split("-"),
                        r.HighlightedText = r.HighlightedText.substring(0, parseInt(u[0])) + t + r.HighlightedText.substring(parseInt(u[0]), parseInt(u[1])) + i + r.HighlightedText.substring(parseInt(u[1]), r.HighlightedText.length)
        }
        ,
        r.populate = function(t) {
            var u = t[0], h, f, a, o, v, l, e, s, i, c, y;
            if (r.options.languagePreference)
                for (h = 0; h < t.length; h++)
                    if (t[h].Language == r.options.languagePreference.toUpperCase()) {
                        u = t[h];
                        break
                    }
            for (r.options.countries.prepopulate && r.countrylist.populate(),
            f = {
                Line1: null,
                Line2: null,
                Line3: null,
                Line4: null,
                Line5: null,
                Street: null,
                Building: null,
                Company: null
            },
            a = 0,
            o = 0; o < r.fields.length; o++)
                for (v in f)
                    ~r.fields[o].field.indexOf(v) && (f[v] = r.fields[o]);
            for (l = 1; l <= 5; l++)
                f["Line" + l] && a++;
            for (f.Building && f.Street && a++,
            e = 1; e <= 5; e++)
                u["FormattedLine" + e] = r.getAddressLine(u, e, a, !f.Company);
            for (r.fire("prepopulate", u, t),
            s = 0; s < r.fields.length && r.options.populate; s++)
                (i = r.fields[s],
                i.mode & n.fieldMode.POPULATE) && (!r.options.onlyInputs || n.inputField(i.element) || n.selectList(i.element) || n.checkBox(i.element)) && (i.mode & n.fieldMode.PRESERVE && n.getValue(i.element) && r.autocomplete.field != n.getElement(i.element) || (c = r.fields[s].field.replace(/(Formatted)?Line/g, "FormattedLine"),
                y = /[\{\}]/.test(c) || c == "" ? n.formatLine(u, c) : u[c],
                n.setValue(i.element, y)));
            return r.hide(),
            r.newSearch(),
            r.fire("populate", u, t),
            r
        }
        ,
        r.getAddressLine = function(n, t, i, r) {
            var u, e = "", o, s, f;
            if (r = r && !!n.Company,
            r) {
                if (t == 1 && i > 1)
                    return n.Company;
                t == 1 && i == 1 ? e = n.Company : (t--,
                i--)
            }
            for (u = n.Line1 ? n.Line2 ? n.Line3 ? n.Line4 ? n.Line5 ? 5 : 4 : 3 : 2 : 1 : 0,
            o = i >= u ? t : Math.floor(1 + (u / i + (i - (t - 1)) / i) * (t - 1)),
            s = Math.floor(u / i + (i - t) / i),
            f = 0; f < s; f++)
                e += (e ? ", " : "") + (n["Line" + (f + o)] || "");
            return e
        }
        ,
        r.switchToCountrySelect = function() {
            r.countrylist.autocomplete.position(r.autocomplete.field),
            r.countrylist.autocomplete.field = r.autocomplete.field,
            r.countrylist.autocomplete.focused = !0,
            r.countrylist.autocomplete.enable().showAll(),
            r.countrylist.autocomplete.list.first(),
            r.autocomplete.disable().hide(),
            r.storedSearch = n.getValue(r.autocomplete.field),
            n.clear(r.autocomplete.field),
            r.autocomplete.field.focus()
        }
        ,
        r.switchToSearchMode = function() {
            var n = r.storedSearch != null;
            r.countrylist.autocomplete.hide(),
            r.autocomplete.enable(),
            n && (r.newSearch(),
            r.autocomplete.field.focus(),
            r.searchFromField())
        }
        ,
        r.setCountry = function(n) {
            return r.countrylist.setCountry(n),
            r
        }
        ,
        r.setCountryByIP = function() {
            return r.countrylist.setCountryByIP(r.key),
            r
        }
        ,
        r.detectLanguage = function() {
            var t = r.options.culture || (window && window.navigator ? window.navigator.language || window.navigator.browserLanguage : "") || "";
            r.language = t && t.length > 1 ? t.substring(0, 2).toLowerCase() : "en",
            n.messages[r.language] || (r.language = "en")
        }
        ,
        r.setCulture = function(n) {
            r.options.culture = n,
            r.reload()
        }
        ,
        r.setWidth = function(n) {
            r.autocomplete.setWidth(n),
            r.countrylist.autocomplete.setWidth(n)
        }
        ,
        r.setHeight = function(n) {
            r.autocomplete.setHeight(n),
            r.countrylist.autocomplete.setHeight(n)
        }
        ,
        r.clear = function() {
            for (var t = 0; t < r.fields.length; t++)
                n.setValue(r.fields[t].element, "");
            return r.fire("clear"),
            r
        }
        ,
        r.reset = function() {
            return r.options.bar.visible ? (r.autocomplete.list.clear().hide(),
            r.autocomplete.header.hide(),
            r.showFooterMessage(),
            r.autocomplete.reposition()) : (r.autocomplete.hide(),
            r.autocomplete.footer.hide()),
            u(),
            r.newSearch(),
            r
        }
        ,
        r.newSearch = function() {
            r.initialSearch = !1,
            r.searchContext = null
        }
        ,
        r.focus = function() {
            r.reset(),
            r.options.autoSearch && r.searchFromField(),
            r.fire("focus")
        }
        ,
        r.blur = function() {
            u(),
            r.countrylist.autocomplete.field = null,
            r.countrylist.autocomplete.focused = !1,
            r.countrylist.autocomplete.checkHide(),
            r.fire("blur")
        }
        ,
        r.hide = function() {
            u(),
            r.autocomplete.hide(),
            r.countrylist.autocomplete.hide(),
            r.fire("hide")
        }
        ,
        r.visible = function() {
            return r.autocomplete.visible || r.countrylist.autocomplete.visible
        }
        ,
        r.reposition = function() {
            r.autocomplete.reposition(),
            r.countrylist.autocomplete.reposition()
        }
        ,
        r.disable = function() {
            return r.autocomplete.disabled = !0,
            r.countrylist.autocomplete.disabled = !0,
            r
        }
        ,
        r.enable = function() {
            return r.autocomplete.disabled = !1,
            r.countrylist.autocomplete.disabled = !1,
            r
        }
        ,
        r.destroy = function() {
            return r.autocomplete && r.autocomplete.destroy(),
            r.countrylist && r.countrylist.autocomplete.destroy(),
            r
        }
        ,
        r.reload = function() {
            r.destroy(),
            r.load()
        }
        ,
        r.manualEntry = function() {
            return window && window.setTimeout && r.options.disableTime && (r.autocomplete.field.focus(),
            r.destroy(),
            window.setTimeout(r.load, r.options.disableTime),
            r.fire("manual")),
            r
        }
        ,
        r.addManualEntryItem = function(t) {
            t = t || n.messages[r.language].MANUALENTRY,
            r.autocomplete.list.setFooterItem({
                text: t
            }, "<u>{text}</u>", r.manualEntry)
        }
        ,
        r.bound = function(i) {
            if (i = n.getElement(i))
                for (var u = 0; u < r.fields.length; u++)
                    if (i == n.getElement(t[u].element))
                        return !0;
            return !1
        }
        ,
        r.checkFormat = function(t) {
            function i(t) {
                for (var i in n.exampleAddress)
                    if (i == t)
                        return !0;
                return !1
            }
            return t.replace(/\{(\w+)([^\}\w])?\}/g, function(n, t) {
                return i(t) ? n : (r.advancedFields.push(n),
                "{Field" + r.advancedFields.length + "}")
            })
        }
        ,
        n.ready(r.load)
    }
}
)();
(function() {
    pca.CapturePlus = function() {
        function i(t) {
            return t = t || {},
            t.countries = t.countries || {},
            t.CapturePlusCountryByIP && (t.setCountryByIP = !!t.CapturePlusCountryByIP),
            t.CapturePlusDefaultLanguage && (t.culture = t.CapturePlusDefaultLanguage),
            t.CapturePlusDefaultCountry && (t.countries.defaultCode = t.CapturePlusDefaultCountry),
            t.CapturePlusCountryList && (t.countries.codesList = t.CapturePlusCountryList),
            t.setCursor = !0,
            t.prompt = !0,
            t.promptDelay = 0,
            n.fire("options", t),
            t
        }
        function t(t) {
            function i() {
                typeof CapturePlusLoaded != "undefined" && CapturePlusLoaded(t)
            }
            t.listen("load", i),
            t.loaded && i(),
            t.listen("populate", function(i, r) {
                typeof CapturePlusCallback != "undefined" && CapturePlusCallback(t, i, r),
                n.fire("populate", t, i, r)
            }),
            t.listen("error", function(i) {
                typeof CapturePlusError != "undefined" && CapturePlusError(t, i),
                n.fire("error", t, i)
            })
        }
        var n = new pca.Eventable(this);
        n.controls = [],
        n.bindings = [],
        n.load = function() {
            var r, u;
            for (n.destroy(),
            r = 0; r < n.bindings.length; r++)
                u = new pca.Address(n.bindings[r].fields,i(n.bindings[r].options)),
                t(u),
                n.controls.push(u),
                n.fire("load", u);
            n.fire("ready")
        }
        ,
        n.all = function(t) {
            for (var i = 0; i < n.controls.length; i++)
                t(n.controls[i])
        }
        ,
        n.setCulture = function(t) {
            n.all(function(n) {
                n.setCulture(t)
            })
        }
        ,
        n.setCountry = function(t) {
            n.all(function(n) {
                n.setCountry(t)
            })
        }
        ,
        n.setWidth = function(t) {
            n.all(function(n) {
                n.setWidth(t)
            })
        }
        ,
        n.setHeight = function(t) {
            n.all(function(n) {
                n.setHeight(t)
            })
        }
        ,
        n.enable = function() {
            n.all(function(n) {
                n.enable()
            })
        }
        ,
        n.disable = function() {
            n.all(function(n) {
                n.disable()
            })
        }
        ,
        n.destroy = function() {
            n.all(function(n) {
                n.destroy()
            }),
            n.controls = []
        }
        ,
        pca.ready(n.load)
    }
    ,
    window.capturePlus = pca.capturePlus = new pca.CapturePlus
}
)()
