'use strict';

var cssConstants = require('_core_ext/cssconstants'),
    JSConstants = require('_core_ext/constants');

var initialized = false,
    hideCookieBanner = $.cookie("hideCookieBanner");

function initializeEvents() {

    if (!hideCookieBanner) {
        $(JSConstants.COOKIE_POLICY).removeClass(cssConstants.HIDDEN);
    }

    $(JSConstants.COOKIE_POLICY_CLOSE).on('click', function(e) {
        e.preventDefault();
        $.cookie('hideCookieBanner', 'yes', { expires: 1, path: '/' });
        $(JSConstants.COOKIE_POLICY).addClass(cssConstants.HIDDEN);
    });

}

module.exports = {
    'init' : function () {
        if (initialized) {
            return;
        }
        initializeEvents();
        initialized = true;
    }
};