module.exports = {
    CLASS_RESERVE_SELECT_STORE: '.js-reserve-select_store',
    CLASS_GO_BACK_TO_SEARCH: '.js-go-back-to-search',
    CLASS_SELECT_STORE: '.js-select-store',
    CLASS_SELECT_DEFAULT_STORE: '.js-select-default-store',
    CLASS_DEFAULT_STORE_CONTAINER: '.js-tile-container',
    CLASS_OPEN_LOCATION: 'js-open-location',
    CLASS_STORE_DETAILS_BUTTON: '.js-store-details-button',
    CLASS_SEARCH_GOOGLE_ADDRESS: '.js-search-google-address',
    CLASS_STORE_DETAILS: '.js-store-details',
    CLASS_STORES_SELECT: '.js-stores-select',
    CLASS_PDP_RESERVE_DELIVERY_CONTAINER: '.js-pdp-reserve-delivery-container',
    CLASS_WRAPPER_SELECT_DIALOG: '.wrapper_stores-select-dialog',
    CLASS_WRAPPER_STORE_DIALOG: '.wrapper_store-dialog',
    CLASS_STORE_LIST_CONTAINER: '.js-store-list-container',
    CLASS_STORE_LIST: '.js-store-list',
    CLASS_STORE_NEAREST_DIALOG_CONTAINER: '.js-store-nearest-dialog-container',
    CLASS_STORE_SELECT: '.js-store-select',
    CLASS_CHOOSE_ANOTHER_STORE: '.js-choose-another-store',
    CLASS_JS_STORE: '.js-store',
    CLASS_SEARCH_BY_CURRENT_LOCATION: '.js-search-by-current-location',
    CLASS_RESERVE_COLLECT_ADDRESS_INPUT: '.js-reserve-collect-address-input',
    CLASS_STORES_SELECT_BTN: '.js-stores-select-search-btn',
    CLASS_QTY_CURRENT: '.js_qty-current',
    CLASS_NEAREST_STORE: '.js-nearest-store',
    CLASS_STORE_DISTANCE: '.js-store-distance',
    CLASS_UI_DIALOG: '.ui-dialog-content',
    CLASS_JS_GO_BACK: '.js-go-back',
    CLASS_JS_SLIDE_DOWN: '.js-popup-slide-down',
    CLASS_QUANTITY_STORE_INPUT: '.js-quantity-store-select',
    CLASS_QUANTITY_ITEM_ERROR: '.js-quantity-item-error',
    CLASS_QUANTITY_LIMIT: ' .js-quantity-limit',
    CLASS_STORE_LIMIT: '.js-store-limit',
    CLASS_PRODUCT_LIMIT: '.js-product-maximumt',

    CLASS_QUANTITY_DECR: '.js-store-decr',
    CLASS_QUANTITY_INC: '.js-store-inc',
    CLASS_QUANTITY_STORE_CONTAINER: '.js-quantity-store-container',
    CLASS_ADD_TO_CART: '.js-addtocart',
    CLASS_PDP_FROM: '.js-pdp-form',
    CLASS_QTY_PDP :'.js_qty-current',
    SELECTOR_PDP_INPUT_STORE_ID: 'input#storeID',

    ID_STORES_SELECT_DIALOG: '#stores_select_dialog',
    ID_STORE_DIALOG: '#store_dialog',
    ID_STORE_SEARCH_MAP_CONTAINER: '#js-store-search-map-container',
    ID_STORE_DETAILS_MAP_CONTAINER: '#js-store-details-map-container',
    KEY_SCROLL_LOCAL_STORAGE: 'scrollpos',

    STR_CLASS_ITEM_NOT_AVAILIABLE: 'item-not-available',

};
