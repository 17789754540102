require('./lib/postcodeanywhere/js/captureplus-2.30.min.js');

var options = {
    'fieldsPrefix': ''
};

var pcaAddress;
var $searchAddress = $('input[name*="_search"]');

var searchFields = [{
        fieldName: '_search',
        name: 'Label'
    }
];

var populatedFields = [{
        fieldName: '_address1',
        name: 'Line1'
    }, {
        fieldName: '_address2',
        name: 'Line2'
    }, {
        fieldName: '_city',
        name: 'City'
    }, {
        fieldName: '_county',
        name: 'Province'
    }, {
        fieldName: '_postal',
        name: 'PostalCode'
    },
];

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function setMaxWidth (element, elementWidth) {
    if (element) {
        element.style.maxWidth = elementWidth.width() + "px";
    }
}

function hideElementWhenScroll(element) {
    document.querySelector(element).style.display = "none";
}

function initPCAPredict(isEditAddress) {
    var fields = [],
        pca = window.pca,
        localesMapping = SitePreferences.LOCALE_CODE_MAPPING,
        countryCodeMapping = SitePreferences.PCA_LOCALES;

    for (var i = 0; i < searchFields.length; i++) {
        fields.push({
            element: options.fieldsPrefix + searchFields[i].fieldName,
            field: searchFields[i].name,
            mode: pca.fieldMode.SEARCH
        });
    }

    for (var j = 0; j < populatedFields.length; j++) {
        fields.push({
            element: options.fieldsPrefix + populatedFields[j].fieldName,
            field: populatedFields[j].name,
            mode: pca.fieldMode.POPULATE
        });
    }
    pcaAddress = new pca.Address(fields, {
        key: SitePreferences.PCA_KEY,
        setCountryByIP: false,
        bar: {
           visible: false
        }
    });
    pcaAddress.listen('show', function() {
        var country = $("[name*='_country']").val();

        var locObj = localesMapping.find(function (obj) {
            return obj.label === country;
        });

        if (locObj) {
            country = locObj.countryCode;
        }

        var pcaObj = countryCodeMapping.find(function (obj) {
            return obj.contryCode === country;
        });

        var isoCountry = pcaObj ? pcaObj.PCAContryCode : '';
        pcaAddress.setCountry(isoCountry);
    });
    pcaAddress.listen('populate', function(response) {
        $("[name*='_search']").val('');
        revalidatePopulatedFields(response);
    });
    pcaAddress.listen('load', function() {
        changeAutocompleteAttr();
        setMaxWidth($('.pcaautocomplete.pcatext')[0], $('.input-text'));
    });
    $searchAddress.on('change', function() {
       $(this).trigger('keyup');
    });

    // change autocomplete attribute if 'addressbookform' was opened
    if (isEditAddress) {
        changeAutocompleteAttr();
        setMaxWidth($('.pcaautocomplete.pcatext')[0], $('.input-text'));
        $('.ui-front.address-popup').on('scroll', function() {
            hideElementWhenScroll('.pcaautocomplete.pcatext');
        });
    }
}

function changeAutocompleteAttr() {
    searchFields.forEach(function(item) {
        $('[name="' + options.fieldsPrefix + item.fieldName + '"]').attr('autocomplete', 'nope');
    });
}

function normalizePopulatedField(fieldName, fieldNamePrefix, response) {
    if (fieldNamePrefix === '_county') {
        var $input = $('input[name="' + fieldName + '"]');
        if ($input.length > 0) {
            $input.val(($input.val() || '').replace('County ', ''));
        } else {
            $('select[name="' + fieldName + '"]').val(response.Province.replace('County ', ''));
        }
    }
}

function revalidatePopulatedFields(response) {
    $('input[name="' + options.fieldsPrefix + '_address1"]').trigger('change');
    for (var i = 0; i < populatedFields.length; i++) {
        var fieldName = options.fieldsPrefix + populatedFields[i].fieldName;
        normalizePopulatedField(fieldName, populatedFields[i].fieldName, response);
        var $input = $('[name="' + fieldName + '"]');
        if ($input.length > 0) {
            $(document).trigger('pca.fieldchanged', $input);
            $input.valid();
        }
    }
}

var postcodeanywhere = {
    init: function(params, isEditAddress) {
        if (!SitePreferences.PCA_ENABLED) {
            return;
        }
        initializeParams(params);
        initPCAPredict(isEditAddress);
    }
};

module.exports = postcodeanywhere;