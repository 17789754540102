var dialog = require("_core_ext/dialog");
var minicart = require("../cart/minicart");
var progress = require('_core_ext/progress');

function initReOrder() {
    var $reOrderBtn = $(".js-reorder_btn");
    var url = $reOrderBtn.attr('href');
    progress.show();

    $.ajax({
        type: "GET",
        url: url,
    })
        .done(function (response) {
            dialog.open({
                html: response,
                options: {
                    dialogClass: "no-close re-order-modal",
                },
                callback: function () {
                    $(".js-close-dialog").on("click", function (e) {
                        dialog.close();
                    });
                    minicart.update(true, true, true);
                    progress.hide();
                },
            });
        })
        .fail(function (response) {
            progress.hide();
        });
}

var index = {
    init: function () {
        $(".js-reorder_btn").on("click", function (e) {
            e.preventDefault();
            initReOrder();
        });
    },
};

module.exports = index;
