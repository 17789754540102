'use strict';

var util = require('_core_ext/util'),
    JSConstants = require('_core_ext/pages/account/constants'),
    layout = require('_core_ext/layout');

var isAJAXinProgress = false;
var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var myaccount = {
    init: function () {
        this.$el = $(JSConstants.CLASS_MY_ACCOUNT);
        this.$content = this.$el.find(JSConstants.CLASS_MY_ACCOUNT_CONTENT);

        if (layout.isDesktop() && this.$el.length && this.$content.length) {
            this.$el.find(JSConstants.CLASS_MY_ACCOUNT_ICON)
                .on('mouseenter', function () {
                    $(JSConstants.CLASS_MINI_CART_CONTENT).hide();
                    if (this.$content.not(':visible')) {
                        this.slide();
                    }
                }.bind(this))
                .on('mouseleave', function () {
                    if (this.$content.is(':visible')) {
                        timer.start(1500, this.close.bind(this));
                    }
                }.bind(this));

            this.$content
                .on('mouseenter', function () {
                    timer.clear();
                })
                .on('mouseleave', function () {
                    timer.start(30, this.close.bind(this));
                }.bind(this));
        }
    },

    show: function (html) {
        if (layout.isDesktop()) {
            this.$el.html(html);
            util.scrollBrowser(0);
            this.init();
            this.slide();
        } else {
            this.$el.html(html);
            util.scrollBrowser(0);
        }
    },

    update: function (showOnLoad) {
        var that = this;
        isAJAXinProgress = true;

        var $request = $.ajax({
            'url' : Urls.myaccount
        }).done(function (response) {
            isAJAXinProgress = false;
            if (response) {
                $(JSConstants.CLASS_MY_ACCOUNT_CONTENT).html(response);
            }
            that.init();
        });
        !!showOnLoad && $request.done();
    },

    /**
     * @function
     * @description Slides down and show the contents of the myaccount
     */
    slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
    },

    /**
     * @function
     * @description Closes the myaccount with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        timer.clear();
        this.$content.slideUp(delay);
    }
};

module.exports = myaccount;