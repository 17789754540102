/**
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
 * Events description https://developers.google.com/analytics/devguides/collection/analyticsjs/events
 */

 'use strict';

 var _pageContext, analyticsData, currentPage,
     constants = require('_core_ext/pages/checkout/constants'),
     _ = require('lodash'),
     util = require('_core_ext/util');

 //Config of functions names, depending on current page
 //Executed on page load
 var pageTagsConfig = {
     'global': ['productImpression', 'promoInpressions'],
     'product': ['productDetailsView'],
     'orderconfirmation': ['purchase', 'confirmationType'],
     'shipping': ['productsInCart'],
     'billing': ['productsInCart'],
     'cart': ['removeFromCart'],
     'account': ['createAccount'],
     'tradecard': ['createAccount']
 };

 //Config of functions names, depending on current page
 //Executed on user interaction
 var pageEventsConfig = {
     'global': ['productClicks', 'promoClicks', 'productImpression', 'chooseStore', 'headerNavigation'],
     'product': ['addToCart', 'pdpStoreSelection'],
     'checkoutlogin': ['checkoutLoginSubmit'],
     'cart': ['removeFromCart', 'cartSubmit', 'cartSubmitSelectorCollect', 'CcSelect', 'reAddToBasket', 'saveForLater', 'viewNearestStore'],
     'shipping': ['shippingSubmit'],
     'billing': ['billingSubmit']
 };

 //Holder for events functions
 var eventsLibrary = {
    'headerNavigation': function () {
        $(document).on('click', '.js-ga-login', function () {
            ga('send', 'event', { eventCategory: 'TopNav', eventAction: 'Click Sign-in', eventLabel: 'Click Sign-in', eventValue: 0});
        });
        $(document).on('click', '.js-ga-mini-cart', function () {
            ga('send', 'event', { eventCategory: 'TopNav', eventAction: 'Click Basket', eventLabel: 'Click Basket', eventValue: 0});
        });
        $('.js-header_nav').on('scroll', _.debounce(function () {

            function isVisible(elem) {
                let coordinates = elem.getBoundingClientRect();
                let windowWidth = document.documentElement.clientWidth;
                // Is the left edge of the element visible?
                let leftVisible = coordinates.left > 0 && coordinates.left < windowWidth;
                // Is the right edge of the element visible?
                let rightVisible = coordinates.right < windowWidth && coordinates.right > 0;
                return leftVisible || rightVisible;
            }

            var visibleCategories = [];
            for (let category of document.querySelectorAll('.js-level1_item')) {
                let categoryId = category.dataset.id;
                if (!categoryId) continue;
                if (isVisible(category)) {
                    visibleCategories.push(categoryId);
                }
            }

            ga('send', 'event', { eventCategory: 'Carousel', eventAction: 'swipe', eventLabel: visibleCategories.join(', '), eventValue: 0});
        }, 300));
    },
     'pdpStoreSelection': function () {
         $(document).on('click', '.js-pdp-search-by-current-location', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Use My Location - Store Finder PDP', eventLabel: 'Use my Location', eventValue: 0});
         });
         $(document).on('click', '.js-ga-change-store', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Change Store - Store Finder PDP', eventLabel: 'Change Store', eventValue: 0});
         });
         $(document).on('click', '.js-ga-check-store', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Check Stock - Store Finder PDP', eventLabel: 'Check Stock', eventValue: 0});
         });
         $(document).on('click', '.js-ga-add-to-wish-list', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Add to Shopping List - Store Finder PDP', eventLabel: 'Add to Shopping List', eventValue: 0});
         });
     },
     'chooseStore': function () {
         $(document).on('click', '.js-ga-select-store-tile', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Click and Collect from here - Store List', eventLabel: 'Choose this Store', eventValue: 0});
         });
         $(document).on('click', '.js-ga-select-store-info', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Choose this Store - Store Detail', eventLabel: 'Choose this Store', eventValue: 0});
         });
     },
     'CcSelect': function() {
         $('#delivery-type-selector-collect').on('click', function() {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Select CandC - Basket and Checkout', eventLabel: 'Select CandC', eventValue: 0});
         });
     },
     'reAddToBasket': function() {
         $('.js-ga-re-add-to-basket').on('click', function() {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Move to Basket - Basket and Checkout', eventLabel: 'Move to Basket', eventValue: 0});
         });
     },
     'saveForLater': function() {
         $('.js-ga-save-for-later').on('click', function() {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Save for Later - Basket and Checkout', eventLabel: 'Save for Later', eventValue: 0});
         });
     },
     'viewNearestStore': function() {
         $('.js-ga-nearest-store').on('click', function() {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'View Store - Basket and Checkout', eventLabel: 'View Store ', eventValue: 0});
         });
     },
     'cartSubmit': function() {
         $('.js_cart_submit_form').on('submit', function() {
             var option = $(constants.CLASS_SHIPPING_METHOD_LIST).find('.input-radio:checked').data('method-name');

             ga('ec:setAction', 'checkout_option', {
                 'step': 1,
                 'option': option
             });

             ga('send', 'event', 'Checkout', 'Option');
         });
     },
     'checkoutLoginSubmit': function() {
         $('.js_checkout_login .login-box_form').on('submit', function() {
             ga('ec:setAction', 'checkout_option', {
                 'step': 2,
                 'option': 'Checkout Login'
             });

             ga('send', 'event', 'Checkout', 'Option');
         });
     },
     'shippingSubmit': function() {
         $('.checkout-shipping').on('submit', function() {
             ga('ec:setAction', 'checkout_option', {
                 'step': 3,
                 'option': $(constants.CLASS_SHIPPING_METHOD_LIST).find('.input-radio:checked').data('method-name')
             });

             ga('send', 'event', 'Checkout', 'Option');
         });
     },
     'billingSubmit': function() {
         $('.checkout-billing').on('submit', function() {
             ga('ec:setAction', 'checkout_option', {
                 'step': 4,
                 'option': $(constants.CLASS_PAYMENT_METHODS).find('.input-radio:checked').val()
             });

             ga('send', 'event', 'Checkout', 'Option');
         });
     },
     'cartSubmitSelectorCollect': function () {
         $(document).on('click', '.js-cart-action-submit', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Checkout with Click & Collect - Basket and Checkout', eventLabel: 'Checkout with Click & Collect ', eventValue: 0});
         });
         $(document).on('click', '.js-ga-checkout-delivery', function () {
             ga('send', 'event', { eventCategory: 'ClickandCollect', eventAction: 'Checkout with Home Delivery - Basket and Checkout', eventLabel: 'Checkout with Home Delivery ', eventValue: 0});
         });
     },
     'removeFromCart': function() {
         $(document).on('product.removeFromCart', function(event, $form) {
             var productData = $form.data('productDetails');

             if (productData) {
                 productData.quantity = $form.find('#Quantity').val();
                 sendRemoveFromCartEvent(productData);
             }
         });

         $(document).on('click', '.js-remove-from-cart', function() {
             var $cartItemWrapper = $(this).closest('.js-product-impression');
             var productData = $cartItemWrapper.data('productDetails');

             if (productData) {
                 productData.quantity = $cartItemWrapper.find('.item-quantity input').val();
                 sendRemoveFromCartEvent(productData);
             }
         });

         function sendRemoveFromCartEvent(productData) {
             ga('ec:addProduct', {
                 'id': productData.id,
                 'name': productData.name,
                 'category': productData.category,
                 'brand': productData.brand,
                 'variant': productData.variant,
                 'price': productData.price === SitePreferences.PRICE_FREE ? '0.00' : productData.price,
                 'quantity': productData.quantity
             });

             ga('ec:setAction', 'remove');
             ga('send', 'event', 'UX', 'click', 'remove from cart');
         }
     },
     'promoClicks': function() {
         $('.js-promo-impression').on('click', function() {
             var $promo = $(this);
             var promoDetails = $promo.data('promoDetails');

             if (promoDetails) {
                 ga('ec:addPromo', {
                     'id': promoDetails.id,
                     'name': promoDetails.name,
                     'creative': promoDetails.creative,
                     'position': promoDetails.position
                 });

                 ga('ec:setAction', 'promo_click');
                 ga('send', 'event', 'Internal Promotions', 'click', promoDetails.name);
             }
         });
     },
     'productClicks': function() {
         $('.js-product-impression').on('click', function() {
             var $product = $(this);
             var productData = $product.data('productDetails');

             if (productData && analyticsData) {
                 ga('ec:addProduct', {
                     'id': productData.id,
                     'name': productData.name,
                     'category': productData.category,
                     'brand': productData.brand,
                     'variant': productData.variant,
                     'position': productData.position || $product.data('position')
                 });

                 ga('ec:setAction', 'click', {list: productData.placement || currentPage});
                 ga('send', 'event', 'UX', 'click', currentPage);
             }
         });
     },

     'addToCart': function() {
         $(document).on('product.addedToCart', function(event, $form) {
             var productData = $form.data('productDetails');

             if (productData && analyticsData) {
                 ga('ec:addProduct', {
                     'id': productData.id,
                     'name': productData.name,
                     'category': productData.category,
                     'brand': productData.brand,
                     'variant': productData.variant,
                     'price': productData.price === SitePreferences.PRICE_FREE ? '0.00' : productData.price,
                     'quantity': $form.find('#Quantity').val() || 1
                 });

                 ga('ec:setAction', 'add');
                 ga('send', 'event', 'UX', 'click', 'add to cart');
             }
         });
     },

     'productImpression': function() {
         var timerId,
             queueSize = 0;

         function gatherImpression() {
             $('.js-product-impression').each(function(index) {
                 var $product = $(this);
                 var productData = $product.data('productDetails');

                 if (!productData || !util.isScrolledIntoView($product) || $product.data('viewed')) {
                     return;
                 }

                 var position = productData.position || (index + 1);

                 if (productData && analyticsData) {
                     ga('ec:addImpression', {
                         'id': productData.id,
                         'name': productData.name,
                         'category': productData.category,
                         'brand': productData.brand,
                         'variant': productData.variant,
                         'list': productData.placement || currentPage,
                         'position': position
                     });
                 }

                 $product.data('position', position);
                 $product.data('viewed', true);
                 queueSize++;
                 if (queueSize >= +SitePreferences.IMPRESSION_BATCH_SIZE) {
                     ga('send','event','UX','impressions','products views',{ nonInteraction: true });
                     queueSize = 0;
                 }
             });
         };

         $(window)
         .on('scroll', function() {
             if (timerId) {
                 clearTimeout(timerId);
             }
             timerId = setTimeout(gatherImpression, +SitePreferences.IMPRESSION_DEBOUNCE);
         })
         .unload(function() {
             if (queueSize > 0) {
                 ga('send','event','UX','impressions','products views',{ nonInteraction: true });
             }
         });
     }
 };

 /**
  * @description collection of functions which sends reporting
  *              specific for each page, page identifier is in _pageContext.currentPage
  */
 var tagsLibrary = {
     'productsInCart': function() {
         if (!analyticsData.basket) {
             return;
         }

         for (var i = 0; i < analyticsData.basket.ecommerce.length; i++) {
             var product = analyticsData.basket.ecommerce[i];
             ga('ec:addProduct', {
               'id': product.id,
               'name': product.name,
               'category': product.category,
               'brand': product.brand,
               'variant':  product.variant,
               'price': product.price === SitePreferences.PRICE_FREE ? '0.00' : product.price,
               'quantity': product.qty
             });
         }

         ga('ec:setAction','checkout', {'step': analyticsData.checkout ? analyticsData.checkout.step : 0});
     },
     'productDetailsView': function() {
         var productData = analyticsData.product;

         if (productData) {
             ga('ec:addProduct', {
                 'id': productData.id,
                 'name': productData.name,
                 'category': productData.category,
                 'brand': productData.brand,
                 'variant': productData.variant,
                 'position': productData.position || 1
             });

             ga('ec:setAction', 'detail');
         }
     },
     'promoImpression': function() {
         $('.js-promo-impression').each(function() {
             var $promo = $(this);
             var promoDetails = $promo.data('promoDetails');

             if (promoDetails) {
                 ga('ec:addPromo', {
                     'id': promoDetails.id,
                     'name': promoDetails.name,
                     'creative': promoDetails.creative,
                     'position': promoDetails.position
                 });
             }
         });
     },
     'productImpression': function() {
         $('.js-product-impression').each(function(index) {
             var $product = $(this);
             var productData = $product.data('productDetails');

             if (!productData || !util.isScrolledIntoView($product) || $product.data('viewed')) {
                 return;
             }

             $product.data('viewed', true);

             var position = productData.position || (index + 1);

             if (productData && analyticsData) {
                 ga('ec:addImpression', {
                     'id': productData.id,
                     'name': productData.name,
                     'category': productData.category,
                     'brand': productData.brand,
                     'variant': productData.variant,
                     'list': productData.placement || currentPage,
                     'position': position
                 });
             }

             $product.data('position', position);
         });
     },
     'confirmationType': function () {
             var queryString = window.location.search;
             var urlParams = new URLSearchParams(queryString);
             var salesOrderType = urlParams.get('sales-order-type');

             ga('send', 'event', 'confirmation', 'page_confirmation_loaded', salesOrderType);
     },
     'purchase' : function() {
         var orderData = analyticsData.purchase;

         if (!orderData) {
             return;
         }

         var orderDetails = orderData.actionField;

         ga('set', 'currencyCode', orderDetails.currencyCode);

         var products = orderData.products;
         if (products) {
             for (var i = 0; i < products.length; i++) {
                 var product = products[i];
                 ga('ec:addProduct', {
                     'name': product.name,
                     'id': product.sku,
                     'category': product.category,
                     'price': product.price === SitePreferences.PRICE_FREE ? '0.00' : product.price,
                     'quantity': product.quantity,
                     'variant': product.variant,
                     'brand': product.brand
                 });
             }
         }

         ga('ec:setAction', 'purchase', {
             'id': orderDetails.id,
             'affiliation': Resources.SITE_NAME,
             'revenue': orderDetails.revenue,
             'shipping': orderDetails.shipping,
             'tax': orderDetails.tax,
             'coupon': orderDetails.coupon
         });
     },
     'createAccount': function () {
         $('.js-register_btn').on('click', function() {
             ga('send', 'event', 'FormAbandonment', 'RegistrationForm', 'Clicked_create_account');
         });

         $('.js-submit-trade-form').on('click', function() {
             ga('send', 'event', 'FormAbandonment', 'RegistrationForm', 'Clicked_application_submission');
         });


 //         All Trade websites-> Tradecard application-> Create account button
 // Event category: FormAbandonment
 // Event Action: RegistrationForm
 // Event label: Clicked_application_submission

 // All Retail websites -> New account creation -> Create account button
 // Event category: FormAbandonment
 // Event Action: RegistrationForm
 // Event label: Clicked_create_account
     }
 };

 /**
  * @description pageContext is a global window object, usually it contains page specific data.
  *              In our case it should contain currentPage variable,
  *              which allows us to differentiate this page from other pages
  */
 function initPageContext() {
     _pageContext = window.pageContext || {};
     //analyticsData need to be collected on BackEnd, should contain page specific or/and global data
     analyticsData = _pageContext.analytics || {};
     currentPage = _pageContext.currentPage || _pageContext.ns || {};
 }

 /**
  * @description includes google analytics js script and registers account
  *
  * @return {Void}
  */
 function initGoogleAnalitycs() {
     (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
     })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

     ga('create', SitePreferences.GA_ACCOUNT_ID, 'auto');

     //adding ecommerce plugin
     ga('require', 'ecommerce');

     //adding enhanced ecommerce plugin
     ga('require', 'ec');
 }


 /**
  * @description initialize events depending on current page
  *
  * @return {Void}
  */
 function initEvents() {
     var eventsToInit = $.merge($.merge([], pageEventsConfig.global || []), pageEventsConfig[currentPage] || []);

     initFunctionsFromObject(eventsToInit, eventsLibrary);
 }

 /**
  * @description sends global data and page specific data, depending on 'currentPage' value
  *
  * @return {Void}
  */
 function sendTags() {
     var tagsToSend = $.merge($.merge([], pageTagsConfig.global || []), pageTagsConfig[currentPage] || []);

     initFunctionsFromObject(tagsToSend, tagsLibrary);

     ga('send', 'pageview');
 }

 /**
  * @description starts functions execution from list of provided functions
  * @param functionsList {Array} List of functions names
  * @param ObjectWithFunctions {Object} contains collection of named functions
  */
 function initFunctionsFromObject(functionsList, ObjectWithFunctions) {
     if (functionsList && ObjectWithFunctions) {
         var functionsListLength = functionsList.length,
             i = 0;

         for (; i < functionsListLength; i++) {
             var functionName = functionsList[i];
             if (functionName in ObjectWithFunctions) {
                 ObjectWithFunctions[functionName]();
             }
         }
     }
 }

 function initFormTracking() {
     var formFieldsList = $('fieldset').find('.input-text, .input-radio, .input-checkbox, .input-select');

     formFieldsList.on('blur', function() {
         if ($(this).val().length > 0) {
             var label = $('label[for=' + $(this).attr('id') + ']').find('.form-row_label_text'),
                 labelValue,
                 fieldPosition = formFieldsList.index($(this)) + 1;

             if (label) {
                 labelValue = label.html();
             }
             ga('send', 'event', 'FormAbandonement', $(this).closest('form').attr('id'), fieldPosition + '_' + (labelValue || $(this).attr('id')));
         }
     });
 }

 module.exports = {
     init: function () {
         if (!SitePreferences.GA_ENABLED) {
             return;
         }
         initPageContext();
         initGoogleAnalitycs();
         sendTags();
         initEvents();
         initFormTracking();
     }
 };
