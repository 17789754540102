'use strict';

var util = require('_core_ext/util'),
    layout = require('_core_ext/layout'),
    cssConstants = require('_core_ext/cssconstants'),
    JSCommonConstants = require('_core_ext/constants'),
    JSSearchConstants = require('_core_ext/pages/search/constants'),
    JSCartConstants = require('_core_ext/pages/cart/constants');


var initialized = false,
    $overlayClose = $(JSSearchConstants.CLASS_HEADER_OVERLAY_CLOSE),
    $cartStickySummary = $(JSCartConstants.CLASS_CART_STICKY_SUMMARY),
    $overlayHideElements = $('.js-loader, ' + JSCommonConstants.CLASS_HEADER_NAV_CLOSE);

var SuggestionsModule = function() {

    var P = {
        currentQuery: null,
        lastQuery: null,
        runningQuery: null,
        listTotal: -1,
        listCurrent: -1,
        delay: 70,
        defaultValue: '',
        resultsID: ''
    };

    var $body = $(cssConstants.BODY),
        $searchForm, $searchField, searchFieldValue,
        $resultsContainer, $searchContainer;

    /**
     * @function
     * @description Configures parameters and required object instances
     */
    function init (container, options) {
        $searchContainer = $(container);
        $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        $searchField = $searchForm.find('input[name="q"]');
        searchFieldValue = '';
        $searchField.each((index, field) => {
            if (field.value) {
                searchFieldValue = field.value.trim();
            }
        });

        P.resultsID = options.resultsID;
        P.defaultValue = options.defaultValue;

        var isCustomerLoggedIn = !!$(JSSearchConstants.CLASS_MYACCOUNT_INFO).length;
        var $showSearchBtn = isCustomerLoggedIn ? $(JSSearchConstants.CLASS_MOBILE_SEARCH_BTN_REG) : $(JSSearchConstants.CLASS_MOBILE_SEARCH_BTN_UNREG);
        $showSearchBtn.removeClass('hidden');

        $showSearchBtn.find(JSSearchConstants.CLASS_SHOW_SEARCH_INPUT).on('click', function () {
            var $searchMobileInput = $(JSSearchConstants.CLASS_SEARCH_SECTION_MOBILE);
            var offset;
            if ($searchMobileInput.hasClass('hidden')) {
                offset = $(document).scrollTop();
                $searchMobileInput.removeClass('hidden');
                $searchMobileInput.find('input').focus();
                $body.css("top", offset * -1 + 'px');
            } else {
                offset = parseInt($body.css("top"), 10);
                $searchMobileInput.addClass('hidden');
                $searchContainer.trigger('suggest:clear');
                $(document).scrollTop(offset * -1);
            }
        });

        $(document).on('click', JSSearchConstants.CLASS_HEADER_SEARCH_OVERLAY, function (event) {
            if (!event.target.classList.contains('js-search-placeholder')) {
                $searchContainer.trigger('suggest:clear');
                _hideOverlay();

                if (layout.isMobile() || layout.isTabletPortrait()) {
                    var offset = parseInt($body.css("top"), 10);
                    var $searchMobileInput = $(JSSearchConstants.CLASS_SEARCH_SECTION_MOBILE);

                    $searchMobileInput.addClass('hidden');
                    $(document).scrollTop(offset * -1);
                }
            }
        });

        // on focus listener (clear default value)
        $searchField.on('focus', function () {
            if (_isSearchPopOpen()) {
                return;
            }

            if (!$resultsContainer) {
                $resultsContainer = $('<div/>').attr({
                    'id': P.resultsID,
                    'class': 'search-suggestions'
                }).appendTo($searchContainer).hide();
            }

            if (searchFieldValue === P.defaultValue) {
                $searchField.each((index, field) => field.value = '');
            }

            if (layout.isMobile() || layout.isTabletPortrait()) {
                _showOverlay();
            }

            setTimeout(function () {
                $(cssConstants.BODY).trigger('layout.lock');
            }, P.delay);

        });

        $(document).on('mousedown', JSSearchConstants.CLASS_HEADER_SEARCH_OVERLAY, function (e) {
            if (layout.isMobile() || layout.isTabletPortrait()) {
                e.preventDefault();
            }
        });

        // do not submit form if search box is empty
        $searchForm
            .on('submit', function (event) {
                var searchFieldValue = '';
                $searchField.each((index, field) => {
                    if (field.value) {
                        searchFieldValue = field.value.trim();
                    }
                });
                var searchFieldFilled = searchFieldValue && searchFieldValue.length && searchFieldValue !== $searchField.attr('placeholder');

                if (!searchFieldFilled) {
                    event.preventDefault();
                } else {
                    $searchField.each((index, field) => {
                        field.value = searchFieldValue;
                    });
                }
            })
            .on('click', JSSearchConstants.CLASS_SEARCH_SUBMIT, function() {
                $searchForm.submit();
            });

        $overlayClose.on('click', function () {
            closeSuggestions();

            if ($searchField.length) {
                $searchField.each((index, field) => field.value = '');
            }
        });

        $searchContainer.on('suggest:clear', function () {
            closeSuggestions();

            if ($searchField.length) {
                $searchField.each((index, field) => field.value = '');
            }
        });

        $searchField.on('keyup', function (e) {
            var keyCode = e.keyCode || window.event.keyCode;
            var searchFieldValue = '';
            $searchField.each((index, field) => {
                if (field.value) {
                    searchFieldValue = field.value.trim();
                }
            });
            P.currentQuery = searchFieldValue;

            if (P.currentQuery.length < 3) {
                return;
            }

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }

            // check for an ENTER
            if (keyCode === 13) {
                $searchForm.submit();
                closeSuggestions();
            }

            // check for an ESC
            if (keyCode === 27) {
                closeSuggestions();
                return;
            }
            // no query currently running, init an update
            if (!P.runningQuery) {
                P.runningQuery = P.currentQuery;
                setTimeout(suggest, P.delay);
            }
        });
    }

    /**
     * @function
     * @description trigger suggest action
     */
    function suggest () {
        // check whether query to execute (runningQuery) is still up to date and had not changed in the meanwhile
        // (we had a little delay)
        if (P.runningQuery !== P.currentQuery) {
            // update running query to the most recent search phrase
            P.runningQuery = P.currentQuery;
        }

        // if it's empty clear the results box and return
        if (P.runningQuery.length === 0) {
            clearResults();
            P.runningQuery = null;
            return;
        }

        // if the current search phrase is the same as for the last suggestion call, just return
        if (P.lastQuery === P.runningQuery && !!$resultsContainer.html().length) {
            P.runningQuery = null;
            return;
        }

        // build the request url
        var reqUrl = util.appendParamToURL(Urls.searchsuggest, 'q', P.runningQuery);

        // execute server call
        $.get(reqUrl, function (data) {
            var suggestionHTML = data,
                ansLength = suggestionHTML.trim().length;
            var searchFieldValue = '';
            $searchField.each((index, field) => {
                if (field.value) {
                    searchFieldValue = field.value.trim();
                }
            });
            var actualLenght = searchFieldValue.length;

            if (actualLenght === 0) {
                P.runningQuery = null;
                return;
            }

            // if there are results populate the results div
            if (ansLength === 0) {
                clearResults();
            } else {
                _showOverlay();

                // update the results div
                $resultsContainer.html(suggestionHTML).fadeIn(200);
                if (!layout.isDesktop()) {
                    $(cssConstants.BODY).trigger('layout.lock');
                }
            }

            // record the query that has been executed
            P.lastQuery = P.runningQuery;
            P.runningQuery = null;

            // check for another required update (if current search phrase is different from just executed call)
            if (P.currentQuery !== P.lastQuery) {
                // ... and execute immediately if search has changed while this server call was in transit
                P.runningQuery = P.currentQuery;
                setTimeout(suggest, P.delay);
            }
        });
    }

    /**
     * @function
     * @description Handles keyboard's arrow keys
     * @param keyCode Code of an arrow key to be handled
     */
    function handleArrowKeys(keyCode) {
        switch (keyCode) {
            case 38:
                // keyUp
                P.listCurrent = (P.listCurrent <= 0) ? (P.listTotal - 1) : (P.listCurrent - 1);
                break;
            case 40:
                // keyDown
                P.listCurrent = (P.listCurrent >= P.listTotal - 1) ? 0 : P.listCurrent + 1;
                break;
            default:
                // reset
                P.listCurrent = -1;
                return false;
        }

        return true;
    }

    /**
     * @function
     * @description Clear Search Suggestion Results
     */
    function clearResults () {
        if (!$resultsContainer || !$searchContainer) {
            return;
        }

        $resultsContainer.fadeOut(200, function () {
            $resultsContainer.empty();
        });

        if ($(cssConstants.BODY).hasClass(cssConstants.LOCK) && !_isNavOpen()) {
            $(cssConstants.BODY).trigger('layout.unlock');
        }
    }

    var _showOverlay = function () {
        if (!$resultsContainer || !$searchContainer) {
            return;
        }

        $resultsContainer.addClass(cssConstants.ACTIVE);
        $searchContainer.addClass(cssConstants.SEARCH_OVERLAY);
        $body.addClass(cssConstants.BODY_SEARCH_ACTIVE);
        $overlayClose.show();

        if (_isNavOpen()) {
            $overlayHideElements.hide();
        }

        if ($cartStickySummary && $cartStickySummary.length) {
            $cartStickySummary.hide();
        }
    };

    var _hideOverlay = function () {
        if (!$resultsContainer || !$searchContainer) {
            return;
        }

        $resultsContainer.removeClass(cssConstants.ACTIVE);
        $searchContainer.removeClass(cssConstants.SEARCH_OVERLAY);
        $body.removeClass(cssConstants.BODY_SEARCH_ACTIVE);
        $overlayClose.hide();

        if (_isNavOpen()) {
            $overlayHideElements.show();
        }

        $searchContainer.removeClass('is-active');

        if ($cartStickySummary && $cartStickySummary.length) {
            $cartStickySummary.show();
        }
    };

    var _isNavOpen = function () {
        return $body.hasClass(cssConstants.BODY_NAV_ACTIVE);
    };

    var _isSearchPopOpen = function () {
        return $body.hasClass(cssConstants.BODY_SEARCH_ACTIVE);
    };

    function closeSuggestions() {
        clearResults();
        _hideOverlay();
    }

    return {
        init: init,
        clearResults: clearResults,
        closeSuggestions: closeSuggestions,
    };
};

function initializeEvents() {
    var $headerSearch = $(JSCommonConstants.CLASS_HEADER_SEARCH);

    if ($headerSearch.length) {
        var headerSuggestions = SuggestionsModule();
        headerSuggestions.init($headerSearch, {
            resultsID: 'head_search-suggestions',
            defaultValue: Resources.SIMPLE_SEARCH,
        });
    }
}

var searchsuggest = {
    init : function () {
        if (initialized) {
            return;
        }

        initializeEvents();
        initialized = true;
    }
};

module.exports = searchsuggest;