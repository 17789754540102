'use strict';

var $loader,
    $tooltip;

/**
 * @function
 * @description Shows an AJAX-loader on top of a given container
 * @param {Element} container The Element on top of which the AJAX-Loader will be shown
 * @param {String} tooltipText The text in the bottom of loader
 */
var show = function (container, tooltipText) {
    var target = (!container || $(container).length === 0) ? $('body') : $(container);
    $loader = $loader || $('.lds-spinner-bg');

    if ($loader.length === 0) {
        var $divs = $(),
            divCount = 12,
            $spiner = $('<div>').addClass('lds-spinner');

        for (var i = 0; i < divCount; i ++) {
            $divs = $divs.add('<div/>');
        }

        $spiner.append($divs);
        $loader = $('<div/>')
            .addClass('lds-spinner-bg')
            .append($spiner);
    }

    if (tooltipText) {
        $tooltip = $('<div/>').addClass('tooltip-text').html(tooltipText);
        $loader.append($tooltip);
    }

    return $loader.appendTo(target).show();
};
/**
 * @function
 * @description Hides an AJAX-loader
 */
var hide = function () {
    if ($loader) {
        $loader.hide();
    }

    if ($tooltip) {
        $tooltip.hide();
    }
};

exports.show = show;
exports.hide = hide;
