'use strict';
var cssConstants = require('_core_ext/cssconstants'),
    JSConstants = require('_core_ext/constants');

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
}

function initializeEvents() {
    $cache.document
        .on('click', JSConstants.CLASS_LANGUAGE_SELECT_LINK, function(e) {
            var $self = $(this);
            if ($self.hasClass(cssConstants.CLASS_CURRENT_LANGUAGE)) {
                e.preventDefault();
            }
        });
}

exports.init = function() {
    initializeCache();
    initializeEvents();
};
