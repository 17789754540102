'use strict';

var coreValidator = require('_core/validator');

var settings = {
    errorClass: 'error form-row_error_msg',
    skipFieldsClass: 'js-skip-validation',
    errorElement: 'div',
    errorPlacement: function ($error, $element) {
        var $form_row = $element.parents('.js-form_row'),
            $tooltip = $form_row.find('.js-form_tooltip'),
            $formfield = $form_row.find('.js-formfield'),
            $caption = $form_row.find('.js-row_caption');

        // define correct place for message
        if ($element.is('input[type="checkbox"]')) {
            var $checkboxGroup = $element.parents('.js-mandatory-checkbox-group');

            if ($checkboxGroup.length > 0) {
                var $oldErrors = $checkboxGroup.find('div.error.form-row_error_msg');
                $oldErrors.remove();

                $checkboxGroup.append($error);
            } else {
                $error.insertAfter($caption);
            }
        } else if ($element.is('input[type="radio"]')) {
            $error.insertAfter($caption);
        } else if ($form_row.hasClass('js-error_after_caption')) {
            $error.insertAfter($caption);
        } else {
            $error.insertAfter($tooltip.length ? $tooltip : $formfield);
        }
    },
    onkeyup: function (element) {
        if ($(element).hasClass('js-onkeyup-validate')) {
            this.element(element);
        }
    },
    onfocusout: function (element) {
        if ($(element).hasClass(this.settings.skipFieldsClass)) return;
        var isCheckBox = (element.localName === 'input' && element.type === 'checkbox');
        var isRadio = (element.localName === 'input' && element.type === 'radio');

        if (!this.checkable(element) || isRadio || isCheckBox) {
            this.element(element);
        }
    },
    onclick: function (element) {
        var isSelect = (element.localName === 'select' && element.value !== '');
        var isCheckBox = (element.localName === 'input' && element.type === 'checkbox');
        var isRadio = (element.localName === 'input' && element.type === 'radio');

        if (isSelect || isCheckBox || isRadio) {
            this.element(element);
        }
    }
};

coreValidator.settings = settings;
$.validator.setDefaults(settings);

module.exports = coreValidator;
