"use strict";

var initialized = false,
    $cache = {},
    level1Timer = null,
    level2Timer = null,
    selectEnterTimer,
    selectOutTimer,
    options = {
        level1HoverDelay: 150,
        level2HoverDelay: 150,
        selectDelay: 250,
        leaveDelay: 0,
        tapCloseDelay: 0,
        debounceDelay: 100,
    };

var _ = require("lodash"),
    layout = require("_core_ext/layout"),
    JSConstants = require("_core_ext/constants"),
    CSSConstants = require("_core_ext/cssconstants");

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.body = $(CSSConstants.BODY);
    $cache.headerNavigationMenu = $(JSConstants.CLASS_HEADER_NAV);
    $cache.level1Items = $(JSConstants.CLASS_FLYOUT_LEVEL1);
    $cache.level2Items = $(JSConstants.CLASS_FLYOUT_LEVEL2);
    $cache.navigationFlyouts = $(
        JSConstants.CLASS_FLYOUT + ", " + JSConstants.CLASS_FLYOUT_LEVEL3
    );
    $cache.backToFirst = "js-back-to-first";
    $cache.backToSecond = "js-back-to-second";
    $cache.stuckLevel = "js-stuck-level";
}

function resetLevel2Menu($menuItem) {
    var $parentFlyout = $menuItem.closest(JSConstants.CLASS_NAV_FLYOUT);
    $parentFlyout
        .find(
            JSConstants.CLASS_FLYOUT_LEVEL2 +
                " " +
                JSConstants.CLASS_FLYOUT_LEVEL3
        )
        .removeClass(CSSConstants.VISIBLE);
    $parentFlyout
        .find(JSConstants.CLASS_FLYOUT_LEVEL2)
        .removeClass(CSSConstants.ACTIVE);
    $parentFlyout
        .find(JSConstants.CLASS_FLYOUT_LEVEL2)
        .find("a.nav-level2_link")
        .removeClass("hovered");
}

function showLevel2Menu($menuItem) {
    resetLevel2Menu($menuItem);
    $menuItem.find("a.nav-level2_link").addClass("hovered");
    $menuItem
        .find(JSConstants.CLASS_FLYOUT_LEVEL3)
        .addClass(CSSConstants.VISIBLE)
        .addClass(CSSConstants.ACTIVE);
}

function showLevel1Menu($menuItem) {
    var $menuFlyout = $(JSConstants.CLASS_CATEGORY_FLYOUT_CONTAINER).find(
        "[data-id=" + $menuItem.data("id") + "]"
    );
    $menuFlyout
        .find(JSConstants.CLASS_FLYOUT_LEVEL3)
        .removeClass(CSSConstants.VISIBLE);

    var $firstLevel2Item = $menuFlyout
        .find(JSConstants.CLASS_FLYOUT_LEVEL2)
        .first();

    showLevel2Menu($firstLevel2Item);
}

function isDesktopDevice() {
    if ('ontouchstart' in window || window.navigator.maxTouchPoints > 0) {
        return false;
    }
    return true;
}

function resetMenuTimer(menuTimer) {
    if (menuTimer !== null) {
        clearTimeout(menuTimer);
        menuTimer = null;
    }
}

function scrollNavTo(yLocation, accumulator, timeInterval) {
    var navWrapper = $(JSConstants.CLASS_HEADER_NAV);

    if (navWrapper && navWrapper.length > 0 && layout.isMobile()) {
        var time = typeof timeInterval !== "undefined" ? timeInterval : 250;
        yLocation = accumulator
            ? navWrapper.scrollTop() + yLocation
            : yLocation;
        navWrapper.animate({ scrollTop: yLocation }, time);
    }
}

function toggleHeaderNavTransparent() {
    var lastCaterory = $(".js-header_nav li:visible").last();

    if (!lastCaterory.length) {
        return;
    }

    var lastCategoryLeftEnd = lastCaterory.offset().left;
    var navParent = $(JSConstants.CLASS_NAV_MENU);

    if (
        $cache.window.width() >=
        lastCategoryLeftEnd + lastCaterory.width() / 2
    ) {
        navParent.addClass("transparent");
    } else {
        navParent.removeClass("transparent");
    }
}

function handleDesktopScroll() {
    var isScrollable = false;
    let lastX = 0;
    $cache.document
        .on("mousedown", JSConstants.CLASS_HEADER_LEVEL1_LINK, function (e) {
            e.preventDefault();
        })

        .on("mousedown", JSConstants.CLASS_HEADER_NAV, function () {
            isScrollable = true;
        })

        .on("mouseup", function () {
            isScrollable = false;
        })

        .on("mousemove", JSConstants.CLASS_HEADER_NAV, function (e) {
            if (isScrollable) {
                $cache.headerNavigationMenu[0].scrollLeft -= e.clientX - lastX;
            }
            lastX = e.clientX;
        });
}

var Navigation = function navigation($menuToShow, $menuToHide) {
    if ($menuToShow) {
        $menuToShow.addClass(CSSConstants.OPEN);
    }

    if ($menuToHide) {
        $menuToHide.removeClass(CSSConstants.OPEN);
        $(JSConstants.CLASS_NAV_FLYOUT).height("inherit");
    }

    if (layout.isMobile()) {
        $(JSConstants.CLASS_NAV_FLYOUT).css(
            "height",
            $(window).height() -
                $(document).find(JSConstants.CLASS_HEADER).outerHeight() -
                $(document).find(JSConstants.CLASS_HEADER_BOTTOM).outerHeight()
        );
    }
};

function initializeEventsMobile() {
    if (!layout.isDesktop() && !isDesktopDevice()) {
        $cache.document
            .on(
                "click",
                ".js-has-second-level",
                function (e) {
                    if (layout.isMobile()) {
                        $cache.body.trigger("layout.lock");
                    }
                    if (!$(this).hasClass($cache.stuckLevel)) {
                        e.preventDefault();
                        var flyout = $(this).data("flyout"),
                            $menuToShow = $(this).next(flyout);

                        Navigation.call($(this), $menuToShow, null);
                        scrollNavTo(0);
                    }
                }
            )

            .on(
                "click",
                ".js-has-third-level",
                function (e) {
                    if (layout.isMobile()) {
                        $cache.body.trigger("layout.lock");
                    }
                    if (!$(this).hasClass($cache.stuckLevel)) {
                        e.preventDefault();
                        if (layout.isTablet()) {
                            showLevel2Menu($(this).parent($cache.level2Items));
                        } else {
                            var flyout = $(this).data("flyout"),
                                $menuToShow = $(this).next(flyout);

                            Navigation.call($(this), $menuToShow, null);
                            scrollNavTo(0);
                        }
                    }
                }
            )

            .on(
                "click",
                "." + $cache.backToFirst + ", ." + $cache.backToSecond,
                function (e) {
                    e.preventDefault();
                    var flyout = $(this).data("flyout"),
                        $menuToHide = $(this).parents(flyout);

                    Navigation.call($(this), null, $menuToHide);

                    if ($(this).hasClass($cache.backToFirst)) {
                        $cache.headerNavigationMenu.height("inherit");
                    }
                }
            )

            .on(
                "click",
                JSConstants.CLASS_OVERLAY +
                    ", " +
                    JSConstants.CLASS_HEADER_NAV_CLOSE,
                function (e) {
                    e.preventDefault();
                    $cache.body.trigger("layout.unlock");
                    if (selectEnterTimer) {
                        clearTimeout(selectEnterTimer);
                    }

                    selectOutTimer = setTimeout(function () {
                        $(JSConstants.CLASS_NAV_MENU)
                            .find("[data-id]")
                            .each(function () {
                                $(this).removeClass(CSSConstants.SELECTED);
                            });
                    }, options.tapCloseDelay);
                }
            )

            .on("click", JSConstants.CLASS_HEADER_NAV_SEARCH_ICON, function () {
                var $navInput = $(JSConstants.CLASS_HEADER_NAV_SEARCH).find(
                    'input[name="q"]'
                );

                if ($navInput.length) {
                    $navInput.trigger("focus");
                }
            })

            // eslint-disable-next-line no-unused-vars
            .on(
                "click",
                JSConstants.CLASS_HEADER_NAV_CURRENCY + " .js-currencies",
                function () {
                    var currenciesList = $(
                        JSConstants.CLASS_HEADER_NAV_CURRENCY +
                            " .js-currencies_selectbox"
                    );

                    if (
                        currenciesList.length &&
                        currenciesList.hasClass(CSSConstants.OPEN)
                    ) {
                        scrollNavTo(currenciesList.outerHeight(), true, 500);
                    }
                }
            );

        $cache.document.off("mouseleave focusout", JSConstants.CLASS_NAV_MENU);
        $cache.level2Items.off("mousemove");
    }
}

function initializeEventsDesktop() {
    $cache.headerNavigationMenu.height("inherit");

    $cache.level1Items.on(
        "mousemove tap focusin focusout",
        _.debounce(function (event) {
            var $target = $(event.target);
            resetMenuTimer(level1Timer);

            if (!$target.hasClass("js-level1-link")) {
                return false;
            }

            level1Timer = setTimeout(
                $.proxy(function () {
                    showLevel1Menu($(this));
                }, this),
                options.level1HoverDelay
            );
        }, options.debounceDelay)
    );

    $cache.level2Items.on("mousemove tap focusin", function () {
        resetMenuTimer(level2Timer);

        level2Timer = setTimeout(
            $.proxy(function () {
                showLevel2Menu($(this));
            }, this),
            options.level2HoverDelay
        );
    });

    $cache.document.on(
        "mouseenter focusin",
        "li" + JSConstants.CLASS_FLYOUT_LEVEL1,
        _.debounce(function (event) {
            event.preventDefault();
            var $target = $(event.target);
            var $targetFlyout = $(
                JSConstants.CLASS_CATEGORY_FLYOUT_CONTAINER
            ).find("[data-id=" + $target.data("id") + "]");

            if (selectOutTimer) {
                clearTimeout(selectOutTimer);
            }

            selectEnterTimer = setTimeout(function () {
                $(JSConstants.CLASS_NAV_MENU)
                    .find("[data-id]")
                    .each(function () {
                        $(this).removeClass(CSSConstants.SELECTED);
                    });
                $target.addClass(CSSConstants.SELECTED);
                $target.closest("li").addClass(CSSConstants.SELECTED);
                $targetFlyout.addClass(CSSConstants.SELECTED);
            }, options.selectDelay);
        }, options.debounceDelay)
    );

    $cache.document.on(
        "mouseleave focusout",
        JSConstants.CLASS_NAV_MENU,
        _.debounce(function (event) {
            event.preventDefault();

            $(JSConstants.CLASS_NAV_MENU).removeClass("js-mouse-over");
            if (selectEnterTimer) {
                clearTimeout(selectEnterTimer);
            }

            selectOutTimer = setTimeout(function () {
                if (!$(JSConstants.CLASS_NAV_MENU).hasClass("js-mouse-over")) {
                    $(JSConstants.CLASS_NAV_MENU)
                        .find("[data-id]")
                        .each(function () {
                            $(this).removeClass(CSSConstants.SELECTED);
                        });
                }
            }, options.leaveDelay);
        }, options.debounceDelay)
    );

    $cache.document.on(
        "mouseenter focusin",
        JSConstants.CLASS_NAV_MENU,
        _.debounce(function () {
            $(JSConstants.CLASS_NAV_MENU).addClass("js-mouse-over");
        }, options.debounceDelay)
    );

    handleDesktopScroll();
}

function initializeEvents() {
    initializeEventsDesktop();
    initializeEventsMobile();
    toggleHeaderNavTransparent();

    $cache.headerNavigationMenu.on("scroll", function () {
        toggleHeaderNavTransparent();
    });

    $cache.document.on("window.modechanged", function () {
        initializeEventsDesktop();
        initializeEventsMobile();
        toggleHeaderNavTransparent();
        // Close sidenav
        $(JSConstants.CLASS_HEADER_NAV_CLOSE).trigger("click");
    });
}

module.exports = {
    init: function (params) {
        if (initialized) {
            return;
        }
        initializeParams(params);
        initializeCache();
        initializeEvents();
        initialized = true;
    },
};
