'use strict';

var util = require('_core/util');

function redirect(newURL) {
    setTimeout(function () {
        window.location.href = newURL;
    }, 0);
}

function refresh() {
    setTimeout(function () {
        window.location.reload();
    }, 500);
}

module.exports = $.extend({
    title: '',
    type: ''
}, window.pageContext, {
    'params': util.getQueryStringParams(window.location.search.substr(1)),
    'redirect': redirect,
    'refresh': refresh
});