"use strict";
require('regenerator-runtime/runtime');
const util = require('_core_ext/util');
const layout = require('_core_ext/layout');

const initializeScroll = () => {
var anchor = document.querySelector('#' + util.getParameterValueFromUrl('anchor', location.href));
    if (anchor) {
        scrollToAnchor(anchor);
        let imgs = Array.from(document.querySelectorAll("img"));
        let loudImg = imgs.map(function (img) {
            return new Promise(function(res) {
                img.addEventListener('load', () => {
                    res();
                });
            });
        });

        Promise.all(loudImg)
            .then(scrollToAnchor(anchor));
    }
};

function scrollToAnchor (anchor) {
    if (!layout.isMobile()) {
        anchor.scrollIntoView({behavior: "smooth"});
    } else {
        util.scrollBrowser(anchor.offsetTop, 800, true);
    }
}

const myoffers = {
    init: function () {
        initializeScroll();
    },
};

module.exports = myoffers;
