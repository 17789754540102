'use strict';

const Quantity = require('./quantity');

function clone(obj) {
    if (null === obj || 'object' != typeof obj) { return obj; }
    var copy = obj.constructor();
    for (var attr in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(attr)) { copy[attr] = obj[attr]; }
    }
    return copy;
}

function PubSub() {
    this.events = {};
    let self = this;

    this.subscribe = function (event, callback) {
        // eslint-disable-next-line no-prototype-builtins
        if (!self.events[event]) {
            self.events[event] = [];
        }

        return self.events[event].push(callback);
    };

    this.publish = function (event, data) {

        // eslint-disable-next-line no-prototype-builtins
        if (!self.events[event]) {
            return [];
        }

        return self.events[event].map(function(callback) {callback(data);});
    };
}

function ControlesState() {
    var self = this;
    self._dom = {};
    self._values = {};
    self.domSelectors = new Set();
    self.valuesSelectors = new Set();


    self.dom = function (cssSelector, element) {
        if (!self._dom) {
            self._dom = {};
        }

        if (!element) {
            return self._dom[cssSelector];
        } else {
            self.domSelectors.add(cssSelector);
            self._dom[cssSelector] = element;
        }
    };

    self.values = function (cssSelector, value) {
        if (!self._values) {
            self._values = {};
        }

        if (!value) {
            return self._values[cssSelector];
        } else {
            self.valuesSelectors.add(cssSelector);
            self._values[cssSelector] = value;
        }
    };
    self.clear = function () {
        self._dom = {};
        self._values = {};
        self.domSelectors = new Set();
        self.valuesSelectors = new Set();
    };
}

function History(params) {
    var self = this;
    self.state = params.state;
    self.states = [];

    self.getLastState = function () {
        try {
            var length = self.states.length;
            return self.states[length - 1] || null;
        } catch (error) {
            return null;
        }
    };

    this.statesPop = function () {
        return self.states.pop();
    };

    this.statesPush = function () {
        self.states.push(clone(self.state));
    };

    this.clear = function () {
        self.states = [];
    };
}

module.exports = function Store() {
    let self = this;
    self.state = {
        name: null,
        fromName: null,
        initialized: false,
        selectedStoreId: null,
        storeList: [],
        selectedStoreLocation: null,
        nearestStores: [],
        csrfData: null,
        selectedProductID: null,
        isPDPPage: null,
        paginatedStores: [],
        controlesState: new ControlesState(),
        quantityValue: null,
        MainMap: null
    };

    self.quantity = new Quantity(self);
    self.history = new History(self);
    self.events = new PubSub();
    self.dialog = null;
};
