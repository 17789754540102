'use strict';

var handlebars = require('handlebars');

var handlebarsHelper = {
    compileTemplates: function (options) {

        handlebars.registerHelper('toJSON', function(object) {
            return new handlebars.SafeString(JSON.stringify(object));
        });

        handlebars.registerHelper('and', function () {
            // Get function args and remove last one (meta object); every(Boolean) checks AND
            return Array.prototype.slice.call(arguments, 0, arguments.length - 1).every(Boolean);
        });

        if (options && options.partials && options.partials.length > 0) {
            options.partials.forEach(function (partial) {
                handlebars.registerPartial(partial.name, partial.template);
            });
        }

        window.Handlebars = {};
        window.Handlebars.templates = {};
        $("script[type='text/x-handlebars-template']").each(function () {
            var $element = $(this),
                source = $(this).html(),
                template = handlebars.compile(source);

            window.Handlebars.templates[$element.attr('id')] = template;
        });
    }
};

module.exports = handlebarsHelper;
