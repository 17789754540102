/** The references object of all modules needed for components initialization */
var references = {
    'account' : require('_core_ext/pages/account'),
    'myoffers' : require('_core_ext/pages/myOffers'),
    'communicationpreferences' : require('_core_ext/pages/communicationpreferences'),
    'order-detail': require('_core_ext/pages/orderDetail'),
    'selectDefaultStore': require('_core_ext/pages/selectDefaultStore'),
    'address': require('_core_ext/pages/checkout/address'),
    'reviews-carousel' : require('_core_ext/reviews-carousel'),
};

module.exports = {
    'references' : references
};
