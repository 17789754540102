"use strict";

require('regenerator-runtime/runtime');

const util = require('_core_ext/util');
const dialog = require('../../dialog');
const layout = require('_core_ext/layout');
const swipehandler = require('./swipehandler');
const offershelper = require('./offershelper');

let clearedOffersCount = 0;
let newOffersCount = document.querySelectorAll('.js-new-badge').length;
let clearedNewOffersCount = 0;

const showStartPopup = () => {
    const isMobileView = layout.isMobile();
    const popapContent = document.querySelector(isMobileView ? "#firstOpenMobilePopap" : "#firstOpenDesktopPopap");
    if (popapContent) {
        dialog.open({
            html: popapContent.innerHTML,
            options: {
                dialogClass: 'my-offers__popup my-offers__popup_main my-offers__first-time',
                buttons: [{
                    text: Resources.SHOW_ME,
                    click: function () {
                        $(this).dialog('close');
                    }
                }]
            }
        });
    }
};

const handleOfferOptions = () => {
    const offerOptionsTriggers = document.querySelectorAll('.js-show-options');
    offerOptionsTriggers.forEach(trigger => {
        trigger.addEventListener('click', (e) => {
            const offerContainer = e.target.closest('.js-offer-swipe-conteiner');
            const optionsBtn = offerContainer.querySelector('.js-show-options-btn');
            const optionsMenu = offerContainer.querySelector('.js-tile-options');
            optionsMenu.classList.toggle('hidden');
            optionsBtn.classList.toggle('my-offer__more-action_high');
        });
    });
};

const handleAllOffersOptions = () => {
    const allOffersOptionsBtns = document.querySelectorAll('.js-all-offers-options-btn');
    allOffersOptionsBtns.forEach(btn => {
        btn.addEventListener('click', (e) => {
            const optionsMenu = document.querySelector('.js-all-offers-options-menu');
            optionsMenu.classList.toggle('hidden');
        });
    });
};

const handleOfferEvents = () => {
    const offersCount = document.querySelectorAll('.js-offer-swipe-conteiner').length;
    const newOffersContainer = document.querySelector('.js-new-offers-conteiner');
    const clearBtns = document.querySelectorAll('.js-clear-offer-btn');
    const undoContainer = document.querySelector('.js-offer-undo-container');
    const undoLink = undoContainer.querySelector('.js-undo-offer-link');
    let offerContainer = null;

    clearBtns.forEach(btn => {
        btn.addEventListener('click', async (e) => {
            e.preventDefault();
            offerContainer = btn.closest('.js-offer-swipe-conteiner');
            const clearOfferUrl = util.appendParamsToUrl(e.target.href || e.target.parentElement.href, {
                cancelOffer: true
            });
            const clearOffer = await offershelper.request(clearOfferUrl);
            if (clearOffer.status) {
                offerContainer.classList.add('hidden');
                undoLink.href = util.appendParamsToUrl(e.target.href || e.target.parentElement.href, {
                    undoCancalation: true
                });
                undoContainer.classList.remove('hidden');
                clearedOffersCount++;
                if (offersCount === clearedOffersCount) offershelper.toggleEmptyOffersState();
                const isOfferNew = Boolean(offerContainer.querySelector('.js-new-badge'));
                if (isOfferNew) clearedNewOffersCount++
                if (clearedNewOffersCount === newOffersCount) newOffersContainer.classList.add('hidden');
            }
        });
    });

    undoLink.addEventListener('click', async (e) => {
        e.preventDefault();
        const undoUrl = undoLink.href
        const undoClearOffer = await offershelper.request(undoUrl);
        if (undoClearOffer.status && offerContainer) {
            offerContainer.classList.remove('hidden');
            undoContainer.classList.add('hidden');
            clearedOffersCount--;
            if (offersCount - clearedOffersCount === 1) offershelper.toggleEmptyOffersState();
            const isOfferNew = Boolean(offerContainer.querySelector('.js-new-badge'));
            if (isOfferNew) clearedNewOffersCount--;
            if (newOffersCount - clearedNewOffersCount === 1) newOffersContainer.classList.remove('hidden');
        }
    });
};

const handleAllOffersEvents = () => {
    const clearAllBtns = document.querySelectorAll('.js-clear-alloffers-link');
    const undoClearAllBtn = document.querySelector('.js-undo-alloffers-link');
    clearAllBtns.forEach(btn => {
        btn.addEventListener('click', async (e) => {
            e.preventDefault();
            const popapContent = document.querySelector('#clearAllPopap');
            dialog.open({
                html: popapContent.innerHTML,
                options: {
                    dialogClass: 'my-offers__popup my-offers__popup_clear-all my-offers__clear-all-flyout',
                    buttons: [{
                        text: Resources.CLEAR_ALL,
                        click: async function () {
                            const clearAll = await offershelper.request(btn.href);
                            if (clearAll.status) {
                                offershelper.toggleEmptyOffersState(true);
                                $(this).dialog('close');
                            }
                        }
                    }]
                }
            });
        });
    })

    undoClearAllBtn.addEventListener('click', async (e) => {
        e.preventDefault();
        const clearAllUrl = undoClearAllBtn.href
        const undoClearAll = await offershelper.request(clearAllUrl);
        if (undoClearAll.status) {
            offershelper.toggleEmptyOffersState(true);
        }
    });
};

const initlMobileEvents = () => {
    if (layout.isMobile()) {
        swipehandler.handleSwipe();
        handleAllOffersOptions();
    } else {
        swipehandler.removeSwipeHandling();
    }
};

const myoffers = {
    init: function () {
        showStartPopup();
        handleOfferOptions();
        handleOfferEvents();
        handleAllOffersEvents();
        initlMobileEvents();
        $(document).on("window.modechanged", initlMobileEvents);
    },
};

module.exports = myoffers;
