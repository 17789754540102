module.exports = {
    BODY: "body",
    HIDDEN: "hidden",
    CLASS_LOGIN_PASSWORD_RESET: ".js-login_password_reset",
    CLASS_REMEMBERME: ".js-rememberme",
    CLASS_REMEMBERME_CHECKBOX: ".js-remeberme_checkbox",
    CLASS_DIALOG: ".js-dialog",
    CLASS_RECAPTCHA: "js-recaptcha",
    CLASS_RECAPTCHA_VALIDATE: ".js-recaptcha_validate",
    CLASS_LOGIN_EXISTING: ".js-account-existing",
    CLASS_LOGIN_EXISTING_BACK: ".js-account-existing-back",
    CLASS_LOGIN_NEW_FORM: ".js-new-customer_form",
    CLASS_LOGIN_REGISTERED_FORM: ".js-registered-customer_form",
    CLASS_LOGIN_NOCARD: ".js-nocard_form",
    CLASS_LOGIN_HAVECARD: ".js-login_havecard",
    CLASS_LOGIN_HAVECARD_BLOCK: ".js-login_havecard_block",
    CLASS_HAVECARD_FORM: ".js-havecard_form",
    CLASS_LOGIN_TRADECARD_INPUT: ".js-login_tradecard",
    CLASS_LOGIN_BUTTON: ".js-login-button",
    CLASS_TRADECARD_CONTINUE_LOGIN: ".js-tradecard_continue_login",
    CLASS_LOGIN_FORM: ".js-login_form",
    CLASS_TRADECARD_CLOSE_POPUP: ".js-tradecard_closepopup",
    CLASS_LOGIN_EMAIL: ".js-login_email",
    CLASS_LOGIN_PASSWORD: ".js-login_password",
    CLASS_LOGIN_ISCHECKOUTLOGIN: ".js-login_ischeckoutlogin",
    CLASS_ACCOUNT_TRADE_CARD_SECTION: ".js-account-trade-section",
    CLASS_ACCOUNT_LOGIN_TITLE: ".js-account-login-title",
    CLASS_ACCOUNT_LOGIN_REGISTER_LINK: ".js-account-register-link",
    CLASS_NAV_FLYOUT: ".js-nav_flyout",
    CLASS_HEADER: ".js-header",
    CLASS_HEADER_BOTTOM: ".js-header-bottom",
    CLASS_CATEGORY_FLYOUT_CONTAINER: ".js-category-flyout-container",
    CLASS_NAV_MENU: ".js-nav-menu",
    CLASS_IMAGES_LAZYLOAD: ".js-img-lazyload",
    CLASS_IMAGES_CAROUSEL_LAZYLOAD: ".js-img-carousel-lazyload",
    CLASS_TOGGLE: ".toggle",
    CLASS_TOGGLE_CONTENT: ".toggle-content",
    CLASS_TOGGLE_EXPANDED: "expanded",
    CLASS_TOGGLE_PARENT: ".js-accordion-parent",
    CLASS_MINI_CART_CONTENT: ".js-mini-cart-content",
    CLASS_TOGGLE_ORDER: "is-ordered",

    CLASS_HEADER_SEARCH: ".js-header_search",
    CLASS_HEADER_MIDDLE: ".js-header-middle",
    CLASS_HEADER_TOP_PROMO: ".js-header-top-promo",
    CLASS_HEADER_TOP_MOCK: ".js-header-top-mock",
    CLASS_HEADER_TOP_PROMO_CLOSE: ".js-header-top-promo_close",
    CLASS_HEADER_TOP_PROMO_OPEN: ".js-header-top-promo_open",

    CLASS_HEADER_NAV: ".js-header_nav",
    CLASS_HEADER_NAV_SEARCH: ".js-header_nav-search",
    CLASS_HEADER_NAV_SEARCH_ICON: ".js-header_nav-search-tapblock",
    CLASS_HEADER_NAV_CLOSE: ".js-header_nav-close",
    CLASS_HEADER_NAV_CURRENCY: ".js-nav-currency",
    CLASS_HEADER_NAV_LANGUAGE: ".js-nav-language",
    CLASS_HEADER_LEVEL1_LINK: ".js-level1-link",
    CLASS_OVERLAY: ".js-overlay",

    CLASS_STICKY_HEADER: ".js-sticky-header",
    CLASS_STICKY_HEADER_MOBILE: ".js-sticky-header-mobile",
    CLASS_STICKY_HEADER_PROMO: ".js-header-top-promo",

    CLASS_FOOTER_YEAR_SPAN: ".js-footer-copyright_span--year",
    ID_FOOTER_COPYRIGHT_LABEL: "#js-copyright-label",

    COOKIE_POLICY: ".js-cookiepolicy",
    COOKIE_POLICY_CLOSE: ".js-cookiepolicy_close",
    CLASS_DIALOG_HINT: ".js-dialog-hint",
    CLASS_DIALOG_HINT_CLOSE: ".js-dialog-hint_close",
    CLASS_CLOSE_DIALOG: ".js-close_dialog",
    CLASS_TRADE_CARD_POP: ".js-tradecard-popup",
    CLASS_TRADE_CARD_POP_SELECT_LINK: ".tradecard-popup_link",
    CLASS_LANGUAGE_POP: ".js-language-popup",
    CLASS_LANGUAGE_POP_SELECT_LINK: ".js-languagepopup-choose",
    CLASS_LANGUAGE_SELECT_LINK: ".js-language_select",

    CLASS_SCROLL_SECTION_LINK: ".js-scroll-section_link",

    CLASS_TOP_NOTIFICATION: ".js-top-notification",
    CLASS_TOP_NOTIFICATION_MSG: ".js-top-notification-msg",
    CLASS_TOP_NOTIFICATION_TEXT: ".js-top-notification-text",
    CLASS_TOP_NOTIFICATION_CLOSE: ".js-top-notification_close",
    CLASS_CSFR_TOKEN: ".js-csfr-token",
    CLASS_CSFR_SHARED_TOKEN: ".js-csfr_shared-token",

    CLASS_FLYOUT: ".js-nav_flyout",
    CLASS_FLYOUT_LEVEL1: ".js-level1_item",
    CLASS_FLYOUT_LEVEL2: ".js-level2_item",
    CLASS_FLYOUT_LEVEL3: ".js-nav_level3",

    CLASS_IMAGE_UPLOADER_CONTAINER: ".image-uploader_container",
    CLASS_DELETE_IMAGE_POPUP: ".delete-image-popUp-text",
    CLASS_SEND_BTN: "#sendBtn",
    CLASS_IMAGE_PATH: ".js-image-path",

    CLASS_EMAIL_INPUT: ".js-email_field",
    CLASS_EMAIL_ERROR: ".js-email_error",
    CLASS_RETRIEVE_FORM: ".js-retrieve-form",
    CLASS_CARD_INPUT: ".js-card-input",
    CLASS_INVOICE_INPUT: ".js-invoice-input",
    CLASS_RETRIEVE_FORM_INPUT: '.js-retrieve-form input[type=text][value=""]',
    CLASS_OFFLINE_REG_STEP: ".js-offline-reg-step",
    CLASS_SELECT_BLOCK_BTN: ".js-selectblockbtn",
    CLASS_RESEND_CTA_BTN: ".js-resendCTABtn",
    CLASS_SUBMIT_ACCOUNT_RETRIEVE: ".js-submit-account-retrieve",
    CLASS_REDIRECT_TRADE: ".js-trade-redirect_link",

    ID_ADD_CARD_NOTIFICATION: "#addToCardNotification",
    ID_OUT_STOCK_NOTIFICATION: "#outStockNotification",
    ID_REDIRECT_B2B: "#js-redirect-b2b",
    ID_REDIRECT_MAINPOPUP: "#js-redirect-mainpopup",
    ID_SUBMIT_BTN: "#sendBtn5",
    ID_IMAGE_UPLOADER_INPUT: "#imageUploader",
    ID_CREATE_RETURN_FORM: "#CreateReturnForm",

    CLASS_SHOW_PASSWORD_INLINE: ".js-show-password",
};
