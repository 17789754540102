'use strict';

var _ = require('lodash'),
    util = require('_core_ext/util'),
    handlebarsHelper = require('_core_ext/handlebarsHelper'),
    perimeterx = require('_core_ext/trackers/perimeterx'),
    progress = require('_core_ext/progress'),
    Utils = require('_core_ext/reservecollect/utils'),
    topnotification = require('_core_ext/pages/topnotification');

var JSConstants = require('../constants'),
    DISABLED = require('../../cssconstants').DISABLED,
    dialog = require('../../dialog'),
    API = require('../utils/api');

// Constants
var CONSTANTS = {
    SELECTED_QUANTITY: 1,
    MAX_NEAREST_STORES_QUANTITY: SitePreferences.MAX_NEAREST_STORES_QUANTITY,
    DEBOUNCE_SCROLL: 200,
};

// Global variable
var $storesDialogWrapper;
var store = null;
var PLPForm = null;

/**
 * Dom initialization
 */
function initDOM() {
    $storesDialogWrapper = $('<div/>')
        .attr({
            id: JSConstants.ID_STORES_SELECT_DIALOG.slice(1),
            class: JSConstants.CLASS_WRAPPER_SELECT_DIALOG.split('.').join(''),
        })
        .appendTo(document.body);
}

/**
 * Initializes google map from by a JSONP request if it hasn't been loaded.
 * Compiles handlebars templates and shows store on the first map (Store search dialog) if it has been selected.
 */
function initStores() {
    var partials = [
        { name: 'storeTile', template: $('#RESERVE_storeTile').html() },
    ];
    handlebarsHelper.compileTemplates({ partials: partials });
    if (!store.state.initialized) {
        store.events.publish('getGoogleMapFromAPI');
    } else {
        store.events.publish('createMainMap');
        store.events.publish('preselectStore');
    }
    store.state.name = 'storeSelect';
    store.quantity.init();
}

function initPDPStoreSearch() {
    var region = Utils.getRegionByLocale(SitePreferences.LOCALE);
    var data = {
        key: SitePreferences.GOOGLE_API_KEY,
        libraries: 'geometry,places',
        region: region,
        language: SitePreferences.LOCALE
    };

    $.ajax({
        url: Urls.googlemapsAPIUrl,
        data: data,
        dataType: 'jsonp',
        complete: function (data) {
            if (data.status === 200) {
                let pdpSearchInputs = document.querySelectorAll(JSConstants.CLASS_SEARCH_GOOGLE_ADDRESS);
                pdpSearchInputs.forEach(pdpSearchInput => {
                    let placesAutocompleteInstance = new google.maps.places.Autocomplete(pdpSearchInput);
                    placesAutocompleteInstance.setComponentRestrictions({
                        country: region
                    });
                    placesAutocompleteInstance.addListener('place_changed', function() {
                        let pid = pdpSearchInput.getAttribute('data-pid');
                        store.state.isPDPPage = true;
                        onChangeStoreButtonClick({
                            pid: pid,
                            onClose: function () {
                                $('body').removeClass('noscroll');
                                store.history.clear();

                                if (!store.state.isPDPPage) {
                                    util.restoreScroll();
                                }

                                if (sessionStorage.getItem('fullStoreList')) {
                                    sessionStorage.removeItem('fullStoreList');
                                }
                            },
                        });
                    });
                });
            }
        }
    });
}

/**
 * Shows nearest store on the store search dialog window and appends 'check another stores' button
 * @param store
 */
function showOnlyNearestStore(storeInfo) {
    $(JSConstants.CLASS_STORE_DETAILS).remove();

    var storeTile = Handlebars.templates.RESERVE_storeTile({
        id: storeInfo.id,
        nearest: true,
        name: storeInfo.name,
        formattedAddress: storeInfo.formattedAddress,
        phone: storeInfo.phone,
        distanceText: storeInfo.distanceText,

        storeAvailableForRC: storeInfo.availability.storeAvailableForRC,
        isProductSpecific: storeInfo.availability.isProductSpecific,
        storeLabel: storeInfo.availability.storeLabel,
        productInfo: storeInfo.availability.productInfo,
        quantitySteper: storeInfo.availability.quantitySteper,
        isNotStoreDetails: true,
    });

    var checkAnotherStoreButton = Handlebars.templates.RESERVE_storeAnotherStoreButton();

    $(JSConstants.CLASS_STORE_NEAREST_DIALOG_CONTAINER).append(storeTile);
    $(JSConstants.CLASS_STORE_DETAILS).append(checkAnotherStoreButton);
    store.state.controlesState.dom(
        JSConstants.CLASS_STORE_NEAREST_DIALOG_CONTAINER,
        storeTile
    );
    store.state.controlesState.dom(
        JSConstants.CLASS_STORE_DETAILS,
        checkAnotherStoreButton
    );
}

/**
 * Shows store details dialog window
 * @TODO Shows store search dialog window
 * @param config
 */
function showDialog(config) {
    dialog.open(
        {
            html: config.html,
            target: $storesDialogWrapper,
            options: {
                width: 600,
                outsideClose: false,
                height: 400,
                dialogClass: 'stores-select-dialog',
                buttons: [],
                close: function () {
                    config.onClose(store.state.selectedStoreId);
                },
                open: initStores,
            },
        },
        false
    );
    store.dialog = dialog;
}

function isScrollReachedBottom(scrollArea) {
    if (scrollArea) {
        var scrolledCount = scrollArea.scrollHeight - scrollArea.scrollTop - 5;
        var sectionHeight = scrollArea.clientHeight;
        // 5px - Light offset for better perception
        return scrolledCount <= sectionHeight;
    } else {
        return false;
    }
}

/**
 * Shows 10 stores on scroll
 */
function onStoresScroll() {
    var scrollArea = this;

    if (
        isScrollReachedBottom(scrollArea) &&
        store.state.paginatedStores.length > 0
    ) {
        updateStoresList(store.state.paginatedStores[0]);
        store.state.paginatedStores[0].forEach(item => store.state.storeList.push(item));
        store.events.publish('resetMainMap');
        store.state.paginatedStores.shift();
    }
}

/**
 * Appends store list template to the stores list
 * @param stores
 */
function updateStoresList(stores) {
    var storesTemplateObject = Utils.getStoreListTemplateObject(stores);
    var storeList = Handlebars.templates.RESERVE_storeList(storesTemplateObject);
    store.state.controlesState.dom(
        JSConstants.CLASS_STORE_LIST,
        storeList
    );
    $(JSConstants.CLASS_STORE_LIST).append(storeList);
}

function onChooseAnotherStoreClick() {
    store.state.controlesState.values(
        JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT,
        $(JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT).val()
    );
    store.history.statesPush();
    store.quantity.destroy();
    store.state.name = 'anotherStore';

    store.state.paginatedStores = Utils.paginateStores(
        store.state.nearestStores,
        CONSTANTS.MAX_NEAREST_STORES_QUANTITY
    );
    $(JSConstants.CLASS_STORE_SELECT).hide();

    var storeListDialogElm = Handlebars.templates.RESERVE_storeListDialog();
    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).append(storeListDialogElm);

    var storeList = Handlebars.templates.RESERVE_storeList({
        stores: store.state.paginatedStores[0],
        isCartPage: store.state.isCartPage,
        isPDPPage: store.state.isPDPPage,
    });
    $(JSConstants.CLASS_STORE_LIST).append(storeList);
    store.state.paginatedStores.shift();

    $(JSConstants.CLASS_STORE_LIST).on('mousewheel',
        _.debounce(onStoresScroll, CONSTANTS.DEBOUNCE_SCROLL)
    );
    store.quantity.init();
    store.state.controlesState.dom(
        JSConstants.CLASS_WRAPPER_SELECT_DIALOG,
        storeListDialogElm
    );
    store.state.controlesState.dom(
        JSConstants.CLASS_STORE_LIST,
        storeList
    );
}

function onGoBackToSearchClick() {
    $(this).closest(JSConstants.CLASS_STORE_SELECT).remove();
    $(JSConstants.CLASS_STORE_SELECT).show();
    store.events.publish('resetMainMap');
    trigerSearchByStr();
}

function onSearchByCurrentLocationClick(e) {
    if (e && e.preventDefault) {e.preventDefault();}
    store.events.publish('searchByCurrentLocation');
}

/**
 * Receive storeList from an API call
 */
function onChangeStoreButtonClick(triggerInfo) {
    if (!store.state.isPDPPage) {
        util.saveScroll();
    }

    progress.show();
    var storeParams = {};

    storeParams.IsTradeOnly = true;
    store.state.selectedProductID = triggerInfo.pid;

    if (store.state.selectedProductID) {
        storeParams.pid = store.state.selectedProductID;
        storeParams.Quantity = triggerInfo.qty || CONSTANTS.SELECTED_QUANTITY;
    }

    if (store.state.isCartPage) {
        storeParams.isCartPage = store.state.isCartPage;
    }
    var d1 = $.Deferred();
    var d2 = $.Deferred();

    API.getJson({
        url: util.appendParamsToUrl(Urls.getStoreSelectDialog, storeParams),
        callback: function (data) {
            d1.resolve( data );
        },
    });

    storeParams.IsTradeOnly = false;

    API.getJson({
        url: util.appendParamsToUrl(Urls.getStoreSelectDialog, storeParams),
        callback: function (data) {
            d2.resolve( data );
        },
    });

    $.when( d1, d2 ).always(function () {
        progress.hide();
    }).done(function ( data, data1) {
        if (data && data.success && data1 && data1.success) {
            var tmpStores = [].concat(data.storeList, data1.storeList);

            for (var obj in tmpStores) {
                tmpStores[obj].storeLocatorView = !store.state.isPDPPage && !store.state.isPLPPage && !store.state.isCartPage;
                tmpStores[obj].buttonStatusView = !store.state.isPDPPage && !store.state.isPLPPage && !store.state.isCartPage || store.state.isCartPage;
                tmpStores[obj].messageStatusView = store.state.isPDPPage || store.state.isPLPPage;
                tmpStores[obj].isSelectDefaltStorePage = store.state.isSelectDefaltStorePage;
            }
            store.state.storeList = tmpStores;

            store.state.csrfData = data.csrf;

            store.state.selectedStoreId = data.selectedStore.id ? String(data.selectedStore.id) : null;

            showDialog({
                html: data.dialogHTML,
                onClose: triggerInfo.onClose,
            });

            var handleDialogActions = new Promise (function initSearch (resolve) {
                setTimeout(function () {
                    var popUpSearchInput = $(JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT);
                    var storeInfoContainer;
                    if (!popUpSearchInput.length) {
                        initSearch();
                    } else {
                        if (triggerInfo.isCurrentLocation) {
                            onSearchByCurrentLocationClick();
                            return;
                        }
                        var pdpSearchInput = $(JSConstants.CLASS_SEARCH_GOOGLE_ADDRESS + ':visible');
                        var eventTarget = triggerInfo.eventTarget;
                        if (eventTarget) {
                            storeInfoContainer = eventTarget.getAttribute('data-store-id') ? eventTarget : eventTarget.closest('[data-store-id]');
                        }
                        var isStoreDetail = storeInfoContainer && storeInfoContainer.classList.contains("js-store-detail");
                        var storeAddressVal = storeInfoContainer && storeInfoContainer.getAttribute('data-store-address').replace(' - ', '%20-%20');

                        var storeData = (pdpSearchInput && pdpSearchInput.val()) || storeAddressVal || null;
                        popUpSearchInput.val(storeData);
                        trigerSearchByStr();
                        resolve ({
                            isStoreDetail: isStoreDetail,
                            storeInfoContainer: storeInfoContainer
                        });
                    }
                }, 500);
            });

            handleDialogActions.then (function handleStoreDetailView (data) {
                setTimeout(function () {
                    if (data.isStoreDetail) {
                        var storeID = data.storeInfoContainer.getAttribute('data-store-id');
                        var storeDetailsButton = $(JSConstants.CLASS_STORE_SELECT).find(`[data-storeid|="${storeID}"]`).find(JSConstants.CLASS_STORE_DETAILS_BUTTON);
                        if (!storeDetailsButton.length) {
                            handleStoreDetailView(data);
                        } else {
                            storeDetailsButton.click();
                        }
                    }

                    if (store.state.isPDPPage || store.state.isPLPPage) {
                        $(JSConstants.CLASS_STORE_LIST).on('mousewheel',
                        _.debounce(onStoresScroll, CONSTANTS.DEBOUNCE_SCROLL)
                        );
                    }
                }, 300);
            });
        }
    });
}

/**
 * Store event listeneres list
 */
function initStoreEvents() {
    store.events.subscribe('showOnlyNearestStore', function(data) {
        showOnlyNearestStore(data);
    });

    store.events.subscribe('renderStoresList', function(data) {
        $(JSConstants.CLASS_STORE_LIST).html('');
        updateStoresList(data);
    });

    store.events.subscribe('restore:storeSelect', function(data) {
        $(JSConstants.CLASS_STORE_SELECT).show();
        store.state.MainMap.setMapZoom(SitePreferences.DEFAULT_MAP_ZOOM);
        if (data.fromName && data.fromName === 'anotherStore') {
            return;
        }
        restoreStoreSelect(data);
    });

    store.events.subscribe('addItemToCart', function() {
        addItemToCart();
    });
}

function restoreStoreSelect(data) {
    data.controlesState.domSelectors.forEach(function(selector) {
        $(selector).append(data.controlesState.dom(selector));
    });

    data.controlesState.valuesSelectors.forEach(function(selector) {
        $(selector).val(data.controlesState.values(selector));
    });
    store.quantity.init();
}

function onEnterKeyButtonClick(e) {
    var key = e.which;
    if (key === 13) {
        trigerSearchByStr(e);
        return false;
    }
}

function trigerSearchByStr(e) {
    if (e && e.preventDefault) {
        e.preventDefault();
    }
    var valueStr = $(JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT).val();
    store.state.controlesState.values(
        JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT,
        valueStr
    );
    store.events.publish('searchByStr', [valueStr]);
}

/**
 * Dom event listeneres list
 */
function initDomEvents() {
    $(document)
        .on('click', JSConstants.CLASS_GO_BACK_TO_SEARCH,
            onGoBackToSearchClick
        ).on('click', JSConstants.CLASS_CHOOSE_ANOTHER_STORE,
            onChooseAnotherStoreClick
        ).on('click', JSConstants.CLASS_SEARCH_BY_CURRENT_LOCATION,
            onSearchByCurrentLocationClick
        ).on('click', JSConstants.CLASS_RESERVE_SELECT_STORE, function (e) {
            e.preventDefault();
            var $this = $(this),
                pid = $this.data('pid');
            store.state.isPDPPage = Boolean(Number(e.target.dataset.currentPdp));
            store.state.isCartPage = Boolean(Number(e.target.dataset.currentCartPage));
            store.state.isPLPPage = Boolean(Number(e.target.dataset.currentPlp));
            store.state.isSelectDefaltStorePage = Boolean(Number(e.target.dataset.currentDefaltStorePage || e.target.parentElement.dataset.currentDefaltStorePage));
            store.state.isStorePreview = Boolean(Number(e.target.dataset.currentStorePreview || (e.target.closest('[data-store-id]') && e.target.closest('[data-store-id]').dataset.currentStorePreview)));

            if (store.state.isPLPPage) {
                PLPForm = $(this.form);
            }

            if (e && e.target.classList.contains('js-pdp-search-by-current-location')) {
                navigator.geolocation.getCurrentPosition(
                    function() {
                        onChangeStoreButtonClick({
                            isCurrentLocation: true,
                            pid: pid,
                            onClose: function () {
                                $('body').removeClass('noscroll');
                                store.history.clear();

                                if (!store.state.isPDPPage) {
                                    util.restoreScroll();
                                }

                                if (sessionStorage.getItem('fullStoreList')) {
                                    sessionStorage.removeItem('fullStoreList');
                                }
                            },
                        });
                    }
                );
            } else {
                onChangeStoreButtonClick({
                    eventTarget: e.target,
                    pid: pid,
                    onClose: function () {
                        $('body').removeClass('noscroll');
                        store.history.clear();
                        if (!store.state.isPDPPage) {
                            util.restoreScroll();
                        }
                        if (sessionStorage.getItem('fullStoreList')) {
                            sessionStorage.removeItem('fullStoreList');
                        }
                    },
                });
            }
        })
        .on('click', JSConstants.CLASS_STORES_SELECT_BTN,
            trigerSearchByStr
        ).on('keypress', JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT,
            onEnterKeyButtonClick
        ).on('click', JSConstants.CLASS_ADD_TO_CART + ', ' + JSConstants.CLASS_SELECT_STORE, function () {
            var $container = $('.pdp-add-to-cart-container');
            if ($container && $container.length) {
                setTimeout(function () {
                    var url = $('.pdp-add-to-cart-container').data('update-url');
                    $.ajax({
                        type: 'GET',
                        url: url,
                    }).done(function(response) {
                        response = typeof response === 'string' ? response.trim() : response;
                        $container.html(response);
                    });
                }, 300);
            }
        }
        ).on('click', JSConstants.CLASS_ADD_TO_CART, function(e) {
                var storeId = $(e.currentTarget).data('storeId');
                addItemToCart(storeId);
            }
        );
}

function addItemToCart(storeId) {
    var $form = $(JSConstants.CLASS_PDP_FROM);
    var $storeInput = $(JSConstants.SELECTOR_PDP_INPUT_STORE_ID);
    var $addToCartBtn = $(`[data-storeid=${storeId}]`).find(JSConstants.CLASS_ADD_TO_CART);
    var isPLP = PLPForm && (PLPForm.length > 0);

    if ($form.length === 0) {
        $form = PLPForm;
    }

    var url = Urls.addProduct;
    var quantityValue = parseInt(store.state.quantityValue);
    if (isNaN(quantityValue)) { quantityValue = 1; }

    var $qtyInput = $($form.find(JSConstants.CLASS_QTY_PDP).first());
    var oldQuantity = Number($qtyInput.val());

    $storeInput.val(storeId);
    $qtyInput.val(quantityValue);
    $addToCartBtn.addClass(DISABLED).addClass('m-added');
    $addToCartBtn.find('.js-add_text').toggleClass('hidden', true);
    $addToCartBtn.find('.js-added_text').toggleClass('hidden', false);

    var formData = $form.serialize();
    if (isNaN(oldQuantity) || isPLP) {
        var formDataObj = new URLSearchParams(formData);

        if (formDataObj.has('Quantity')) {
            formDataObj.delete('Quantity');
        }
        formDataObj.append('Quantity', quantityValue);

        var storeIDParam = formDataObj.get('storeID');
        if (!storeIDParam && isPLP) {
            formDataObj.append('storeID', storeId);
        }

        formData = formDataObj.toString();
    }
    $storeInput.val(null);

    $.ajax({
        type: 'POST',
        url: util.ajaxUrl(url),
        headers: {
            'X-PERIMETER-TYPE': 'json',
        },
        data: formData,
    }).then(function (response) {
        if (response.error) {
            throw new Error(response.error);
        }

        store.state.quantityValue = 0;
        $qtyInput.val(oldQuantity);

        topnotification.showAddToCard(quantityValue || oldQuantity || 0);
        store.dialog.close();

        if (sessionStorage.getItem('fullStoreList')) {
            sessionStorage.removeItem('fullStoreList');
        }

        app.components.get('minicart').show(response);
        window.initMonetateTracking();

        return {
            success: true,
            handled: false,
            html: response,
        };
    })
    .catch(function (response) {
        perimeterx.handleResponce(response);
    });
}

module.exports = {
    init: function (_store) {
        store = _store;
        initDOM();
        initDomEvents();
        initStoreEvents();
        if (pageContext.ns === 'product') {
            initPDPStoreSearch();
        }
    },
    showDialog: function (params) {
        onChangeStoreButtonClick(params);
    },
};
