'use strict';

var quickview = require('_core/quickview');
var dialog = require("_core_ext/dialog");
var JSConstants = require('_core_ext/pages/search/constants');
var moduleParams = {},
    $cache = {};

function initializeCache() {
    $cache.tile = $('.js-tile');
    $cache.tileContainer = $('.js-tile_container');
    $cache.tileImage = $('.js-tile-image');
}

function initQuickViewButtons() {
    $cache.tileImage.on('mouseenter', function () {
        var $qvButton = $('#quickviewbutton');
        if ($qvButton.length === 0) {
            $qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '<i class="fa fa-arrows-alt"></i></a>');
        }
        var $link = $(this).find('.thumb-link');
        $qvButton.attr({
            'href': $link.attr('href'),
            'title': $link.attr('title')
        }).appendTo(this);
        $qvButton.on('click', function (e) {
            e.preventDefault();
            quickview.show({
                url: $(this).attr('href'),
                source: 'quickview'
            });
        });
    });
}

function swatchActions() {
    $('.swatch-list').on('mouseleave', function () {
        // Restore current thumb image
        var $tile = $(this).closest('.js-tile'),
            $thumb = $tile.find('.js-tile-image img').eq(0),
            data = $thumb.data('current');

        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $('.swatch-list .swatch').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('selected')) { return; }

        var $tile = $(this).closest('.js-tile');
        $(this).closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $(this).addClass('selected');
        $tile.find('.thumb-link').attr('href', $(this).attr('href'));
        $tile.find('name-link').attr('href', $(this).attr('href'));

        var data = $(this).children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.js-tile-image img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };
        $thumb.attr(currentAttrs);
        $thumb.data('current', currentAttrs);
    }).on('mouseenter', function () {
        // get current thumb details
        var $tile = $(this).closest('.js-tile'),
            $thumb = $tile.find('.js-tile-image img').eq(0),
            data = $(this).children('img').filter(':first').data('thumb'),
            current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
}

function initBackInStock() {
    $(document).on('click', JSConstants.CLASS_NOTIFY_ME_TILE, function (e) {
        e.preventDefault();

        var $btn = $(e.target);

        $(document).trigger('product.notifyMePopup', [$btn]);

        dialog.open({
            url: $btn.data('href'),
            options: {
                dialogClass: 'm-back_in_stock'
            },
            callback: function () {
                $(JSConstants.CLASS_BACKINSTOCK_CLOSE).one('click', function () {
                    dialog.close();
                });

                const $form = $('form[id="backInStock"]');

                $form.on('submit', function (e) {
                    if (!$form.valid()) {
                        return false;
                    }

                    e.preventDefault();
                    $(document).trigger('product.notifyMeSubmitted', [$btn]);

                    var data = $(this).serialize();

                    $.ajax({
                        type: "POST",
                        url: $(this).attr("action"),
                        data: data,
                    }).done(function (response) {
                        if (response && response.success) {
                            $(JSConstants.CLASS_BACKINSTOCK_EMAIL).text(response.email);
                            $(JSConstants.CLASS_BACKINSTOCK_SUCCESS_MSG).removeClass('hidden');
                            $(JSConstants.CLASS_BACKINSTOCK_CATEGORY_LINK).removeClass('hidden');
                            $(JSConstants.CLASS_BACKINSTOCK_CLOSE).removeClass('hidden');
                            $(JSConstants.CLASS_BACKINSTOCK_FORM).addClass('hidden');
                            $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).addClass('hidden');
                            $(JSConstants.CLASS_BACKINSTOCK_TITLE_DEFAULT).addClass('hidden');
                            $(JSConstants.CLASS_BACKINSTOCK_TITLE_SUCCESS).removeClass('hidden');
                        } else if (response && response.errorMsg) {
                            $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).text(response.errorMsg);
                            $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).removeClass('hidden');
                        } else {
                            $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).text(Resources.SERVER_ERROR);
                            $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).removeClass('hidden');
                        }
                    }).fail(function() {
                        $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).text(Resources.BAD_RESPONSE);
                        $(JSConstants.CLASS_BACKINSTOCK_ERROR_MSG).removeClass('hidden');
                    });
                });
            }
        });
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    if (moduleParams && !!moduleParams.initQuickView) {
        initQuickViewButtons();
    }
    swatchActions();
}

exports.init = function (params) {
    var $tiles = $('.js-tile_container .js-tile');
    moduleParams = params || {};
    if ($tiles.length === 0) {
        return;
    }
    initializeCache();
    initializeEvents();
};

exports.initBackInStock = initBackInStock;
