'use strict';

var perimeterx = require('_core_ext/trackers/perimeterx');
var dialog = require('_core_ext/dialog'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    validator = require('_core_ext/validator'),
    JSConstants = require('_core_ext/constants'),
    CSSConstants = require('_core_ext/cssconstants');

var initialized = false;

function submitLoginForm() {
    var $loginForm = $(JSConstants.CLASS_LOGIN_FORM),
        $loginSubmit = $loginForm.find(JSConstants.CLASS_LOGIN_BUTTON);

    $loginForm.append($('<input />').attr({
        'type' : 'hidden',
        'name' : $loginSubmit.attr('name')
    }));

    $loginForm.submit();
}

function showRedirectPopUp() {
    var $redirectWrapper = $(JSConstants.ID_REDIRECT_B2B);

    if ($redirectWrapper.length) {
        var isB2bCustomer = $redirectWrapper.data('b2b-customer');
        var url = isB2bCustomer ? Urls.tradeOtherSite : Urls.retailOtherSite;

        dialog.open({
            url: url,
            options: {
                closeOnEscape: false,
                dialogClass: 'alert-dialog no-close',
            },
            callback: function () {
                dialog.$container.one('click', JSConstants.CLASS_CLOSE_DIALOG, function() {
                    dialog.close();
                });
            }
        });
    }
}

function showRedirectToTradePopUp() {
    dialog.open({
        url: Urls.tradeOtherSite,
        options: {
            closeOnEscape: false,
            dialogClass: 'ui-dialog_promo',
        },
        callback: function () {
            dialog.$container.one('click', JSConstants.CLASS_CLOSE_DIALOG, function() {
                dialog.close();
            });
        }
    });
}

function showPasswordResetPopUp() {
    var $resetButton = $(JSConstants.CLASS_LOGIN_PASSWORD_RESET),
        resetLink = $resetButton.attr('href');

    dialog.open({
        url: resetLink,
        options: {
            dialogClass: 'reset-password_popup',
            title: Resources.FORGOT_PASSWORD,
            open: function() {
                validator.init();

                var $requestPasswordForm = $('[name$="_requestpassword"]'),
                    $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');

                $($submit).on('click', onSubmitPasswordReset);
            }
        }
    });
}

function onSubmitPasswordReset(e) {
    var $requestPasswordForm = $('[name$="_requestpassword"]'),
        $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');

    if (!$requestPasswordForm.valid()) {
        return;
    }

    e.preventDefault();
    var data = $requestPasswordForm.serialize(),
        url = $requestPasswordForm.attr('action');

    // add form action to data
    data += '&' + $submit.attr('name') + '=';
    // make sure the server knows this is an ajax request
    if (data.indexOf('ajax') === -1) {
        data += '&format=ajax';
    }

    $.ajax({
        type: 'POST',
        url: url,
        data: data,
        success: function(response) {
            if (typeof response === 'object' && !response.success &&
                response.error === 'CSRF Token Mismatch') {
                page.redirect(Urls.csrffailed);

            } else if (typeof response === 'string') {
                dialog.$container.html(response);
            }
        },
        failure: function() {
            dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
        }
    });
}

function initializeDOM() {
    showRedirectPopUp();
}

function initializeEvents() {
    $(document)
    .on('click', JSConstants.CLASS_TRADECARD_CONTINUE_LOGIN, function(e) {
        e.preventDefault();
        dialog.close();
        submitLoginForm();
    })
    .on('click', JSConstants.CLASS_TRADECARD_CLOSE_POPUP, function(e) {
        dialog.close();
        e.preventDefault();
        var $this = $(this),
            link = $this.attr('href'),
            target = $this.attr('target');

        if (target) {
            window.open(link, target);
        } else {
            window.location.href = link;
        }
    })
    .on('click', JSConstants.CLASS_LOGIN_BUTTON, function(e) {
        e.preventDefault();
        var $email = $(this).closest('form').find(JSConstants.CLASS_LOGIN_EMAIL),
            $password = $(this).closest('form').find(JSConstants.CLASS_LOGIN_PASSWORD),
            $loginForm = $(JSConstants.CLASS_LOGIN_FORM);

        if (!$loginForm.valid()) {
            return false;
        }

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: util.appendParamsToUrl(Urls.checkPasswordResetRequired, {
                'username': $email.val()
            })
        }).done(function(response) {
            if (response && response.success) {
                util.setCookie('customerTimezone', Intl.DateTimeFormat().resolvedOptions().timeZone, 1);

                if (response.isPasswordResetRequired) {
                    // Show password reset pop-up
                    dialog.open({
                        html: Resources['PASSWORD_CONFIRMED_MESSAGE' + (response.isOldBEX ? '_MIGR' : '')],
                        options: {
                            dialogClass: ''
                        }
                    });
                } else {
                    $.ajax({
                        type: 'POST',
                        dataType: 'json',
                        data: {
                            'password': $password.val(),
                            'isAllowLogin': true,
                            'isCheckoutLogin': $(JSConstants.CLASS_LOGIN_ISCHECKOUTLOGIN).val() || false,
                            'email': $email.val()
                        },
                        url: Urls.tradeCardCheckLogin
                    }).done(function(response) {
                        if (response.allowLogin) {
                            // Submit login request
                            submitLoginForm();
                            return;
                        }

                        // Show trade-card information
                        dialog.open({
                            html: response.popup,
                            options: {
                                dialogClass: response.cssClass,
                                title: response.title,
                            }
                        });
                    });
                }
            }
        }).fail(function(response) {
            perimeterx.handleResponce(response);
        });
    })
    .on('click', JSConstants.CLASS_LOGIN_PASSWORD_RESET, function(e) {
        e.preventDefault();
        showPasswordResetPopUp();
    })
    .on('click', JSConstants.CLASS_LOGIN_HAVECARD, function() {
        $(this).hide();
        $(JSConstants.CLASS_LOGIN_HAVECARD_BLOCK).show();
        $(JSConstants.BODY).find(JSConstants.CLASS_ACCOUNT_TRADE_CARD_SECTION).hide();
        $(JSConstants.BODY).find(JSConstants.CLASS_ACCOUNT_LOGIN_REGISTER_LINK).hide();
        $(JSConstants.BODY).find(JSConstants.CLASS_ACCOUNT_LOGIN_TITLE).addClass(JSConstants.HIDDEN);
        $(JSConstants.BODY).find(JSConstants.CLASS_ACCOUNT_LOGIN_TITLE).next().removeClass(JSConstants.HIDDEN);
        $(JSConstants.BODY).find(JSConstants.CLASS_LOGIN_NOCARD).addClass(JSConstants.CLASS_TOGGLE_ORDER);
    })
    .on('click', JSConstants.CLASS_LOGIN_EXISTING, function(e) {
        e.preventDefault();
        $(JSConstants.CLASS_LOGIN_REGISTERED_FORM).show();
        $(JSConstants.CLASS_LOGIN_NEW_FORM).hide();
    })
    .on('click', JSConstants.CLASS_REDIRECT_TRADE, function(e) {
        e.preventDefault();
        showRedirectToTradePopUp();
    })
    .on('click', JSConstants.CLASS_LOGIN_EXISTING_BACK, function(e) {
        e.preventDefault();
        $(JSConstants.CLASS_LOGIN_REGISTERED_FORM).hide();
        $(JSConstants.CLASS_LOGIN_NEW_FORM).show();
    })
    .on('submit', JSConstants.CLASS_HAVECARD_FORM, function(e) {
        e.preventDefault();
        var $this = $(this);
        $(JSConstants.CLASS_LOGIN_TRADECARD_INPUT).closest('.js-formfield').find('.' + CSSConstants.ERROR).remove();

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: $this.attr('action'),
            data: $this.serialize() + '&' + $this.find('button[type=submit]').attr('name') + '=true'
        }).done(function(response) {
            if (response && response.success && response.customerID) {
                var url = Urls.registerProfileTradeCart;
                util.setCookie('customerUUID', JSON.stringify(response.customerID), 10);
                page.redirect(url);
            } else {
                if (response && response.errorMessage) {
                    $(JSConstants.CLASS_LOGIN_TRADECARD_INPUT).closest('.js-formfield').before('<div class="error form-row_error_msg">' + response.errorMessage + '</div>');
                }
            }
        }).fail(function(response) {
            perimeterx.handleResponce(response);
        });
    });

    //toggle the value of the rememberme checkbox
    $(JSConstants.CLASS_REMEMBERME_CHECKBOX).bind('change', function() {
        var $rememberMe = $(JSConstants.CLASS_REMEMBERME);
        if ($(this).prop('checked')) {
            $rememberMe.val('true');
        } else {
            $rememberMe.val('false');
        }
    });
}

module.exports = {
    init: function() {
        if (initialized) {
            return;
        }
        initializeDOM();
        initializeEvents();
        initialized = true;
    }
};
