'use strict';

var perimeterx = require('_core_ext/trackers/perimeterx');
var image = require('_core_ext/pages/product/image'),
    progress = require('_core_ext/progress'),
    tooltip = require('_core/tooltip'),
    util = require('_core_ext/util'),
    JSConstants = require('_core_ext/pages/product/constants'),
    CSSConstants = require('_core_ext/cssconstants'),
    layout = require('_core_ext/layout'),
    quantity = require('_core_ext/pages/product/quantity'),
    formPrepare = require('_core_ext/pages/checkout/formPrepare');
var $cache = {};
/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href, isVenue, isVariantUpdated) {
    var $prevPdpForm = $cache.pdpContent.find(JSConstants.CLASS_PDP_FORM);
    var $prevQty = $prevPdpForm.find('input[name="Quantity"]').first();
    var qty = $prevQty.val();
    var isVenueChanges = isVenue || false;
    var params = {
        Quantity: isNaN(qty) ? '1' : qty,
        format: 'ajax',
        productlistid: $prevPdpForm.find('input[name="productlistid"]').first().val()
    };

    var $kitRequiredContent = $(JSConstants.CLASS_KITREQUIRED),
        $delegateInfoContent = $(JSConstants.CLASS_DELEGATE_INFO);

    progress.show($cache.pdpContent);

    var getVisibility = function() {
        var $variations = $(this).find(JSConstants.CLASS_PRODUCT_VARIATIONS);
        return $variations.data('hidden');
    };

    var PDPVisibility = $cache.pdpContent.map(getVisibility).get();
    var KitRequiredVisibility = $(JSConstants.CLASS_REQUIRED_PRODUCT).map(getVisibility).get();

    $.ajax({
        type: 'GET',
        url: util.appendParamsToUrl(href, params),
        dataType: 'html',
        headers: {
            'X-PERIMETER-TYPE': 'json',
        },
        xhrFields: {
            withCredentials: true
        }
    }).done(function (response) {
        var updatedHtml = response;
        var $dateBlock;
        var $avaiability;
        var $response = $(updatedHtml);

        $cache.pdpContent.empty().html(updatedHtml);
        $dateBlock = $response.find(JSConstants.CLASS_DATE_BLOCK);
        var $pdpForm = $cache.pdpContent.find(JSConstants.CLASS_PDP_FORM);
        var $qtyInput = $pdpForm.find('input[name="Quantity"]').first();

        if (isVenueChanges && $dateBlock.length) {
            $avaiability = $response.find(JSConstants.CLASS_AVAILABILITY);
            if ($avaiability.length) {
                $avaiability.hide();
            }
            $dateBlock.hide();
        } else if ($dateBlock.length) {
            $dateBlock.show();
        }

        tooltip.init();
        image.initCarousels();

        $kitRequiredContent.length && $(JSConstants.CLASS_KITREQUIRED).replaceWith($kitRequiredContent);
        $delegateInfoContent.length && $(JSConstants.CLASS_DELEGATE_INFO).replaceWith($delegateInfoContent);

        $cache.pdpContent.each(function(index) {
            var $variations = $(this).find(JSConstants.CLASS_PRODUCT_VARIATIONS);
            var hideVariation = (PDPVisibility.length && index in PDPVisibility) ? PDPVisibility[index] : true;
            hideColours($variations, Resources.COLOURS_QTY_DESKTOP, Resources.COLOURS_QTY_TABLET, Resources.COLOURS_QTY_DELTA, hideVariation);
        });

        $(JSConstants.CLASS_REQUIRED_PRODUCT).each(function(index) {
            var $variations = $(this).find(JSConstants.CLASS_PRODUCT_VARIATIONS);
            var hideVariation = (KitRequiredVisibility.length && index in KitRequiredVisibility) ? KitRequiredVisibility[index] : true;
            hideColours($variations,  Resources.COLOURS_QTY_KIT_DESKTOP, Resources.COLOURS_QTY_KIT_TABLET, Resources.COLOURS_QTY_KIT_DELTA, hideVariation);
        });

        quantity.handleQtyChange($qtyInput);
        quantity.availabilityQuantity(true);
        image.updatePinButton();

        if (isVenueChanges) {
            var $firstDate = $dateBlock.find(JSConstants.CLASS_VARIATION_LINK).first();

            if ($dateBlock.length && $firstDate.length && !$firstDate.closest(JSConstants.CLASS_SWATCH).hasClass(CSSConstants.SELECTED)) {
                updateContent($firstDate.attr('href'));
            }
        }

        if ($dateBlock.length) {
            handleActualDates($dateBlock);
        }

        if (!$response.find(JSConstants.CLASS_ADD_TO_CART).hasClass(JSConstants.CLASS_ADD_TO_CART_DISABLED)) {
            formPrepare.init({
                formSelector: JSConstants.CLASS_PDP_FORM,
                continueSelector: JSConstants.CLASS_ADD_TO_CART
            });
        }

        setTimeout(function() {
            var pageContextType = window.pageContext.type;

            if (pageContextType !== 'Wishlist' && pageContextType !== 'Cart') {
                util.scrollBrowserTo($(JSConstants.CLASS_PRODUCT_VARIATIONS), 500, true);
            }
        }, 0);

        $(document).trigger('pdp.updated', [$response]);

        if (isVariantUpdated && SitePreferences.MONETATE_ENABLED) {
            window.initMonetateTracking(true);
        }

    }).fail(function(response) {
        perimeterx.handleResponce(response);
    });
};

// Filter actual trainings by date
function handleActualDates($dateContainer) {
    var $dateBlock = $dateContainer || $(JSConstants.CLASS_PDP_FORM_WRAPPER).find(JSConstants.CLASS_DATE_BLOCK);

    if ($dateBlock && $dateBlock.length) {
        $dateBlock.find(JSConstants.CLASS_SWATCH).each(function() {
            var $this = $(this),
                startDate = $this.data('start'),
                nowDate = util.getDayTimestamp(new Date());

            if (!$this.hasClass(CSSConstants.SELECTED) && (!startDate || (startDate < nowDate))) {
                $this.addClass(CSSConstants.HIDDEN);
            }
        });
    }
}

function hideColours($variations, desktopQuantity, tabletQuantity, delta, hidden) {
    var $colourSwatches = $variations.find(JSConstants.CLASS_COLOUR_SWATCH);
    var $showMore = $variations.find(JSConstants.CLASS_SWATCHES_SHOW_MORE);
    var count = layout.isDesktop() ? +desktopQuantity : +tabletQuantity;
    var itemsToShow = count - delta;
    var itemsToHide = $colourSwatches.length - itemsToShow;

    if ($colourSwatches.length > count) {
        $colourSwatches
            .removeClass(JSConstants.CLASS_MORE_SHOWN.substring(1))
            .show()
            .slice(itemsToShow)
            .addClass(JSConstants.CLASS_MORE_SHOWN.substring(1))
            .hide();

        var showMoreMessageWithWrongNumbers = $showMore.data('message');
        // replace all numbers with proper values
        $showMore.data('message', showMoreMessageWithWrongNumbers.replace(/\d+/g, itemsToHide));

        $showMore
            .addClass(JSConstants.CLASS_MORE_COLOURS)
            .text($showMore.data('message'));

    } else {
        $colourSwatches
            .removeClass(JSConstants.CLASS_MORE_SHOWN.substring(1))
            .show();

        $showMore
            .removeClass(JSConstants.CLASS_MORE_COLOURS)
            .text('')
            .addClass('hidden');
    }

    if (!hidden) {
        showMore($variations);
        $variations.data('hidden', false);
    } else {
        $variations.data('hidden', true);
    }
}

function initializeColoursEvents(container) {
    $(container)
    .on('click', JSConstants.CLASS_VARIATION_DROPDOWN, function() {
        $(this).find(JSConstants.CLASS_VARIATION_DROPDOWN_CONTAINER).toggleClass('open');
    })
    .on('click', JSConstants.CLASS_SWATCHES_SHOW_MORE, function() {
        var $variations = $(this).closest(JSConstants.CLASS_PRODUCT_VARIATIONS);

        if ($variations.data('hidden')) {
            showMore($variations);
            $variations.data('hidden', false);
        } else {
            hideMore($variations);
            $variations.data('hidden', true);
        }
    });

    $(JSConstants.CLASS_BODY).on('click', function(event) {
        var $target = $(event.target);
        if (!$target.hasClass(JSConstants.CLASS_VARIATION_DROPDOWN_OPEN) && !$target.closest(JSConstants.CLASS_VARIATION_DROPDOWN).length) {
            $(JSConstants.CLASS_VARIATION_DROPDOWN_CONTAINER + '.open').removeClass('open');
        }
    });

}

function showMore($variations) {
    $variations
    .find(JSConstants.CLASS_MORE_SHOWN)
    .show();

    $variations
    .find(JSConstants.CLASS_SWATCHES_SHOW_MORE)
    .toggleClass(JSConstants.CLASS_MORE_COLOURS)
    .text(Resources.COLOURS_SHOW_FEWER);
}

function hideMore($variations) {
    var $more = $variations.find(JSConstants.CLASS_SWATCHES_SHOW_MORE);

    $variations
    .find(JSConstants.CLASS_MORE_SHOWN)
    .hide();

    $more
    .toggleClass(JSConstants.CLASS_MORE_COLOURS)
    .text($more.data('message'));
}

function showFullDesc (show) {
    var $wrapper = $(JSConstants.CLASS_PRODUCT_WRAPPER);

    if (show) {
        $wrapper.addClass(CSSConstants.OPEN);
    } else {
        $wrapper.removeClass(CSSConstants.OPEN);
    }

    updateDescState();
}

function updateDescState() {
    var $readMore = $(JSConstants.CLASS_PRODUCT_READMORE),
        $readLess = $(JSConstants.CLASS_PRODUCT_READLESS),
        $fullContainer = $(JSConstants.CLASS_PRODUCT_FULLDESC),
        $shortConstainer = $(JSConstants.CLASS_PRODUCT_SHORTDESC),
        open = $(JSConstants.CLASS_PRODUCT_WRAPPER).hasClass(CSSConstants.OPEN);

    if ($fullContainer.length && $shortConstainer.length) {
        if (open) {
            $fullContainer.removeClass(CSSConstants.HIDDEN);
            $shortConstainer.addClass(CSSConstants.HIDDEN);
            $readLess.removeClass(CSSConstants.HIDDEN);
            $readMore.addClass(CSSConstants.HIDDEN);
        } else {
            $fullContainer.addClass(CSSConstants.HIDDEN);
            $shortConstainer.removeClass(CSSConstants.HIDDEN);
            $readLess.addClass(CSSConstants.HIDDEN);
            $readMore.removeClass(CSSConstants.HIDDEN);
        }
    }
}

function initializeKitRequired() {
    $cache.pdpContent
        .on('click', JSConstants.CLASS_REQUIRED_SHOW_OPTIONS, function() {
            var $this = $(this);

            if (!$this.hasClass(JSConstants.CLASS_SHOWN_OPTIONS)) {
                $this.parents(JSConstants.CLASS_REQUIRED_PRODUCT).find(JSConstants.CLASS_REQUIRED_VARIATIONS).show();
                $this.toggleClass(JSConstants.CLASS_SHOWN_OPTIONS).text(Resources.HIDE_OPTIONS);
            } else {
                $this.parents(JSConstants.CLASS_REQUIRED_PRODUCT).find(JSConstants.CLASS_REQUIRED_VARIATIONS).hide();
                $this.toggleClass(JSConstants.CLASS_SHOWN_OPTIONS).text(Resources.SHOW_OPTIONS);
            }
        })
        .on('click', JSConstants.CLASS_REQUIRED_VARIATION_BLOCK + ' ' + JSConstants.CLASS_VARIATION_DROPDOWN_VARIANT, function () {
            var $this = $(this);
            if ($this.hasClass(CSSConstants.SELECTABLE)) {
                updateKitRequiredVariant(this, $this.data('href'));
            }
        })
        .on('click', JSConstants.CLASS_REQUIRED_VARIATION_BLOCK + ' ' + JSConstants.CLASS_VARIATION_LINK, function(e) {
            e.preventDefault();
            var $this = $(this),
                $productLine = $this.closest(JSConstants.CLASS_REQUIRED_PRODUCT),
                $parentElement = $this.parent(JSConstants.CLASS_SWATCH);

            if ($parentElement.hasClass(CSSConstants.SELECTABLE)) {
                updateKitRequiredVariant(this, this.href);
                if ($parentElement.hasClass(CSSConstants.SELECTED)) {
                    $productLine.find(JSConstants.CLASS_REQUIRED_PRODUCT_CHECKBOX).prop('checked', false);
                }
            }
        })
        .on('change', JSConstants.CLASS_REQUIRED_VARIATION_BLOCK + ' ' + JSConstants.CLASS_VARIATION_SELECT, function () {
            var $this = $(this);
            if ($this.val().length === 0) {
                return;
            }
            updateKitRequiredVariant(this, $this.val());
        })
        .on('click', JSConstants.CLASS_SELECT_ALL_KITREQUIRED, function() {
            $(JSConstants.CLASS_REQUIRED_PRODUCT_CHECKBOX).each(function() {
                var $this = $(this),
                    $productLine = $this.closest(JSConstants.CLASS_REQUIRED_PRODUCT),
                    multipleProductType = $productLine.find(JSConstants.CLASS_REQUIRED_SHOW_OPTIONS).length > 0;
                if (multipleProductType) {
                    $productLine.find(JSConstants.CLASS_REQUIRED_SHOW_OPTIONS).click();
                } else {
                    $this.prop('checked', true);
                }
            });
            calculateKitRequired();
        })
        .on('click', JSConstants.CLASS_REQUIRED_PRODUCT_CHECKBOX, function() {
            var $this = $(this),
                $productLine = $this.closest(JSConstants.CLASS_REQUIRED_PRODUCT),
                multipleProductType = $productLine.find(JSConstants.CLASS_REQUIRED_SHOW_OPTIONS).length > 0;
            if (multipleProductType) {
                if (!$this.prop('checked')) {
                    var $selectedSwatch = $productLine.find(JSConstants.CLASS_SWATCH + '.' + CSSConstants.SELECTED + ' a');
                    if ($selectedSwatch.length > 0) {
                        $selectedSwatch.click();
                    } else {
                        var $variationsDropdown = $productLine.find(JSConstants.CLASS_VARIATION_SELECT);
                        if ($variationsDropdown.length > 0) {
                            $variationsDropdown.find('option:first').prop('selected', true);
                            $variationsDropdown.trigger('change');
                        }
                    }
                } else {
                    $productLine.find(JSConstants.CLASS_REQUIRED_SHOW_OPTIONS).click();
                }
                return false;
            } else {
                calculateKitRequired();
            }
        });
}

function updateKitRequiredVariant(swatch, url) {
    var $this = $(swatch);
    url = util.removeParamFromURL(url, 'source');
    url = util.appendParamsToUrl(url, {
        'source': 'kitrequired',
        'format': 'ajax',
        'productType': $this.closest(JSConstants.CLASS_REQUIRED_PRODUCT).data('producttype'),
        'kitHolderID' : $('#pid').val()
    });
    $.ajax({
        url: url,
        success: function (response) {
            var $requiredProduct = $this.closest(JSConstants.CLASS_REQUIRED_PRODUCT);
            var $oldVariations = $requiredProduct.find(JSConstants.CLASS_PRODUCT_VARIATIONS);
            var isHidden = $oldVariations.data('hidden');
            $requiredProduct.empty().html(response);
            hideColours($requiredProduct.find(JSConstants.CLASS_PRODUCT_VARIATIONS), Resources.COLOURS_QTY_KIT_DESKTOP, Resources.COLOURS_QTY_KIT_TABLET, Resources.COLOURS_QTY_KIT_DELTA, isHidden);
            calculateKitRequired();
        }
    });
}

function calculateKitRequired() {
    var total = 0,
        $price = $(JSConstants.CLASS_KITREQUIRED_TOTAL_PRICE);
     $(JSConstants.CLASS_REQUIRED_PRODUCT_CHECKBOX + ':checked').each(function() {
        var price = $(this).data('price');
        if (!isNaN(price)) {
            total += (+price);
        }
    });

    if (total > 0) {
        $price.show();
        var totalPrice = +(new Array(Math.max(2 - String(total).length + 1, 0)).join(0)) + total;
            totalPrice = isNaN(totalPrice) ? totalPrice : totalPrice.toFixed(2);

        $(JSConstants.CLASS_KITREQUIRED_TOTAL_PRICE_VALUE).text(Constants.CURRENCY_SYMBOL + totalPrice);
    } else {
        $price.hide();
    }
}

function initializeTraining() {
    var $venueBlock = $('.' + JSConstants.CLASS_VENUE_BLOCK),
        $firstOption = $venueBlock.find('option').first();
    if ($venueBlock.length && $firstOption.length && !$(JSConstants.CLASS_VENUE_LABEL).length) {
        updateContent($firstOption.val(), true);
    }
}

function resetTraining() {
    var $venueBlock = $('.' + JSConstants.CLASS_VENUE_BLOCK),
        $firstOption = $venueBlock.find('option').first();
    if ($venueBlock.length && $firstOption.length) {
        updateContent($firstOption.val(), true);
    }
}

function initializeCache () {
    $cache.document = $(document);
    $cache.pdpContent = $cache.document.find(JSConstants.CLASS_PDP_CONTENT);
}

function initializeEvents() {
    // click on swatch - should replace product content with new variant
    $cache.pdpContent
        .on('click', JSConstants.CLASS_MAIN_VARIATIONS + ' ' + JSConstants.CLASS_VARIATION_LINK, function (e) {
            e.preventDefault();
            var $swatch = $(this).parents(JSConstants.CLASS_SWATCH);

            if ($swatch.hasClass(CSSConstants.SELECTED)) {
                return;
            }

            if (!$cache.pdpContent.data('is-backinstock-enabled') && $swatch.hasClass(CSSConstants.UNSELECTABLE)) {
                return;
            }

            updateContent(this.href, false, true);
        })
        .on('click', JSConstants.CLASS_MAIN_VARIATIONS + ' ' + JSConstants.CLASS_VARIATION_DROPDOWN_VARIANT, function() {
            var $this = $(this);

            if ($this.hasClass(CSSConstants.SELECTABLE) && !$this.hasClass(CSSConstants.SELECTED)) {
                updateContent($this.data('href'), false, true);
            }
        })
        .on('click', JSConstants.CLASS_PRODUCT_READMORE, function (e) {
            e.preventDefault();
            showFullDesc(true);
        })
        .on('click', JSConstants.CLASS_PRODUCT_READLESS, function (e) {
            e.preventDefault();
            showFullDesc(false);
        });

    // change drop down variation attribute - should replace product content with new variant
    $cache.pdpContent.on('change', JSConstants.CLASS_MAIN_VARIATIONS + ' ' + JSConstants.CLASS_VARIATION_SELECT, function () {
        var $this = $(this),
            variantLink = $this.val();

        if (variantLink.length === 0) {
            return;
        }

        updateContent(variantLink, $this.hasClass(JSConstants.CLASS_VENUE_BLOCK), true);
    });

    $(document).on('clickCollect:SelectedStore', function(event, config) {
        if (config.isUpdate && config.product) {
            var productURL = util.appendParamsToUrl(Urls.getProductVariationHTML, { pid: config.product });
            updateContent(productURL);
        }
    });

    initializeColoursEvents($cache.pdpContent);
}

var variant = {
    init: function() {
        initializeCache();
        initializeEvents();
        initializeTraining();
        initializeKitRequired();
        updateDescState();
        handleActualDates();

        $cache.pdpContent.each(function() {
            var $variations = $(this).find(JSConstants.CLASS_PRODUCT_VARIATIONS);
            hideColours($variations, Resources.COLOURS_QTY_DESKTOP, Resources.COLOURS_QTY_TABLET, Resources.COLOURS_QTY_DELTA, true);
        });

        $(JSConstants.CLASS_REQUIRED_PRODUCT).each(function(index) {
            var $variations = $(this).find(JSConstants.CLASS_PRODUCT_VARIATIONS),
                KitRequiredVisibility = $variations.data('hidden') || [];

            hideColours($variations,  Resources.COLOURS_QTY_KIT_DESKTOP, Resources.COLOURS_QTY_KIT_TABLET, Resources.COLOURS_QTY_KIT_DELTA, KitRequiredVisibility.length ? KitRequiredVisibility[index] : true);
        });
    },
    hideColours: hideColours,
    initializeColoursEvents: initializeColoursEvents,
    resetTraining: resetTraining,
    updateContent: updateContent
};

module.exports = variant;
