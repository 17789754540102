'use strict';
// jQuery extensions

var validator = require('_core_ext/validator');

var defaultPhoneRegEx = new RegExp(Resources.REGEXP_PHONE);

var regex = $.extend(true, validator.regex, {
        'email': new RegExp(Resources.REGEXP_EMAIL),
        'mobile': new RegExp(Resources.REGEXP_MOBILE),
        'password': new RegExp(Resources.REGEXP_PASSWORD),
        'emoji': new RegExp(Resources.REGEXP_EMOJI),
        'streetNumberSuffix': new RegExp(Resources.REGEXP_STREET_NUMBER_SUFFIX),
        'siret': new RegExp(Resources.REGEXP_SIRET),
        'kvk': new RegExp(Resources.REGEXP_KVK),
        'enterprisenumber': new RegExp(Resources.REGEXP_ENTERPRISENUMBER),
        'regular': SitePreferences.IS_PROFESSIONAL ? new RegExp(Resources.REGEXP_REGULAR_PRODUO) : new RegExp(Resources.REGEXP_REGULAR_UK),
        'digits': new RegExp(Resources.REGEXP_DIGITS),
        'postalCode': {
            'uk': new RegExp(Resources.UK_POSTAL_CODE_REG_EXP),
            'fr': new RegExp(Resources.FR_POSTAL_CODE_REG_EXP),
            'de': new RegExp(Resources.DE_POSTAL_CODE_REG_EXP),
            'nl': new RegExp(Resources.NL_POSTAL_CODE_REG_EXP),
            'bfpo': new RegExp(Resources.BFPO_NUMBER_REG_EXP),
            'be': new RegExp(Resources.BE_POSTAL_CODE_REG_EXP)
        },
        'postal': {
            'gb': new RegExp(Resources.UK_POSTAL_CODE_REG_EXP),
            'ie': new RegExp(Resources.IE_POSTAL_CODE_REG_EXP),
            'fr': new RegExp(Resources.FR_POSTAL_CODE_REG_EXP),
            'de': new RegExp(Resources.DE_POSTAL_CODE_REG_EXP),
            'nl': new RegExp(Resources.NL_POSTAL_CODE_REG_EXP),
            'be': new RegExp(Resources.BE_POSTAL_CODE_REG_EXP),
            'united kingdom': new RegExp(Resources.UK_POSTAL_CODE_REG_EXP),
            'republic of ireland': new RegExp(Resources.IE_POSTAL_CODE_REG_EXP),
            'france': new RegExp(Resources.FR_POSTAL_CODE_REG_EXP),
            'germany': new RegExp(Resources.DE_POSTAL_CODE_REG_EXP),
            'netherland': new RegExp(Resources.NL_POSTAL_CODE_REG_EXP),
            'belgium': new RegExp(Resources.BE_POSTAL_CODE_REG_EXP)
        },
        'phone': {
            'en_GB': defaultPhoneRegEx,
            'en_IE': defaultPhoneRegEx,
            'fr_FR': new RegExp(Resources.REGEXP_PHONE_FR),
            'de_DE': new RegExp(Resources.REGEXP_PHONE_DE),
            'nl_NL': new RegExp(Resources.REGEXP_PHONE_NL),
            'nl_BE': new RegExp(Resources.REGEXP_PHONE_BE),
            'fr_BE': new RegExp(Resources.REGEXP_PHONE_BE)
        },
        'username': new RegExp(Resources.REGEXP_USERNAME),
        'telephone': {
            'mobile': {
                'fr': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_FR),
                'at': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_AT),
                'be': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_BE),
                'bg': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_BG),
                'hr': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_HR),
                'cy': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_CY),
                'cz': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_CZ),
                'dk': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_DK),
                'ee': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_EE),
                'fi': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_FI),
                'de': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_DE),
                'gr': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_GR),
                'hu': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_HU),
                'ie': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_IE),
                'it': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_IT),
                'lv': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_LV),
                'lt': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_LT),
                'lu': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_LU),
                'mt': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_MT),
                'nl': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_NL),
                'pl': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_PL),
                'pt': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_PT),
                'ro': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_RO),
                'sk': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_SK),
                'si': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_SI),
                'es': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_ES),
                'we': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_WE),
                'gb': new RegExp(Resources.REGEXP_MOBILE_TELEPHONE_GB),
            },
            'landing': {
                'fr': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_FR),
                'at': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_AT),
                'be': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_BE),
                'bg': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_BG),
                'hr': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_HR),
                'cy': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_CY),
                'cz': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_CZ),
                'dk': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_DK),
                'ee': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_EE),
                'fi': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_FI),
                'de': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_DE),
                'gr': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_GR),
                'hu': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_HU),
                'ie': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_IE),
                'it': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_IT),
                'lv': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_LV),
                'lt': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_LT),
                'lu': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_LU),
                'mt': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_MT),
                'nl': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_NL),
                'pl': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_PL),
                'pt': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_PT),
                'ro': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_RO),
                'sk': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_SK),
                'si': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_SI),
                'es': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_ES),
                'we': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_WE),
                'gb': new RegExp(Resources.REGEXP_LANDING_TELEPHONE_GB),
            }
        },
    }),
    settings = validator.settings,
    CONSTANTS = {
        MOBILE_PREFIX_SELECTOR: '.js-mobile-phone-prefix',
        COMBO_LENGTH_PREFIX_SELECTOR: '.js-validate-combolength-prefix',
        COMBO_LENGTH_SELECTOR: '.js-validate-combolength',
        MOBILE_PHONE_SELECTOR: '.js-mobile-phone-validation',
        LANDING_PREFIX_SELECTOR: '.js-fixed-line-phone-prefix',
        LANDING_PHONE_SELECTOR: '.js-fixed-line-phone-validation',
        FORM_ROW: '.js-form_row'
    };

var validatePhone = function (value, el) {
    var phoneRegex,
        locale = SitePreferences.LOCALE;

    if (locale.length === 0 || !regex.phone[locale]) {
        if (!$(el).hasClass('force-simple-phone-validate')) {
            return true;
        } else {
            phoneRegex = defaultPhoneRegEx;
        }
    }

    var rgx = phoneRegex ? phoneRegex : regex.phone[locale];
    var isOptional = this.optional(el);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
};

function validateEmail(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.email.test($.trim(value));
    var $confirmationField = $('input.js-emailconfirm_field');
    if ($confirmationField && $confirmationField.length) {
        var $form = $(element).closest('form');
        var confirmValue = $confirmationField.val();
        if ($form && $form.length && confirmValue.length) {
            var validator = $form.validate();
            var isConfirmValid = value == confirmValue;
            isConfirmValid ? clearError($confirmationField) : validator.showErrors({
                [$confirmationField.attr('name')] : Resources.VALIDATE_EMAIL_NOTMATCH
            });
        }
    }
    return isOptional || isValid;
}

function getPostalRegExp(element) {
    var regExpValue = '';
    for (var name in regex.postalCode) {
        if ($(element).hasClass(name)) {
            return regex.postalCode[name];
        }
    }
    return regExpValue;
}

function validatePostalCode(value, element) {
    var isOptional = this.optional(element);
    var regExpPostalCode = getPostalRegExp(element);
    if (regExpPostalCode === '' || isOptional) {
        return true;
    }
    var isValid = regExpPostalCode.test($.trim(value));
    return isOptional || isValid;
}

function validatePostalByCountry(value, element) {
    var country = $(element).closest('form').find('.country');
    if (country.length === 0 || country.val().length === 0 || !regex.postal[country.val().toLowerCase()]) {
        return true;
    }

    var rgx = regex.postal[country.val().toLowerCase()];
    var isOptional = this.optional(element);
    var isValid = rgx.test($.trim(value));

    return isOptional || isValid;
}

function definePostalError (value, element) {
    var country = $(element).closest('form').find('.country');
    var addressCountry = country.attr('addressCountry');
    return addressCountry ? Resources['VALIDATE_POSTALCODE_' + country.attr('addressCountry')] : Resources.VALIDATE_POSTALCODE;
}

function validatePassword(value, element) {
    var isOptional = this.optional(element);
    var isValid = regex.password.test($.trim(value));
    var $confirmationField = $('input.js-passwordconfirm_field');

    if ($confirmationField && $confirmationField.length) {
        var $form = $(element).closest('form');
        var confirmValue = $confirmationField.val();

        if ($form && $form.length && confirmValue.length) {
            var validator = $form.validate();
            var isConfirmValid = validator.check($confirmationField);

            isConfirmValid && clearError($confirmationField);
        }
    }

    return isOptional || isValid;
}

function validateEmoji(value, element) {
    var isOptional = this.optional(element), isValid;
    var str = $.trim(value);

    if (str.length === 0) {
        isValid = true;
    } else {
        isValid = str.match(regex.emoji) ? false : true;
    }
    return isOptional || isValid;
}

function validateMobile(value, element) {
    value = $.trim(value);
    var isOptional = this.optional(element);
    var isValid = regex.mobile.test(value);
    return isOptional || isValid;
}

/*
 * validate the first name and the last name fields in the address form
 * returns true if the user input string satisfies the regular expression
 */
function validateUserName(value, element) {
    value = $.trim(value);
    return regex.username.test(value);
}

/*
 * returns true if the user input string satisfies the regular expression
 */
function validateRegular(value, element) {
    value = $.trim(value);
    return regex.regular.test(value);
}

function validateDigits(value, element) {
    return regex.digits.test(value);
}

function validateStreetNumberSuffix(value, element) {
    return regex.streetNumberSuffix.test(value);
}

/*
 * validate maximum length of input field
 */
function validateMaxLength(value, element) {
    if (element !== null && value) {
        return value.length <= element.dataset.maxLength;
    }
    return true;
}

/*
 * validate minimum length of input field
 */
function validateMinLength(value, element) {
    if (element !== null && value) {
        return value.length >= element.dataset.minLength;
    }
    return true;
}


/*
 * validate siret number of input field
 */
function validateSiretNumber(value, element) {
    if (element !== null && value && value.length > 0) {
        return regex.siret.test($.trim(value));
    }
    return true;
}

/*
 * validate siret number of input field
 */
function validateKvkNumber(value, element) {
    if (element !== null && value && value.length > 0) {
        return regex.kvk.test(value);
    }
    return true;
}

/*
 * validate enterpriseNumber of input field
 */
function validateEnterpriseNumber(value, element) {
    if (element !== null && value && value.length > 0) {
        return regex.enterprisenumber.test(value);
    }
    return true;
}


/*
 * validate mobile telephone number
 */
function validateMobilePhone(value, element) {
    var isValid = true,
        $input = $(element);

    var countryCode = $input.data('code') || getCountryPrefixSelector($input, CONSTANTS.MOBILE_PREFIX_SELECTOR);

    if (countryCode) {
        var regularExpression = regex.telephone.mobile[countryCode];
        isValid = regularExpression ? regularExpression.test($.trim(value)) : isValid;
    }

    var isOptional = this.optional(element);
    return isOptional || isValid;
}

/*
 * validate combo length
 */
function validateComboLength(value, element) {
    var $input = $(element),
        $prefix = getComboRelatedInput($input, CONSTANTS.COMBO_LENGTH_PREFIX_SELECTOR, true);

    return ($input.val().length + $prefix.val().length + 1) <= $input.data('maxcombo-length');
}

/*
 * validate fixed line telephone number
 */
function validateFixedLinePhone(value, element) {
    var isValid = true,
        $input = $(element);

    var countryCode = $input.data('code') || getCountryPrefixSelector($input, CONSTANTS.LANDING_PREFIX_SELECTOR);

    if (value && countryCode) {
        var regularExpression = regex.telephone.landing[countryCode];
        isValid = regularExpression ? regularExpression.test($.trim(value)) : isValid;
    }

    return isValid;
}

function getCountryPrefixSelector($input, selectorClass) {
    var selectorCode;

    if ($input && selectorClass && $input.length) {
        var $selectorRow = $input.parents(CONSTANTS.FORM_ROW).prev(selectorClass);

        if ($selectorRow && $selectorRow.length) {
            var $selector = $selectorRow.find('select');
            selectorCode = getSelectorCountry($selector);
        }
    }
    return selectorCode;
}

function getPrefixRelatedInput($selector, inputClass, isPrev) {
    var $input;

    if ($selector && inputClass && $selector.length) {
        var $inputRow;

        if (isPrev) {
            $inputRow = $selector.parents(CONSTANTS.FORM_ROW).prev(CONSTANTS.FORM_ROW);
        } else  {
            $inputRow = $selector.parents(CONSTANTS.FORM_ROW).next(CONSTANTS.FORM_ROW);
        }

        if ($inputRow && $inputRow.length) {
            $input = $inputRow.find('input' + inputClass);
        }
    }
    return $input;
}

function getComboRelatedInput($selector, inputClass, isPrev) {
    var $input;

    if ($selector && inputClass && $selector.length) {
        var $form = $selector.closest('form'),
            comboType = $selector.data('combotype'),
            selector = 'input' + inputClass + '[data-combotype=\'' + comboType + '\']';

        $input = $form.find(selector);
    }
    return $input;
}

function getSelectorCountry($selector) {
    var countryCode = '';

    if ($selector && $selector.length) {
        var value = $selector.val();

        if (value && value.length) {
            var contryInfo = value.split('#');
            countryCode = contryInfo[1] ? contryInfo[1].toLowerCase() : '';
        }
    }
    return countryCode;
}

function $pluginFallback() {
    return $.fn;
}

function initializeEvents() {
    $(document).on('quickview.open', function() {
        validator.init();
    });

    $("[id$=country]").on('change', function() {
        $(this).closest("form").find('[id$=postal]').trigger('change');
    });

    $(document)
        .on('change', 'input', function () {
            var $formRow = $(this).closest('.js-form_row'),
                errorClasses = 'form-row_error error';

            if ($formRow.hasClass(errorClasses) && $(this).valid()) {
                $formRow.removeClass(errorClasses);
            }
        })
        .on('change', CONSTANTS.MOBILE_PREFIX_SELECTOR + ' select', function () {
            phonePrefixChange($(this), CONSTANTS.MOBILE_PHONE_SELECTOR);
        })
        .on('change', CONSTANTS.LANDING_PREFIX_SELECTOR + ' select', function () {
            phonePrefixChange($(this), CONSTANTS.LANDING_PHONE_SELECTOR);
        })
        .on('change', CONSTANTS.COMBO_LENGTH_PREFIX_SELECTOR, function () {
            comboLengthPrefixChange($(this), CONSTANTS.COMBO_LENGTH_SELECTOR);
        });
}

function phonePrefixChange($selector, inputClass) {
    var $input = getPrefixRelatedInput($selector, inputClass);

    if ($input && $input.length && $input.val().length > 0) {
        var selectedCountry = getSelectorCountry($selector);

        $input.data('code', selectedCountry);
        $input.blur();
    }
}

function comboLengthPrefixChange($selector, inputClass) {
    var $input = getComboRelatedInput($selector, inputClass);

    if ($input && $input.length && $input.val().length > 0) {
        $input.blur();
    }
}

function validateBirthdayFields(value, el) {
    var isValid = true;
    var $form = $(el).closest("form");
    var $dayHolder = $(el).hasClass('js-birthday_day') ? $(el) : $form.find("select[class*='_day']");
    var $monthHolder = $(el).hasClass('js-birthday_month') ? $(el) : $form.find("select[class*='_month']");
    var $yearHolder = $(el).hasClass('js-birthday_year') ? $(el) : $form.find("select[class*='_year']");
    var day = $dayHolder.val();
    var month = $monthHolder.val();
    var year = $yearHolder.val();

    // in case if customer chose all fields. Check date on valid
    if (day && month && year) {
        var currentDate = new Date(new Date().toDateString());

        if (year < currentDate.getFullYear() - 100 || year > currentDate.getFullYear() || month < 1 || month > 12) {
            isValid = false;
        }

        var monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        // Adjust for leap years
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLengths[1] = 29;
        }
        if (day < 0 || day > monthLengths[month - 1]) {
            isValid = false;
        }

        var birthdate = new Date( year, month - 1, day);

        if (!isValid || birthdate > currentDate) {
            var errorsObject = {};
            errorsObject[$dayHolder.attr('name')] = Resources.VALIDATE_BIRTHDAY_FAIL;
            errorsObject[$monthHolder.attr('name')] = '';
            errorsObject[$yearHolder.attr('name')] = '';
            var validator = $form.validate();
            validator.showErrors(errorsObject);
            return false;
        } else {
            var dateError = getInputError($dayHolder);
            if (dateError === Resources.VALIDATE_BIRTHDAY_FAIL) {
                clearError($dayHolder);
                clearError($monthHolder);
                clearError($yearHolder);
            }
        }
        validateElements([$dayHolder, $monthHolder, $yearHolder], true);
        return true;
    } else {
        return true;
    }
}

function validateMobileOrLandlineNumber(value, el) {
    var $form = $(el).closest('form'),
        $phoneMobile = $form.find('.js-phonemobile_field'),
        $phoneHome = $form.find('.js-phonehome_field'),
        phoneMobileName = $phoneMobile.attr('name'),
        phoneHomeName = $phoneHome.attr('name'),
        $phonesInputDescr = $('.js-phones-input-descr') || null;

    var validator = $form.validate();

    var mobileValue = $.trim($phoneMobile.val()),
        homeValue = $.trim($phoneHome.val());

    if (mobileValue.length === 0 && homeValue.length === 0) {
        var errorsObject = {};

        errorsObject[phoneMobileName] = '';
        errorsObject[phoneHomeName] = Resources.PHONES_INVALID;

        if ($phonesInputDescr && !$phonesInputDescr.hasClass('hidden')) {
            $phonesInputDescr.addClass('hidden');
        }


        validator.showErrors(errorsObject);
    } else {
        var currentHomeError = getInputError($phoneHome),
            currentMobileError = getInputError($phoneMobile);

        if (currentMobileError === '') {
            clearError($phoneMobile);
        }
        if (currentHomeError === Resources.PHONES_INVALID) {
            clearError($phoneHome);
        }
        if ($phonesInputDescr && $phonesInputDescr.hasClass('hidden')) {
            $phonesInputDescr.removeClass('hidden');
        }
    }

    return true;
}

function clearError($elem) {
    $elem.removeClass('error').parent().next('.error').remove();
}

function getInputError($elem) {
    return $elem.parent().next('.error').text();
}

function validateCourseDate(value, el) {
    var $form = $(el).closest('form'),
        validator = $form.validate(),
        fieldSelectors = ['.js-course_start_month', '.js-course_start_year', '.js-course_end_month', '.js-course_end_year'],
        fields = fieldSelectors.map(function (selector) {
           return $form.find(selector);
        });

    if (!fields.every(function($field) {
        return $field.val();
    })) {
        return true;
    }

    var diff = fields.reduce(function(result, $field, index) {
        var val = index % 2 ? $field.val() * 12 : +$field.val();
        return index > 1 ? result + val : result - val;
    }, 0);

    if (diff < 0) {
        return false;
    } else {
        fields.forEach(function ($field) {
           clearError($field);
        });
        return true;
    }
}

function showBlockViolation($elementContainer, $elementMessage, resourceMessage) {
    $elementMessage
        .text(resourceMessage)
        .addClass(settings.errorClass).show();
    validateElements($elementContainer, false);
}

function validateElements($elementContainer, isValid) {
    for (var i = 0; i < $elementContainer.length; i++ ) {
        if ($elementContainer[i].val()) {
            $elementContainer[i]
                .removeClass(isValid ? settings.errorClass : settings.validClass)
                .addClass(isValid ? settings.validClass : settings.errorClass);
        }
    }
}

module.exports = function () {
    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler. defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }
        var list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) {options.callback();}
        });
    };

    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort(function (a, b) {
            return $(a).height() - $(b).height();
        });
        return this.height($(arr[arr.length - 1]).height());
    };

    /**
     * The event handling as first handler
     * @param {String} name
     * @param {Function} fn
     */
    $.fn.bindFirst = function (name, fn) {
        this.bindNth(name, fn, 0);
    };

    /**
     * The event handling on given specific position on handlers queue
     * @param {String} name
     * @param {Function} fn
     * @param {Number} index
     */
    $.fn.bindNth = function (name, fn, index) {
        // Bind event normally.
        this.bind(name, fn);
        // Move to nth position.
        this.changeEventOrder(name, index);
    };

    /**
     * Changing the order of event handler for current element
     * @param {String} names
     * @param {Number} newIndex
     */
    $.fn.changeEventOrder = function (names, newIndex) {
        var that = this;
        // Allow for multiple events.
        $.each(names.split(' '), function (idx, name) {
            that.each(function () {
                var handlers = $._data(this, 'events')[name.split('.')[0]];
                // Validate requested position.
                newIndex = Math.min(newIndex, handlers.length - 1);
                handlers.splice(newIndex, 0, handlers.pop());
            });
        });
    };

    /**
     * Getting the data attributes collection by the given prefix
     * @param  {String} prefix
     * @return {Array}
     */
    $.fn.dataByPrefix = function (prefix) {
        var data = this.data(),
            regex = new RegExp('^' + prefix),
            result = {};

        for (var key in data) {
            if (regex.test(key)) {
                result[key] = data[key];
            }
        }

        return result;
    };

    /** Setting the default options set for volidator plugin */
    $.validator.setDefaults({
        'ignore' : ':hidden, .js-validate-ignore'
    });

    /**
     * Add email validation method to jQuery validation plugin.
     * Text fields must have 'email' css class to be validated as email
     */
    $.validator.addMethod('email', validateEmail, Resources.VALIDATE_EMAIL);

    $.validator.addMethod('postalcode', validatePostalCode, Resources.VALIDATE_POSTALCODE);

    $.validator.addMethod('postal', validatePostalByCountry, definePostalError);

    $.validator.addMethod('phone', validatePhone, Resources.VALIDATE_PHONE_INVALID);

    /**
     * Add email validation method to jQuery validation plugin.
     * Text fields must have 'mobile' css class to be validated as mobile number
     */
    $.validator.addMethod('mobile', validateMobile, Resources.VALIDATE_MOBILE);

    /**
     * Add password validation method to jQuery validation plugin.
     */
    $.validator.addMethod('passwordValidation', validatePassword, Resources.VALIDATE_PASSWORD);

    $.validator.addMethod('emojiValidation', validateEmoji, Resources.VALIDATE_SPECIAL_EMOJI);

    $.validator.addMethod('usernameValidation', validateUserName, Resources.VALIDATE_USERNAME_FAIL);

    $.validator.addMethod('streetNumberSuffixValidation', validateStreetNumberSuffix, Resources.VALIDATE_STREET_NUMBER_SUFFIX);

    $.validator.addMethod('regularValidation', validateRegular, Resources.VALIDATE_REGULAR_FAIL);

    $.validator.addMethod('digitsValidation', validateDigits, Resources.VALIDATE_DIGITS_FAIL);

    // Validate form input max length
    $.validator.addMethod('maxLengthValidation', validateMaxLength, function(params, element) {
        var maxLength = $(element).data('max-length'),
            customError = $(element).data('max-lenght-error');

        if (customError && customError.length) {
            return customError.replace('{0}', maxLength);
        }
        return Resources.VALIDATE_MAXLENGTH_FAIL.replace('{0}', maxLength);
    });

    // Validate form input min length
    $.validator.addMethod('minLengthValidation', validateMinLength, function(params, element) {
        var minLength = $(element).data('min-length');
        return Resources.VALIDATE_MINLENGTH_FAIL.replace('{0}', minLength);
    });

    // validate siret number
    $.validator.addMethod('siretNumberValidation', validateSiretNumber, Resources.VALIDATE_SIRET_NUMBER_FAIL);

    // validate kvk number
    $.validator.addMethod('kvkNumberValidation', validateKvkNumber, Resources.VALIDATE_KVK_NUMBER_FAIL);

    // validate enterpriseNumber
    $.validator.addMethod('enterpriseNumberValidation', validateEnterpriseNumber, Resources.VALIDATE_ENTERPRISE_NUMBER_FAIL);

    // validate mobile telephone number
    $.validator.addMethod('mobilePhoneValidation', validateMobilePhone, Resources.VALIDATE_MOBILE_PHONE_FAIL);

    // validate combo length
    $.validator.addMethod('comboLengthValidation', validateComboLength, function(params, element) {
        var maxLength = $(element).data('maxcombo-length'),
            elementType = $(element).data('combotype');

        if (elementType === 'name' || elementType == 'tradeName') {
            return Resources.VALIDATE_NAME_COMBOLEN_FAIL.replace('{0}', maxLength - 1);
        } else if (elementType === 'address') {
            return Resources.VALIDATE_ADDRESS_COMBOLEN_FAIL.replace('{0}', maxLength);
        }
    });

    // validate fixed line telephone number
    $.validator.addMethod('fixedLinePhoneValidation', validateFixedLinePhone, Resources.VALIDATE_FIXED_LINE_PHONE_FAIL);

    /**
     * Add date via selectbox validation method to jQuery validation plugin.
     * Text fields must have 'js-date_fields' css class to be validated
     */
    $.validator.addMethod('js-birthday_field', validateBirthdayFields, "");

    $.validator.addMethod('js-phone_tradecard', validateMobileOrLandlineNumber, Resources.PHONES_INVALID);

    $.validator.addMethod('js-course_date', validateCourseDate, Resources.COURSE_DATE_INVALID);

    $.validator.addMethod('isEmailEqual', function(val, el) {
        return val.toLowerCase() == $('.js-email_field').val().toLowerCase();
    }, Resources.VALIDATE_EMAIL_NOTMATCH);

    $.validator.addMethod('isPasswordEqual', function(val, el) {
        return val == $('.js-password_field').val();
    }, Resources.VALIDATE_PASSWORD_NOTMATCH);

    /**
     * Clear server-side validation error message on input change event.
     * Text fields must have 'input-text' css class to be validated
     */
    $.validator.addMethod('input-text', function(val, el) {
        $(el).parents('.js-form_row').find('.error-message').remove();
        return true;
    }, '');

    $.validator.addClassRules({
        'js-emailconfirm_field': { isEmailEqual: true },
        'js-passwordconfirm_field': { isPasswordEqual: true },
        'js-password_field': { passwordValidation: true },
        'js-validate-emoji': { emojiValidation: true },
        'js-validate_address_name' : {
            remote: {
                url: Urls.validateAddressName,
                type: 'post'
            }
        },
        'js-streetnumber-suffix': {streetNumberSuffixValidation: true},
        'js-validate-username': {usernameValidation: true},
        'js-validate-regular': {regularValidation: true},
        'js-validate-digits': {digitsValidation: true},
        'js-validate-maxlength': {maxLengthValidation: true},
        'js-validate-minlength': {minLengthValidation: true},
        'js-validate-combolength': {comboLengthValidation: true},
        'js-mobile-phone-validation': {mobilePhoneValidation: true},
        'js-fixed-line-phone-validation': {fixedLinePhoneValidation: true},
        'js-validate-siretnumber': {siretNumberValidation: true},
        'js-validate-kvknumber': {kvkNumberValidation: true},
        'js-validate-enterprisenumber': {enterpriseNumberValidation: true},
    });
    /**
     * Add possibility to get required message from attribute in if it is else will be used default value
     */
    $.validator.messages.required = function($1, element, $3) {
        var requiredText = $(element).data('required-text');
        return requiredText || Resources.VALIDATE_REQUIRED;
    };

    initializeEvents();

    /** Fallback to avoid application crash in case lagacy carousel plugin executed */
    $.fn.jcarousel = $pluginFallback;
    $.fn.jcarouselControl = $pluginFallback;
    $.fn.jcarouselPagination = $pluginFallback;
    $.fn.jcarouselAutoscroll = $pluginFallback;
};