'use strict';

var _ = require('lodash'),
    progress = require('_core_ext/progress'),
    JSConstant = require('_core_ext/constants'),
    sessionStorage = require('_core_ext/storage'),
    JSConstantsCart = require('_core_ext/pages/cart/constants'),
    CSSConstants = require('_core_ext/cssconstants');

var util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (!_.isString(url)) { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {};
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        qs.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = decodeURIComponent($3).replace(/\+/g, ' ');
            }
        );
        return params;
    },

    getParameterValueFromUrl: function(parameterName, url) {
        var currentQueryString = url || window.location.search;
        var currentQueryStringParams = this.getQueryStringParams(currentQueryString);

        return currentQueryStringParams[parameterName];
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    checkLimitMessage: function() {
        $('form').find('textarea[data-character-limit]').each(function() {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container) {
        var dialog = require('_core_ext/dialog');
        var utils = this,
            dialogURL = utils.ajaxUrl(Urls.pageShow),
            confirmResult = false;

        $(container).on('click', '.delete', function (event) {
            var $this = $(this),
                $dialog;

            if (!confirmResult) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                return;
            }

            dialog.open({
                'url' : utils.appendParamToURL(dialogURL, 'cid', Resources.CONFIRMATION_DIALOG_CID),
                'options' : {
                    'width' : 400
                },
                'callback' : function() {

                    function closeDialogCallback(event) {
                        event.preventDefault();
                        $dialog.dialog('isOpen') && $dialog.dialog('close');
                    }

                    $('#delete-cancel').on('click', closeDialogCallback);

                    $('#delete-confirm')
                        .on('click', closeDialogCallback)
                        .on('click', function () {
                            confirmResult = true;
                            $this[0].click();
                        });
                }
            });

            $dialog = dialog.$container;
            return false;
        });

    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    deleteConfirmation: function (container, message) {
        $(container).on('click', '.js-delete', function () {
            return window.confirm(message);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation, timeInterval, includeHeader) {
        var offset = 5; // Light offset, just for better UX
        var time = typeof(timeInterval) !== 'undefined' ? timeInterval : 500;

        if (includeHeader) {
            var $stikyHeader = $(JSConstant.CLASS_STICKY_HEADER_MOBILE);
            var headerHeight = $stikyHeader.length ? $stikyHeader.outerHeight(true) : 0;
            offset += headerHeight;
        }

        $('html, body').animate({scrollTop: xLocation - offset}, time);
    },
    /**
     * @function
     * @description Scrolls a browser window to a given element
     * @param {$elem} The jquery elem
     * @param {Number} timeInterval
     */
    scrollBrowserTo: function ($elem, timeInterval, offset) {
        if ($elem && $elem.length) {
            offset = parseInt(offset);
            offset = isNaN(offset) ? 0 : offset;

            this.scrollBrowser($elem.offset().top - offset, timeInterval, true);
        }
    },

    eventDelay: function(callback, threshhold, scope, skipInitialCall) {
        threshhold || (threshhold = 250);
        var last,
            deferTimer;

        /**
         * @todo  Add description
         */
        return function() {
            var context = scope || this,
                now = (new Date()).getTime(),
                args = arguments;

            if (last && now < last + threshhold) {
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function() {
                    last = now;
                    callback.apply(context, args);
                }, threshhold);
            } else {
                last = now;

                if (!skipInitialCall) {
                    callback.apply(context, args);
                }
            }
        };
    },

    /**
     * Throttling Function Calls
     *
     * @see http://www.nczonline.net/blog/2007/11/30/the-throttle-function/
     * @param {Function} callback Callback function to call
     * @param {Number} delay Delay before callback fire
     * @param {Object} scope The context to for callback fire
     */
    throttle: function(callback, delay, scope) {
        clearTimeout(callback._tId);
        callback._tId = setTimeout(function() {
            callback.call(scope);
        }, delay || 100);
    },

    getTimer: function() {
        return {
            id: null,
            clear: function () {
                if (this.id) {
                    window.clearTimeout(this.id);
                    delete this.id;
                }
            },
            start: function (duration, callback) {
                this.id = setTimeout(callback, duration);
            }
        };
    },

    /**
     * Add/remove value from array depending on its existing in array
     * @param inArr
     * @param value
     * @returns {Array}
     */
    toggleFromArray: function(inArr, value) {
        var arr = inArr.slice();
        var valueIndex = arr.indexOf(value);
        if (valueIndex >= 0) {
            arr.splice(valueIndex, 1);
        } else {
            arr.push(value);
        }

        return arr;
    },

    /**
     * Helper function.
     * Checks if two arrays have the same values. It doesn't depend from order
     * @param arr1
     * @param arr2
     * @returns {Boolean}
     */
    arraysEqualByValues: function(arr1, arr2) {
        if (!arr1 || !arr2) {
            return arr1 === arr2;
        }

        var len1 = arr1.length,
            len2 = arr2.length;

        if (len1 !== len2) {
            return false;
        }
        for (var i = 0; i < len1; i++) {
            if (arr2.indexOf(arr1[i]) < 0) {
                return false;
            }
        }

        return true;
    },

    /**
     * Helper function.
     * Construct :nth-child selector with given element and start position
     * @param {String} elem
     * @param {Number} from
     * @returns {String}
     */
    fromNthSelector: function(elem, from) {
        var selector = elem + ':nth-child(n+';
        selector += (from + 1);
        selector += ')';

        return selector;
    },

    getKeyByValue: function(HashMapCollection, value ) {
        for ( var prop in HashMapCollection ) {
            // eslint-disable-next-line no-prototype-builtins
            if ( HashMapCollection.hasOwnProperty( prop ) ) {
                if ( HashMapCollection[ prop ] === value )
                     {return prop;}
            }
        }
    },

    /**
     * [getUri description]
     * @param  {[type]} o [description]
     * @return {[type]}   [description]
     */
    getUri : function(o) {
        var a;

        if (o.tagName && $(o).attr('href')) {
            a = o;
        } else if (typeof o === 'string') {
            a = document.createElement("a");
            a.href = o;
        } else {
            return null;
        }

        // overcome some stupid ie behaviour
        if (a.host === '') {
            // eslint-disable-next-line no-self-assign
            a.href = a.href;
        }

        // all actual version of IE not so smart to correctly process
        // protocol independent locations, so wee need to help them
        if (a.protocol === ':') {
            a.protocol = window.location.protocol;
        }

        // fix for some IE browsers
        if (a.pathname.indexOf('/') !== 0) {
            a.pathname = '/' + a.pathname;
        }

        return Object.create({
            'protocol' : a.protocol, //http:
            'host' : a.host, //www.myexample.com
            'hostname' : a.hostname, //www.myexample.com'
            'port' : a.port, //:80
            'path' : a.pathname, // /sub1/sub2
            'query' : a.search, // ?param1=val1&param2=val2
            'queryParams' : a.search.length > 1 ? this.getQueryStringParams(a.search.substr(1)) : {},
            'hash' : a.hash, // #OU812,5150
            'url' : a.protocol + '//' + a.host + a.pathname,
            'toString' : function () {
                return this.protocol + '//' + this.host + this.port + this.pathname + this.search + this.hash;
            }
        });
    },

    getTimestamp: function (date) {
        return date ?  + Math.floor(date / 1000) : '';
    },

    toTimestamp: function (strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    },

    getDayTimestamp: function (day) {
        if (day) {
            var secondsFromDayStart = day.getSeconds() + (60 * (day.getMinutes() + (60 * day.getHours())));
            return this.getTimestamp(day) - secondsFromDayStart;
        }
    },

    progressShow: function ($container) {
        $container.addClass('js-loading');
        progress.show($container);
    },

    progressHide: function ($container) {
        $container.removeClass('js-loading');
        progress.hide();
    },

    contentShow: function (target) {
        var dialog = require('_core_ext/dialog');
        var contentId = $(target).data('contentid');

        if (!contentId) {
            return;
        }

        $.ajax({
            url: Urls.pageShow,
            data: {
                format: 'ajax',
                cid: contentId,
                type: 'POST'
            }
        }).done(function(response) {
            dialog.open({
                html: response
            });
        });
    },
    checkExpiredTradeCard: function (container) {

        var expirationPopupWasShown = $(CSSConstants.CLASS_JS_TRADE_CARD_EXPIRED).data('expirationpopupwasshown');
        if (expirationPopupWasShown) {
            return;
        }

        $.ajax({
            type: 'POST',
            dataType: 'json',
            data: {
                email: User.CUSTOMER_EMAIL
            },
            url: Urls.tradeCardCheckLogin
        }).done(function(response) {
            if (response && response.expired) {
                require('_core_ext/dialog').open({
                    html: response.popup,
                    options: {
                        dialogClass: response.cssClass,
                        title: response.title
                    }
                });
            }
        });
    },

    isScrolledIntoView: function($elem) {
        var $window = $(window);
        var docViewTop = $window.scrollTop();
        var docViewBottom = docViewTop + $window.height();

        var middle = $elem.offset().top +  $elem.height() / 2;

        return ((middle <= docViewBottom) && (middle >= docViewTop));
    },

    isElementUderHeader: function($elem, stickyTabs) {
        var elementTop = $elem.offset().top;
        var viewportTop = $(window).scrollTop();
        var headerHeight = $('.js-sticky-header-mobile').outerHeight();
        var stickyTabsHeight = stickyTabs ? stickyTabs.offsetHeight + 4 : 0;
        return elementTop <= (viewportTop + headerHeight + stickyTabsHeight);
    },

    /**
     * Detecting iOS
     * @returns {Boolean}
     */
    isIOS: function() {
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (!iOS) {
            iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        }

        return iOS;
    },

    /**
     * Helper function.
     * Trim given string
     * @param {String} str
     * @param {Integer} strLength
     * @param {String} symbol
     * @returns {String}
     */
    getTrimString: function(str, strLength, symbol) {
        var trimmedString = str;
        symbol = symbol || '';

        if (str && str.length && str.length > strLength) {
            trimmedString = str.substr(0, strLength);
            var correctLength = Math.min(trimmedString.length, trimmedString.lastIndexOf(" "));
            trimmedString = trimmedString.substr(0, correctLength);

            if (str.length !== trimmedString.length) {
                trimmedString += symbol;
            }
        }

        return trimmedString;
    },

    /**
     * Helper function.
     * Set custom cookie value
     * @param {String} name
     * @param {String} value
     * @param {Integer} value
     * @returns {Boolean}
     */
    setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    /**
     * Helper function.
     * Return custom cookie value
     * @returns {String}
     */
    getCookie: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
    },

    saveScroll: function() {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop);
        localStorage.setItem(JSConstantsCart.KEY_SCROLL_LOCAL_STORAGE, top);
    },
    restoreScroll: function() {
        var scrollpos = Number(localStorage.getItem(JSConstantsCart.KEY_SCROLL_LOCAL_STORAGE));
        if (scrollpos) {
            this.scrollBrowser(scrollpos);
            localStorage.removeItem(JSConstantsCart.KEY_SCROLL_LOCAL_STORAGE);
        }
    }
};

module.exports = util;
