require('./globalintuitive/contact-data-services.js');

var options = {
    'fieldsPrefix': ''
};

var addressFields = [{
        fieldName: '_address1',
        qasName: 'addressLine1'
    }, {
        fieldName: '_address2',
        qasName: 'addressLine3'
    }, {
        fieldName: '_city',
        qasName: 'locality'
    }, {
        fieldName: '_county',
        qasName: 'province'
    }, {
        fieldName: '_postal',
        qasName: 'postalCode'
    }, {
        fieldName: '_postalCode',
        qasName: 'postalCode'
    }
];

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function initGlobalIntuitive(token) {
    var countryCodeMapping = SitePreferences.QAS_LOCALES,
        localesMapping = SitePreferences.LOCALE_CODE_MAPPING,
        $searchAddress = $('input[name*="_search"],input.js-search-field');

    if (!$searchAddress.length) {
        return;
    }

    var country = $("[name*='_country']").val();

    var locObj = localesMapping.find(function (obj) {
        return obj.label === country;
    });

    if (locObj) {
        country = locObj.countryCode;
    }

    var pcaObj = countryCodeMapping.find(function (obj) {
        return obj.contryCode === country;
    });

    var currentCountry = pcaObj ? pcaObj.QASContryCode : '';
    var $address1Element = $("input[name='" + options.fieldsPrefix + "_address1']");

    // set options for Global Intuitive
    var qasOptions = {
        minStrLength: SitePreferences.QAS_MIN_STR_LENGTH || 0,
        maxSize: SitePreferences.QAS_MAX_LIST_COUNT || 25,
        token: token,
        elements: {
            input: $searchAddress[0],
            countryList: {value: currentCountry}
        }
    };

    var populatedFields = [];
    for (var i = 0; i < addressFields.length; i++) {
        var selector = $("input[name='" + options.fieldsPrefix + addressFields[i].fieldName + "']");
        if (selector.length) {
            qasOptions.elements[addressFields[i].qasName] = selector[0];
            populatedFields.push(selector);
        }
    }

    // initialize the Global Intuitive (Address search)
    var address = new ContactDataServices.address(qasOptions);
    $searchAddress.attr('autocomplete', 'none');

    // revalidate fields when a result is found, enable the 'submit button', clear error messages
    address.events.on("post-formatting-search", function(experianAddress) {
        setTimeout(function() { // it should be executed at the end
            if (experianAddress.address && experianAddress.components) {
                var address1Components = $.map(experianAddress.address, function(field) {
                    var fieldKey = Object.keys(field).join();
                    return ['addressLine1', 'addressLine2'].indexOf(fieldKey) >= 0 ? field[fieldKey] : '';
                });

                address1Components = $.grep(address1Components, function(component) {
                    return !!component;
                });

                $address1Element.val(address1Components.join(', '));

                for (var i = 0; i < populatedFields.length; i++) {
                    populatedFields[i].trigger('change').trigger('focusout');
                }
            } else {
                if (!$address1Element.hasClass('js-search-toggleable')) {
                    $address1Element.val('');
                }
            }
        }, 0);
    });
}

var globalintuitive = {
    init: function(params) {
        var token = SitePreferences.QAS_TOKEN;
        var allowedLocale = (SitePreferences.QAS_LOCALES).find(function (obj) {
            return obj.locale === SitePreferences.LOCALE;
        });

        // initialize it only on allowed locales (NOTE: it can be changed if we will use this feature on other locales)
        if (allowedLocale && SitePreferences.QAS_ENABLED && SitePreferences.QAS_SUGGESTION_ENABLED && token) {
            initializeParams(params);
            initGlobalIntuitive(token);
        }
    }
};

module.exports = globalintuitive;