var JSConstants = require('_core_ext/constants'),
    layout = require('_core_ext/layout'),
    util = require('_core_ext/util');

var captcha = null;

function initializeCaptcha() {
    window.onloadCaptchaCallback = function() {
        $(document).trigger('captcha.loaded');
    };

    var reCaptchaUrl = util.appendParamsToUrl(Urls.reCaptcha, {
        onload: 'onloadCaptchaCallback',
        render: 'explicit',
        hl: SitePreferences.LOCALE.toLowerCase().substring(0, 2)
    });

    $.getScript(reCaptchaUrl);
}

function renderCaptcha() {
    if ($('#' + JSConstants.CLASS_RECAPTCHA).length) {
        captcha = grecaptcha.render(JSConstants.CLASS_RECAPTCHA, {
            'sitekey' : SitePreferences.GOOGLE_RECAPTCHA_KEY,
            'callback': onAfterVerification,
            'error-callback': captchaErrorCallback,
            'theme' : 'light'
        });
    }
}

function onAfterVerification() {
    var topOffset = document.getElementById(JSConstants.CLASS_RECAPTCHA).offsetTop;

    layout.isMobile() && util.scrollBrowser(topOffset, 200, true);
    $(document).trigger('captcha.success');
}

function captchaErrorCallback() {
    $(document).trigger('captcha.error');
}


function validateCaptcha(e) {
    if (!grecaptcha) {
        return;
    }

    var $captchaForm = $(e.target);
    var responceValue = grecaptcha.getResponse(captcha);

    if (responceValue === '') {
        e.preventDefault();
    } else {
        var inputName = $('#' + JSConstants.CLASS_RECAPTCHA).data('name');

        // Append captchaValue to form
        $captchaForm.append($('<input />').attr({
            'type' : 'hidden',
            'name' : inputName
        }).val(responceValue));
    }
}

function initializeEvents() {
    $(document)
        .on('captcha.loaded', renderCaptcha)
        .on('submit', JSConstants.CLASS_RECAPTCHA_VALIDATE, validateCaptcha);
}

module.exports = {
    init: function() {
        var $captchaWrapper = $('#' + JSConstants.CLASS_RECAPTCHA);

        if (SitePreferences.GOOGLE_RECAPTCHA_ENABLED && $captchaWrapper.length) {
            initializeEvents();
            initializeCaptcha();
        }
    }
};