'use strict';

var constants = require('_core_ext/constants');

/**
 * This logic was originally located in a content asset, but after BM update
 * we no longer can add  JS directly to an asset without authentication (such as MFA via Account Manager).
 *
 */
function addYearToCopyrightLabel() {
    var copyrightLabel = $(constants.ID_FOOTER_COPYRIGHT_LABEL);
    var span = $(constants.CLASS_FOOTER_YEAR_SPAN);
    if (copyrightLabel.length > 0 && span.length > 0) {
        span.html(new Date().getFullYear().toString());
    }
}

var footer = {
    init : function() {
       addYearToCopyrightLabel();
    }
};

module.exports = footer;
