'use strict';

var _pageContext, analyticsData, currentPage,
    constants = require('_core_ext/pages/checkout/constants');

//Config of functions names, depending on current page
//Executed on page load
var pageTagsConfig = {
    'orderconfirmation': ['purchase']
};

//Config of functions names, depending on current page
//Executed on user interaction
var pageEventsConfig = {
    'global': ['subscriptionSubmit'],
    'product': ['addToCart'],
    'cart': ['cartSubmit'],
    'tradecard': ['registrationSubmit']
};

//Holder for events functions
var eventsLibrary = {
    'addToCart': function() {
        $(document).on('product.addedToCart', function(event, $form) {
            var productData = $form.data('productDetails'),
                productIDs = [];

            if (productData && analyticsData) {
                productIDs.push(productData.id);

                fbq('track', 'AddToCart', {
                    content_ids: productIDs,
                    content_type: 'product',
                    value: productData.price,
                    currency: productData.currencyCode
                });
            }
        });
    },

    'cartSubmit': function() {
        $('.js_cart_submit_form').on('submit', function() {
            fbq('track', 'InitiateCheckout');
        });

        $('.mini-cart_checkout').on('click', function() {
            fbq('track', 'InitiateCheckout');
        });
    },

    'subscriptionSubmit': function() {
        $('.js-footer_subscription').on('submit', function() {
            fbq('track', 'Lead');
        });
    },

    'registrationSubmit': function() {
        $(constants.CLASS_SHIPPING_METHOD_LIST).on('submit', function() {
            fbq('track', 'CompleteRegistration');
        });
    }
};

var tagsLibrary = {
    'purchase' : function() {
        var orderData = analyticsData.purchase;

        if (!orderData) {
            return;
        }

        var orderDetails = orderData.actionField,
            products = orderData.products,
            productIDs = [];

        if (products) {
            for (var i = 0; i < products.length; i++) {
                productIDs.push(products[i].id);
            }
        }

        fbq('track', 'Purchase', {
            content_ids: productIDs,
            content_type: 'product',
            value: orderDetails.revenue,
            currency: orderDetails.currencyCode
        });

    }
};

function initPageContext() {
    _pageContext = window.pageContext || {};
    //analyticsData need to be collected on BackEnd, should contain page specific or/and global data
    analyticsData = _pageContext.analytics || {};
    currentPage = _pageContext.currentPage || _pageContext.ns || {};
}

function initFacebookTracking() {
    !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
    n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
    document,'script','https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', SitePreferences.FB_TRACKING_ACCOUNT_ID); // Insert your pixel ID here.
}

/**
 * @description initialize events depending on current page
 *
 * @return {Void}
 */
function initEvents() {
    var eventsToInit = $.merge($.merge([], pageEventsConfig.global || []), pageEventsConfig[currentPage] || []);

    initFunctionsFromObject(eventsToInit, eventsLibrary);
}

/**
 * @description sends global data and page specific data, depending on 'currentPage' value
 *
 * @return {Void}
 */
function sendTags() {
    var tagsToSend = $.merge($.merge([], pageTagsConfig.global || []), pageTagsConfig[currentPage] || []);

    initFunctionsFromObject(tagsToSend, tagsLibrary);

    fbq('track', 'PageView');
}

/**
 * @description starts functions execution from list of provided functions
 * @param functionsList {Array} List of functions names
 * @param ObjectWithFunctions {Object} contains collection of named functions
 */
function initFunctionsFromObject(functionsList, ObjectWithFunctions) {
    if (functionsList && ObjectWithFunctions) {
        var functionsListLength = functionsList.length,
            i = 0;

        for (; i < functionsListLength; i++) {
            var functionName = functionsList[i];
            if (functionName in ObjectWithFunctions) {
                ObjectWithFunctions[functionName]();
            }
        }
    }
}

module.exports = {
    init: function () {
        if (!SitePreferences.FB_TRACKING_ENABLED) {
            return;
        }
        initPageContext();
        initFacebookTracking();
        sendTags();
        initEvents();
    }
};