/** The references object of all modules needed for components initialization */
var references = {
    /** Core level components */
    'tooltip' : require('_core/tooltip'),
    'validator' : require('_core_ext/validator'),

    /** Components from extension cartridge */
    'prototypes' : require('_core_ext/prototypes'),
    'common-elements' : require('_core_ext/common-elements'),
    'header-flyouts' : require('_core_ext/header-flyouts'),
    'layout' : require('_core_ext/layout'),
    'header' : require('_core_ext/header'),
    'carousel' : require('_core_ext/carousel'),
    'subscription': require('_core_ext/subscription'),
    'searchsuggest' : require('_core_ext/searchsuggest'),
    'currency-switch': require('_core_ext/currency-switch'),
    'language-switch': require('_core_ext/language-switch'),
    'language-popup': require('_core_ext/language-popup'),
    'images-devicespecific' : require('_core_ext/images-devicespecific'),
    'criteo' : require('_core_ext/trackers/criteo'),
    'perimeterx' : require('_core_ext/trackers/perimeterx'),
    'tabs': require('_core_ext/tabs'),
    'quantity': require('_core_ext/pages/product/quantity'),
    'myaccount': require('_core_ext/pages/account/myaccount'),
    'minicart' : require('_core_ext/pages/cart/minicart'),
    'emarsys': require('_cartridge/int_emarsys/modules/emarsys'),
    'captcha': require('_core_ext/captcha'),
    'cookiepolicy': require('_core_ext/cookiepolicy'),
    'dialog-popup': require('_core_ext/dialog-popup'),
    'gaec': require('_core_ext/analytics/gaec/enhanced-ecommerce'),
    'ga4': require('_core_ext/analytics/ga4/gtm'),
    'facebook': require('_core_ext/analytics/facebook/facebook-tracking'),
    'topnotification': require('_core_ext/pages/topnotification'),
    'reservecollect': require('_core_ext/reservecollect/index'),
    'footer': require('_core_ext/footer'),
    'notifications': require('_core_ext/notifications'),
    'lottie-player': require("@lottiefiles/lottie-player")
};

/**
 * The components initialization configuration object
 *
 * @example New "Page" configuration
 *  var configuration = {
 *      //...
 *      'newpage' : {
 *          'enabled' : true,
 *          'options' : {},
 *          'components' : {
 *              'pagination' : {
 *                  'enabled' : false,
 *                  'options' : {}
 *              }
 *          }
 *      }
 *      //...
 *  }
 */
var configuration = {
    'global' : {
        'components': {
            'prototypes' : {},
            'common-elements' : {},
            'layout' : {},
            'tooltip' : {},
            'validator' : {},
            'searchsuggest' : {},
            'carousel': {},
            'minicart' : {},
            'myaccount': {},
            'header-flyouts' : {},
            'header' : {},
            'subscription': {},
            'currency-switch': {},
            'language-switch': {},
            'language-popup': {},
            'images-devicespecific' : {},
            'criteo' : {},
            'tabs': {},
            'emarsys' : {},
            'perimeterx' : {},
            'captcha': {},
            'cookiepolicy': {},
            'dialog-popup': {},
            'gaec': {},
            'ga4': {},
            'facebook': {},
            'quantity': {},
            'topnotification': {},
            'footer': {},
            'notifications': {},
            'reservecollect': {},
        }
    },
    'login': {
        'components': {
            'reviews-carousel' : {}
        }
    },
    'account' : {
        'pages': {
            'myoffers' : {
                'components': {
                    'myoffers' : {}
                }
            },
            'order-detail': {
                'components': {
                    'order-detail' : {}
                }
            },
            'default-store-selection': {
                'components': {
                    'selectDefaultStore' : {}
                }
            },
            // 'cataloguerequest': {
            //     'components': {
            //         'postcodeanywhere': {
            //             'options': {
            //                 'fieldsPrefix': 'dwfrm_cataloguerequest_address'
            //             }
            //         },
            //         'globalintuitive': {
            //             'options': {
            //                 'fieldsPrefix': 'dwfrm_cataloguerequest_address'
            //             }
            //         },
            //         'address': {}
            //     }
            // },
        }
    },
    'communicationpreferences' : {
        'components': {
            'communicationpreferences' : {}
        }
    },
    'tradecardreplacement': {
        'components': {
            'postcodeanywhere': {
                'options': {
                    'fieldsPrefix': 'dwfrm_profile_tradecardaddress'
                }
            },
            'globalintuitive': {
                'options': {
                    'fieldsPrefix': 'dwfrm_profile_tradecardaddress'
                }
            }
        }
    },
    'cart' : {},
    'checkout' : {
        'pages': {
            'shipping': {
                'components': {
                    'address': {},
                    'shipping-address': {},
                    'postcodeanywhere': {
                        'options': {
                            'fieldsPrefix': 'dwfrm_singleshipping_shippingAddress_addressFields'
                        }
                    },
                    'globalintuitive': {
                        'options': {
                            'fieldsPrefix': 'dwfrm_singleshipping_shippingAddress_addressFields'
                        }
                    }
                }
            },
            'billing': {
                'components': {
                    'address': {},
                    'paypal': {},
                    'postcodeanywhere': {
                        'options': {
                            'fieldsPrefix': 'dwfrm_billing_billingAddress_addressFields'
                        }
                    },
                    'globalintuitive': {
                        'options': {
                            'fieldsPrefix': 'dwfrm_billing_billingAddress_addressFields'
                        }
                    }
                }
            },
        }
    },
    'compare' : {},
    'orderconfirmation': {},
    'product' : {
        'components' : {
            'feefo' : {},
        }
    },
    // 'registry' : {},
    'search' : {
        'components' : {
            'brands' : {},
            'refinements': {},
        }
    },
    'clp' : {
        'components' : {
            'brands' : {},
            'refinements': {},
        }
    },
    'storefront' : {
        'components': {
            'reviews-carousel' : {},
        },
        'pages': {
            'homepage': {}
        }
    },
    'offlineTradecardRegistration' : {
        'components': {
            'offlineTradecardRegistration': {}
        },
    },
    'wishlist' : {},
    'storelocator' : {},
    'storedetails' : {},
    'customerservice' :{
        'components': {
            'createreturn' : {}
        }
    },
    'blog': {},
    'tradecard': {
        'components': {
            'postcodeanywhere': {
                'options': {
                    'fieldsPrefix': 'dwfrm_profile_tradecardaddress'
                }
            },
        }
    },


};

module.exports = {
    'configuration' : configuration,
    'references' : references
};
