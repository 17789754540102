'use strict';

var Urls = require('urls');

var $cache = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.currenciesSelect = $cache.document.find('.js-currencies_selectbox');
}

function setCurrency(currency) {
    $.ajax({
        dataType: 'json',
        url: Urls.setSessionCurrency,
        data: {
            format: 'ajax',
            currencyMnemonic: currency
        }
    }).done(function(response) {
        if (response && response.success) {
            location.reload();
        } else {
            throw new Error('Unable to set currency');
        }
    });
}

function initializeEvents() {
    $cache.document
        .on('click', '.js-current_currency', function() {
            $(this).toggleClass('open');
            $cache.currenciesSelect.toggleClass('open');
        })
        .on('click', '.js-currency_option', function() {
            $cache.currenciesSelect.hide();
            setCurrency($(this).data('currency'));
        });
}

exports.init = function() {
    initializeCache();
    initializeEvents();
};
