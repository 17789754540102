var API = require('_core_ext/reservecollect/utils/api');
var util = require('_core_ext/util');
var Utils = require('_core_ext/reservecollect/utils');
var region = Utils.getRegionByLocale(SitePreferences.LOCALE);
var _ = require('lodash');

var mapsApiOptions = {
    key: SitePreferences.GOOGLE_API_KEY,
    libraries: 'geometry,places',
    region: region,
    language: SitePreferences.LOCALE
};

function getIconScaledSize() {
    return new google.maps.Size(
        parseFloat(SitePreferences.STORE_PIN_WIDTH),
        parseFloat(SitePreferences.STORE_PIN_HEIGHT)
    );
}

function getstoreIconRetail () {
    return {
        url: Urls.mapStoreRetailIcon,
        scaledSize: getIconScaledSize(),
    };
}

function getstoreIconTrade () {
    return {
        url: Urls.mapStoreIcon,
        scaledSize: getIconScaledSize(),
    };
}

function addMarkers (map, stores) {
    stores.forEach(store => {
        var marker = new google.maps.Marker({
            position: {lat: store.latitude, lng: store.longitude},
            map: map,
            icon: store.isTradeOnly ? getstoreIconTrade() : getstoreIconRetail()
        });
    });
}

function getMiles(i) {
    return i * 0.000621371192;
}

function getNearestStoresRadius (mapContainer, mapCenter) {
    var metersPerPx = 156543.03392 * Math.cos(mapCenter.lat * Math.PI / 180) / Math.pow(2, SitePreferences.DEFAULT_MAP_ZOOM);
    var metersPreMap = metersPerPx * Math.max(mapContainer.width(), mapContainer.height());
    var distanceInMiles = getMiles(metersPreMap);
    var nearestStoresRadius = Math.ceil(distanceInMiles / 2);
    return {
        lat: Number(mapContainer[0].dataset.storeLat),
        lng: Number(mapContainer[0].dataset.storeLng),
        nearestStoresRadius: nearestStoresRadius
    };
}

function configureNewMap () {
    var mapContainer = $('.js-store_map');

    if (!mapContainer.length) {
        return;
    }
    var mapParams = {
        lat: Number(mapContainer[0].dataset.storeLat),
        lng: Number(mapContainer[0].dataset.storeLng)
    };

    var mapInitOptions = {
        center: JSON.parse(JSON.stringify(mapParams)),
        zoom: SitePreferences.DEFAULT_MAP_ZOOM,
        disableDefaultUI: true,
        keyboardShortcuts: false,
        draggable: false,
    };

    mapParams.nearestStoresRadius = getNearestStoresRadius(mapContainer, mapParams);
    var d2 = $.Deferred();
    API.getJson({
        url: util.appendParamsToUrl(Urls.getNearestStores, mapParams),
        callback: function (data) {
            d2.resolve( data );
        },
    });

    $.when(d2).done(function (data2) {
        var stores;
        if (data2 && data2.success) {
            stores = data2.stores;
        }
        var map = new google.maps.Map(mapContainer[0], mapInitOptions);
        addMarkers(map, stores);
    });
}

var googleAPIWrapper = () => {
    var googleAPIInited = false;
    return function buildMap () {

        if (googleAPIInited) {
            configureNewMap();
            return;
        }

        var d1 = $.Deferred();
        API.getJson({
            url: Urls.googlemapsAPIUrl,
            data: mapsApiOptions,
            dataType: 'jsonp',
            callback: function (data) {
                d1.resolve(data);
            },
        });

        $.when(d1).done(function (data1) {
            googleAPIInited = true;
            configureNewMap();
        });
    };
};

var buildMap = googleAPIWrapper();

module.exports = {
    init: buildMap
};