'use strict';

var jsonUtil = {

    /**
     * Genereal wrapper for JSON.parse(...) with error catching
     * @param {String} stringified The string object representation to parse
     * @result {Object}
     */
    parse : function (stringified, defaultObject) {
        var parsed = {};

        if (typeof stringified !== 'string') {
            return defaultObject;
        }

        try {
            parsed = JSON.parse(stringified);
        } catch (e) {
            return defaultObject;
        }

        return parsed;
    }
};

module.exports = jsonUtil;