'use strict';

var perimeterx = require('_core_ext/trackers/perimeterx');
var dialog = require('_core_ext/dialog'),
    page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    JSConstants = require('_core_ext/bonus-products/constants'),
    CSSConstants = require('_core_ext/cssconstants'),
    variant = require('_core_ext/pages/product/variant'),
    quantityModule = require('_core_ext/pages/product/quantity');

var selectedList = [],
    maxItems = 1,
    dialogOpened = false,
    bliUUID = '';

/**
 * @private
 * @function
 * description Gets a list of bonus products related to a promoted product
 */
function getBonusProducts() {
    var bonusproducts = [];

    var i, len;
    for (i = 0, len = selectedList.length; i < len; i++) {
        var p = {
            pid: selectedList[i].pid,
            qty: selectedList[i].qty,
            options: {}
        };
        var a, alen, bp = selectedList[i];
        if (bp.options) {
            for (a = 0, alen = bp.options.length; a < alen; a++) {
                var opt = bp.options[a];
                p.options = {optionName:opt.name, optionValue:opt.value};
            }
        }
        bonusproducts.push({product: p});
    }
    return bonusproducts;
}

function getSelectedItemQty() {
    return selectedList.reduce(function(sum, curr) {
        return sum + curr.qty;
    }, 0);
}

/**
 * @private
 * @function
 * @description Updates the summary page with the selected bonus product
 */
function updateSummary() {
    var $bonusProductList = $(JSConstants.CLASS_BONUS_PRODUCT_LIST),
        $bonusProductAdded = $(JSConstants.CLASS_BONUS_PRODUCT_ADDED);

    if (selectedList.length) {
        var qty = getSelectedItemQty();
        if (!$bonusProductAdded.find(JSConstants.CLASS_REMOVE_LINK).length) {
            $('<span>', {
                'class': JSConstants.CLASS_REMOVE_LINK.substring(1) + ' bonus-remove'
            }).appendTo(JSConstants.CLASS_BONUS_PRODUCT_ADDED);
        }
        if ($bonusProductAdded.find(JSConstants.CLASS_BONUS_PRODUCT_ADDED_QTY).length) {
            $(JSConstants.CLASS_BONUS_PRODUCT_ADDED_QTY).text(qty);
        } else {
            $('<span>', {
                'class': JSConstants.CLASS_BONUS_PRODUCT_ADDED_QTY.substring(1),
                'text': qty
            }).appendTo(JSConstants.CLASS_BONUS_PRODUCT_ADDED);
            $bonusProductAdded.append(' ' + Resources.BONUS_PRODUCT_SELECTED);
        }
        if ($bonusProductList.find(JSConstants.CLASS_BONUS_PRODUCT_ITEM).length > 2) {
            $(JSConstants.CLASS_BONUS_PRODUCT_LIST).addClass(JSConstants.CLASS_POPUP_FOOTER_STICKY);
        }
    } else {
        $bonusProductAdded.text('');
        $(JSConstants.CLASS_BONUS_PRODUCT_LIST).removeClass(JSConstants.CLASS_POPUP_FOOTER_STICKY);
    }

    // get remaining item count
    var remain = maxItems - qty;
    if (remain <= 0) {
        $bonusProductList.find(JSConstants.CLASS_SELECT_BONUS_ITEM).not('.' + JSConstants.CLASS_BONUS_PRODUCT_SELECTED).attr('disabled', 'disabled');
        $(JSConstants.CLASS_ADD_TO_CART_BONUS).removeAttr("disabled");
    } else {
        $bonusProductList.find(JSConstants.CLASS_SELECT_BONUS_ITEM).removeAttr('disabled');
        $(JSConstants.CLASS_ADD_TO_CART_BONUS).removeAttr("disabled");
        $bonusProductList.find(JSConstants.CLASS_SELECT_BONUS_ITEM).not('.' + JSConstants.CLASS_BONUS_PRODUCT_SELECTED).each(function() {
            var $this = $(this);
            if ($this.hasClass(JSConstants.CLASS_DISABLED)) {
                $this.attr('disabled', 'disabled');
            } else {
                $this.removeAttr('disabled');
                $(JSConstants.CLASS_ADD_TO_CART_BONUS).attr('disabled', 'disabled');
            }
        });
    }
}

function updateVariant(swatch, url) {
    var $this = $(swatch);
    url = util.appendParamsToUrl(url, {
        'source': 'bonus',
        'format': 'ajax',
        'productType': $this.closest(JSConstants.CLASS_BONUS_PRODUCT_ITEM).data('producttype')
    });
    $.ajax({
        url: url,
        success: function (response) {
            var $bonusProduct = $this.closest(JSConstants.CLASS_BONUS_PRODUCT_ITEM);
            var pid = $bonusProduct.find('input[name="pid"]').val();
            var isSelected = $bonusProduct.find('.' + JSConstants.CLASS_BONUS_PRODUCT_SELECTED).length;

            $bonusProduct.empty().html(response);
            var $selectBtn = $bonusProduct.find(JSConstants.CLASS_SELECT_BONUS_ITEM);

            if (isSelected && $selectBtn.length && pid) {
                selectedList.forEach(function(item, i) {
                    if (item.pid === pid) {
                        selectedList.splice(i,1);
                    }
                });
                $selectBtn.trigger('click', true);
            } else {
                updateSummary();
            }

            quantityModule.availabilityQuantity(true);
        }
    });
}

function initializeGrid() {
    var $body = $(CSSConstants.BODY),
        $bonusProduct = $('#bonus-product-dialog'),
        $bonusProductList = $(JSConstants.CLASS_BONUS_PRODUCT_LIST),
        bliData = $bonusProductList.data('line-item-detail');

    $(JSConstants.CLASS_ADD_TO_CART_BONUS).attr('disabled', 'disabled');

    maxItems = bliData.maxItems;
    bliUUID = bliData.uuid;

    if (bliData.itemCount > maxItems) {
        $bonusProductList.find(JSConstants.CLASS_SELECT_BONUS_ITEM).attr('disabled', 'disabled');
    }

    $bonusProductList.on('click', JSConstants.CLASS_BONUS_PRODUCT_ITEM + ' ' + JSConstants.CLASS_VARIATION_LINK, function (e) {
        e.preventDefault();
        var $this = $(this);
        var $swatch = $this.parent(JSConstants.CLASS_SWATCH_ITEM);
        var href = this.href;

        if (bliUUID) {
            href = util.appendParamToURL(href, 'bonusDiscountLineItemUUID', bliUUID);
        }

        if ($swatch.hasClass(CSSConstants.SELECTABLE) && !$swatch.hasClass(CSSConstants.SELECTED)) {
            updateVariant(this, href);
        }
    })
    .on('click', JSConstants.CLASS_VARIATION_DROPDOWN_VARIANT, function () {
        var $this = $(this);
        var href = $this.data('href');

        if (bliUUID) {
            href = util.appendParamToURL(href, 'bonusDiscountLineItemUUID', bliUUID);
        }

        if ($this.hasClass(CSSConstants.SELECTABLE) && !$this.hasClass(CSSConstants.SELECTED)) {
            updateVariant(this, href);
        }
    })
    .on('input', JSConstants.CLASS_INPUT_TEXT, function () {
        var $form = $(this).closest(JSConstants.CLASS_BONUS_PRODUCT_FORM),
            $bonusItem = $form.find(JSConstants.CLASS_SELECT_BONUS_ITEM);

        if (!$bonusItem.hasClass(JSConstants.CLASS_DISABLED)) {
            $bonusItem.removeAttr('disabled');
        }

        $form.find(JSConstants.CLASS_BONUS_QTY_ERROR).text('');
        updateSummary();
    })
    .on('click', JSConstants.CLASS_SELECT_BONUS_ITEM, function (e) {
        e.preventDefault();

        var form = $(this).closest(JSConstants.CLASS_BONUS_PRODUCT_FORM),
            detail = $(this).closest(JSConstants.CLASS_PRODUCT_DETAIL);

        var uuid = form.find('input[name="productUUID"]').val(),
            qtyVal = form.find('input[name="Quantity"]').val(),
            qty = (isNaN(qtyVal)) ? 1 : (+qtyVal),
            isAdded = false,
            $button = form.find(JSConstants.CLASS_SELECT_BONUS_ITEM);

        var product = {
            uuid: uuid,
            pid: form.find('input[name="pid"]').val(),
            qty: qty,
            name: detail.find(JSConstants.CLASS_PRODUCT_NAME).text(),
            attributes: detail.find(JSConstants.CLASS_PRODUCT_VARIATIONS).data('attributes'),
            options: []
        };

        var optionSelects = form.find(JSConstants.CLASS_PRODUCT_OPTION);

        optionSelects.each(function() {
            product.options.push({
                name: this.name,
                value: $(this).val(),
                display: $(this).children(':selected').first().html()
            });
        });

        if ($button.hasClass(JSConstants.CLASS_BONUS_PRODUCT_SELECTED)) {
            selectedList.forEach(function(item, i) {
                if (item.pid === product.pid) {
                    selectedList.splice(i,1);
                    isAdded = true;
                }
            });
            $button.removeClass(JSConstants.CLASS_BONUS_PRODUCT_SELECTED);
        } else {
            if (qty > maxItems || (getSelectedItemQty() + qty) > maxItems) {
                $bonusProductList.find(JSConstants.CLASS_SELECT_BONUS_ITEM).not('.' + JSConstants.CLASS_BONUS_PRODUCT_SELECTED).attr('disabled', 'disabled');
                form.find(JSConstants.CLASS_BONUS_QTY_ERROR).text(Resources.BONUS_PRODUCT_TOOMANY);
                return;
            }
            $button.addClass(JSConstants.CLASS_BONUS_PRODUCT_SELECTED);
        }

        selectedList.forEach(function(item) {
            if (item.pid === product.pid) {
                item.qty += qty;
                isAdded = true;
            }
        });

        if (!isAdded) {
            selectedList.push(product);
        }

        updateSummary();
    })
    .on('change', JSConstants.CLASS_VARIATION_SELECT, function () {
        var href = $(this).val();

        if (bliUUID) {
            href = util.appendParamToURL(href, 'bonusDiscountLineItemUUID', bliUUID);
        }

        updateVariant(this, href);
    })
    .on('click', JSConstants.CLASS_REMOVE_LINK, function (e) {
        e.preventDefault();
        selectedList = [];
        $(JSConstants.CLASS_BONUS_PRODUCT_LIST)
            .find(JSConstants.CLASS_SELECT_BONUS_ITEM)
            .removeClass(JSConstants.CLASS_BONUS_PRODUCT_SELECTED);
        updateSummary();
    })
    .on('click', JSConstants.CLASS_ADD_TO_CART_BONUS, function (e) {
        e.preventDefault();
        var dataObject = {},
            url = util.appendParamsToUrl(Urls.addBonusProduct, {bonusDiscountLineItemUUID: bliUUID});

        dataObject.bonusproducts = getBonusProducts();

        if (dataObject.bonusproducts.length <= 0) {
            return;
        } else if (dataObject.bonusproducts[0].product.qty > maxItems) {
            dataObject.bonusproducts[0].product.qty = maxItems;
        }

        // make the server call
        $.ajax({
            type: 'POST',
            dataType: 'json',
            cache: false,
            contentType: 'application/json',
            url: url,
            data: JSON.stringify(dataObject)
        })
        .done(function () {
            // success
            page.refresh();
        })
        .fail(function (response, textStatus) {
            // failed
            var handleResult = perimeterx.handleResponce(response);

            if (handleResult) {
                return;
            } else if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        })
        .always(function () {
            $bonusProduct.dialog('close');
            $body.trigger('layout.unlock');
        });
    })
    .on('click', JSConstants.CLASS_MORE_BONUS_PRODUCTS, function (e) {
        e.preventDefault();
        var uuid = $(JSConstants.CLASS_BONUS_PRODUCT_LIST).data().lineItemDetail.uuid,
            $bonusProductList = $(JSConstants.CLASS_BONUS_PRODUCT_LIST);

        //get the next page of choice of bonus products
        var lineItemDetail = JSON.parse($bonusProductList.attr('data-line-item-detail'));
        lineItemDetail.pageStart = lineItemDetail.pageStart + lineItemDetail.pageSize;
        $bonusProductList.attr('data-line-item-detail', JSON.stringify(lineItemDetail));
        var pageSize = parseInt($bonusProductList.data().lineItemDetail.pageSize);

        var url = util.appendParamsToUrl(Urls.getBonusProducts, {
            bonusDiscountLineItemUUID: uuid,
            format: 'ajax',
            lazyLoad: 'true',
            pageStart: lineItemDetail.pageStart,
            pageSize: !isNaN(pageSize) ? pageSize : 10,
            bonusProductsTotal: $bonusProductList.data().lineItemDetail.bpTotal
        });

        $.ajax({
            type: 'GET',
            cache: false,
            contentType: 'application/json',
            url: url
        })
        .done(function (data) {
            //add the new page to DOM and remove 'More' link if it is the last page of results
            var $moreBonusProducts = $(JSConstants.CLASS_MORE_BONUS_PRODUCTS);
            $moreBonusProducts.before(data);
            if ((lineItemDetail.pageStart + lineItemDetail.pageSize) >= $bonusProductList.data().lineItemDetail.bpTotal) {
                $moreBonusProducts.remove();
            }
        })
        .fail(function (xhr, textStatus) {
            if (textStatus === 'parsererror') {
                window.alert(Resources.BAD_RESPONSE);
            } else {
                window.alert(Resources.SERVER_CONNECTION_ERROR);
            }
        });
    });
}

var bonusProductsView = {
    /**
     * @function
     * @description Open the list of bonus products selection dialog
     */
    show: function (url, title, isBonusNoChoice) {
        var $bonusProduct = $('#bonus-product-dialog');

        dialogOpened = true;
        // create the dialog
        dialog.open({
            target: $bonusProduct,
            url: url,
            options: {
                dialogClass: 'ui-dialog_bonus',
                title: title
            },
            callback: function () {
                selectedList = [];

                if (!isBonusNoChoice) {
                    initializeGrid();
                }

                $(JSConstants.CLASS_BONUS_PRODUCT_ITEM).each(function() {
                    var $variations = $(this).find(JSConstants.CLASS_PRODUCT_VARIATIONS);
                    variant.hideColours($variations, Resources.COLOURS_QTY_BONUS, Resources.COLOURS_QTY_BONUS, Resources.COLOURS_QTY_DELTA, true);
                });

                variant.initializeColoursEvents(JSConstants.CLASS_BONUS_PRODUCT_LIST);
                dialog.$container.one('click', JSConstants.CLASS_CLOSE_DIALOG, function() {
                    dialog.close();
                });

                quantityModule.availabilityQuantity(true);
            }
        });
    },
    /**
     * @function
     * @description Open bonus product promo prompt dialog
     */
    loadBonusOption: function() {
        var self = this,
            bonusDiscountContainer = $(JSConstants.CLASS_BONUS_DISCOUNT_CONTAINER);

        if (!bonusDiscountContainer.length) {
            return;
        }

        // get the html from minicart, then trash it
        var bonusDiscountContainerHtml = bonusDiscountContainer.html();
        bonusDiscountContainer.empty();


        var bonusPromo = $(bonusDiscountContainerHtml).filter(JSConstants.CLASS_BONUS_PRODUCT_PROMO);
        var uuid = bonusPromo.data('lineitemid'),
            lineitemsuuids = bonusPromo.data('lineitemsuuids'),
            isBonusNoChoice = bonusPromo.data('isbonusnochoice') || false,
            url = util.appendParamsToUrl(Urls.getBonusProducts, {
                bonusDiscountLineItemUUID: uuid ? uuid : null,
                bonusLineItemsUUIDs: lineitemsuuids ? lineitemsuuids : null,
                isBonusNoChoice: isBonusNoChoice,
                source: 'bonus',
                format: 'ajax',
                lazyLoad: 'false',
                pageStart: 0,
                pageSize: 10,
                bonusProductsTotal: -1
            });

        if ((uuid || lineitemsuuids) && url) {
            // eslint-disable-next-line no-nested-ternary
            var title = uuid ? Resources.BONUS_PRODUCTS
                : (lineitemsuuids.split(',').length === 1 ? Resources.BONUS_PRODUCT_NO_CHOICE : Resources.BONUS_PRODUCTS_NO_CHOICE);
            self.show(url, title, isBonusNoChoice);
        }
    },

    /**
     * @function
     * @description Dialog opened
     */
    dialogOpened: function() {
        return dialogOpened;
    }
};

module.exports = bonusProductsView;
