'use strict';

const util = require('_core_ext/util');

const getPageContext = () => {
    const _pageContext = window.pageContext || {};
    const currentPage = _pageContext.currentPage || _pageContext.ns || null;
    return currentPage;
}

const getPersonalMessageContent = async (personalMessage) => {
    const notificationsUrl = util.appendParamsToUrl(Urls.getNotificationsData, {
        getPersonalMessageContent: personalMessage.individualRenderNeeded,
        messageID: personalMessage.messageID,
    });

    const data  = await fetch (
        notificationsUrl,
        {
            'headers': {
                'Content-type': 'text/html',
            }
        })
        .then(req => {
            if (req.status !== 200) {
                return Promise.reject(new Error(response.statusText))
            }
            return req.text();
        })
        .catch(error => console.log('error', error))

    return data;
}

const getNotificationsData = async () => {
    const currentPage = getPageContext();
    const notificationsUrl = util.appendParamsToUrl(Urls.getNotificationsData, {
        calcNotificationsQuantity:     currentPage && currentPage === "myaccount",
        orderNotificationsEnabled:     SitePreferences.ORDER_NOTIFICATIONS_ENABLED && (!currentPage || currentPage !== "orderhistory"),
        promotionNotificationsEnabled: SitePreferences.PROMOTION_NOTIFICATIONS_ENABLED && SiteDynamic.MYOFFERS_OPT_IN && (!currentPage || currentPage !== "myoffers"),
        wishListNotificationsEnabled:  SitePreferences.WISHLIST_NOTIFICATIONS_ENABLED && (!currentPage || currentPage !== "wishlist"),
        personalMessagesEnabled:       SitePreferences.PERSONAL_MESSAGES_ENABLED,
        headerNotificationsEnabled:    SitePreferences.HEADER_NOTIFICATIONS_ENABLED,
    });

    const data  = await fetch (
        notificationsUrl,
        {
            'headers': {
                'Content-type': 'application/json',
            }
        })
        .then(res => {
            return res
        })
        .catch(error => console.log('error', error))
    return data;
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const renderNotifications = async () => {
    const customerLoggedInIcon = document.querySelector('.js-myaccount-info');
    const notificationsEnabled = SitePreferences.ACCOUNT_NOTIFICATIONS_ENABLED;
    let notificationsData = null;

    // check if current customer logged in, notifications enabled and trade card requested
    if (!customerLoggedInIcon || !notificationsEnabled || SiteDynamic.TRADE_CARD_REQUESTED) {
        return
    }

    let response = await getNotificationsData();

    if (response && response.status && response.status === 429) {
        await timeout(4000);
        response = await getNotificationsData();
    }

    if (response && response.status && response.status === 200) {
        notificationsData = await response.json();
    } else {
        return;
    }

    if ("notificationsQuantity" in notificationsData && notificationsData.notificationsQuantity) {
        const notificationsQuantity = notificationsData.notificationsQuantity

        if ("ordersNotifications" in notificationsQuantity && notificationsQuantity.ordersNotifications) {
            const ordersNotificationsElement = document.querySelector('.js-orders-notifications');
            if (ordersNotificationsElement) {
                const notification = document.createElement("span");
                notification.classList.add("js-notification", "account-options-notification");
                notification.textContent = notificationsQuantity.ordersNotifications +
                                                    ` ${Number(notificationsQuantity.ordersNotifications) > 1 ?
                                                        Resources.NOTIFICATIONS_UPDATES :
                                                        Resources.NOTIFICATIONS_UPDATE}`;
                    ordersNotificationsElement.appendChild(notification);
            }
        }

        if ("promotionsNotifications" in notificationsQuantity && notificationsQuantity.promotionsNotifications) {
            const promotionsNotificationsElement = document.querySelector('.js-promotions-notifications');
            if (promotionsNotificationsElement) {
                const notification = document.createElement("span");
                notification.classList.add("js-notification", "account-options-notification");
                notification.textContent = notificationsQuantity.promotionsNotifications + ` ${Resources.NOTIFICATIONS_NEW}`;
                promotionsNotificationsElement.appendChild(notification);
            }
        }

        if ("wishListNotifications" in notificationsQuantity && notificationsQuantity.wishListNotifications) {
            const wishListNotificationsElement = document.querySelector('.js-wishList-notifications');
            if (wishListNotificationsElement) {
                const notification = document.createElement("span");
                notification.classList.add("js-notification", "account-options-notification");
                notification.textContent = Resources.NOTIFICATIONS_OFFERS;
                wishListNotificationsElement.appendChild(notification);
            }
        }
    }

    if ('isHeaderNotificationsAvailabile' in notificationsData && notificationsData.isHeaderNotificationsAvailabile) {
        const customerLoggedInAnimation = customerLoggedInIcon.querySelector(".js-notifications_animation-wrap");
        customerLoggedInAnimation.classList.remove('animation-hidden');
    }

    if ('personalMessage' in notificationsData && notificationsData.personalMessage) {
        const personalMessageContainer = document.querySelector('.js-personal-message-container');
        if (personalMessageContainer) {
            if (notificationsData.personalMessage.individualRenderNeeded) {
                const personalMessageContent = await getPersonalMessageContent(notificationsData.personalMessage);
                personalMessageContainer.innerHTML = personalMessageContent;
            } else {
                personalMessageContainer.innerHTML = notificationsData.personalMessage.personalMessageContent
            }
        }
    }
}

const notifications = {
    init : function() {
        renderNotifications();
    }
};

module.exports = notifications;
