module.exports = {
    BODY: 'body',
    HIDDEN: 'hidden',
    VISIBLE: 'visible',
    HIDDEN_DESKTOP: 'hidden-desktop',
    HIDDEN_TABLET: 'hidden-tablet',
    HIDDEN_MOBILE: 'hidden-mobile',
    HIDDEN_NOT_DESKTOP: 'hidden-not_desktop',
    HIDDEN_NOT_MOBILE: 'hidden-not_mobile',
    ERROR: 'error',
    HIGHLIGHT: 'highlight',
    VALID: 'valid',
    REQUIRED: 'required',
    LOCK: 'noscroll',
    TILE_GRID: 'tile-grid',
    TILE_WIDE: 'tile-wide',
    SELECTABLE: 'selectable',
    SELECTED: 'selected',
    UNSELECTABLE: 'unselectable',
    ACTIVE: 'active',
    OPEN: 'open',
    LOADING: 'loading',
    EXPANDED : 'expanded',
    DISABLED: 'disabled',
    STICKY_BOTTOM: 'sticky-bottom',
    STICKY_UP: 'sticky-up',
    PAYMENTMETHOD_EXPANDED: 'payment-method_expanded',
    PAYMENT_METHOD : 'payment-method_method',
    CROP_IMAGE_CONTAINER: '.js-crop-image',
    BACK_TO_TOP: '.js-back-to-top',
    CLASS_JS_TRADE_CARD_EXPIRED: '.js_trade-card_expired',

    IS_ACTIVE_SUBSCRIPTION: 'is-active-subscription',
    SEARCH_OVERLAY: 'header-search_overlay',

    BODY_NAV_ACTIVE: 'header-nav_active',
    BODY_SEARCH_ACTIVE: 'search-popup_active',
    STICKY_ACTIVE: 'is_stuck',
    TRANSITION_DURATION: 200,

    CLASS_PASSWORD_INLINE_HIDDEN: 'password-hidden',
    CLASS_PASSWORD_INLINE_VISIBLE: 'password-visible',
    CLASS_CURRENT_LANGUAGE: 'current-language'
};
