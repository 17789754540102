'use strict';

var JSConstants = require('_core_ext/constants'),
    dialog = require('_core_ext/dialog');

var initialized = false;

var $cache = {};
var COOKIE = {
    name: 'isLanguageDialogShown'
};

function initializeCache() {
    $cache.document = $(document);
}

function initializeEvents() {
    $cache.document.on('click', JSConstants.CLASS_LANGUAGE_POP_SELECT_LINK, function() {
        setPopupCookie();
    });
}

function initializePopup() {
    dialog.open({
        url: Urls.languagePopup,
        options: {
            closeOnEscape: false,
            dialogClass: 'alert-dialog no-close',
        },
        callback: function () {
            dialog.$container.one('click', JSConstants.CLASS_LANGUAGE_POP_SELECT_LINK, function() {
                dialog.close();
            });
        }
    });
}

function setPopupCookie() {
    $.cookie(COOKIE.name, true, { expires: 365, path: '/;SameSite=None', secure: true });
}

exports.init = function() {
    if (initialized) {
        return;
    }

    initializeCache();
    initializeEvents();
    initialized = true;

    var isPopupShown = $.cookie(COOKIE.name) === 'true';
    var isShouldShow = $(JSConstants.CLASS_LANGUAGE_POP);

    if (isShouldShow.length && !isPopupShown) {
        initializePopup();
        setPopupCookie();
    }
};


