module.exports = {
    CLASS_BONUS_PRODUCT_LIST: '.js-bonus_product_list',
    CLASS_BONUS_PRODUCT_ADDED: '.js-bonus_products_added',
    CLASS_BONUS_PRODUCT_ADDED_QTY: '.js-bonus_products_added_qty',
    CLASS_BONUS_PRODUCT_ITEM: '.js-bonus_product_item',
    CLASS_REMOVE_LINK: '.js-remove_link',
    CLASS_SELECT_BONUS_ITEM: '.js-select_bonus_item',
    CLASS_VARIATION_DROPDOWN_VARIANT: '.js-variation_dropdown_variant',
    CLASS_VARIATION_SELECT: '.js-variation-select',
    CLASS_INPUT_TEXT: '.js_qty-current',
    CLASS_BONUS_PRODUCT_FORM: '.js-bonus_product_form',
    CLASS_PRODUCT_DETAIL: '.js-product_detail',
    CLASS_PRODUCT_NAME: '.js-product_name',
    CLASS_PRODUCT_VARIATIONS: '.js-product_variations',
    CLASS_QUANTITY_ERROR: '.js-quantity_error',
    CLASS_BONUS_PRODUCT_SELECTED: 'js-bonus_product_selected',
    CLASS_ADD_TO_CART_BONUS: '.js-add_to_cart_bonus',
    CLASS_MORE_BONUS_PRODUCTS: '.js-more_bonus_products',
    CLASS_CLOSE_DIALOG: '.js-close_dialog',
    CLASS_BONUS_DISCOUNT_CONTAINER: '.js-bonus_discount_container',
    CLASS_BONUS_PRODUCT_PROMO: '.js-bonus_product_promo',
    CLASS_VARIATION_LINK: '.js-variation-link',
    CLASS_PRODUCT_OPTION: '.js-product_option',
    CLASS_DISABLED: 'js-disabled',
    CLASS_POPUP_FOOTER: ".js-bonus-footer",
    CLASS_POPUP_FOOTER_STICKY: "bonus-sticky",
    CLASS_BONUS_QTY_ERROR: '.js-bonus_quantity_error',
};