'use strict';

var storeSelect = require('./helpers/storeSelect');
var storeDetails = require('./helpers/storeDetails');
var mapFunc = require('./helpers/mapFunc');
var Store = require('./utils/store');

module.exports = {
    showSelectStoreDialog: storeSelect.showDialog,
    init: function () {
        var store = new Store({});
        mapFunc.init(store);
        storeSelect.init(store);
        storeDetails.init(store);
    },
};
