module.exports = {
    CLASS_TABLE: '.js-cart-table',
    CLASS_EDIT_PRODUCT: '.js-edit-product',
    CLASS_EDIT_BONUS: '.js-edit-bonus',
    CLASS_QTY_CURRENT: '.js_qty-current',
    CLASS_QUANTITY: '.js_quantity',
    CLASS_QTY_CMP: '.js-quantity-cmp',
    CLASS_QTY_BLOCK: '.js-quantity-block',
    CLASS_QTY_ERROR: '.js-quantity_error',
    CLASS_DECR: '.js-decr',
    CLASS_INC: '.js-inc',

    CLASS_CSFR_SHARED_TOKEN: '.js-csfr_shared-token',
    CLASS_CART_CONTENT: '.js-cart_content',
    CLASS_CART_SUBTOTAL: '.js-cart_subtotal',
    CLASS_CART_SCROLLZONE: '.js-cart-scrollzone',
    CLASS_CART_ITEMS_FORM: '.js-cart-items-form',
    CLASS_HEADER_CART: '.js-header-cart',
    CLASS_MINI_CART_CONTENT: '.js-mini-cart-content',
    CLASS_MINI_CART_TOTAL: '.js-mini-cart-total',
    CLASS_COUPON_ADD: '.js-apply-coupon',
    CLASS_COUPON_ADD_FORM: '.js-cart-coupon-form',
    ID_CART_COUPON_INPUT: '#js-cart-coupon-input',
    CLASS_COUPON_ADD_DISCLAIMER: '.js-coupon-disclaimer',
    CLASS_SHIPPING_METHOD_LIST: '.js-shipping_method_list',
    CLASS_ORDER_TOTAL: '.js-order_total',
    CLASS_SHIPPING_FIELDS: '.js-shipping_fields',
    ClASS_NO_SHIPPING_METHODS: '.js-no_shipping_methods',
    CLASS_CHECKOUTCART_BTN: '.js-checkoutcart_btn',
    CLASS_CART_ADD_COUPON: '.js-cart-addcoupon',
    CLASS_EDIT_DETAILS: '.js-edit_details',
    CLASS_BONUS_ITEM: '.js-bonus_item',
    CLASS_CART_PROMO_FORM: '.js-cart-promo_form',
    CLASS_CART_PROMO_FORM_ADD_COUPON: '.js-cart-promo_form-add_coupon',
    CLASS_CART_PROMO_FORM_DELETE_COUPON: '.js-cart-promo_form-delete_coupon',
    SELECTOR_COUPON_INPUT: 'input[name$="_couponCode"]',
    CLASS_SEARCH_RESULT_CONTENT: '.js-search-result-content',
    CLASS_VAT_EXPLANATION: '.js-vat-explanation',
    CLASS_VAT_DISCLAIMER: '.js-vat-disclaimer',
    CLASS_SAVED_PRODUCTS: '.js-saved_products',
    CLASS_TABLE_SAVED_PRODUCTS: '.js-table-saved_products',
    CLASS_CART_TITLE: '.js-cart-title',
    CLASS_EDIT_SAVED_PRODUCT: '.js-edit_saved_product',
    CLASS_UPDATE_SAVED_PRODUCT: '.js-update_saved_variant',
    CLASS_PDP_FORM_WRAPPER: '.js-pdp_form_wrapper',
    CLASS_PDP_MAIN: '.js-pdp-main',
    CLASS_CAROUSEL: '.js-carousel',
    CLASS_PROMO_CAROUSEL: '.js-promo-carousel',
    CLASS_MINI_CART_PRODUCTS: '.js-mini-cart_products',
    CLASS_ITEM: '.js-item',
    CLASS_MINI_CART_PRODUCT: '.js-mini-cart_product',
    CLASS_MINI_CART_TOTALS: '.js-mini-cart_totals',
    CLASS_MINI_CART: '.js-minicart',
    CLASS_MINI_CART_WRAPPER: '.js-mini-cart_wrapper',
    CLASS_MINI_CART_ERROR: '.js-error_message',
    CLASS_MINI_CART_BONUS_DISC: '.js-bonus_discount_container',
    CLASS_MINI_CART_HEADER: '.js-mini-cart_header',
    CLASS_PROMO_ANCHOR: '.js-order-promo-anchor',
    ID_ORDER_SUMMARY: '#js-order-summary',
    CLASS_MINI_CART_DISCOUNTS: '.js-mini-cart-discounts',
    CLASS_RESERVE_SELECT_STORE: '.js-reserve-select_store',
    CLASS_STORE_NAME: '.reserve-delivery__store-name',
    CLASS_MY_ACCOUNT_CONTENT: '.js-myaccount-info_content',

    ID_CART_DELIVERY_TAB_DELIVERY: '#delivery-type-selector-delivery',
    ID_CART_DELIVERY_TAB_COLLECT: '#delivery-type-selector-collect',
    ID_CART_DELIVERY_TABS: '#deliveryTabs',
    ID_CART_DELIVERY_TABS_MOCK: '#deliveryTabsMock',
    ID_CART_DELIVERY_TABS_SECOND_MOCK: '#deliveryTabsSecondMock',

    CLASS_TAB_TITLE: '.js-tab-title',
    CLASS_STICKY_TABS: '.js-sticky-tabs',
    CLASS_CART_BUTTON_SUBMIT: '.js-cart-action-submit',
    CLASS_CART_BUTTON_REMOVE_UNAVAILABLE: '.js-cart-action-remove-unavailable',
    CLASS_CART_DELIVERY_TABS: '.js-delivery-tabs',
    CLASS_CHANGE_DELIVERY_YES: '.js-change-delivery-yes',
    CLASS_CHANGE_DELIVERY_NO: '.js-change-delivery-no',
    CLASS_HEADER_MIDDLE: '.js-header-middle',

    CLASS_CONFIRM_CHECKOUT_YES: '.js-confirm-checkout-yes',
    CLASS_CONFIRM_CHECKOUT_NO: '.js-confirm-checkout-no',
    CLASS_CHECKOUT_FORM: '.js_cart_submit_form',

    KEY_SCROLL_LOCAL_STORAGE: 'scrollpos',
    LS_KEY_PRODUCTS_ERRORS: 'cartProductErrors'
};
