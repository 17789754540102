const cssconstants = require('../../cssconstants');
var DISABLED = cssconstants.DISABLED;

var JSConstants = require('../constants');
var store;
var basketQuotaRestriction = 1000;

var inputEvent = function () {
    var $input = $(this);
    var productInfo = $input.data('productInfo');
    $(JSConstants.CLASS_QUANTITY_ITEM_ERROR).hide();

    var value = Number($input.val());
    store.state.quantityValue = value;

    var quantityLimit = productInfo.quantityLimit;
    var totalInStore = productInfo.totalInStore;

    var $containerParent = $input.closest(
        JSConstants.CLASS_QUANTITY_STORE_CONTAINER
    );

    $containerParent.removeClass(JSConstants.STR_CLASS_ITEM_NOT_AVAILIABLE);

    var $errorElmQuantity = $containerParent.find(JSConstants.CLASS_QUANTITY_LIMIT)[0];

    var $errorElmStore = $containerParent.find(JSConstants.CLASS_STORE_LIMIT)[0];

    var $errorProductStore = $containerParent.find(JSConstants.CLASS_PRODUCT_LIMIT)[0];

    var $incrementBt = $containerParent.find(JSConstants.CLASS_QUANTITY_INC)[0];

    var $decrementBt = $containerParent.find(JSConstants.CLASS_QUANTITY_DECR)[0];

    $($incrementBt).removeClass(DISABLED);
    $($decrementBt).removeClass(DISABLED);
    $input.removeClass(DISABLED);

    if (value <= 0) {
        $input.val(1);
        store.state.quantityValue = 1;
        $($decrementBt).addClass(DISABLED);
    }

    var isQuantityLimitQuota = !quantityLimit || (quantityLimit && quantityLimit > basketQuotaRestriction);
    if (value >= basketQuotaRestriction && basketQuotaRestriction < totalInStore && isQuantityLimitQuota) {
        $input.val(basketQuotaRestriction);
        renderErrorQuantity($errorProductStore, basketQuotaRestriction);
        $($incrementBt).addClass(DISABLED);
        store.state.quantityValue = basketQuotaRestriction;
        return;
    }

    if (quantityLimit) {
        if (value >= quantityLimit && quantityLimit < totalInStore) {
            $input.val(quantityLimit);
            renderErrorQuantity($errorElmQuantity, quantityLimit);
            $($incrementBt).addClass(DISABLED);
            store.state.quantityValue = quantityLimit;
            return;
        }
    }

    if (value >= totalInStore) {
        $input.val(totalInStore);
        renderErrorQuantity($errorElmStore, totalInStore);
        $($incrementBt).addClass(DISABLED);
        store.state.quantityValue = totalInStore;
        return;
    }
};

var increment = function (e) {
    var $containerParent = $(this).closest(
        JSConstants.CLASS_QUANTITY_STORE_CONTAINER
    );

    $containerParent.removeClass(JSConstants.STR_CLASS_ITEM_NOT_AVAILIABLE);

    var $input = $containerParent.find(
        JSConstants.CLASS_QUANTITY_STORE_INPUT
    )[0];

    var $errorElmQuantity = $containerParent.find(
        JSConstants.CLASS_QUANTITY_LIMIT
    )[0];

    var $errorProductStore = $containerParent.find(JSConstants.CLASS_PRODUCT_LIMIT)[0];

    var $errorElmStore = $containerParent.find(JSConstants.CLASS_STORE_LIMIT)[0];

    var $decrementBt = $containerParent.find(JSConstants.CLASS_QUANTITY_DECR)[0];

    $(this).removeClass(DISABLED);
    $($decrementBt).removeClass(DISABLED);
    $(JSConstants.CLASS_QUANTITY_ITEM_ERROR).hide();

    var productInfo = JSON.parse($input.dataset.productInfo);
    var quantityVal = Number($input.value);
    var quantityLimit = productInfo.quantityLimit;
    var totalInStore = productInfo.totalInStore;

    quantityVal = quantityVal + 1;

    var limit = quantityLimit || totalInStore;

    if (quantityVal >= basketQuotaRestriction) {
        quantityVal = basketQuotaRestriction;
        renderErrorQuantity($errorProductStore, basketQuotaRestriction);
        $(this).addClass(DISABLED);
        store.state.quantityValue = basketQuotaRestriction;
    }

    if (limit <= quantityVal) {
        quantityVal = limit;

        if (quantityLimit) {
            renderErrorQuantity($errorElmQuantity, limit);
        } else if (totalInStore) {
            renderErrorQuantity($errorElmStore, limit);
        }
        $(this).addClass(DISABLED);
        store.state.quantityValue = limit;
    }

    $($input).val(quantityVal);
    store.state.quantityValue = quantityVal;
};

var decrement = function (e) {
    var $containerParent = $(this).closest(
        JSConstants.CLASS_QUANTITY_STORE_CONTAINER
    );

    $containerParent.removeClass(JSConstants.STR_CLASS_ITEM_NOT_AVAILIABLE);

    var $incrementBt = $containerParent.find(JSConstants.CLASS_QUANTITY_INC)[0];

    var $input = $containerParent.find(JSConstants.CLASS_QUANTITY_STORE_INPUT)[0];

    $($incrementBt).removeClass(DISABLED);
    $(this).removeClass(DISABLED);
    $(JSConstants.CLASS_QUANTITY_ITEM_ERROR).hide();

    var quantityVal = Number($input.value);

    quantityVal = quantityVal - 1;

    if (quantityVal < 1) {
        quantityVal = 1;
        $(this).addClass(DISABLED);
    }

    $($input).val(quantityVal);
    store.state.quantityValue = quantityVal;
};

function initListener() {
    $(JSConstants.CLASS_QUANTITY_ITEM_ERROR).hide();
    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).on(
        'input',
        JSConstants.CLASS_QUANTITY_STORE_INPUT,
        inputEvent
    );

    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).on(
        'click',
        JSConstants.CLASS_QUANTITY_DECR,
        decrement
    );

    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).on(
        'click',
        JSConstants.CLASS_QUANTITY_INC,
        increment
    );

}

function removeEventListeners() {
    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).off(
        'input',
        JSConstants.CLASS_QUANTITY_STORE_SELECT,
        inputEvent
    );

    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).off(
        'click',
        JSConstants.CLASS_QUANTITY_DECR,
        decrement
    );

    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).off(
        'click',
        JSConstants.CLASS_QUANTITY_INC,
        increment
    );
}

function renderErrorQuantity(elm, quantity) {
    var $containerParent = $(elm).closest(
        JSConstants.CLASS_QUANTITY_STORE_CONTAINER
    );

    $containerParent.addClass(JSConstants.STR_CLASS_ITEM_NOT_AVAILIABLE);

    var error = $(elm).data('error');
    var data = {
        0: quantity,
    };

    var str = error.replace(/\{(.*?)\}/g, function (i, match) {
        return data[match];
    });

    $(elm).text(str);
    $(elm).show();
}

module.exports = function Quantity(_store) {
    store = _store;

    this.init = function() {
        removeEventListeners();
        initListener();
    };
    this.destroy = function() {
        removeEventListeners();
    };
};
