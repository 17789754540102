'use strict';

var _ = require('lodash'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    sessionStorage = require('_core_ext/storage'),
    dialog = require('_core_ext/dialog');

var perimeterx = require('_core_ext/trackers/perimeterx');
var bonusProductsView = require('_core_ext/bonus-products/bonus-products-view'),
    topnotification = require('_core_ext/pages/topnotification'),
    JSConstants = require('_core_ext/pages/product/constants'),
    formPrepare = require('_core_ext/pages/checkout/formPrepare');

var initialized = false;
/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
        $qty = $form.find('input[name="Quantity"]'),
        isWishlistPage = pageContext.ns === 'wishlist',
        url = isWishlistPage ? Urls.addProductFromWishlist : Urls.addProduct;

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    generateKitRequiredProducts();

    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(url),
        headers: {
            'X-PERIMETER-TYPE': 'json',
        },
        data: $form.serialize()
    })).then(function (response) {

        if (response.error) {
            // handle error in the response
            throw new Error(response.error);
        }

        return {
            success: true,
            handled: false,
            html: response
        };
    }).catch(function (response) {
        var handled = perimeterx.handleResponce(response);

        return {
            handled: handled,
            success: false,
            html: response.responseText || ''
        };
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e, callback) {
    e.preventDefault();
    var $addBtn = $(this);

    $addBtn.attr('disabled','disabled');

    var $addBtnText = $addBtn.find('.js-add_text');
    var $addedBtnText = $addBtn.find('.js-added_text');
    var $form = $addBtn.closest('form');
    var productId = $form.find('input[name="pid"]').val();
    var resTotalQty = parseInt($form.find('input[name="Quantity"]').val());

    addItemToCart($form).then(function (response) {
        var $response = $(response.html),
            $uuid = $form.find('input[name="uuid"]');

        if (!response.success && response.handled) {
            return;
        } else if (($uuid.length > 0 && $uuid.val().length > 0) || !response || pageContext.ns === 'cart') {
            page.refresh();
        } else {
            var $responceMiniCart = $response.filter(JSConstants.CLASS_MINI_CART);
            var $errorMessage = $responceMiniCart.find(JSConstants.CLASS_ERROR_MESSAGE);
            var $respQuantity;

            var $productItem = $responceMiniCart
                .find(JSConstants.CLASS_MINI_CART_PRODUCT + '[data-product-id="' + productId + '"]');

            if ($productItem.length > 0) {
                $respQuantity = $productItem.find('input[name="Quantity"]');
            } else {
                $respQuantity = $responceMiniCart.find('input[name="Quantity"]');
            }

            var isAddToCart = $errorMessage.length ? $errorMessage.data('add-to-cart') : false;
            var availableQuantity = parseInt($respQuantity.data('available'));
            var resAddedQty = parseInt($errorMessage.data('posibly-added'));

            if (isNaN(availableQuantity)) { availableQuantity = 0; }
            if (isNaN(resAddedQty)) { resAddedQty = -1; }
            if (isNaN(resTotalQty)) { resTotalQty = 1; }

            // Notification logic block
            if ($errorMessage.length && !isAddToCart) {
                var errorMessage = $errorMessage.text(),
                    errorLevel = $errorMessage.data('errorLevel');

                $(JSConstants.CLASS_ERROR_BLOCK).show().text(errorMessage);

                if (errorLevel === 'stock') {
                    topnotification.showOutStock(availableQuantity);
                } else if (errorLevel === 'quota') {
                    topnotification.showMessage(errorMessage);
                }

                $addBtn.removeAttr('disabled');
            } else {
                topnotification.showAddToCard(
                    (resAddedQty && resAddedQty >= 0) ? resAddedQty : resTotalQty
                );
                $addBtnText.toggleClass('hidden', true);
                $addedBtnText.toggleClass('hidden', false);
                $addBtn.attr('disabled','disabled').addClass('m-added');
                setTimeout(function () {
                    $addBtnText.toggleClass('hidden', false);
                    $addedBtnText.toggleClass('hidden', true);
                    $addBtn.removeAttr('disabled').removeClass('m-added');
                }, 3000);
            }

            $(document).trigger('product.addedToCart', [$form]);

            // do not close quickview if adding individual item that is part of product set
            if (!$(this).hasClass('sub-product-item')) {
                dialog.closeSafe();
            }

            if (!$errorMessage.length || isAddToCart) {
                app.components.get('minicart').show(response.html);
                callTradeDialog(productId);
            }
        }
        if (SitePreferences.MONETATE_ENABLED) {
            window.initMonetateTracking();
        }
        return response.html;
    }.bind(this)).then(callback ? callback.bind(this) : null);
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            app.components.get('minicart').show(responses[responses.length - 1]);
        });
};

/**
 * @description Check dialog show requirements
 */
function callTradeDialog(productId) {
    var showPropName = 'isDialogShown',
        showed = sessionStorage.getItem(showPropName),
        showTrade = SitePreferences.SHOW_TRADE_DIALOG && !User.AUTHENTICATED;

    if (showTrade && !showed) {
        showTradeDialog(250, productId);
        sessionStorage.setItem(showPropName, true);
    }
}

/**
 * @description Show Trade card dialog
 */
function showTradeDialog(showDelay, productId) {
    if (!bonusProductsView.dialogOpened()) {
        setTimeout(function() {
            if (!bonusProductsView.dialogOpened()) {
                dialog.open({
                    url: Urls.tradeCardDialog,
                    data: {
                        pid: productId
                    },
                    options: {
                        modal: false,
                        maxWidth: 400,
                        dialogClass: 'ui-dialog_tradecard no-titlebar',
                        open: function() {
                            $('.ui-dialog-titlebar-close').hide();
                        }
                    },
                    callback: function () {
                        dialog.$container.one('click', JSConstants.CLASS_CLOSE_DIALOG, function() {
                            dialog.closeSafe();
                        });
                    }
                });
            }
        }, showDelay || 500);
    }
}

function generateKitRequiredProducts() {
    if (!JSConstants.CLASS_KITREQUIRED.length) {
        return;
    }

    var selected = [];
    $(JSConstants.CLASS_REQUIRED_PRODUCT_CHECKBOX + ':checked').each(function() {
        var $this = $(this);
        if ($this.data('type') !== 'master') {
            selected.push($this.val());
        }
    });

    $(JSConstants.CLASS_KITREQUIRED_PRODUCTS).val(selected.join());
}

/**
 * @function
 * @description Binds the click event to a given target for the js-add_to_cart handling
 */
module.exports = function (callback) {
    $('.js-add_to_cart[disabled]').attr('title', $('.availability-msg').text());

    if (initialized) {
        return;
    }

    var addToCartClasses = [JSConstants.CLASS_ADD_TO_CART, '.js-add_to_cart'];

    $(document)
        .on('click', addToCartClasses.join(','), function (e) {
            addToCart.call(this, e, callback);
        })
        .on('click', '#add-all-to-cart', addAllToCart)
        .on('submit', JSConstants.CLASS_PDP_FORM, function(e) {
            e.preventDefault();
            var $addBtn = $(this).find(addToCartClasses.join(','));

            if ($addBtn.length) {
                addToCart.call($addBtn.get(0), e, callback);
            }
        });

    if (!$(JSConstants.CLASS_ADD_TO_CART).hasClass(JSConstants.CLASS_ADD_TO_CART_DISABLED)) {
        formPrepare.init({
            formSelector: JSConstants.CLASS_PDP_FORM,
            continueSelector: JSConstants.CLASS_ADD_TO_CART
        });
    }
    initialized = true;
};
