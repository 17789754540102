'use strict';

var qs = require('qs'),
    url = require('url'),
    _ = require('lodash');

var util = require('_core_ext/util'),
    layout = require('_core_ext/layout'),
    carousel = require('_core_ext/carousel'),
    JSCommonConstants = require('_core_ext/constants'),
    JSConstants = require('_core_ext/pages/product/constants');

var initialized = false,
    $cache = {}, $zoom = {};

function initializeCache() {
    $cache.document = $(document);
    $cache.window = $(window);
    $cache.imagePosition = $(JSConstants.CLASS_MAIN_IMAGE_WRAPPER).length > 0 ? $(JSConstants.CLASS_MAIN_IMAGE_WRAPPER).offset().top : 0;
    $cache.heightLimit = $(JSConstants.CLASS_PRODUCT_DATA).outerHeight();
    $cache.layoutPadding = 20;
    $cache.stickyHeaderHeight = $(JSCommonConstants.CLASS_STICKY_HEADER_MOBILE).outerHeight() + $cache.layoutPadding;
    $cache.isOS = util.isIOS();
}

function updatePinButton (imageUrl) {
    var newUrl = imageUrl,
        $pinButton = $(JSConstants.CLASS_PDP_SOCIAL_ICON + '[data-share=pinterest]');

    if (!$pinButton.length) {
        return;
    }

    if (!imageUrl) {
        newUrl = $(JSConstants.CLASS_MAIN_IMAGE).attr('src');
    }

    var href = url.parse($pinButton.attr('href'));
    var query = qs.parse(href.query);
    query.media = url.resolve(window.location.href, newUrl);
    query.url = window.location.href;

    var newHref = url.format(_.extend({}, href, {
        query: query, // query is used only if search is absent
        search: qs.stringify(query)
    }));

    $pinButton.attr('href', newHref);
}

function postVideoMessage(action) {
    var $iframe = $(JSConstants.ID_VIDEO_PLAYER);
    if ($iframe.length) {
        if (~$iframe.attr('src').indexOf('youtube')) {
            $iframe.get(0).contentWindow.postMessage('{"event":"command","func":"' + action + 'Video","args":""}', '*');
        } else {
            $iframe.get(0).contentWindow.postMessage('{ "method": "' + action + '" }', Urls.vimeoPlayer);
        }
    }
}

function initCarousels(callback) {
    carousel.initCarousels(JSConstants.CLASS_CAROUSEL, function () {

        if (typeof callback === 'function') {
            // TODO: Investigate - InitCarousels callback return invalid execution time
            setTimeout(callback, 50);
        }
    });

    $(JSConstants.CLASS_MAIN_CAROUSEL).slick('resize');
    $(JSConstants.CLASS_ALT_CAROUSEL).slick('resize');
}

function initializeEvents() {
    $cache.document
        .on('beforeChange', JSConstants.CLASS_MAIN_CAROUSEL, function() {
            var $iframe = $(JSConstants.ID_VIDEO_PLAYER);

            if (!$iframe.length) {
                var placeholder = $(this).find(JSConstants.CLASS_VIDEO_PLACEHOLDER);
                var videoSrc = placeholder.data('vimeo-src');
                var iframe = $('<iframe>', {
                    id: 'js-videoPlayer',
                    class: 'pdp-image_video',
                    src: videoSrc,
                    frameborder: '0',
                    allow: 'autoplay; encrypted-media',
                    allowfullscreen: ''
                });

                placeholder.html(iframe);
            }

            postVideoMessage('pause');
            $(JSConstants.CLASS_VIDEO_IFRAME_OVERLAY).removeClass(JSConstants.CLASS_PLAYING_VIDEO);
        })

        .on('setPosition', JSConstants.CLASS_ALT_CAROUSEL, function() {
            var $this = $(this);

            if ($this.find('> button').length === 0) {
                $this.find('.slick-track').css('left', '0');
            }
        })

        .on('afterChange', JSConstants.CLASS_MAIN_CAROUSEL, function(e, slick, currentSlide) {
            var $image = $(this).find(JSConstants.CLASS_MAIN_IMAGE).eq(currentSlide);
            if ($image.length) {
                updatePinButton($image.attr('src'));
            }
        })

        .on('click', JSConstants.CLASS_VIDEO_IFRAME_OVERLAY, function () {
            var $iframe = $(JSConstants.ID_VIDEO_PLAYER);
            if ($iframe.length) {
                if ($(this).hasClass(JSConstants.CLASS_PLAYING_VIDEO)) {
                    postVideoMessage('pause');
                } else {
                    postVideoMessage('play');
                }
                $(this).toggleClass(JSConstants.CLASS_PLAYING_VIDEO);
            }
        });
}

function initializeMobileEvents() {
    if ($cache.isOS && layout.isMobile()) {

        $cache.document
            .on('click', JSConstants.CLASS_MAIN_IMAGE_LINK, function(e) {
                if (!$zoom.focused) {
                    e.preventDefault();
                    var $this = $(this),
                        $overlay = $this.find($zoom.overlay);

                    $overlay.css('pointer-events', 'auto');
                    $zoom.focused = true;
                }
            })
            .on('click touchstart', function(e) {
                var $target = $(e.target),
                    $image = $(JSConstants.CLASS_MAIN_IMAGE_LINK),
                    isImage = $target.is($image) || $target.parents(JSConstants.CLASS_MAIN_IMAGE_LINK).length > 0;

                if (!isImage && $zoom.focused) {
                    var $overlay = $image.find($zoom.overlay);

                    $overlay.css('pointer-events', 'none');
                    $zoom.focused = false;
                }
            });
    }
}

module.exports = {
    init: function() {
        if (initialized) {
            return;
        }

        initializeCache();

        initializeEvents();
        initializeMobileEvents();
        updatePinButton();
        initialized = true;
    },
    initCarousels: initCarousels,
    updatePinButton: updatePinButton,
};
