'use strict';

var perimeterx = require('_core_ext/trackers/perimeterx');
var JSConstants = require('_core_ext/pages/account/constants'),
    CSSConstants = require('_core_ext/cssconstants');

function isAgreement() {
    var $agreeCbox = $(JSConstants.CLASS_AGREEMENT);
    return $agreeCbox.length > 0 && $agreeCbox.is(':checked');
}
function isRadioSelected() {
    var $radios = $(JSConstants.CLASS_PROFESIONAL_RADIO+':checked');
    return $radios.length > 0;
}

function validateArgeement() {
    var valid = isAgreement() || false;
    var $errorA = $(JSConstants.CLASS_AGREEMENT+'-'+CSSConstants.ERROR);

    setFieldMsg(valid, Resources.EMAIL_AGREEMENT_INVALID, $errorA);
    return valid;
}

function validateRadio() {
    var valid = isRadioSelected() || false;
    var $errorR = $(JSConstants.CLASS_PROFESIONAL_RADIO+'-'+CSSConstants.ERROR);

    setFieldMsg(valid, Resources.EMAIL_RADIO_INVALID, $errorR);
    return valid;
}

function setFieldMsg(valid, msg, $elem) {
    if (!valid) {
        $elem.text(msg).show();
    } else {
        $elem.text('').hide();
    }
}

function initializeEvents() {
    $(document)
        .on('submit', JSConstants.CLASS_EMAIL_FORM, function(e) {
            e.preventDefault();
            var $this = $(this);

            if (!validateRadio() || !validateArgeement()) {
                return;
            }

            $.ajax({
                type: 'POST',
                dataType: 'json',
                url: $this.attr('action'),
                data: $this.serialize() + '&' + $this.find('button[type=submit]').attr('name') + '=true'
            }).then(function(response) {
                if (response && response.success) {
                    $(JSConstants.CLASS_EMAIL_FORM).hide();
                    $(JSConstants.CLASS_EMAIL_FORM_MESSAGE)
                        .addClass(CSSConstants.IS_ACTIVE_SUBSCRIPTION)
                        .text(Resources.SUBSCRIPTION_SUCCESS);
                } else {
                    location.reload();
                }
            }).fail(function(response) {
                if (!perimeterx.handleResponce(response)) {
                    location.reload();
                }
            });
        });
}

module.exports = {
    init: function() {
        if (!SitePreferences.SUBSCRIPTION_ENABLED) {
            return;
        }
        initializeEvents();
    }
};