'use strict';

var dialog = require('_core_ext/dialog'),
    sessionStorage = require('_core_ext/storage'),
    addToCart = require('_core_ext/pages/product/addToCart'),
    JSConstants = require('_core_ext/constants');

var initialized = false;

function initializeEvents() {
    $('#homepage-slider')
        // responsive slides
        .on('jcarousel:create jcarousel:reload', function () {
            var element = $(this),
                width = element.innerWidth();
            element.jcarousel('items').css('width', width + 'px');
        })
        .jcarousel({
            wrap: 'circular'
        })
        .jcarouselAutoscroll({
            interval: 5000
        });
    $('#homepage-slider .jcarousel-control')
        .on('jcarouselpagination:active', 'a', function () {
            $(this).addClass('active');
        })
        .on('jcarouselpagination:inactive', 'a', function () {
            $(this).removeClass('active');
        })
        .jcarouselPagination({
            item: function (page) {
                return '<a href="#' + page + '">' + page + '</a>';
            }
        });

    $('#vertical-carousel')
        .jcarousel({
            vertical: true
        })
        .jcarouselAutoscroll({
            interval: 5000
        });
    $('#vertical-carousel .jcarousel-prev')
        .on('jcarouselcontrol:active', function () {
            $(this).removeClass('inactive');
        })
        .on('jcarouselcontrol:inactive', function () {
            $(this).addClass('inactive');
        })
        .jcarouselControl({
            target: '-=1'
        });

    $('#vertical-carousel .jcarousel-next')
        .on('jcarouselcontrol:active', function () {
            $(this).removeClass('inactive');
        })
        .on('jcarouselcontrol:inactive', function () {
            $(this).addClass('inactive');
        })
        .jcarouselControl({
            target: '+=1'
        });

    $('.js-recommendation-input').on('click', function() {
        var id = $(this).attr('id').split('_')[0];
        $(this).closest('.js-recommendation').removeClass (function (index, className) {
            return (className.match (/(^|\s)carousel-\S+/g) || []).join(' ');
        });
        $(this).closest('.js-recommendation').addClass(id);
    });

    (function () {
        var $referenceNode = $(document.body).find('img').first()[0];
        var webbeaconImg = document.createElement('img');
        webbeaconImg.src = SitePreferences.WEBBEACON_URL || '';
        webbeaconImg.style.position = 'absolute';
        webbeaconImg.referrerPolicy = 'no-referrer-when-downgrade';
        var parentNode = $referenceNode.parentNode;
        parentNode.insertBefore(webbeaconImg, $referenceNode.nextSibling);
    })();
}

function showRedirectPopUp($redirectWrapper, showDelay) {
    var assetLink = $redirectWrapper.data('mainpopup-assetlink');

    setTimeout(function() {
        dialog.open({
            url: assetLink,
            options: {
                modal: true,
                maxWidth: 400,
                closeOnEscape: false,
                dialogClass: 'ui-dialog_redirect-mainpopup no-close'
            },
            callback: function () {
                dialog.$container.one('click', JSConstants.CLASS_CLOSE_DIALOG, function() {
                    dialog.close();
                });
            }
        });
    }, showDelay || 500);
}

function initializeDOM() {
    var $redirectWrapper = $(JSConstants.ID_REDIRECT_MAINPOPUP);
    var assetId = $redirectWrapper.data('assetid');

    if ($redirectWrapper.length) {
        var showPopUpPropName = 'isMainPopUpShown-' + assetId,
            isMainPopUpShown = !!sessionStorage.getItem(showPopUpPropName);

        if (!isMainPopUpShown) {
            showRedirectPopUp($redirectWrapper, 250);
            sessionStorage.setItem(showPopUpPropName, true);
        }
    }
}

exports.init = function () {
    if (initialized) {
        return;
    }

    addToCart();
    initializeDOM();
    initializeEvents();

    initialized = true;
};
