'use strict';

var JSConstants = require('../constants'),
    util = require('_core_ext/util'),
    progress = require('_core_ext/progress'),
    perimeterx = require('_core_ext/trackers/perimeterx'),
    API = require('../utils/api');

var store = null;

function initDOM() {
    $('<div/>')
        .attr({
            id: JSConstants.ID_STORE_DIALOG.slice(1),
            class: JSConstants.CLASS_WRAPPER_STORE_DIALOG.split('.').join(''),
        })
        .appendTo(document.body);
}

/**
 * Makes API call and receives store details dialog html content.
 * @param storeId
 * @param distance
 */
function renderStoreDetails(storeId, distance) {
    if (store.state.name !== 'storeDetails') {
        store.history.statesPush();
        store.quantity.destroy();
    }

    var storeDetailParams = {
        storeId: storeId,
    };

    if (store.state.selectedProductID) {
        storeDetailParams.pid = store.state.selectedProductID;
    }
    progress.show(store.dialog.$container);

    API.getJson({
        url: util.appendParamsToUrl(
            Urls.storeInfoDialogJSON,
            storeDetailParams
        ),
        callback: function (data) {
            progress.hide();
            if (data && data.success) {
                renderStoreDetailsContent(data.storeInfo);
                $(JSConstants.CLASS_STORE_DISTANCE).text(distance);
                $(JSConstants.CLASS_STORE_DETAILS).remove();
            }
        },
    });
}

/**
 * Shows store details window
 * @TODO Move to another file
 * @param html
 */
function renderStoreDetailsContent(storeInfo) {
    store.state.name = 'storeDetails';

    store.state.MainMap.setMapZoom(14);
    store.state.selectedStoreLocation = new google.maps.LatLng(storeInfo.latitude, storeInfo.longitude);

    store.state.controlesState.values(
        JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT,
        $(JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT).val()
    );
    $(JSConstants.CLASS_STORE_SELECT).hide();
    $(JSConstants.CLASS_STORE_DETAILS).remove();
    var checkAnotherStoreButton = Handlebars.templates.RESERVE_storeAnotherStoreButton();
    $(JSConstants.CLASS_QUANTITY_STORE_CONTAINER).after(
        checkAnotherStoreButton
    );
    $(JSConstants.CLASS_WRAPPER_SELECT_DIALOG).append(
        Handlebars.templates.RESERVE_storeInfoDialog({
            store: storeInfo,
            id: storeInfo.id,
            isStoresFacilities:
                storeInfo.custom.isNewCCEnabled ||
                storeInfo.custom.isBarbershop ||
                storeInfo.custom.isEquipmentCentre ||
                storeInfo.custom.isTrainingRoom,
            isInStoreInfo:
                !storeInfo.availability.storeAvailableForRC ||
                storeInfo.availability.productUnavailable,
            storePhone: storeInfo.phone !== null ? storeInfo.phone : '',
            storeAvailableForRC: storeInfo.availability.storeAvailableForRC,
            isProductSpecific: storeInfo.availability.isProductSpecific,
            storeLabel: storeInfo.availability.storeLabel,
            productInfo: storeInfo.availability.productInfo,
            isCartPage: store.state.isCartPage,
            isPDPPage: store.state.isPDPPage,
            isPLPPage: store.state.isPLPPage,
            isSelectDefaltStorePage: store.state.isSelectDefaltStorePage,
            isNotProductDialog: !store.state.isPDPPage && !store.state.isPLPPage,
            isStorePreview: store.state.isStorePreview
        })
    );

    store.quantity.init();
    store.events.publish('createStoreDetailsMap');
}

function onSlideDownClick() {
    $(JSConstants.CLASS_STORE_SELECT + ':visible').slideUp(600, () => {
        store.dialog.close();
    });
}

function onGoBackClick() {
    $(JSConstants.CLASS_STORES_SELECT).closest(JSConstants.CLASS_STORE_SELECT).remove();
    var state = store.history.statesPop();
    store.state.name = state.name;
    store.events.publish('restore:' + state.name, state);
    store.events.publish('resetMainMap');
}

function fillAddressField(address) {
    $(JSConstants.CLASS_RESERVE_COLLECT_ADDRESS_INPUT).val(
        address.formatted_address
    );
    store.events.publish('renderNearestStores');
}

function getStoreIdFromListDataAttr($context) {
    return $($context)
        .closest(JSConstants.CLASS_STORE_DETAILS).attr('data-storeid') ||
        $($context)
        .closest(JSConstants.CLASS_STORE_SELECT).attr('data-storeid');
}

/**
 * Gets store distance from a data attribute in order to pass it to the store details page.
 * @param $context
 * @returns {jQuery}
 */
function getStoreDistanceFromListDataAttr($context) {
    return $($context)
        .closest(JSConstants.CLASS_STORE_DETAILS)
        .attr('data-distance');
}

function onStoreDetailsClick() {
    var distance = getStoreDistanceFromListDataAttr(this);
    var storeId = getStoreIdFromListDataAttr(this);
    $(JSConstants.CLASS_STORE_LIST_CONTAINER).remove();
    window.history.pushState({state: 1}, '', window.location.href);
    renderStoreDetails(storeId, distance);
}

function onStoreSelectClick() {
    var storeId = getStoreIdFromListDataAttr(this);
    selectStoreAPICall(storeId);
}

/**
 * Selects default store and refreshes page on success
 * @param storeId
 */
 function selectDefaultStoreAPICall() {
    progress.show();
    var storeId = getStoreIdFromListDataAttr(this);
    var requestData = {};
    requestData[store.state.csrfData.name] = store.state.csrfData.token;

    $.ajax({
        type: 'POST',
        url: util.appendParamsToUrl(Urls.selectDefaultStore, {
            storeId: storeId,
        }),
        data: requestData,
    }).done(function (response) {
        progress.hide();
        response = typeof response === 'string' ? response.trim() : response;
        $(JSConstants.CLASS_DEFAULT_STORE_CONTAINER).html(response);
        var componentsConfig = require('_core_ext/ns-dependencies/accountDepandencies');
        var selectDefaultStore = componentsConfig.references.selectDefaultStore;
        selectDefaultStore.init();
        store.dialog.close();
    })
    .fail(function (response) {
        perimeterx.handleResponce(response);
    });
}

/**
 * Selects store and refreshes page on success
 * @param storeId
 */
function selectStoreAPICall(storeId) {
    progress.show();
    var requestData = {};
    requestData[store.state.csrfData.name] = store.state.csrfData.token;

    $.ajax({
        type: 'POST',
        url: util.appendParamsToUrl(Urls.selectStoreForRC, {
            storeId: storeId,
        }),
        dataType: 'json',
        data: requestData,
    }).done(function (data) {
        progress.hide();
        if (data && data.success) {
            onStoreSelect(storeId);
            store.dialog.close();
            if (sessionStorage.getItem('fullStoreList')) {
                sessionStorage.removeItem('fullStoreList');
            }
        }
    })
    .fail(function (response) {
        perimeterx.handleResponce(response);
    });
}

function onStoreSelect(storeId) {
    var storeItem = store.state.storeList.find(
        function (storeItem) { return storeItem.id === storeId; }
    );

    $(document).trigger('clickCollect:SelectedStore', [
        {
            isUpdate: storeId !== store.state.selectedStoreId,
            store: storeItem,
            product: store.state.selectedProductID,
        },
    ]);
}

module.exports = {
    init: function (_store) {
        initDOM();
        store = _store;
        $(document)
            .on('click', JSConstants.CLASS_SELECT_STORE,
                onStoreSelectClick
            ).on('click', JSConstants.CLASS_SELECT_DEFAULT_STORE,
                selectDefaultStoreAPICall
            ).on('click', JSConstants.CLASS_STORE_DETAILS_BUTTON,
                onStoreDetailsClick
            ).on('click', JSConstants.CLASS_JS_GO_BACK,
                onGoBackClick
            ).on('click tap touchmove', JSConstants.CLASS_JS_SLIDE_DOWN,
                onSlideDownClick
            );

        store.events.subscribe('renderStoreDetails', function(args) {
            renderStoreDetails.apply(null,args);
        });

        store.events.subscribe('fillAddressField', function(args) {
            fillAddressField.apply(null,args);
        });

        store.events.subscribe('restore:anotherStore', function(data) {
            $(JSConstants.CLASS_STORE_LIST_CONTAINER).remove();
            data.controlesState.domSelectors.forEach(function (selector) {
                $(selector).append(data.controlesState.dom(selector));
            });

            data.controlesState.valuesSelectors.forEach(function (selector) {
                $(selector).val(data.controlesState.values(selector));
            });
        });
    },
};
