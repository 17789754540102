/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('../jquery-ext')();
require('../jquery-ext')();
require('_core/cookieprivacy')();

var app = window.app = {
    init: function () {
        this.components.extendConfig(require('../ns-dependencies/storefrontDepandencies')).initAll();
    },
    'page' : require('_core/page'),
    'components' : require('../components')
};

// initialize app
$(document).ready(function () {
    app.init();
});